import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Dropdown, Grid, Icon, Popup, Segment, Table } from 'semantic-ui-react'
import EmptyGridMessage from '../../../shared/EmptyGridMessage';
import GridFooter from '../../../shared/GridFooter';
import { useMediaQuery } from 'react-responsive';
import { createGridInitialState, formatCurrency, formatFullDate, getEnumValue, getEtherscanUrl, getFullSizeWidth, getRandomNumber, handleGridNavigation, handleGridSort } from '../../../shared/utils';
import { PageParams } from '../../../app/models/common/PagedResult';
import EntityContext from '../../../app/context/entityContext';
import { LoanEscrow } from '../../../app/models/Loan/LoanEscrow';
import { useStore } from '../../../app/stores/store';
import { TransferHistory } from '../../../app/models/settings/transferHistory';
import { LoanEscrowFilter } from '../../../app/models/Loan/LoanEscrowFilter';
import { CapBlockTable } from '../../../app/models/common/CapblockTable';
import { TransferActionEnum, TransferActionParam } from '../../../app/models/settings/transfer';
import TransferAction from '../../transfer/transferAction';

interface Props {
    loanId: number,
    escrow: LoanEscrow
}

function EscrowHistory({ loanId, escrow }: Props) {
    const [loading, setLoading] = useState(false);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const { entity } = useContext(EntityContext);
    const [escrowHistory, setEscrowHistory] = useState([new TransferHistory()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const { loanStore } = useStore();

    const [openTransfer, setOpenTransfer] = useState(false);
    const [trxActionParams, setTrxActionParams] = useState(new TransferActionParam());
    const [triggerRefresh, setTriggerRefresh] = useState(0);

    const initialState: LoanEscrowFilter = createGridInitialState({
        loanId: loanId,
        entityId: entity.id,
        loanEscrowId: escrow.id,
        includeBalance: true,
        sortIndex: 'TransferDate',
        sortOrder: 'descending'
    });

    const [params, setParams] = useState(initialState);

    const loadLoanEscrows = useCallback(() => {
        setLoading(true);
        loanStore.getLoanEscrowHistory(params).then(r => {
            setTotalRecords(r.totalRecords);
            setEscrowHistory(r.data);
            setLoading(false);
        });
    }, [loanStore, params, setLoading, setEscrowHistory])


    useEffect(() => {
        loadLoanEscrows();
    }, [loadLoanEscrows, triggerRefresh]);

    const handleSort = (clickedColumn: string) => {
        if (escrowHistory.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    const getHistoryUrl = (transfer: TransferHistory) => {
        const url = getEtherscanUrl();
        return url + `tx/${transfer.transactionHash}`;
    }
    const replaceTrx = (trxHash: string, action: TransferActionEnum) => {
        setTrxActionParams(prevState => ({
            ...prevState,
            trxHash: trxHash,
            action: action
        }));
        setOpenTransfer(true);
    }
    const ActionDropdown = ({ history }: any) => {

        const shouldDisplayDropdown = () => {
            if (!history) return false;

            const senderCapblockTable = getEnumValue(CapBlockTable, history.senderCapblockTable);

            return history.statusId === 1 &&
                senderCapblockTable === CapBlockTable.loanEscrow && history.toTableKeyId === entity.id;
        };

        if (!shouldDisplayDropdown()) {
            return null;
        }

        return <Dropdown text='Actions'>
                <Dropdown.Menu>
                    <Dropdown.Item text='Speed Up' value='1' icon='rocket' onClick={() => replaceTrx(history.transactionHash, TransferActionEnum.speedUp)} />
                    <Dropdown.Item text='Cancel' value='2' icon='delete' onClick={() => replaceTrx(history.transactionHash, TransferActionEnum.cancel)} />
                </Dropdown.Menu>
            </Dropdown>;
    }

    return (
        <>
        <Segment clearing basic loading={loading}>
            <Table singleLine sortable selectable>
                {showFullSize &&
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={() => handleSort("TransferDate")} sorted={params.sortIndex === "TransferDate" && escrowHistory.length > 0 ? params.sortOrder : undefined}>Date</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("ActionType")} sorted={params.sortIndex === "ActionType" && escrowHistory.length > 0 ? params.sortOrder : undefined}>Type</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Address")} sorted={params.sortIndex === "Address" && escrowHistory.length > 0 ? params.sortOrder : undefined}>Address</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Amount")} sorted={params.sortIndex === "Amount" && escrowHistory.length > 0 ? params.sortOrder : undefined}>Amount</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("StatusID")} sorted={params.sortIndex === "StatusID" && escrowHistory.length > 0 ? params.sortOrder : undefined}>Status</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Note")} sorted={params.sortIndex === "Note" && escrowHistory.length > 0 ? params.sortOrder : undefined}>Note</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                }

                <Table.Body>
                    {!loading && escrowHistory.map((history, i) => {
                        if (showFullSize) {
                            return <Table.Row key={i}>
                                <Table.Cell>{formatFullDate(history.transferDate)}</Table.Cell>
                                <Table.Cell>{history.actionTypeDescription}</Table.Cell>
                                <Table.Cell>
                                    <Popup header='Address' position='bottom left' content={history.address} trigger={<label style={{color: '#4183c4'}}>{history.name}</label>} />
                                </Table.Cell>
                                <Table.Cell textAlign='right'>{formatCurrency(history.amount)}</Table.Cell>
                                <Table.Cell>
                                        <a href={getHistoryUrl(history)} target='_blank' rel="noreferrer">{history.statusDescription}</a>
                                </Table.Cell>
                                <Table.Cell>
                                    {history.note?.length > 30 ?
                                        <Popup header='Note' position='bottom left' content={history.note}
                                            trigger={<label style={{ color: '#4183c4' }}>{history.note.substring(0, 30)}...</label>} 
                                        />
                                        :
                                        <>{history.note}</>
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    <ActionDropdown history={history} />
                                </Table.Cell>
                            </Table.Row>
                        }
                        else {
                            return <Table.Row key={i}>
                                <Table.Cell>
                                    {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                    <Grid>
                                        <Grid.Row style={{ cursor: 'pointer' }}>
                                            <Grid.Column width={6}>
                                                <label onClick={() => handleSort("TransferDate")}>Date:</label><br />
                                                <label onClick={() => handleSort("ActionType")}>Type:</label><br />
                                                <label onClick={() => handleSort("Address")}>Address:</label><br />
                                                <label onClick={() => handleSort("Amount")}>Amount:</label><br />
                                                <label onClick={() => handleSort("StatusID")}>Status:</label><br />
                                            </Grid.Column>
                                            <Grid.Column style={{ fontWeight: 'normal' }} >
                                                <div style={{ width: '45vw' }}>
                                                    {formatFullDate(history.transferDate)}<br />
                                                    {history.actionTypeDescription}<br />
                                                    {history.name}<br />
                                                    {formatCurrency(history.amount)}<br />
                                                    {history.statusDescription === "Success" ?
                                                        <a href={getHistoryUrl(history)} target='_blank' rel="noreferrer">{history.statusDescription}</a>
                                                        :
                                                        <label>{history.statusDescription}</label>
                                                    }<br/>
                                                    <ActionDropdown history={history} />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                        }
                    })}
                    {!loading && escrowHistory.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 7 : 1} message='No escrow history available' />}
                </Table.Body>

                {!loading && escrowHistory.length > 0 &&
                    <GridFooter colSpan={showFullSize ? 7 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                }
            </Table>
        </Segment>

        {openTransfer && <TransferAction show={openTransfer} actionParams={trxActionParams}
            closeModal={refresh => {
                if (refresh) setTriggerRefresh(getRandomNumber);
                setOpenTransfer(false);
            }}
            />}
        </>
    )
}

export default EscrowHistory