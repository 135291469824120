import { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Container, Form, Header, Icon, Label, List, Segment, Table } from 'semantic-ui-react';
import { useStore } from '../../../app/stores/store';
import { toast } from 'react-toastify';
import { getFileSize, getFileIcon, maxSize, maxFiles, minSize } from '../../../shared/utils';
import { observer } from 'mobx-react-lite';
import { FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import TextInput from '../../../app/common/form/TextInput';
import SelectInput from '../../../app/common/form/SelectInput';
import EntityContext from '../../../app/context/entityContext';
import { Loan } from '../../../app/models/Loan/Loan';
import { Document } from '../../../app/models/common/Document';

interface Props {
  loan: Loan;
  setReloadDocuments: (reload: boolean) => void;
  showUploadPanel: boolean;
  setShowUploadPanel: (showPanel: boolean) => void;
}

function UploadDocuments({ loan, setReloadDocuments, showUploadPanel, setShowUploadPanel }: Props) {

  const { entity } = useContext(EntityContext);
  const [fileLimitReached, setFileLimitReached] = useState(false);
  const [validFiles, setValidFiles] = useState(new Array<Document>());

  const onDrop = useCallback((uploadedFiles: any) => {
    uploadedFiles.forEach((file: File) => {
      if (!fileLimitReached) {
        if (validFiles.findIndex((vf: Document) => vf.documentName === file.name) === -1) {
          var validFile = new Document();
          validFile.originalFileName = file.name;
          validFile.documentName = file.name;
          validFile.fileExtension = file.name.substring(file.name.lastIndexOf('.'));
          validFile.fileSizeInKb = parseFloat((file.size / 1024).toFixed(2));
          validFile.sharedWithEntityId = 0;
          validFile.documentType = 1;
          validFile.file = file;

          validFiles.push(validFile);
          setValidFiles(validFiles);

          if (validFiles.length === maxFiles) setFileLimitReached(true);
        }
      }
      else {
        setErrorMsg(`You cannot upload more than ${maxFiles} files`);
      }
    });
  }, [fileLimitReached, validFiles]);


  const dropZoneConfig = {
    maxFiles: maxFiles,
    maxSize: maxSize,
    minSize: minSize,
    multiple: true,
    accept: {
      'image/*': [],
      'text/*': [],
      'application/pdf': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': []
    },
    onDrop: onDrop
  };

  const emptyOption = { key: '', value: 0 };
  const [loading, setLoading] = useState(false);
  const { commonStore, loanStore } = useStore();
  const [documentTypes, setDocumentTypes] = useState([emptyOption]);
  const { getRootProps, getInputProps } = useDropzone(dropZoneConfig);
  const [errorMsg, setErrorMsg] = useState("");
  const loanId = loan.id;
  const entityId = entity.id;

  useEffect(() => {
    setLoading(true);

    commonStore.getDocumentTypes().then(r => {
      setDocumentTypes(r);
    })
    .finally(() => setLoading(false));

  }, [commonStore, setDocumentTypes, setLoading]);

  const removeFile = (index: number) => {
    let data = [...validFiles];
    data.splice(index, 1);
    setValidFiles(data);
    setFileLimitReached(false);
  }

  const uploadDocuments = (files: Document[]) => {
    if (files.length === 0) {
      setErrorMsg("please add at least one document to continue");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('loanId', loanId.toString());
    formData.append('entityId', entityId.toString());

    for (let i = 0; i < files.length; i++) {
      formData.append(`uploadedDocuments[${i}].originalFileName`, files[i].originalFileName);
      formData.append(`uploadedDocuments[${i}].documentName`, files[i].documentName);
      formData.append(`uploadedDocuments[${i}].fileSizeInKb`, files[i].fileSizeInKb.toString());
      formData.append(`uploadedDocuments[${i}].documentType`, files[i].documentType.toString());
      formData.append(`uploadedDocuments[${i}].file`, files[i].file);
    }

    const timeoutInSec = 1000 * 60 * 5; //5 min
    loanStore.uploadLoanDocuments(formData, timeoutInSec).then((r) => {
      setValidFiles(new Array<Document>());
      setFileLimitReached(false);
      setErrorMsg("");
      setLoading(false);
      setShowUploadPanel(false);
      toast.info("Loan Documents uploaded successfully.", { theme: "colored" });
      setReloadDocuments(true);
    }).catch(err => {
      setLoading(false);
      toast.error("There was an issue uploading documents.", { theme: "colored" });
    });
  };

  const validationSchema = yup.object().shape({
    files: yup.array().of(
      yup.object().shape({
        documentName: yup.string().required('Name is required'),
        documentType: yup.string().required('Type is required')
      })
    )
  });

  return (
    <>
      {showUploadPanel &&
        <>
          <Segment>
            <Header>Upload Loan Documents</Header>
            <List>
              <List.Item>Documents to upload should be legal, valid, and virus-free</List.Item>
              <List.Item>Each File cannot be larger than &nbsp;
                {getFileSize(maxSize)}</List.Item>
              <List.Item>Up to {maxFiles} Files are allowed to upload</List.Item>
              <List.Item>Allowed Files Types are: PDF, Word, Excel, CSV, TXT, and Image (PNG, JPG, GIF)</List.Item>
            </List>
          </Segment>
          <Segment>
            <div {...getRootProps({ className: 'dropzone' })}
              style={{
                height: 200, width: "80%", margin: 'auto',
                borderStyle: "dashed", textAlign: 'center', paddingTop: '20px',
                verticalAlign: "middle",
                cursor: (validFiles.length < maxFiles) ? "copy" : "no-drop"
              }}>
              <input type="file" {...getInputProps()}
                disabled={fileLimitReached} />
              <br /><br />
              <p>Drag and Drop up to {maxFiles} allowed documents (files) here
                <br />
                OR
                <br />
                Click in this zone to select Documents (files) from your local file system</p>
            </div>
            {errorMsg && <Label color='red'>{errorMsg}</Label>}
          </Segment>

          {validFiles?.length > 0 && <Segment>
            <h4>List of Documents To Upload:</h4>
            <Formik
              validationSchema={validationSchema}
              enableReinitialize
              initialValues={{ files: validFiles }}
              onSubmit={(values) => {
                uploadDocuments(values.files);
              }}
            >
              {({ values, handleSubmit }) => (
                <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                  <Table celled padded>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell singleLine></Table.HeaderCell>
                        <Table.HeaderCell style={{ width: "400px" }}>Name</Table.HeaderCell>
                        <Table.HeaderCell>Size</Table.HeaderCell>
                        <Table.HeaderCell>Document Type</Table.HeaderCell>
                        <Table.HeaderCell>Remove</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <FieldArray name="files"
                      render={(arrayHelpers) => (
                        <Table.Body>
                          {values.files.map((doc, i) => (
                            <Table.Row key={i}>
                              <Table.Cell textAlign='center'><i aria-hidden="true" className={"icon large " + getFileIcon(doc.fileExtension)} /></Table.Cell>
                              <Table.Cell>
                                <TextInput key={i} placeholder={''} name={`files[${i}].documentName`} maxLength={100} />
                              </Table.Cell>
                              <Table.Cell textAlign='right'>{getFileSize(doc.file.size)}</Table.Cell>
                              <Table.Cell textAlign='center'>
                                <SelectInput
                                  options={documentTypes}
                                  name={`files[${i}].documentType`} placeholder={''} />
                              </Table.Cell>
                              <Table.Cell textAlign='center'>
                                <Icon link name='delete' onClick={removeFile} />
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      )}
                    />
                  </Table>
                  <Container textAlign='right'>
                    <Button primary type='submit' loading={loading} disabled={validFiles.length === 0 || loading}>Upload Documents</Button>
                  </Container>
                </Form>
              )}
            </Formik>
          </Segment>
          }
          </>}
    </>
  )
}

export default observer(UploadDocuments);

