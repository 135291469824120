import React, { useContext } from 'react'
import { Icon, Step } from 'semantic-ui-react'
import { FormContext } from "./registrationForm";

function Stepper() {
    const { activeStepIndex } = useContext(FormContext);
    return (
        <Step.Group widths={2}>
            <Step active={activeStepIndex === 0} completed={activeStepIndex > 0}>
                <Icon name="user" />
                <Step.Content>
                    <Step.Title>User Account Details</Step.Title>
                    <Step.Description>Please complete your registration</Step.Description>
                </Step.Content>
            </Step>
        </Step.Group>
    )
}

export default Stepper