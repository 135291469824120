import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { CodeType } from "../models/verificationCode/verificationCode";

export default class VerificationCodeStore {
    constructor() {
        makeAutoObservable(this)
    }

    getCode = async (codeType: CodeType, alternatePhone?: string) => {
        return await agent.VerificationCode.getCode(codeType, alternatePhone)
    }

    verifyCode = async (code: string) => {
        return await agent.VerificationCode.verifyCode(code)
    }
}
