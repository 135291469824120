import { Button, Form, Modal } from "semantic-ui-react";
import * as Yup from 'yup';
import { Formik } from "formik";
import CurrencyInput from "../../../app/common/form/CurrencyInput";
import { LoanDrawSchedule } from "../../../app/models/Loan/LoanDrawSchedule";
import { Loan } from "../../../app/models/Loan/Loan";
import { useStore } from "../../../app/stores/store";
import { toast } from "react-toastify";
import { formatCurrency, getRandomNumber } from "../../../shared/utils";
import { useContext, useState } from "react";
import EntityContext from "../../../app/context/entityContext";
import DateInput from "../../../app/common/form/DateInput";
import { InputMasks } from "../../../shared/InputMasks";

interface RequestModelData {
    loan: Loan,
    drawSchedule: LoanDrawSchedule,
    openModal: boolean,
    ModalOpen: (showOrHide: boolean) => void;
    setReloading: (reload: number) => void;
    setParentReloading: (reload: number) => void;
}

function ProcessDrawModal(input: RequestModelData) {
    const { entity } = useContext(EntityContext);
    const { loanDrawStore } = useStore();
    const [submitting, setSubmitting] = useState(false);
    const totalDrawAmountAllowed = input.loan.totalDrawAmountAvailable;
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date();

    interface DrawRequestFormData { drawAmount: number, drawDate: Date }

    const handleDrawRequest = async (formValues: DrawRequestFormData) => {
        if (formValues.drawAmount <= totalDrawAmountAllowed) {
            setSubmitting(true);
            loanDrawStore.processLoanDraw(entity.id, input.drawSchedule.id, formValues.drawAmount, formValues.drawDate)
                .then(r => {
                    toast.success("The draw has been processed!", { theme: "colored" });
                    input.setReloading(getRandomNumber());
                    input.setParentReloading(getRandomNumber());
                    input.ModalOpen(false);
                    setSubmitting(false);
                })
                .catch(err => {
                    toast.error("There was an issue processing the loan draw.", { theme: "colored" });
                    input.ModalOpen(false);
                    setSubmitting(false);
                });
        }
    }
    return (
        <Modal
            open={input.openModal} closeOnEscape={false} closeOnDimmerClick={false} onClose={() => input.ModalOpen(false)} size="mini">
            <Modal.Header>Process Draw #{input.drawSchedule.drawNumber}</Modal.Header>
            <Modal.Content>
                <Formik
                    validationSchema={Yup.object({
                        drawAmount: Yup.number().typeError('Invalid Draw Amount')
                            .transform((_value, originalValue) => parseFloat(originalValue))
                            .required('Amount is required')
                            .positive('Amount must be greater than 0')
                            .test(
                                'match',
                                `Draw Amount cannot be more than ${formatCurrency(totalDrawAmountAllowed)}`,
                                function (value) { return value! <= totalDrawAmountAllowed; }
                            ),
                        drawDate: Yup
                            .date()
                            .typeError("Please enter a valid date")
                            .required("First Payment Date is required")
                            .min(firstDay, "Date must not be earlier than the first day of the current month")
                            .max(lastDay, "Date must not be later than today")
                            ,
                    })}
                    enableReinitialize
                    initialValues={{ 'drawAmount': input.drawSchedule.drawAmount ?? 0, 'drawDate': lastDay }}
                    onSubmit={(values) => handleDrawRequest(values)}>
                    {({ handleSubmit }) => (
                        <Form id="requestForm" className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                            <label>Max. Allowed Draw Amount: {formatCurrency(totalDrawAmountAllowed)}</label><br /><br />
                            <CurrencyInput size="large" placeholder='Draw Amount ($)' name='drawAmount' showRequired thousandSeparator=',' decimalScale={2} allowNegative={false} maxLength={16} />
                            <DateInput placeholder='Date' name='drawDate' startDate={firstDay} maxDate={lastDay}  mask={InputMasks.date} dateFormat='MM/dd/yyyy' showRequired showMonthYearDropdown={false} />
                        </Form>
                    )}
                </Formik>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => input.ModalOpen(false)}>Cancel</Button>
                <Button floated='right' form="requestForm" primary type='submit' content='Process Draw' disabled={submitting} loading={submitting} />
            </Modal.Actions>
        </Modal>
    )
}

export default ProcessDrawModal;