import { CapBlockTable } from "../common/CapblockTable";

export interface TransferHistory {
  transferDate: Date,
  transferTypeDescription: string,
  actionTypeDescription: number,
  name: string,
  address: string,
  amount: number,
  statusId: number,
  statusDescription: string,
  transactionHash: string,
  note: string,
  senderCapblockTable: CapBlockTable,
  senderTableKeyId: number;
  toCapblockTable: CapBlockTable,
  toTableKeyId: number;
}

export class TransferHistory implements TransferHistory {
  constructor(init?: TransferHistory) {
    Object.assign(this, init);
  }
}


export enum ActionTypeDescriptionEnum {
  deposit = 1,
  withdrawal = 2
}