import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { Entity } from "../models/registration/entity";
import { CacheNameEnum } from "../../shared/utils";

export default class EntityStore {
    constructor() {
        makeAutoObservable(this)
    }

    async getUserEntities(): Promise<Entity[]> {
        try {
            const cachedData = sessionStorage.getItem(CacheNameEnum.UserEntities);

            if (cachedData) {
                const parsedData = JSON.parse(cachedData) as { timestamp: number; data: Entity[] };
                const currentTime = new Date().getTime();
                const fifteen_min = 15 * 60 * 1000;
                if (parsedData.timestamp && currentTime - parsedData.timestamp < fifteen_min) {
                    return parsedData.data;
                }
            }

            const entities = await agent.Entities.getUserEntities();
            if (entities.length > 0) {
                const newData = {
                    timestamp: new Date().getTime(),
                    data: entities
                };

                sessionStorage.setItem(CacheNameEnum.UserEntities, JSON.stringify(newData));
            }

            return entities;
        } catch (error) {
            console.error('Error fetching user entities:', error);
            return [];
        }
    }


    getBorrowerEntities = async () => {
        return agent.Entities.getBorrowerEntities();
    }

    getEntity = async (entityId: number) => {
        return agent.Entities.get(entityId);
    }

    createEntity = async (entity: Entity) => {
        return await agent.Entities.create(entity);
    }

    updateEntity = async (entity: Entity) => {
        return await agent.Entities.update(entity);
    }

    deleteEntity = async (entityId: number) => {
        return await agent.Entities.delete(entityId);
    }
}
