import { useAuth0 } from '@auth0/auth0-react';
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { Button, Container, Header, Segment, Image, Grid } from 'semantic-ui-react';
import EntityContext from '../../app/context/entityContext';
import { useStore } from '../../app/stores/store';
import { check2Step, clearRedirectUrl, getRedirectUrl } from '../../shared/utils';
import { useSearchParams } from 'react-router-dom';
import Loading from '../../app/layout/Loading';

function HomePage() {
  const { isAuthenticated, loginWithRedirect, isLoading, getAccessTokenSilently } = useAuth0();
  const { entityStore } = useStore();
  const { setEntity } = useContext(EntityContext);
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();
  const redirect = urlParams.get("redirectURL") !== null ? urlParams.get("redirectURL") : "";

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then(() => {

        if (!check2Step()) return;
        entityStore.getUserEntities().then(r => {
          if (r.length === 1) {
            setEntity(r[0]);
            var registerRedirectUrl = getRedirectUrl();
            if(registerRedirectUrl != null)
            {
              clearRedirectUrl();
              window.location.href = String(registerRedirectUrl);
              return;
            }
            navigate('/dashboard');
          }
          else {
            navigate('/switch');
          }
        });
      })
    }
  }, [getAccessTokenSilently, entityStore, isAuthenticated, navigate, setEntity]);


  useEffect(() => {
    if (redirect !== "") {
      loginWithRedirect({ appState: { redirectTo: '/verification?redirectURL=' + encodeURI(redirect!) } });
    }
  }, [isAuthenticated, redirect, loginWithRedirect]);

  const Login = () => {
    return <Grid.Row verticalAlign='middle'>
      <Grid.Column>
        <Button.Group size='huge'>
          <Button onClick={() => loginWithRedirect({ appState: { redirectTo: '/verification' } })} size="huge" primary>
            &nbsp;&nbsp;&nbsp;Login&nbsp;&nbsp;&nbsp;
          </Button>
          <Button.Or />
          <Button onClick={() => loginWithRedirect({ screen_hint: 'signup', appState: { redirectTo: '/register' } })} size="huge" secondary>
            Register
          </Button>
        </Button.Group>
      </Grid.Column>
    </Grid.Row>
  }

  if (redirect !== "") {
    return <Loading content='Loading...' />

  }
  else if (!isLoading && !isAuthenticated) {
    return (
      <Segment placeholder vertical className="masthead" style={{ height: '100vh' }}>
        <Grid relaxed textAlign='center'>
          <Grid.Row verticalAlign='middle'>
            <Grid.Column>
              <Container text textAlign="center">
                <Header as="h1">
                  <Image src='/assets/logo.png' alt="logo" style={{ marginBottom: 12 }} />
                </Header>
              </Container>
            </Grid.Column>
          </Grid.Row>
          <Login />
        </Grid>
      </Segment>
    );
  }
  else {
    return (
      <></>
    )
  }
}

export default observer(HomePage)
