import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react'
import { Header, Segment, Form, Button, Divider, Radio, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import TabPane from 'semantic-ui-react/dist/commonjs/modules/Tab/TabPane';
import SelectInput from '../../../../app/common/form/SelectInput';
import TextInput from '../../../../app/common/form/TextInput';
import { useStore } from '../../../../app/stores/store';
import { InvestmentFormValues, InvestmentStatus } from '../../../../app/models/Investment/Investment';
import { NavLink, useNavigate } from 'react-router-dom';
import { InputMasks } from '../../../../shared/InputMasks';
import DateInput from '../../../../app/common/form/DateInput';
import TextArea from '../../../../app/common/form/Textarea';
import * as yup from 'yup';
import { CommonRegularExpressions } from '../../../../shared/CommonRegularExpressions';
import EntityContext from '../../../../app/context/entityContext';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import { getFullSizeWidth } from '../../../../shared/utils';

interface Props {
    investmentId?: number;
    continueNextTab: () => void;
}

function Details({ investmentId, continueNextTab }: Props) {
    const emptyOption = { key: '' };
    const { entity } = useContext(EntityContext);
    const { commonStore, investmentStore } = useStore();
    const [stateOptions, setStateOptions] = useState([emptyOption]);
    const [secTypeOptions, setSecTypeOptions] = useState([emptyOption]);
    const [investmentTypeOptions, setInvestmentTypeOptions] = useState([emptyOption]);
    const [dealTypeOptions, setDealTypeOptions] = useState([emptyOption]);
    const [investment, setInvestment] = useState(new InvestmentFormValues());
    const [loading, setLoading] = useState(true);
    const [continueButtonCliked, setContinueButtonCliked] = useState(false);
    const navigate = useNavigate();
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` });

    useEffect(() => {
        commonStore.getStates().then(r => setStateOptions(r));

        commonStore.getInvestmentEnums().then(r => {
            setSecTypeOptions(r.secType);
            setInvestmentTypeOptions(r.investmentType);
            setDealTypeOptions(r.dealType);
        })

        if (investmentId! > 0)
        {
            investmentStore.getInvestmentById(entity.id, investmentId!).then((r) => {
                const result = new InvestmentFormValues(r);
                setInvestment(result);
            })
            .finally(() => setLoading(false));
        }
        else
        {
            setInvestment(prevState => ({
                ...prevState,
                sponsorEntityId: entity.id,
                sponsorName: entity.name,
                investmentStatus: InvestmentStatus.draft
            }));
            setLoading(false);
        }

    }, [commonStore, setStateOptions, investmentId, setInvestment, entity, investmentStore, setLoading])

    const validationSchema = yup.object({
        name: yup
            .string()
            .required('Name is required'),
        investmentType: yup
            .number()
            .min(1, 'Investment Type is required'),
        addressLine1: yup
            .string()
            .required('Address Line 1 is required'),
        city: yup
            .string()
            .required('City is required'),
        stateCode: yup
            .string()
            .required('State is required'),
        zip: yup
            .string()
            .required('Zip code is required')
            .matches(CommonRegularExpressions.zip, 'Invalid zip code'),
        closeDate: yup
            .date()
            .typeError("Please enter a valid date")
            .required("Close Date is required"),
        exitDate: yup
            .date()
            .typeError("Please enter a valid date")
            .required("Exit Date is required"),
        dealTypeId: yup
            .number()
            .min(1, 'Deal Type is required'),
        secType: yup
            .number()
            .min(1, 'SEC Type is required'),
    });

    const handleChange = (e:any, { value }: any) => {
        setInvestment(prevState => ({
            ...prevState,
            investmentStatus: value
        }));
    }

    const handleFormSubmit = async (values: any, setSubmitting: any) => {
        values.closeDate = new Date(values.closeDate);
        values.exitDate = new Date(values.exitDate);
        investmentStore.saveInvestment(new InvestmentFormValues(values))
            .then(r => {
                if (r.data > 0) {
                    toast.success("Success!", { theme: "colored" });
                    if (investmentId === undefined) {
                        navigate(`/investment/manage/${r.data}`);
                    }
                    if (continueButtonCliked) continueNextTab();
                }
            })
            .catch(err => {
                toast.error("There was an issue saving the investment.", { theme: "colored" });
            })
            .finally(() => setSubmitting(false));
    }
    return (
        <TabPane>
            {investmentId === undefined &&
                <Grid columns={2}>
                    <GridColumn>
                        <Header as='h3' color='blue'>
                            New Investment
                        </Header>
                    </GridColumn>
                    <GridColumn textAlign='right'>
                        <Button as={NavLink} to='/investments' type='button' content='Back to Investments' className='cancelButton' />
                    </GridColumn>
                </Grid>
            }
            <Segment clearing loading={loading}>
                <Formik
                    validationSchema={validationSchema}
                    enableReinitialize
                    initialValues={investment}
                    onSubmit={(values, { setSubmitting,  }) => {
                        handleFormSubmit(values, setSubmitting);
                    }}
                >
                    {({ values, handleSubmit, isSubmitting }) => (
                        <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                            <Grid>
                                <GridColumn width={3}  />
                                <GridColumn width={10} textAlign='center'>
                                <Segment>
                                    {showFullSize ?
                                            <Grid columns={6}>
                                                <GridColumn><Radio label="Draft" checked={values.investmentStatus === InvestmentStatus.draft} name='statusGroup' value={InvestmentStatus.draft} onChange={handleChange} /></GridColumn>
                                                <GridColumn><Radio label="Interest" checked={values.investmentStatus === InvestmentStatus.interested} name='statusGroup' value={InvestmentStatus.interested} onChange={handleChange} /></GridColumn>
                                                <GridColumn><Radio label="Raising" checked={values.investmentStatus === InvestmentStatus.raising} name='statusGroup' value={InvestmentStatus.raising} onChange={handleChange} /></GridColumn>
                                                <GridColumn><Radio label="Hold" checked={values.investmentStatus === InvestmentStatus.hold} name='statusGroup' value={InvestmentStatus.hold} onChange={handleChange} /></GridColumn>
                                                <GridColumn><Radio label="Active" checked={values.investmentStatus === InvestmentStatus.active} name='statusGroup' value={InvestmentStatus.active} onChange={handleChange} /></GridColumn>
                                                <GridColumn><Radio label="Disposed" checked={values.investmentStatus === InvestmentStatus.disposed} name='statusGroup' value={InvestmentStatus.disposed} onChange={handleChange} /></GridColumn>
                                            </Grid>
                                    :
                                            <Grid columns={2}>
                                                <GridRow>
                                                    <GridColumn width={4} />
                                                    <GridColumn width={11} textAlign='left'><Radio label="Draft" checked={values.investmentStatus === InvestmentStatus.draft} name='statusGroup' value={InvestmentStatus.draft} onChange={handleChange} /></GridColumn>
                                                </GridRow>
                                                <GridRow>
                                                    <GridColumn width={4} />
                                                    <GridColumn width={11} textAlign='left'><Radio label="Interest" checked={values.investmentStatus === InvestmentStatus.interested} name='statusGroup' value={InvestmentStatus.interested} onChange={handleChange} /></GridColumn>
                                                </GridRow>
                                                <GridRow>
                                                    <GridColumn width={4} />
                                                    <GridColumn width={11} textAlign='left'><Radio label="Raising" checked={values.investmentStatus === InvestmentStatus.raising} name='statusGroup' value={InvestmentStatus.raising} onChange={handleChange} /></GridColumn>
                                                </GridRow>
                                                <GridRow>
                                                    <GridColumn width={4} />
                                                    <GridColumn width={11} textAlign='left'><Radio label="Hold" checked={values.investmentStatus === InvestmentStatus.hold} name='statusGroup' value={InvestmentStatus.hold} onChange={handleChange} /></GridColumn>
                                                </GridRow>
                                                <GridRow>
                                                    <GridColumn width={4} />
                                                    <GridColumn width={11} textAlign='left'><Radio label="Active" checked={values.investmentStatus === InvestmentStatus.active} name='statusGroup' value={InvestmentStatus.active} onChange={handleChange} /></GridColumn>
                                                </GridRow>
                                                <GridRow>
                                                    <GridColumn width={4} />
                                                    <GridColumn width={11} textAlign='left'><Radio label="Disposed" checked={values.investmentStatus === InvestmentStatus.disposed} name='statusGroup' value={InvestmentStatus.disposed} onChange={handleChange} /></GridColumn>
                                                </GridRow>
                                            </Grid>
                                    }
                                    </Segment>
                                </GridColumn>
                                <GridColumn width={3} textAlign='right' />
                            </Grid>

                            <TextInput placeholder='Investment Name' name='name' maxLength={255} showRequired />
                            <Divider horizontal section>
                                <Header as='h4'>
                                    General Details
                                </Header>
                            </Divider>
                            <Form.Group widths='equal'>
                                <SelectInput options={investmentTypeOptions} placeholder='Investment Type' name='investmentType' showRequired  />
                                <TextArea placeholder='Description' name='description' rows={3} maxLength={1024} />
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <TextInput placeholder='Property Address' name='addressLine1' maxLength={100} showRequired />
                                <TextInput placeholder='Address Line 2' name='addressLine2' maxLength={100} />
                                <TextInput placeholder='City' name='city' maxLength={50} showRequired />
                                <SelectInput options={stateOptions} placeholder='State' name='stateCode' showRequired />
                                <TextInput placeholder='Zip' name='zip' mask={InputMasks.zip} showRequired />
                            </Form.Group>
                            <Divider horizontal section>
                                <Header as='h4'>
                                    Deal Information
                                </Header>
                            </Divider>
                            <Form.Group widths='equal'>
                                <DateInput placeholder='Close Date' name='closeDate' mask={InputMasks.date} showRequired />
                                <DateInput placeholder='Exit Date' name='exitDate' mask={InputMasks.date} showRequired />
                                <SelectInput options={dealTypeOptions} placeholder='Deal Type' name='dealTypeId' showRequired />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <SelectInput options={secTypeOptions} placeholder='SEC Type' name='secType' showRequired />
                                <TextInput placeholder='Owning Entity' name='sponsorName' maxLength={255} readOnly />
                                <TextInput placeholder='Asset Manager' name='assetManager' maxLength={512}  />
                            </Form.Group>
                            <br />
                            {investmentId! > 0 && <Button disabled={isSubmitting} loading={isSubmitting} floated='right' color='green' type='submit' content='Continue' onClick={() => setContinueButtonCliked(true)} />}
                            <Button disabled={isSubmitting} loading={isSubmitting} floated='right' primary type='submit' content='Save' />
                        </Form>
                    )}
                </Formik>
            </Segment>
        </TabPane>
    )
}

export default Details