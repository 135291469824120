import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { WireXferProfile } from "../models/wireXfer/wireXferProfile";

export default class WireXferProfileStore {
    constructor() {
        makeAutoObservable(this)
    }

    getWireXferProfile= async (entityId: number) => {
        return agent.WireXfer.getWireXferProfile(entityId);
    }

    saveWireXferProfile = async (profile: WireXferProfile) => {
        return agent.WireXfer.saveWireXrerProfile(profile);
    }

    deleteZellProfile = async(entityId:number) => {
        return agent.WireXfer.deleteWireXferProfile(entityId);
    }
}