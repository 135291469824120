import { Button, Form, Modal } from "semantic-ui-react";
import * as Yup from 'yup';
import { Formik } from "formik";
import { useStore } from "../../../app/stores/store";
import { LoanDrawSchedule } from "../../../app/models/Loan/LoanDrawSchedule";
import { toast } from "react-toastify";
import TextArea from "../../../app/common/form/Textarea";
import { useContext, useState } from "react";
import EntityContext from "../../../app/context/entityContext";
import { getRandomNumber } from "../../../shared/utils";

interface DenyModelData {
    drawSchedule: LoanDrawSchedule,
    denyModalOpen: boolean,
    setDenyModalOpen: (showOrHide: boolean) => void;
    setReloading : (reload:number) => void;
    setParentReloading : (reload:number) => void;
}

export const DenyDrawModal = (input : DenyModelData) => {
    const { entity } = useContext(EntityContext);
    const { loanDrawStore } = useStore();
    const [submitting, setSubmitting] = useState(false);
    interface DrawDenyFormData {denyReason:string}
    const handleDenyDrawRequest = async (formValues: DrawDenyFormData) => {
        if (formValues?.denyReason?.trim() !== "") {
            setSubmitting(true);
            loanDrawStore.denyLoanDraw(entity.id, input.drawSchedule.id, formValues.denyReason)
                .then(r => {
                    toast.success("Loan Draw Denied successfully!", { theme: "colored" });
                    input.setReloading(getRandomNumber());
                    input.setParentReloading(getRandomNumber());
                    input.setDenyModalOpen(false);
                    setSubmitting(false);
                })
                .catch(err => {
                    toast.error("There was an issue denying the loan draw", { theme: "colored" });
                    input.setDenyModalOpen(false);
                    setSubmitting(false);
                });
        }
    };

    return (
        <Modal
            open={input.denyModalOpen} closeOnEscape={false} closeOnDimmerClick={false} onClose={() => input.setDenyModalOpen(false)} size="small">
            <Modal.Header>Deny Selected Loan Draw #{input.drawSchedule.drawNumber} ?</Modal.Header>
            <Modal.Content>
                <Formik
                    validationSchema={Yup.object({
                        denyReason: Yup.string()
                                .required('Deny Reason is required')
                                .max(250, 'Deny Reason can be at most 250 characters')
                    })}
                    enableReinitialize
                    initialValues={{'denyReason' :''}}
                    onSubmit={(values) => handleDenyDrawRequest(values)}>
                    {({ handleSubmit }) => (
                        <Form id="denyForm" className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                            <TextArea placeholder='Deny Reason' name='denyReason' maxLength={250} rows={3} />
                        </Form>
                    )}
                </Formik>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() =>  input.setDenyModalOpen(false)}>Cancel</Button>
                <Button floated='right' form="denyForm" color='red' type='submit' content='Deny Draw Request' disabled={submitting}/>
            </Modal.Actions>
        </Modal>
    )
};