export interface UserCryptoAddress {
  id: number,
  entityID: number,
  cryptoAddress: string,
  label: string,
  statusId: number;
}

export class UserCryptoAddress implements UserCryptoAddress {
  constructor(init?: UserCryptoAddressFormValues) {
    Object.assign(this, init);
  }
}

export class UserCryptoAddressFormValues {
  id: number = 0;
  entityID: number = 0;
  cryptoAddress: string = '';
  label: string = '';
  statusId: number = 1;

  constructor(address?: UserCryptoAddressFormValues) {
    if (address) {
      this.id = address.id;
      this.entityID = address.entityID;
      this.cryptoAddress = address.cryptoAddress;
      this.label = address.label;
      this.statusId = address.statusId;
    }
  }
}