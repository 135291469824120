import { makeAutoObservable } from "mobx";
import agent from "../api/agent";

export default class LoanDrawStore {

    constructor() {
        makeAutoObservable(this)
    }

    requestLoanDraw = async (entityId:number, loanDrawScheduleId: number, drawRequestedAmount: number) => {
        return await agent.LoanDraws.requestLoanDraw(entityId, loanDrawScheduleId, drawRequestedAmount);
    }

    getLoanDrawRequestHistory = async (loanId: number) => {
        return await agent.LoanDraws.getLoanDrawRequestHistory(loanId);
    }

    denyLoanDraw = async (entityId: number, loanDrawScheduleId: number, denyReason: string) => {
        return await agent.LoanDraws.denyLoanDraw(entityId, loanDrawScheduleId, denyReason);
    }

    cancelLoanDraw = async (entityId: number, loanDrawScheduleId: number) => {
        return await agent.LoanDraws.cancelLoanDraw(entityId, loanDrawScheduleId);
    }

    processLoanDraw = async (entityId:number, loanDrawScheduleId: number, drawRequestedAmount: number, drawDate: Date) => {
        return agent.LoanDraws.processLoanDraw(entityId, loanDrawScheduleId, drawRequestedAmount, drawDate);
    }
}
