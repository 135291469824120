import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { Transfer } from "../models/settings/transfer";

export default class TransferStore {

    constructor() {
        makeAutoObservable(this)
    }

    transfer = async (transfer: Transfer) => {
        return agent.TransferAgent.transfer(transfer);
    }

    getTransferByHash = async (trxHash: string) => {
        return agent.TransferAgent.getTransferByHash(trxHash);
    }

    transferAction = async (transfer: Transfer) => {
        return agent.TransferAgent.transferAction(transfer);
    }
}
