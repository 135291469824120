import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Dropdown, Grid, Icon, Popup, Segment, Table } from 'semantic-ui-react'
import EmptyGridMessage from '../../../shared/EmptyGridMessage';
import GridFooter from '../../../shared/GridFooter';
import { useMediaQuery } from 'react-responsive';
import { createGridInitialState, formatCurrency, formatFullDate, getEnumValue, getEtherscanUrl, getFullSizeWidth, getRandomNumber, handleGridNavigation, handleGridSort } from '../../../shared/utils';
import { PageParams } from '../../../app/models/common/PagedResult';
import EntityContext from '../../../app/context/entityContext';
import { useStore } from '../../../app/stores/store';
import { TransferHistory } from '../../../app/models/settings/transferHistory';
import { BalanceResult } from '../../../app/models/crypto/BalanceResult';
import { TokenFilter } from '../../../app/models/crypto/TokenFilter';
import { TransferActionEnum, TransferActionParam } from '../../../app/models/settings/transfer';
import TransferAction from '../../transfer/transferAction';
import { CapBlockTable } from '../../../app/models/common/CapblockTable';

interface Props {
    token: BalanceResult
}

function TokenHistory({ token }: Props) {
    const [loading, setLoading] = useState(false);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const { entity } = useContext(EntityContext);
    const [tokenHistory, setTokenHistory] = useState([new TransferHistory()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const { cryptoStore } = useStore();
    const [openTransfer, setOpenTransfer] = useState(false);
    const [trxActionParams, setTrxActionParams] = useState(new TransferActionParam());
    const [triggerRefresh, setTriggerRefresh] = useState(0);

    const initialState: TokenFilter = createGridInitialState({
        entityId: entity.id,
        tokenType: token.tokenType,
        tokenAddress: token.accountAddress,
        sortIndex: 'TransferDate',
        sortOrder: 'descending'
    });

    const [params, setParams] = useState(initialState);

    const loadLoanEscrows = useCallback(() => {
        setLoading(true);
        cryptoStore.getTokenHistory(params).then(r => {
            setTotalRecords(r.totalRecords);
            setTokenHistory(r.data);
            setLoading(false);
        });
    }, [cryptoStore, params, setLoading, setTokenHistory])


    useEffect(() => {
        loadLoanEscrows();
    }, [loadLoanEscrows, triggerRefresh]);

    const handleSort = (clickedColumn: string) => {
        if (tokenHistory.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    const getHistoryUrl = (transfer: TransferHistory) => {
        const url = getEtherscanUrl();
        return url + `tx/${transfer.transactionHash}`;
    }

    const replaceTrx = (trxHash: string, action: TransferActionEnum) => {
        setTrxActionParams(prevState => ({
            ...prevState,
            trxHash: trxHash,
            action: action
        }));
        setOpenTransfer(true);
    }

    const ActionDropdown = ({ history }: any) => {

        const shouldDisplayDropdown = () => {
            if (!history) return false;

            const senderCapblockTable = getEnumValue(CapBlockTable, history.senderCapblockTable);
            return history.statusId === 1 &&
                ((senderCapblockTable === CapBlockTable.entity && history.senderTableKeyId === entity.id) ||
                (senderCapblockTable === CapBlockTable.loanEscrow && history.toTableKeyId === entity.id));
        };

        if (!shouldDisplayDropdown()) {
            return null;
        }

        return <Dropdown text='Actions'>
                <Dropdown.Menu>
                    <Dropdown.Item text='Speed Up' value='1' icon='rocket' onClick={() => replaceTrx(history.transactionHash, TransferActionEnum.speedUp)} />

                    {history.transferType !== 'OfferSellerPayment' &&
                        <Dropdown.Item text='Cancel' value='2' icon='delete' onClick={() => replaceTrx(history.transactionHash, TransferActionEnum.cancel)} />
                    }
                </Dropdown.Menu>
            </Dropdown>;
    }

    return (
        <>
        <Segment clearing basic loading={loading}>
            <Table singleLine sortable selectable>
                {showFullSize &&
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={() => handleSort("TransferDate")} sorted={params.sortIndex === "TransferDate" && tokenHistory.length > 0 ? params.sortOrder : undefined}>Date</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("TransferTypeDescription")} sorted={params.sortIndex === "TransferType" && tokenHistory.length > 0 ? params.sortOrder : undefined}>Transfer Type</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("ActionType")} sorted={params.sortIndex === "ActionType" && tokenHistory.length > 0 ? params.sortOrder : undefined}>Type</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Name")} sorted={params.sortIndex === "Address" && tokenHistory.length > 0 ? params.sortOrder : undefined}>Address</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Amount")} sorted={params.sortIndex === "Amount" && tokenHistory.length > 0 ? params.sortOrder : undefined}>Amount</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("StatusID")} sorted={params.sortIndex === "StatusID" && tokenHistory.length > 0 ? params.sortOrder : undefined}>Status</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Note")} sorted={params.sortIndex === "Note" && tokenHistory.length > 0 ? params.sortOrder : undefined}>Note</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                }

                <Table.Body>
                    {!loading && tokenHistory.map((history, i) => {
                        if (showFullSize) {
                            return <Table.Row key={i}>
                                <Table.Cell>{formatFullDate(history.transferDate)}</Table.Cell>
                                <Table.Cell>{history.transferTypeDescription}</Table.Cell>
                                <Table.Cell>{history.actionTypeDescription}</Table.Cell>
                                <Table.Cell>
                                    <Popup header='Address' position='bottom left' content={history.address} trigger={<label style={{ color: '#4183c4' }}>{history.name}</label>} />
                                </Table.Cell>
                                <Table.Cell textAlign='right'>{formatCurrency(history.amount)}</Table.Cell>
                                <Table.Cell>
                                        <a href={getHistoryUrl(history)} target='_blank' rel="noreferrer">{history.statusDescription}</a>
                                </Table.Cell>
                                <Table.Cell>
                                    {history.note?.length > 30 ?
                                        <Popup header='Note' position='bottom left' content={history.note} 
                                            trigger={<label style={{ color: '#4183c4' }}>{history.note.substring(0, 30)}...</label>} 
                                        />
                                        :
                                        <>{history.note}</>
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    <ActionDropdown history={history} />
                                </Table.Cell>
                            </Table.Row>
                        }
                        else {
                            return <Table.Row key={i}>
                                <Table.Cell>
                                    {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                    <Grid>
                                        <Grid.Row style={{ cursor: 'pointer' }}>
                                            <Grid.Column width={6}>
                                                <label onClick={() => handleSort("TransferDate")}>Date:</label><br />
                                                <label onClick={() => handleSort("TransferTypeDescription")}>Transfer Type:</label><br />
                                                <label onClick={() => handleSort("ActionType")}>Type:</label><br />
                                                <label onClick={() => handleSort("Name")}>Address:</label><br />
                                                <label onClick={() => handleSort("Amount")}>Amount:</label><br />
                                                <label onClick={() => handleSort("StatusID")}>Status:</label><br />
                                            </Grid.Column>
                                            <Grid.Column style={{ fontWeight: 'normal' }} >
                                                <div style={{ width: '45vw' }}>
                                                    {formatFullDate(history.transferDate)}<br />
                                                    {history.transferTypeDescription}<br />
                                                    {history.actionTypeDescription}<br />
                                                    {history.name}<br />
                                                    {formatCurrency(history.amount)}<br />
                                                    <a href={getHistoryUrl(history)} target='_blank' rel="noreferrer">{history.statusDescription}</a><br/>
                                                    <ActionDropdown history={history} />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                        }
                    })}
                    {!loading && tokenHistory.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 8 : 1} message='No history available' />}
                </Table.Body>

                {!loading && tokenHistory.length > 0 &&
                    <GridFooter colSpan={showFullSize ? 8 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                }
            </Table>
        </Segment>

            {openTransfer && <TransferAction show={openTransfer} actionParams={trxActionParams}
                    closeModal={refresh => {
                        if (refresh) setTriggerRefresh(getRandomNumber);
                        setOpenTransfer(false);
                    }}
                    />}
        </>
    )
}

export default TokenHistory