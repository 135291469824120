import { TabPane } from "semantic-ui-react";
import UploadDocuments from "./UploadDocuments";
import DocumentsGrid from "./DocumentsGrid";
import { useContext, useState } from "react";
import EntityContext from "../../../app/context/entityContext";
import { Loan } from "../../../app/models/Loan/Loan";

interface Props {
    loan: Loan
}

function LoanDocuments({ loan }: Props) {
    const { entity } = useContext(EntityContext);
    const [reloadDocuments, setReloadDocuments] = useState(false);
    const [showUploadPanel, setShowUploadPanel] = useState(false);

    return (
        <TabPane>
            <DocumentsGrid loan={loan} reloadDocuments={reloadDocuments} showUploadPanel={showUploadPanel} setShowUploadPanel={setShowUploadPanel} />
            {(loan.lenderEntityId === entity.id || loan.borrowerEntityId === entity.id) &&
                <UploadDocuments loan={loan} setReloadDocuments={() => setReloadDocuments(!reloadDocuments)} showUploadPanel={showUploadPanel} setShowUploadPanel={setShowUploadPanel} />
            }
        </TabPane>
    );
}

export default LoanDocuments;