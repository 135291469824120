import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Container, Segment, Sidebar } from "semantic-ui-react";
import { CacheNameEnum, check2Step, clearCache } from "../../../shared/utils";
import Loading from "../Loading";
import NavBar from "./NavBarDependencies/NavBar";
import SideBar from "./NavBarDependencies/SideBar";

export default function NavBarAuthenticationRequired() {

    const { logout, user } = useAuth0();
    const navigate = useNavigate();

    const getDefaultSidebarOn = () => {
        var json = localStorage.getItem(CacheNameEnum.SideBar);
        if (json === null) {
            return true;
        }
        return JSON.parse(json)[CacheNameEnum.SideBar];;
    }
    const [sidebarOn, setSidebarOn] = useState(getDefaultSidebarOn());
    const [notificationOn, setNotificationOn] = useState(false);

    const getDefaultIconsOnly = () => {
        var json = localStorage.getItem(CacheNameEnum.IconsOnly);
        if (json === null) {
            return true;
        }
        return JSON.parse(json)[CacheNameEnum.IconsOnly];
    }
    const [iconsOnly, setIconsOnly] = useState(getDefaultIconsOnly());

    const showSidebar = () => {
        var updatedSidebarOn = !sidebarOn;
        setSidebarOn(updatedSidebarOn);
        localStorage.setItem(CacheNameEnum.SideBar, JSON.stringify({ sidebarOn: updatedSidebarOn }));
    }

    const showNotificationbar = () => {
        setNotificationOn(!notificationOn);
    }

    const showExpandedSidebar = () => {
        var updatedIconsOnly = !iconsOnly;
        setIconsOnly(updatedIconsOnly);
        localStorage.setItem(CacheNameEnum.IconsOnly, JSON.stringify({ iconsOnly: updatedIconsOnly }));
    }
    const logoutHandler = () => {
        clearCache();
        logout({ returnTo: window.location.origin });
    }

    if (user && user!["capblock_userid"] === 0) navigate(`/register`);

    if (!check2Step()) return <Loading content="Loading..." />;

    return (
        <>
            <NavBar sidebarOn={sidebarOn} showSidebar={showSidebar} logoutHandler={logoutHandler} showNotificationBar={showNotificationbar} />
            <Sidebar.Pushable style={{ transform: "none" }}>
                <SideBar sidebarOn={sidebarOn} notificationOn={notificationOn}
                    iconsOnly={iconsOnly} showExpandedSidebar={showExpandedSidebar}
                    logoutHandler={logoutHandler} />
                <Sidebar.Pusher style={{
                    marginLeft: sidebarOn && iconsOnly ? "-15px"
                        : sidebarOn && !iconsOnly ? "0px"
                            : "0px",
                    width: sidebarOn && iconsOnly ? "calc(100% - 45px)"
                        : sidebarOn && !iconsOnly ? "calc(100% - 150px)"
                            : "100%"
                }}>
                    <Segment className="main-content-segment">
                        <Container>
                            <Outlet />
                        </Container>
                    </Segment>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </>
    )
}
