import React, { useContext, useEffect, useState } from 'react'
import {  Grid, Header, Segment, Table } from 'semantic-ui-react'
import EmptyGridMessage from '../../../shared/EmptyGridMessage'
import { useStore } from '../../../app/stores/store';
import EntityContext from '../../../app/context/entityContext';
import { useMediaQuery } from 'react-responsive';
import { formatCurrency, formatFullDate, getFullSizeWidth} from '../../../shared/utils';
import { ShareHolderTransaction } from '../../../app/models/Investment/ShareHolderTransaction';
import { Investment } from '../../../app/models/Investment/Investment';

interface Props {
    investment: Investment;
    shareClass: string;
}

function ShareClassTransactionLotGrid({ investment, shareClass }: Props) {
    const [loading, setLoading] = useState(true);
    const { investmentStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [lots, setLots] = useState([new ShareHolderTransaction()]);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` });

    useEffect(() => {
        setLoading(true);
        investmentStore.getShareClassLotTransactions(entity.id, investment.id, shareClass).then(r => {
            setLots(r);
            setLoading(false);
        });
    }, [investmentStore,investment, shareClass, entity.id]);

    return (
         <Segment clearing loading={loading}>
            <Header>Share Class: {shareClass} - Active Lots</Header>
            <Table singleLine sortable selectable>
                {showFullSize &&
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign='center'>Transaction Date</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Quantity</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Cost Basis/Share</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Total Invested</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Total Value</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                }

                <Table.Body>
                    {!loading && lots.map((lot, i) => {
                        if (showFullSize) {
                            return <Table.Row key={i} >
                                <Table.Cell>{formatFullDate(lot.transactionDate)}</Table.Cell>
                                <Table.Cell textAlign='right'>{lot.shareCount}</Table.Cell>
                                <Table.Cell textAlign='right'>{formatCurrency(lot.costBasis ?? 0)}</Table.Cell>
                                <Table.Cell textAlign='right'>{formatCurrency(lot.amount ?? 0)}</Table.Cell>
                                <Table.Cell textAlign='right'>{formatCurrency(lot.totalValue ?? 0)}</Table.Cell>
                            </Table.Row>
                        }
                        else {
                            return <Table.Row key={i}>
                                <Table.Cell>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={6}>
                                                <label>Transaction Date:</label><br />
                                                <label>Quantity:</label><br />
                                                <label>Cost Basis/Share:</label><br />
                                                <label>Total Invested:</label><br />
                                                <label>Total Value:</label><br />
                                            </Grid.Column>
                                            <Grid.Column style={{ fontWeight: 'normal' }} >
                                                <div style={{ width: '45vw' }}>
                                                    {formatFullDate(lot.transactionDate)}<br />
                                                    {lot.shareCount}<br />
                                                    {formatCurrency(lot.costBasis ?? 0)}<br />
                                                    {formatCurrency(lot.amount ?? 0)} <br />
                                                    {formatCurrency(lot.totalValue ?? 0)} <br />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                        }
                    })}
                    {!loading && lots.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 5 : 1} message='You do not hold any share lots on CapBlock' />}
                </Table.Body>
            </Table>
        </Segment>
    )
}

export default ShareClassTransactionLotGrid