import { useContext, useEffect, useState } from "react";
import { Button, Card, CardGroup, Grid, GridColumn, GridRow, Label, List, Popup, Segment, Statistic, StatisticLabel, StatisticValue, Tab } from "semantic-ui-react";
import EntityContext from "../../../app/context/entityContext";
import { Loan } from "../../../app/models/Loan/Loan";
import { MonthlyStatement, MonthlyStatementDetail } from "../../../app/models/MonthlyStatement/MonthlyStatement";
import { useStore } from "../../../app/stores/store";
import { downloadFile, formatCurrency, formatDate, getRandomNumber } from "../../../shared/utils";
import TransferModal from "../../transfer/transferModal";
import LoanPaymentHistoryGrid from "./LoanPaymentHistoryGrid";
import ProcessPaymentView from "./ProcessPaymentView";
import { observer } from "mobx-react-lite";
import { CapBlockTable } from "../../../app/models/common/CapblockTable";
import { TransferModel, TransferType } from "../../../app/models/settings/transfer";
import ManageStatement from "../Statement/ManageStatement";
import { Role } from "../../../app/models/registration/entity";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator, faListCheck, faMoneyCheck, faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons'
import PayoffView from "../PayOff/PayoffView";
import ProcessPayoff from "../PayOff/ProcessPayoff";
import PaymentMethodModal from "./PaymentMethod";
import ZellePaymentModal from "./ZellePaymentModal";
import WireXferPaymentModal from "./WireXferPaymentModal";

interface Props {
    loan: Loan,
    setParentReloading: (reload: number) => void;
}

function LoanViewPaymentDetails({ loan, setParentReloading }: Props) {
    const { entity } = useContext(EntityContext);
    const { monthlyStatementStore } = useStore();
    const [statements, setStatements] = useState([new MonthlyStatement()]);
    const [selectedStatement, setSelectedStatement] = useState(new MonthlyStatement());
    const [loading, setLoading] = useState(true);
    const [showHistory, setShowHistory] = useState(false);
    const [showTransfer, setShowTransfer] = useState(false);
    const [paymentAction, setPaymentAction] = useState<PaymentActionEnum>(1);
    const [downloading, setDownloading] = useState<number[]>([]);
    const [triggerHook, setTriggerHook] = useState(0);
    const [showPaymentMethod, setShowPaymentMethod] = useState(false);
    const { stripeAccountStore } = useStore();
    const [showZelleRecipientModal, setShowZelleRecipientModal] = useState(false);
    const [makePaymentAction, setMakePaymentAction] = useState(0);
    const [showWireXferPaymentModal, setShowWireXferPaymentModal] = useState(false);
    const {paymentMethodStore} = useStore();
    const emptyOption = { key: '' };
    const [paymentOptions, setPaymentOptions] = useState([emptyOption]);

    const enum PaymentActionEnum {
        PaymentInfo = 1,
        ProcessPayment = 2,
        PayoffView = 3,
        ManageStatements = 4,
        ProcessPayoff = 5
      }

    const hasAccessToManageStatements = () => {
        var userRole = entity?.userRoles.filter(x => x === Role[Role.ManageStatement]);
        return userRole?.length === 1;
    }

    useEffect(() => {
        setLoading(true);
        if (loan.id) {
            monthlyStatementStore.getMonthlyStatementDue(loan.id).then((r) => {
                setShowHistory(true);
                setStatements(r);
            }).then(() =>{
                paymentMethodStore.getAvaileblePaymentMethods(loan.id).then((r) => {
                    const options = JSON.stringify(r.map(s => ({ value: s.value, text: s.label })));
                    setPaymentOptions(JSON.parse(options));
                  })
            })
                .finally(() => {
                    setLoading(false);
                });
        }
        else {
            setShowHistory(false);
        }
    }, [monthlyStatementStore, loan.id, triggerHook, paymentMethodStore])

    const handleTransferClose = (refreshBalance: boolean) => {
        setShowTransfer(false);
        if (refreshBalance) {
            const data = { ...statements };
            const index = statements.findIndex((item) => item.id === selectedStatement.id);
            const payment = statements[index];
            payment.status = 'Pending';
            data[index] = payment;
        }
    }

    const handlePaymentMethodClose = (selectedPaymentMethod:string) => {
        setShowPaymentMethod(false);
        if(selectedPaymentMethod === 'Stripe')
        {
            stripeAccountStore.getPaymentUrl(selectedStatement.id).then((r) =>{
                window.location.href = r;               
            });
        }
        else if(selectedPaymentMethod === 'Zelle')
        {
            setShowZelleRecipientModal(true);

        }
        else if(selectedPaymentMethod === 'Crypto')
        {
            setShowTransfer(true);
        }
        else if(selectedPaymentMethod === 'WireXfer')
        {
            setShowWireXferPaymentModal(true);
        }
    }

    useEffect(() => {
        if (makePaymentAction > 0 && selectedStatement && selectedStatement.id !== 0)
        {
                if(paymentOptions.length > 1)
                {
                    setShowPaymentMethod(true);
                }
                else
                {
                    setShowTransfer(true);                   
                }
        }
    }, [makePaymentAction, selectedStatement, paymentMethodStore, loan.id, paymentOptions.length])

    const handleZelleModalClose = () => {
        setShowZelleRecipientModal(false);
    }

    const handleWireXferModalClose = () => {
        setShowWireXferPaymentModal(false);
    }

    const getPaymentModel = (): TransferModel => {
        var model = {
            senderCapblockTable: CapBlockTable.entity,
            senderTableKeyId: entity.id,
            transferType: TransferType.statementPayment,
            sourceId: selectedStatement.id,
            amount: selectedStatement.amount ?? 0,
            toCapblockTable: CapBlockTable.entity,
            toTableKeyId: loan.lenderEntityId,
            toName: loan.lenderName
        };
        return model;
    }

    const makePayment = (statement: MonthlyStatement) => {
        setMakePaymentAction(getRandomNumber);
        setSelectedStatement(statement);
    }

    const downloadStatement = (statementId: number, index: number) => {
        setDownloading([...downloading, index]);
        monthlyStatementStore.downloadMonthlyStatement(loan.id, statementId)
            .then(response => {
                downloadFile(response);
            })
            .finally(() => setDownloading((prevDisabledButtons) => prevDisabledButtons.filter((i) => i !== index)));
    }

    const getDetailedInfo = (details: MonthlyStatementDetail) => {
        if (details) {
            const containerStyle = {
                paddingTop: '0px',
                paddingBottom: '2px'
            };
            return <>
                <Grid.Row style={containerStyle}>
                    <Grid.Column as="h5">Principal:</Grid.Column>
                    <Grid.Column>{formatCurrency(details.principalDue)}</Grid.Column>
                </Grid.Row>
                <Grid.Row style={containerStyle}>
                    <Grid.Column as="h5">Interest:</Grid.Column>
                    <Grid.Column>{formatCurrency(details.interestDue)}</Grid.Column>
                </Grid.Row>
                <Grid.Row style={containerStyle}>
                    <Grid.Column as="h5">Escrow:</Grid.Column>
                    <Grid.Column>{formatCurrency(details.totalEscrow)}</Grid.Column>
                </Grid.Row>
                <Grid.Row style={containerStyle}>
                    <Grid.Column as="h5">Credits:</Grid.Column>
                    <Grid.Column>{formatCurrency(details.totalCredits)}</Grid.Column>
                </Grid.Row>
                <Grid.Row style={containerStyle}>
                    <Grid.Column as="h5">Fees:</Grid.Column>
                    <Grid.Column>{formatCurrency(details.totalFees)}</Grid.Column>
                </Grid.Row>

                {details.loan.lastStatement &&
                    <Grid.Row style={containerStyle}>
                        <Grid.Column as="h5">Balance:</Grid.Column>
                        <Grid.Column>{formatCurrency(details.outstandingBalance)}</Grid.Column>
                    </Grid.Row>
                }

            </>;
        }
    }

    const DownloadStatement = ({ statementId, index }: any) => {
        return <Button primary content="Download Statement" onClick={() => downloadStatement(statementId, index)} disabled={downloading.includes(index)} loading={downloading.includes(index)} />;
    }

    const PaymentInfo = () => {
        return <>
            &nbsp;
            <Grid columns={3}>
                <GridRow>
                    <GridColumn>
                        {loan.loanStatus !== 5 &&
                            <Button primary type='button' onClick={() => setPaymentAction(PaymentActionEnum.PayoffView)}>
                                <FontAwesomeIcon icon={faCalculator} /> Loan Payoff
                            </Button>
                        }
                    </GridColumn>
                    <GridColumn textAlign="center">
                        {loan.currentCreditBalance >= 1 &&
                            <Statistic size="tiny" color="green">
                                <StatisticValue>
                                    {formatCurrency(loan.currentCreditBalance)}
                                </StatisticValue>
                                <StatisticLabel>Credit Balance</StatisticLabel>
                            </Statistic>
                        }
                    </GridColumn>
                    <GridColumn textAlign="right">
                        {loan.lenderEntityId === entity.id && loan.loanStatus !== 5 && (statements.length <= 1 || hasAccessToManageStatements()) &&
                            <Popup trigger={<Button content='Actions' basic color="blue" />} on='click' position='bottom left'>
                                <List divided selection size='large'>
                                    {statements.length <= 1 &&
                                        <List.Item>
                                            <List.Icon verticalAlign='middle'>
                                                <FontAwesomeIcon icon={faMoneyCheck} />
                                            </List.Icon>
                                            <List.Content>
                                                <List.Header onClick={() => processPayment(statements[0])}>Process Payment</List.Header>
                                            </List.Content>
                                        </List.Item>
                                    }
                                    {hasAccessToManageStatements() &&
                                        <>
                                        <List.Item>
                                            <List.Icon verticalAlign='middle'>
                                                <FontAwesomeIcon icon={faMoneyCheckDollar} />
                                            </List.Icon>
                                            <List.Content>
                                                <List.Header onClick={() => setPaymentAction(PaymentActionEnum.ProcessPayoff)}>Process Payoff</List.Header>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Icon verticalAlign='middle'>
                                                <FontAwesomeIcon icon={faListCheck} />
                                            </List.Icon>
                                            <List.Content>
                                                <List.Header onClick={() => setPaymentAction(PaymentActionEnum.ManageStatements)}>Manage</List.Header>
                                            </List.Content>
                                        </List.Item>
                                        </>
                                    }
                                </List>
                            </Popup>
                        }
                    </GridColumn>
                </GridRow>
            </Grid>

            <Segment basic>
                {statements.length === 0 ? (
                    <Card centered raised style={{ padding: "5px" }}>
                        <Card.Header as="h3" textAlign='center'>No payment is currently due</Card.Header>
                    </Card>
                ) : (
                    <CardGroup>
                        {statements.map((statement, index) => (
                            <Card key={index} centered raised>
                                <Card.Header as="h3" textAlign='center' style={{ paddingTop: "5px", paddingLeft: "5px" }}>Payment Due</Card.Header>
                                <Card.Content textAlign='left'>
                                    <Grid columns={2}>
                                        <Grid.Row>
                                            <Grid.Column as="h4">Date Due:</Grid.Column>
                                            <Grid.Column>{formatDate(statement.dueDate)}</Grid.Column>
                                        </Grid.Row>

                                        {getDetailedInfo(statement.details)}

                                        <Grid.Row>
                                            <Grid.Column as="h4">Total Due:</Grid.Column>
                                            <Grid.Column>{formatCurrency(statement.amount)}</Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Card.Content>
                                {loan.borrowerEntityId === entity.id &&
                                    <Card.Content textAlign='center'>

                                        <Grid columns={2}>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    {statement.status === 'Overdue' ? <Button primary content='Make Payment' onClick={() => makePayment(statement)} />
                                                        : <Label>Payment Pending</Label>}
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <DownloadStatement statementId={statement.id} index={index} />
                                                </Grid.Column>
                                            </Grid.Row>

                                        </Grid>
                                    </Card.Content>

                                }
                                {loan.lenderEntityId === entity.id &&
                                    <Card.Content textAlign='center'>
                                        {statements.length > 1 ?
                                            <Grid columns={2}>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Button primary type='button' content='Process Payment' onClick={() => processPayment(statement)} disabled={index > 0 && loan.loanType === 2} />
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <DownloadStatement statementId={statement.id} index={index} />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                            :
                                            <DownloadStatement statementId={statement.id} index={index} />
                                        }
                                    </Card.Content>
                                }

                            </Card>
                        ))}
                    </CardGroup>
                )}
            </Segment>

            {showHistory && <LoanPaymentHistoryGrid loanId={loan.id} />}

            {showTransfer && <TransferModal closeModal={handleTransferClose}
                show={showTransfer}
                hideReceive={true}
                transferModel={getPaymentModel()} />
            }

            {showPaymentMethod &&
                <PaymentMethodModal
                    show={showPaymentMethod}
                    loanId={loan.id}
                    closeModal={handlePaymentMethodClose}/>
            }

            {showZelleRecipientModal &&
                <ZellePaymentModal
                    entityId={loan.lenderEntityId}
                    show={showZelleRecipientModal}
                    closeModal={handleZelleModalClose} />
            }

            {showWireXferPaymentModal &&
                <WireXferPaymentModal
                    entityId={loan.lenderEntityId}
                    show={showWireXferPaymentModal}
                    closeModal={handleWireXferModalClose} />
            }

        </>
    }

    const processPayment = (statement: MonthlyStatement) => {
        setSelectedStatement(statement);
        setPaymentAction(PaymentActionEnum.ProcessPayment);
    }

    const showHideForm = (removePaid: boolean, newBalance?: number) => {
        if (removePaid) {
            const index = statements.findIndex((item) => item.id === selectedStatement.id);
            statements.splice(index, 1);
            setParentReloading(getRandomNumber());
        }
        setPaymentAction(PaymentActionEnum.PaymentInfo);
    }

    return (
        <Tab.Pane attached={true} loading={loading}>
            {paymentAction === PaymentActionEnum.ProcessPayment &&
                <ProcessPaymentView statement={selectedStatement} onCancel={showHideForm} />
            }

            {paymentAction === PaymentActionEnum.PayoffView &&
                <PayoffView loan={loan} onCancel={() => setPaymentAction(PaymentActionEnum.PaymentInfo)} />
            }

            {paymentAction === PaymentActionEnum.ManageStatements &&
                <ManageStatement loan={loan} onCancel={() => { setTriggerHook(getRandomNumber()); setPaymentAction(PaymentActionEnum.PaymentInfo); }} processPayment={processPayment} />
            }

            {paymentAction === PaymentActionEnum.ProcessPayoff &&
                <ProcessPayoff loan={loan} onCancel={() => {setParentReloading(getRandomNumber()); setTriggerHook(getRandomNumber()); setPaymentAction(PaymentActionEnum.PaymentInfo);}} />
            }

            {paymentAction === PaymentActionEnum.PaymentInfo && <PaymentInfo />}

        </Tab.Pane>
    )
}

export default observer(LoanViewPaymentDetails)