import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, Segment } from 'semantic-ui-react';
import EntityContext from '../../app/context/entityContext';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../app/stores/store';
import TextInput from '../../app/common/form/TextInput';
import { ZelleAccountProfile } from '../../app/models/zelle/zelleAccountProfile';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as yup from 'yup';

function ZelleAccountPanel()
{
    const { zelleAccountStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [zelleAccountProfile, setZelleAccountProfile] = useState<ZelleAccountProfile>(new ZelleAccountProfile());

    useEffect(() => {
        zelleAccountStore.getZelleProfile(entity.id).then(result => {
            setZelleAccountProfile(result);
            setLoading(false);
        })
    }, [zelleAccountStore, setLoading, entity]);

    const handleFormSubmit = (profile: ZelleAccountProfile, setFieldError: any, setSubmitting: any) => {
        profile.entityId = entity.id;
        zelleAccountStore.saveZelleProfile(profile)
            .then(r => {
                toast.success("Zelle payment updated", { theme: "colored" });
                setZelleAccountProfile(profile);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    const validationSchema = yup.object({
        recipientAccount: yup
            .string()
            .required('This field is required')
            .matches(
                /^(?:\+?1[-.\s]?)?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$|^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}@([a-zA-Z\d.-]{1,253}\.[a-zA-Z]{2,63})$/,
                'Invalid phone number or email'
            ),
    });

      return (
        <Segment clearing loading={loading}>
            <Formik
                enableReinitialize
                initialValues={zelleAccountProfile}
                validationSchema={validationSchema}
                onSubmit={(values, { setFieldError, setSubmitting }) => {
                    return handleFormSubmit(values, setFieldError, setSubmitting);
                }} >
                {({ handleSubmit, isSubmitting, handleReset }) => (
                    <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                        <TextInput placeholder='Email or Text number to receive Zelle payments' name='recipientAccount' maxLength={256} />
                        <Button
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            floated='right'
                            primary
                            type='submit'
                            content='Save'
                        />
                        <Button onClick={() => 
                            zelleAccountStore.deleteZellProfile(entity.id)
                            .then(r => 
                                {setZelleAccountProfile(new ZelleAccountProfile());                 
                                 toast.success("Zelle payment deleted", { theme: "colored" });})
                                                    
                        } floated='right' type='reset' content='Delete' disabled={isSubmitting || zelleAccountProfile.entityId === 0}/>
                        <Button onClick={() => navigate('/dashboard')} floated='right' type='button' content='Cancel' disabled={isSubmitting}/>
                    </Form>
                )}
            </Formik>
        </Segment>
  );
}

export default ZelleAccountPanel