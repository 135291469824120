import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { Container, Grid, GridColumn, GridRow, Header, Icon, Label } from "semantic-ui-react";
import { InvestmentSummaryView } from "../../app/models/GpLanding/InvestmentSummaryView";
import GPInvestmentCard from "./gpInvestmentCard";

interface Params {
    entityId: number;
    onlyActiveInvestments: boolean;
    isConnected: boolean;
    headerText: string
}
function GPInvestmentListCard({ entityId, onlyActiveInvestments, headerText, isConnected }: Params) {
    const [loading, setLoading] = useState(true);
    const { entityProfileStore } = useStore();
    const [investments, setInvestments] = useState([new InvestmentSummaryView()])
    useEffect(() => {
        if (onlyActiveInvestments) {
            entityProfileStore.getGpInvestmentActiveDeals(entityId)
                .then(r => setInvestments(r))
                .finally(() => setLoading(false));
        }
        else {
            entityProfileStore.getGpInvestmentTrackRecord(entityId)
                .then(r => setInvestments(r))
                .finally(() => setLoading(false));
        }
    }, [entityId, entityProfileStore, onlyActiveInvestments, setInvestments, setLoading])

    return (
        <Container className='investmentMarketingPanel'>
            <Grid columns={2}>
                <GridRow>
                    <GridColumn>
                    <Header as='h2' image>
                                <Icon name='building'></Icon>
                                <Header.Content>{headerText}</Header.Content>
                            </Header>
                    </GridColumn>
                </GridRow>
                {investments.length > 0 ?
                    <>
                        {!loading && investments.map((investment, i) => {
                            return <GridColumn key={i}>
                                <GPInvestmentCard investment={investment} isConnected={isConnected}></GPInvestmentCard>
                            </GridColumn>
                        })}
                    </>
                    :
                    <>
                        <GridColumn>
                            <div>
                                <Label><Icon name='building outline' size='big'></Icon> This company has no closed deals</Label>
                            </div>
                        </GridColumn>
                    </>
                }
            </Grid>
            </Container>
    )
}
export default GPInvestmentListCard