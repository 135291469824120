import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { InvestmentQueryFilter } from "../models/common/InvestmentQueryFilter";

export default class InvestmentInsightsStore
{
    constructor() {
        makeAutoObservable(this)
    }

    getOverview = async (investmmentId:number) => {
        return agent.InvestmentInsights.getInvestmentOverview(investmmentId);
    }

    getDebtFinance = async (investmentId:number) => {
        return agent.InvestmentInsights.getInvestmentDebtFinance(investmentId);
    }

    getTermsFees = async (investmentId:number) => {
        return agent.InvestmentInsights.getInvestmentTermsFees(investmentId);
    }

    getAssetDetails = async (investmentId:number) => {
        return agent.InvestmentInsights.getInvestmentAssetDetails(investmentId);
    }

    getKeyMetricsHeader = async(investmentId:number) => {
        return agent.InvestmentInsights.getKeyMetricsHeader(investmentId);
    }

    getProjectedReturns = async(investmentId:number) =>{
        return agent.InvestmentInsights.getProjectedReturns(investmentId);
    }
    
    getSponsorInvestmentDocuments = async(params:InvestmentQueryFilter) => {
        return agent.InvestmentInsights.getSponsorInvestmentDocuments(params);
    }

    getSponsorshipTeam = async(investmentId:number) => {
        return agent.InvestmentInsights.getSponsorshipTeam(investmentId);
    }
}