import React, { useContext, useEffect, useState } from 'react'
import { useStore } from '../../../app/stores/store';
import EntityContext from '../../../app/context/entityContext';
import { DistributionFormValues, ShareholderDistribution } from '../../../app/models/Investment/Distribution';
import { useMediaQuery } from 'react-responsive';
import { createGridInitialState, formatCurrency, getFloatValue, getFullSizeWidth, handleGridNavigation, handleGridSort } from '../../../shared/utils';
import { InvestmentQueryFilter } from '../../../app/models/common/InvestmentQueryFilter';
import { Grid, Icon, Table } from 'semantic-ui-react';
import GridFooter from '../../../shared/GridFooter';
import { PageParams } from '../../../app/models/common/PagedResult';
import { CalculateDistribution } from '../../../app/models/Investment/CalculateDistribution';

export interface Props {
    investmentId: number;
    distributionId: number;
    values: DistributionFormValues;
}

function DistributionDetailShareholdersGrid({ investmentId, distributionId, values }: Props) {
    const [loading, setLoading] = useState(true);
    const { investmentStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [shareholders, setShareholders] = useState([new ShareholderDistribution()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })

    const initialState: InvestmentQueryFilter = createGridInitialState({
        entityId: entity.id,
        investmentId: investmentId,
        distributionId: distributionId,
        showAll: false,
        sortIndex: 'ShareholderName',
        sortOrder: 'ascending'
    });

    const [params, setParams] = useState(initialState);

    useEffect(() => {
        setLoading(true);
        if (distributionId > 0) {
            investmentStore.getDistributionShareholders(params).then(r => {
                setTotalRecords(r.totalRecords);
                setShareholders(r.data);
                setLoading(false);
            });
        }
        else {
            values.distributionAmount = getFloatValue(values.distributionAmount);
            const calculation = new CalculateDistribution();
            calculation.filter = params;
            calculation.distribution = new DistributionFormValues(values);
            investmentStore.calculatePreDistribution(calculation).then(r => {
                setTotalRecords(r.totalRecords);
                setShareholders(r.data);
                setLoading(false);
            });
        }
    }, [investmentStore, params, setLoading, distributionId, values]);


    const handleSort = (clickedColumn: string) => {
        if (shareholders.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    return (
            <Table singleLine sortable>
                {showFullSize &&
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={() => handleSort("ShareholderName")} sorted={params.sortIndex === "ShareholderName" && shareholders.length > 0 ? params.sortOrder : undefined}>Name</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("ShareClass")} sorted={params.sortIndex === "ShareClass" && shareholders.length > 0 ? params.sortOrder : undefined}>Class</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Amount")} sorted={params.sortIndex === "Amount" ? params.sortOrder : undefined}>Amount</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("ShareCount")} sorted={params.sortIndex === "ShareCount" ? params.sortOrder : undefined}>Shares</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                }

                <Table.Body>
                    {!loading && shareholders.map((shareholder, i) => {
                        if (showFullSize) {
                            return <Table.Row key={i}>
                                <Table.Cell>{shareholder.shareholderName}</Table.Cell>
                                <Table.Cell>Class {shareholder.shareClass}</Table.Cell>
                                <Table.Cell textAlign='right'>{formatCurrency(shareholder.amount)}</Table.Cell>
                                <Table.Cell textAlign='right'>{shareholder.shareCount}</Table.Cell>
                            </Table.Row>
                        }
                        else {
                            return <Table.Row key={i}>
                                <Table.Cell>
                                    {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                    <Grid>
                                        <Grid.Row style={{ cursor: 'pointer' }}>
                                            <Grid.Column width={6}>
                                                <label onClick={() => handleSort("ShareholderName")}>Name:</label><br />
                                                <label onClick={() => handleSort("ShareClass")}>Class:</label><br />
                                                <label onClick={() => handleSort("Amount")}>Amount:</label><br />
                                                <label onClick={() => handleSort("ShareCount")}>Shares:</label><br />
                                            </Grid.Column>
                                            <Grid.Column style={{ fontWeight: 'normal' }} >
                                                <div style={{ width: '45vw' }}>
                                                    {shareholder.shareholderName}<br />
                                                    {shareholder.shareClass}<br />
                                                    {formatCurrency(shareholder.amount)}<br />
                                                    {shareholder.shareCount}<br />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                        }
                    })}
                </Table.Body>
                {!loading && shareholders.length > 0 &&
                    <GridFooter colSpan={showFullSize ? 4 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                }
            </Table>
    )
}

export default DistributionDetailShareholdersGrid