import { Fragment, useEffect, useState } from "react";
import { Button, Form, Header, Icon, Label, Rating, Segment, Table, TextArea } from "semantic-ui-react";
import { EntityReview } from "../../app/models/GpLanding/entityReview";
import { useStore } from "../../app/stores/store";
import { Formik } from "formik";
import { toast } from "react-toastify";

interface Params {
    sponsorEntityId: number;
    entityId: number;
    isAuthenticated: boolean;
    isConnectedToSponsor: boolean;
    numberOfReviews: number;
}

function GPReviewsCard({ sponsorEntityId, entityId, isAuthenticated, isConnectedToSponsor, numberOfReviews }: Params) {
    const [loading, setLoading] = useState(true);
    const { entityProfileStore } = useStore();
    const [writeReview, setWriteReview] = useState(false);
    const [entityReviews, setEntityReviews] = useState([new EntityReview()]);
    const [review, setReview] = useState(new EntityReview());
    const [descriptionText, setDescriptionText] = useState("");
    const [formRating, setFormRating] = useState(3);
    const { sponsorContactStore } = useStore();
    const maxRatingValue = 5;

    useEffect(() => {
        entityProfileStore.getSponsorReviews(sponsorEntityId, numberOfReviews)
            .then(r => setEntityReviews(r))
            .then(() => {
            })
            .finally(() => setLoading(false))
    }, [sponsorEntityId, entityProfileStore, numberOfReviews, isAuthenticated, sponsorContactStore, entityId, isConnectedToSponsor]);

    const enableWriteReview = () => {
        entityProfileStore.getUserSponsorReview(sponsorEntityId)
            .then((r) => {
                setReview(r);
                setDescriptionText(r.reviewText);
            })
            .finally(() => {
                setWriteReview(true);
            })
    }

    const handleFormSubmit = (review: EntityReview, setFieldError: any, setSubmitting: any) => {
        review.entityId = sponsorEntityId;
        review.rating = formRating;
        review.reviewText = descriptionText;
        entityProfileStore.saveUserSponsorRewiew(review)
            .then(() => {
                toast.success("Your review has been submitted.", { theme: "colored" });

            })
            .then(() => {
                entityProfileStore.getSponsorReviews(sponsorEntityId, numberOfReviews)
                    .then((r) => {
                        setEntityReviews(r);
                    })
            })
            .finally(() => {
                setWriteReview(false);
            })
    }

    return (
        <Segment clearing loading={loading} className='classTable'>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <Header as='h2'>Reviews
                                {isAuthenticated && isConnectedToSponsor &&
                                    <Button primary floated="right" content='Write Review' onClick={() => enableWriteReview()}></Button>
                                }
                            </Header>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {writeReview && isAuthenticated ?
                        <>
                            <Table.Row>
                                <Table.Cell>
                                    <Formik
                                        enableReinitialize
                                        initialValues={review}
                                        onSubmit={(values, { setFieldError, setSubmitting }) => {
                                            // Weird work-around for TextArea and Rating value binding.
                                            values.reviewText = descriptionText;
                                            values.rating = formRating;
                                            return handleFormSubmit(values, setFieldError, setSubmitting);
                                        }} >
                                        {({ handleSubmit, isSubmitting }) => (
                                            <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                                                <div>
                                                    <Label>Your rating</Label><Rating onRate={(e, { rating, maxRating }) => {
                                                        setFormRating(Number(rating));

                                                    }} rating={formRating} icon='star' clearable={true} maxRating={5} defaultRating={review.rating}></Rating>
                                                </div>
                                                <TextArea maxLength='1024' value={descriptionText} onChange={(e, { value }) => { setDescriptionText(String(value)) }}>
                                                </TextArea>
                                                <div style={{ marginTop: 20 }}>
                                                    <Button floated='right' primary type='submit' loading={loading} disabled={isSubmitting} content='Save'></Button>
                                                    <Button floated='right' content="Cancel" onClick={() => setWriteReview(false)}></Button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </Table.Cell>
                            </Table.Row>
                        </>
                        :
                        <>
                            <Table.Row>
                                <Table.Cell>
                                    {(entityReviews.length) > 0 ?
                                        <>
                                            {!loading && entityReviews.map((review, i) => {
                                                return <Fragment key={i}>
                                                    <Rating readOnly rating={review.rating} maxRating={maxRatingValue} size='huge'></Rating> <Icon name='user'></Icon> {review.reviewerFirstName} {review.reviewerLastName}
                                                    <p>
                                                        &quot;{review.reviewText}&quot;
                                                    </p>
                                                </Fragment>
                                            })}
                                        </>
                                        :
                                        <div><p>This company has no reviews.</p></div>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        </>
                    }
                </Table.Body>
            </Table>
        </Segment>
    )
}

export default GPReviewsCard