import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Header, Item, Segment } from 'semantic-ui-react';
import { useStore } from '../../app/stores/store';
import EntityContext from '../../app/context/entityContext';
import { useNavigate } from 'react-router-dom';

function StripeAccountPannel()
{
    const { entity } = useContext(EntityContext);
    const {stripeAccountStore} = useStore();
    const [hasStripeAccount, setHasStripeAccount] = useState(false);
    const [stripeAccountStatus, setStripeAccountStatus] = useState('Getting status');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const getStripeAccountInfo = useCallback(() => {
        stripeAccountStore.getStripeAccountInfo(entity.id).then(r => {
            setHasStripeAccount(r.hasStripeAccount);
            setStripeAccountStatus(r.accountStatus);
            setLoading(false);
        });
    }, [stripeAccountStore, entity.id]);

    useEffect(() => {

        getStripeAccountInfo();
    }, [getStripeAccountInfo, entity, navigate]);

    return (
        <Segment clearing loading={loading}>
            <Header as='h2'>
                Stripe Connect Account
                {(!hasStripeAccount || stripeAccountStatus !== 'Connected') && 
                    <Button floated='right' primary 
                        disabled={buttonDisabled ? buttonDisabled : false} 
                        loading={loading}
                        content='Create Stripe Connect Account' 
                        onClick={() => 
                        {
                            setLoading(true);
                            setButtonDisabled(true); 
                            stripeAccountStore.onboardEntity(entity.id).then(r => 
                                {
                                    window.location.href = r;
                                })
                        }} 
                />}
            </Header>
            <Item>
                <Item.Content>
                    <Item.Description>
                        Use Stripe Connect to manage credit card payments.
                    </Item.Description>
                </Item.Content>
            </Item>
        
            {hasStripeAccount? 
                <span>
                    <p>Status: {stripeAccountStatus}</p>
                </span>
                :
                <></>
            }
        </Segment>
  );
}

export default StripeAccountPannel