export interface InvestmentDebtFinanceView 
{
    financingType:string,
    loanToValue:number,
    interestRate:number,
    loanTerm:number,
    interestOnly:boolean,
    interestOnlyPeriod:number
}

export class InvestmentDebtFinanceView implements InvestmentDebtFinanceView
{
    constructor(init?: InvestmentDebtFinanceView) {
        Object.assign(this, init);
      }
}