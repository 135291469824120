import { Formik } from 'formik';
import React, { useRef, useState } from 'react'
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react'
import TextInput from '../../app/common/form/TextInput';
import { ChangePasswordFormValues } from '../../app/models/settings/changePassword';
import * as yup from 'yup';
import { CommonRegularExpressions } from '../../shared/CommonRegularExpressions';
import { useStore } from '../../app/stores/store';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import VerificationCodeModal from '../../shared/VerificationCodeModal';
import { observer } from 'mobx-react-lite';
import { clearCache } from '../../shared/utils';
import { User } from '../../app/models/registration/user';

interface Props {
    show: boolean;
    onCancel: () => void;
    user: User;
}

function ChangePasswordModal({ show, onCancel, user }: Props) {

    const { userStore } = useStore();
    const [password] = useState<ChangePasswordFormValues>(new ChangePasswordFormValues());
    const [loading, setLoading] = useState(false);
    const [showVerification, setShowVerification] = useState(false);
    const passwordRegexMessage = 'Password must contain at least one uppercase, one lowercase, one number and one special character';
    const passwordLenght = 'Password should be of minimum 8 characters length';
    const { logout } = useAuth0();
    const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [formValues, setFormaValues] = useState(new ChangePasswordFormValues());
    const formikRef = useRef<any>();

    const validationSchema = yup.object({
        currentPassword: yup
            .string()
            .required('Current Password is required')
            .min(8, passwordLenght)
            .matches(CommonRegularExpressions.password, passwordRegexMessage),
        newPassword: yup
            .string()
            .required('New Password is required')
            .min(8, passwordLenght)
            .matches(CommonRegularExpressions.password, passwordRegexMessage)
            .notOneOf([yup.ref('currentPassword'), null], 'New password must be different from current password'),
        confirmPassword: yup
            .string()
            .required('Confirm Password is required')
            .min(8, passwordLenght)
            .matches(CommonRegularExpressions.password, passwordRegexMessage)
            .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
    });

    const handleFormSubmit = (values: ChangePasswordFormValues, setFieldError: any) => {
        setFormaValues(values);
        setShowVerification(true);
    }

    const submitForm = async (verificationCode: string) => {
        setShowVerification(false);
        setLoading(true);
        const updatedFormValues = {
            ...formValues,
            verificationCode: verificationCode,
          };
        userStore.changePassword(updatedFormValues)
            .then(response => {
                if (response !== null) {
                    clearCache();
                    logout({ returnTo: window.location.origin });
                }
            })
            .catch(err => {
                const formikContext = formikRef.current;
                if (err.response.status === 400 && err.response.data === 'Invalid Password') {
                    formikContext.setFieldError('currentPassword', 'Invalid Password!')
                }
                else {
                    toast.error(err.response.data, { theme: "colored" });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Modal size="tiny" centered={false} open={show} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>Change Password</Modal.Header>
            <Modal.Content>
                <Formik
                    validationSchema={validationSchema}
                    enableReinitialize
                    initialValues={password}
                    onSubmit={(values, { setFieldError }) => {
                        handleFormSubmit(values, setFieldError);
                    }}
                    innerRef={formikRef}
                    >
                    {({ handleSubmit }) => (
                        <Form id='passwordForm' className="ui form" onSubmit={e => { e.preventDefault(); handleSubmit(); }} autoComplete='Off'>
                            <TextInput type={showCurrentPassword ? 'text' : 'password'} placeholder='Old Password' name='currentPassword' maxLength={50} showRequired icon={<Icon name={showCurrentPassword ? 'eye' : 'eye slash'} size='large' id='test' link onClick={() => setShowCurrentPassword(!showCurrentPassword)} />} />
                            <TextInput type={showNewPassword ? 'text' : 'password'} placeholder='New Password' name='newPassword' maxLength={20} showRequired icon={<Icon name={showNewPassword ? 'eye' : 'eye slash'} size='large' link onClick={() => setShowNewPassword(!showNewPassword)} />} />
                            <TextInput type={showConfirmPassword ? 'text' : 'password'} placeholder='Confirm Password' name='confirmPassword' maxLength={50} showRequired icon={<Icon name={showConfirmPassword ? 'eye' : 'eye slash'} size='large' link onClick={() => setShowConfirmPassword(!showConfirmPassword)} />} />
                        </Form>
                    )}
                </Formik>
                <Message info>
                    <Icon name='info circle' />
                    Password change will redirect you to the login page
                </Message>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onCancel} content='Cancel' />
                <Button id="submitButton" form="passwordForm" primary type='submit' content='Submit' loading={loading} disabled={loading} />
            </Modal.Actions>

            {showVerification && <VerificationCodeModal show={showVerification} onCancel={() => { setLoading(false); setShowVerification(false); }} onValidCode={submitForm} userOptIn={user.optInSMS}/>}

        </Modal>
    )
}

export default observer(ChangePasswordModal)