import { CheckInfo } from "./CheckInfo";
import { WireTransferInfo } from "./WireTransferInfo";

export interface FundingInfo {
    investmentId: number;
    checkInfo: CheckInfo;
    wireTransferInfo: WireTransferInfo;
    hasStripe: boolean;
}

export class FundingInfo implements FundingInfo {
    investmentId: number = 0;
    checkInfo: CheckInfo = new CheckInfo();
    wireTransferInfo: WireTransferInfo = new WireTransferInfo();
    hasStripe: boolean = false;

    constructor(init?: FundingInfo) {
        Object.assign(this, init);
    }
}

export enum BankAccountTypeEnum {
    Unknown = 0,
    SavingsAccount = 1,
    CheckingAccount = 2
}