import { HistoricFinancialInfo } from "./HistoricFinancialInfo";
import { MonthlyInvestmentFinancial } from "./MonthlyInvestmentFinancial";

export enum ParticipationType {
  unknown = 0,
  sponsor = 1,
  shareholder = 2,
  nonShareholder = 3
}

export enum InvestmentStatus {
  draft = 0,
  interested = 1,
  raising = 2,
  hold = 3,
  active = 4,
  disposed = 5
}

export interface Investment {
    id: number;
    name: string;
    description: string;
    sponsorEntityId: number;
    sponsorName: string;
    assetManager: string;
    asofDate: string;
    created: string;
    dateActive: string;
    investmentType: number;
    isSecurity: number;
    secType: number;
    purchasePrice: number | string;
    loanProceeds: number;
    capitalRaise: number;
    loanType: number;
    targetHoldPeriod: number;
    historicFinancialInfo: HistoricFinancialInfo[];
    filteredFinancialInfo: MonthlyInvestmentFinancial;
    investmentStatus: InvestmentStatus;
    stateCode: string;
    entityParticipation: ParticipationType;
    currentFinancialInfo: MonthlyInvestmentFinancial;

    addressLine1: string;
    addressLine2: string;
    city: string;
    zip: string;
    closeDate: string;
    exitDate: string;
    dealTypeId: number;

    propertyTypeId: number;
    propertyClass: string;
    numberOfUnits: number | string;
    yearBuilt: number |string;
    yearRenovated: string;
    acquisitionDate: string;
    valueAtAcquisition: number |string;

    acquisitionFee: number | string;
    assetManagementFee: number | string;
    dispositionFee: number | string;
    refinanceFee: number | string;
    profitSharingId: number;

    minimumInvestment: number | string;
    totalRaiseAmount: number | string;
  }

  export class Investment implements Investment {
    constructor(init?: InvestmentFormValues) {
      Object.assign(this, init);
    }
  }

  export class InvestmentFormValues {
    id: number = 0;
    name: string = '';
    description: string = '';
    sponsorEntityId: number = 0;
    sponsorName: string = '';
    assetManager: string = '';
    asofDate: string = '';
    created: string = '';
    dateActive: string = '';
    investmentType: number = 0;
    isSecurity: number = 0;
    secType: number = 0;
    purchasePrice: number | string = '';
    loanProceeds: number = 0;
    capitalRaise: number = 0;
    loanType: number = 0;
    targetHoldPeriod: number = 0;
    historicFinancialInfo: HistoricFinancialInfo[] = [];
    filteredFinancialInfo: MonthlyInvestmentFinancial = new MonthlyInvestmentFinancial();
    investmentStatus: InvestmentStatus = 0;
    stateCode: string = '';
    entityParticipation: ParticipationType = 0;
    currentFinancialInfo: MonthlyInvestmentFinancial = new MonthlyInvestmentFinancial();

    addressLine1: string = '';
    addressLine2: string = '';
    city: string = '';
    zip: string = '';

    closeDate: string = '';
    exitDate: string = '';
    dealTypeId: number = 0;

    propertyTypeId: number = 0;
    propertyClass: string = '';
    numberOfUnits: number | string = '';
    yearBuilt: number |string = '';
    yearRenovated: string = '';
    acquisitionDate: string = '';
    valueAtAcquisition: number | string = '';

    acquisitionFee: number | string = '';
    assetManagementFee: number | string = '';
    dispositionFee: number | string = '';
    refinanceFee: number | string = '';
    profitSharingId: number = 0;

    minimumInvestment: number | string = '';
    totalRaiseAmount: number | string = '';

    constructor(investment?: InvestmentFormValues) {
      if (investment) {
        this.id = investment.id;
        this.name = investment.name;
        this.description = investment.description;
        this.sponsorEntityId = investment.sponsorEntityId;
        this.sponsorName = investment.sponsorName;
        this.assetManager = investment.assetManager;
        this.asofDate = investment.asofDate;
        this.created = investment.created
        this.dateActive = investment.dateActive;
        this.investmentType = investment.investmentType;
        this.isSecurity = investment.isSecurity;
        this.secType = investment.secType;
        this.purchasePrice = investment.purchasePrice;
        this.loanProceeds = investment.loanProceeds;
        this.capitalRaise = investment.capitalRaise;
        this.loanType = investment.loanType;
        this.targetHoldPeriod = investment.targetHoldPeriod;
        this.filteredFinancialInfo = investment.filteredFinancialInfo;
        this.historicFinancialInfo = investment.historicFinancialInfo;
        this.investmentStatus = investment.investmentStatus;
        this.stateCode = investment.stateCode;
        this.entityParticipation = investment.entityParticipation;
        this.currentFinancialInfo = investment.currentFinancialInfo;

        this.addressLine1 = investment.addressLine1;
        this.addressLine2 = investment.addressLine2;
        this.city = investment.city;
        this.zip = investment.zip;

        this.closeDate = investment.closeDate;
        this.exitDate = investment.exitDate;
        this.dealTypeId = investment.dealTypeId;
        this.propertyTypeId = investment.propertyTypeId;
        this.propertyClass = investment.propertyClass;
        this.numberOfUnits = investment.numberOfUnits;
        this.yearBuilt = investment.yearBuilt;
        this.yearRenovated = investment.yearRenovated;
        this.acquisitionDate = investment.acquisitionDate;
        this.valueAtAcquisition = investment.valueAtAcquisition;

        this.acquisitionFee = investment.acquisitionFee;
        this.assetManagementFee = investment.assetManagementFee;
        this.dispositionFee = investment.dispositionFee;
        this.refinanceFee = investment.refinanceFee;
        this.profitSharingId = investment.profitSharingId;

        this.minimumInvestment = investment.minimumInvestment;
        this.totalRaiseAmount = investment.totalRaiseAmount;

      }
    }
  }