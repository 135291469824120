import { useEffect, useState } from "react";
import { APIProvider, InfoWindow, Map, Marker, useMap, useMapsLibrary, useMarkerRef } from "@vis.gl/react-google-maps"
import { InvestmentSummaryView } from "../../app/models/GpLanding/InvestmentSummaryView";


interface Params {
    investment: InvestmentSummaryView
}
const getInvestmentAddress = (inv: InvestmentSummaryView) => {
    return inv.address1 + ' ' + inv.address2 + ' ' + inv.city + ' ' + inv.stateCode + ' ' + inv.zipCode;
}
function GoogleMapsComponent({ investment }: Params) {
    const googleMapsApiKey = process.env.REACT_APP_GOOGLEMAPS_API_KEY as string;

    return (
        <APIProvider apiKey={googleMapsApiKey}>
            <GeoCoding investment={investment} />
        </APIProvider>
    );
}

function GeoCoding({ investment }: Params) {
    const geocodingLibraryLoaded = useMapsLibrary("geocoding");
    const [geocodingService, setGeoCodingService] = useState<google.maps.Geocoder>();
    const [geocodingResult, setGeocodingResult] = useState<google.maps.GeocoderResult>();
    const [address, setAddress] = useState('');
    const [markerRef] = useMarkerRef();
    const [showInfo, setShowInfo] = useState(false);

    useEffect(() => {
        if (!geocodingLibraryLoaded) return;
        setGeoCodingService(new window.google.maps.Geocoder());
    }, [geocodingLibraryLoaded]);

    useEffect(() => {
        if (!geocodingService || !address) return;
        geocodingService.geocode({ address }, (results, status) => {
            if (results && status === "OK") {
                setGeocodingResult(results[0]);
            }
        })

    }, [geocodingService, address]);

    useEffect(() => {
        setAddress(getInvestmentAddress(investment));
    }, [investment])

    if (!geocodingService) return <div>Loading...</div>
    if (!geocodingResult) return <div>Geocoding...</div>

    return <>
        <div style={{ width: '100%', height: '50vh' }}>
            <Map
                
                style={{ width: '100%', height: '50vh' }}
                defaultCenter={{ lat: geocodingResult.geometry.location.lat(), lng: geocodingResult.geometry.location.lng() }}
                defaultZoom={12}
                gestureHandling={'greedy'}
                disableDefaultUI={false}
            >
                <Marker onClick={() => { setShowInfo(true) }}
                    ref={markerRef} position={{ lat: geocodingResult.geometry.location.lat(), lng: geocodingResult.geometry.location.lng() }}></Marker>
                {showInfo &&
                    <InfoWindow
                        onClose={() => {setShowInfo(false)}}
                        position={{ lat: geocodingResult.geometry.location.lat(), lng: geocodingResult.geometry.location.lng() }}
                        headerContent={investment.name}>
                        <div>
                            {geocodingResult.formatted_address}
                        </div>
                    </InfoWindow>
                }
            </Map>
        </div>
    </>
}
export default GoogleMapsComponent
