import axios, { AxiosResponse } from 'axios'
import { toast } from 'react-toastify';
import { Country } from '../models/common/country';
import { KeyValue } from '../models/common/keyValue';
import { Registration } from '../models/registration/registration';
import { State } from '../models/common/state';
import { User, UserFormValues } from '../models/registration/user';
import { store } from '../stores/store';
import { Entity } from '../models/registration/entity';
import { ChangePasswordFormValues } from '../models/settings/changePassword';
import { UserCryptoAddress } from '../models/settings/userCryptoAddress';
import { AddressBalance } from '../models/crypto/AddressBalance';
import { Transfer } from '../models/settings/transfer';
import { GasEstimate } from '../models/crypto/GasEstimate';
import { PhoneNumbers, PhoneNumbersFormValues } from '../models/settings/phoneNumbers';
import { BorrowerLoanParams, LenderLoanParams, LoanParams } from '../models/Loan/LoanParams';
import { PagedResult } from '../models/common/PagedResult';
import { LoanEnums } from '../models/Loan/LoanEnums';
import { AmortizationTable, Loan, LoanFormValues, LoanPrePayment } from '../models/Loan/Loan';
import { LoanDrawRequestAudit } from '../models/Loan/LoanDrawSchedule';
import { MonthlyStatement } from '../models/MonthlyStatement/MonthlyStatement';
import { EntityFilterParams } from '../models/common/entityFilterParams';
import { LoanPayment } from '../models/Loan/LoanPayment';
import { EntityInvestment } from '../models/Investment/EntityInvestment';
import { Investment, InvestmentFormValues } from '../models/Investment/Investment';
import { InvestmentEnums } from '../models/Investment/InvestmentEnums';
import { ShareHolderTransaction } from '../models/Investment/ShareHolderTransaction';
import { ShareClassification } from '../models/Investment/ShareClassification';
import { InvestmentQueryFilter } from '../models/common/InvestmentQueryFilter';
import { Distribution, DistributionFormValues, ShareholderDistribution } from '../models/Investment/Distribution';
import { CalculateDistribution } from '../models/Investment/CalculateDistribution';
import { MarketPlaceDashboard } from '../models/Investment/MarketPlaceDashboard';
import { Offering, OfferingFormValues, OfferingInitiate, OfferingSellEstimation } from '../models/Investment/InvestmentOffering';
import { CalculateMonthlyFinancial, InvestmentValuationInfo } from '../models/Investment/CalculateMonthlyFinancial';
import { EscrowType, LoanEscrow } from '../models/Loan/LoanEscrow';
import { CapBlockTable } from '../models/common/CapblockTable';
import { TransferHistory } from '../models/settings/transferHistory';
import { LoanEscrowFilter } from '../models/Loan/LoanEscrowFilter';
import { TokenFilter } from '../models/crypto/TokenFilter';
import { CodeType } from '../models/verificationCode/verificationCode';
import { LoanQueryFilter } from '../models/common/loanQueryFilter';
import { Document } from '../models/common/Document';
import { Task } from '../models/Notifications/task';
import { Notification } from '../models/Notifications/notifications';
import { LoanPaymentProcessing } from '../models/Loan/LoanPaymentProcessing';
import { PayoffInput } from '../models/Loan/PayoffInput';
import { PayoffInfo } from '../models/Loan/PayoffInfo';
import { MonthlyStatementFilter } from '../models/MonthlyStatement/MonthlyStatementFilter';
import { StripeAccountInfo } from '../models/settings/stripeaccountinfo';
import { LoanFeeEnums } from '../models/Loan/LoanFeeEnums';
import { StripeBalance } from '../models/stripe/balance';
import { ZelleAccountProfile } from '../models/zelle/zelleAccountProfile';
import { AvailablePaymmentMethods } from '../models/Loan/AvailablePaymentMethods';
import { WireXferProfile } from '../models/wireXfer/wireXferProfile';
import { SponsorContactParams } from '../models/sponsorContacts/SponsorContactParams';
import { SponsorContact } from '../models/sponsorContacts/SponsorContact';
import { FundingInfo } from '../models/Investment/FundingInfo';
import { MonthlyInvestmentFinancialFormValues } from '../models/Investment/MonthlyInvestmentFinancial';
import { NewMonthlyInvestmentFinancialFormValues } from '../models/Investment/Manage/NewMonthlyInvestmentFinancial';
import { KeyMetric } from '../models/Investment/KeyMetric';
import { FileImportJobStatusDTO } from '../models/sponsorContacts/FileImportJobStatusDTO';
import { EntityProfileDTO } from '../models/registration/entityProfile';
import { EntityInvestmentInsightsView } from '../models/GpLanding/EntityInvestmentInsightsView';
import { EntityReview } from '../models/GpLanding/entityReview';
import { InvestmentSummaryView } from '../models/GpLanding/InvestmentSummaryView';
import { SponsorshipTeamView } from '../models/GpLanding/sponsorshipTeamView';
import { EntityProfileView } from '../models/GpLanding/entityProfileView';
import { SponsorContactAccess } from '../models/sponsorContacts/SponsorContactAccess';
import { InvestmentShareInfo } from '../models/Investment/InvestmentShareInfo';
import { InvestmentDebtFinanceView } from '../models/Investment/InvestmentDebtFinanceView';
import { InvestmentTermsFeesView } from '../models/Investment/InvestmentTermsFeesView';
import { InvestmentAssetDetailsView } from '../models/Investment/InvestmentAssetDetailsView';
axios.defaults.baseURL = process.env.REACT_APP_AUTH0_AUDIENCE;

axios.interceptors.response.use(async response => {
    try {
        return response;
    } catch (error) {
        console.log(error);
        return await Promise.reject(error);
    }
}, (error) => {
    const { data, status } = error.response!;
    switch (status) {
        case 400:
            if (data.errors) {
                const modalStateErrors = [];
                for (const key in data.errors) {
                    modalStateErrors.push(data.errors[key]);
                }
                throw modalStateErrors.flat();
            }
            break;
        case 401:
            toast.error('Unauthorized', { theme: "colored" });
            break;
        case 404:
            toast.error('Not found', { theme: "colored" });
            break;
        case 500:
            store.commonStore.setServerError(data);
            break;
    }
    return Promise.reject(error);
})

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}

const Users = {
    create: (registration: Registration) => {
        let formData = new FormData();
        formData.append('registration', JSON.stringify(registration));
        return axios.post('registration', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        })
    },
    getUserFromAuth: () => requests.get<UserFormValues>('user/current-user-auth'),
    getCurrentUser: () => requests.get<UserFormValues>('user/get-user-info'),
    changePassword: (passwords: ChangePasswordFormValues) => requests.put<void>('user/change-password', passwords),
    getUserPhones: () => requests.get<PhoneNumbersFormValues>('user/user-phones'),
    updateUserPhones: (userPhones: PhoneNumbers) => requests.put<void>('user/user-phones', userPhones),
    updateUser: (user: UserFormValues) => requests.put<void>('user', user),
    saveUserVerificationOptions: (user: User) => requests.put<boolean>('user/update-two-step-info', user),
}

const Entities = {
    create: (entity: Entity) => {
        let formData = new FormData();
        formData.append('entity', JSON.stringify(entity));
        if (entity.entityType === 1) {
            formData.append('file', entity.individual?.dlFile ?? new Blob());
        }
        return axios.post('entity', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        })
    },
    update: (entity: Entity) => {
        let formData = new FormData();
        formData.append('entity', JSON.stringify(entity));
        if (entity.entityType === 1) {
            formData.append('file', entity.individual?.dlFile ?? new Blob());
        }
        return axios.put('entity', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        })
    },
    delete: (entityId: number) => requests.del<boolean>(`entity/${entityId}`),
    get: (entityId: number) => requests.get<Entity>(`entity/${entityId}`),
    getUserEntities: () => requests.get<Entity[]>('entity/user-entities'),
    getBorrowerEntities: () => requests.get<Entity[]>('entity/borrower-entities')
}

const Common = {
    getStates: () => requests.get<State[]>('common/states'),
    getCountries: () => requests.get<Country[]>('common/countries'),
    getResidencyStatus: () => requests.get<KeyValue[]>('common/residency-status'),
    getLoanEnums: () => requests.get<LoanEnums>('common/loan-enums'),
    getInvestmentEnums: () => requests.get<InvestmentEnums>('common/investment-enums'),
    getDocumentTypeEnums: () => requests.get<KeyValue[]>('common/document-types'),
    getOfferingExpiryDurationEnums: () => requests.get<KeyValue[]>('common/offering-expiry-duration'),
    getEscrowTypes: () => requests.get<EscrowType[]>('common/escrow-type'),
    getFeeEnums: () => requests.get<LoanFeeEnums>('common/fee-enums'),
}

const VerificationCode = {
    getCode: (codeType: CodeType, alternatePhone?: string) => {
        let query = `VerificationCode?codeType=${codeType}`;
        if (alternatePhone) {
            query += `&alternatePhone=${alternatePhone}`;
        }
        return requests.post<boolean>(query, {});
    },
    verifyCode: (code: string) => requests.post<boolean>(`verificationcode/verify?verificationCode=${code}`, {}),
}

const UsersCryptoAddress = {
    save: (userCryptoAddress: UserCryptoAddress) => requests.post<boolean>('usercryptoaddress', userCryptoAddress),
    getUserAddresses: (params: EntityFilterParams) => axios.get<PagedResult<UserCryptoAddress[]>>('usercryptoaddress', { params }).then(responseBody),
    getTargetEntityAddress: (entityId: number, targetEntityId: number) => requests.get<UserCryptoAddress>(`usercryptoaddress/${entityId}/target-entity-address/${targetEntityId}`),
    delete: (userCryptoAddressId: number) => requests.del<boolean>(`usercryptoaddress/${userCryptoAddressId}`),
}

const Crypto = {
    getUserBalance: (entityId: number) => requests.get<AddressBalance>(`crypto/${entityId}`),
    getUserTokenBalance: (capblockTable: CapBlockTable, senderTableKeyId: number, tokenType: string) => requests.get<AddressBalance>(`crypto/get-token-balance?capblockTable=${capblockTable}&tableKeyId=${senderTableKeyId}${tokenType !== "" ? `&tokenType=${tokenType}` : ""}`),
    getGasEstimate: (capblockTable: CapBlockTable, senderTableKeyId: number, transferInfo: Transfer) => requests.post<GasEstimate>(`crypto/calculate-gas?senderCapblockTable=${capblockTable}&senderTableKeyId=${senderTableKeyId}`, transferInfo),
    getTokenHistory: (params: TokenFilter) => axios.get<PagedResult<TransferHistory[]>>('crypto/token-history', { params }).then(responseBody),
}

const TransferAgent = {
    transfer: (transfer: Transfer) => requests.post<boolean>('transfer', transfer),
    getTransferByHash: (trxHash: string) => requests.get<Transfer>(`transfer/trx-hash?hash=${trxHash}`),
    transferAction: (transfer: Transfer) => requests.post<boolean>('transfer/transfer-action', transfer),
}

const Loans = {
    getLenderLoans: (params: LenderLoanParams) => axios.get<PagedResult<Loan[]>>('loan/lender', { params }).then(responseBody),
    getBorrowerLoans: (params: BorrowerLoanParams) => axios.get<PagedResult<Loan[]>>('loan/borrower', { params }).then(responseBody),
    save: (loan: LoanFormValues) => requests.post<Loan>('loan/', loan),
    get: (loanId: number) => requests.get<Loan>(`loan/${loanId}`),
    delete: (loanId: number) => requests.del<boolean>(`loan/${loanId}`),
    finalize: (loanId: number) => requests.put<boolean>(`loan/finalize/${loanId}`, {}),
    calculatePrePayment: (loan: LoanFormValues) => requests.post<LoanPrePayment[]>('loan/pre-payment', loan),
    getLoanPaymentHistory: (params: LoanParams) => axios.get<PagedResult<LoanPayment[]>>('loan/payment-history/', { params }).then(responseBody),
    calculateAmortization: (loan: LoanFormValues, pageSize: number, pageIndex: number) => requests.post<PagedResult<AmortizationTable[]>>(`loan/amortization-table?pageSize=${pageSize}&pageIndex=${pageIndex}`, loan),
    processPayment: (entityId: number, loanPayment: LoanPaymentProcessing) => requests.post<number>(`loan/process-payment/${entityId}`, loanPayment),

    getLoanEscrowById: (loanId: number, loanEscrowId: number) => requests.get<LoanEscrow>(`loan/${loanId}/escrow/${loanEscrowId}`),
    getLoanEscrows: (params: LoanEscrowFilter) => axios.get<PagedResult<LoanEscrow[]>>('loan/escrows', { params }).then(responseBody),
    saveLoanEscrow: (loanEscrow: LoanEscrow) => requests.post<boolean>('loan/save-escrow', loanEscrow),
    getLoanEscrowHistory: (params: LoanEscrowFilter) => axios.get<PagedResult<TransferHistory[]>>('loan/escrow-history', { params }).then(responseBody),

    getLoanDocuments: (params: LoanQueryFilter) => axios.get<PagedResult<Document[]>>('loan/loan-documents', { params }).then(responseBody),
    deleteLoanDocument: (entityId: number, loanId: number, documentId: number) => axios.delete<boolean>(`loan/delete-loan-document/${entityId}/${loanId}/${documentId}`),
    uploadLoanDocuments: (input: FormData, timeoutInSec: number) => axios.post('loan/upload-loan-documents', input, { timeout: timeoutInSec }),
    getPayoffStatement: (payoffInput: PayoffInput) => axios.get<PayoffInfo>('loan/payoff-statement', { params: payoffInput }).then(responseBody),
    downloadPayoffStatement: (payoffInput: PayoffInput) => axios.get('loan/payoff-statement-download', { params: payoffInput, responseType: "blob" }),
    processPayoff: (payoffInput: PayoffInput) => requests.post<number>(`loan/process-payoff`, payoffInput),
    downloadPayoffPayment: (loanId: number) => axios.get(`loan/payoff-payment-download/${loanId}`, { responseType: "blob" }),
    downloadLenderBreakdown: (loanId: number) => axios.get(`loan/lender-breakdown-download/${loanId}`, { responseType: "blob" }),

    getLoanPaymentHistoryExport: (params: LoanParams) => axios.get(`loan/payment-history-export`, { params: params, responseType: "blob" }),
}

const LoanDraws = {
    requestLoanDraw: (entityId: number, loanDrawScheduleId: number, drawRequestedAmount: number) => requests.post<boolean>(`loandraw/request-draw/${entityId}/${loanDrawScheduleId}/${drawRequestedAmount}`, {}),
    getLoanDrawRequestHistory: (loanId: number) => requests.get<LoanDrawRequestAudit[]>(`loandraw/loan-draw-request-history/${loanId}`),
    denyLoanDraw: (entityId: number, loanDrawScheduleId: number, denyReason: string) => {
        var input = { entityId: entityId, loanDrawScheduleId: loanDrawScheduleId, denyReason: denyReason };
        return requests.post<boolean>('loandraw/deny-draw/', input);
    },
    cancelLoanDraw: (entityId: number, loanDrawScheduleId: number) => {
        var input = { entityId: entityId, loanDrawScheduleId: loanDrawScheduleId };
        return requests.post<boolean>('loandraw/cancel-draw/', input);
    },
    processLoanDraw: (entityId: number, loanDrawScheduleId: number, drawRequestedAmount: number, drawDate: Date) => {
        var input = { entityId: entityId, loanDrawScheduleId: loanDrawScheduleId, drawAmount: drawRequestedAmount, drawDate: drawDate };
        return requests.post<boolean>('loandraw/process-draw', input);
    },
}

const MonthlyStatementAgent = {
    getMonthlyStatementDue: (loanId: number) => requests.get<MonthlyStatement[]>(`monthlystatement/due/${loanId}`),
    downloadMonthlyStatement: (loanId: number, statementId: number) => axios.get(`monthlystatement/download/${loanId}/${statementId}`, { responseType: "blob" }),
    getMonthlyStatements: (params: MonthlyStatementFilter) => axios.get<PagedResult<MonthlyStatement[]>>('monthlystatement/statements', { params }).then(responseBody),
    downloadMonthlyStatementJSON: (loanId: number, statementId: number) => axios.get(`monthlystatement/download-json/${loanId}/${statementId}`, { responseType: "blob" }),
    deleteMonthlyStatement: (entityId: number, loanId: number, statementId: number) => axios.delete<Date>(`monthlystatement/delete/${entityId}/${loanId}/${statementId}`),
    regenerateStatement: (entityId: number, loanId: number, statementId: number) => axios.post<boolean>(`monthlystatement/regenerate/${entityId}/${loanId}/${statementId}`),
    generateNextStatement: (entityId: number, loanId: number) => axios.post<Date>(`monthlystatement/generate-next/${entityId}/${loanId}`),
}

const InvestmentAgent = {
    getEntityInvestments: (params: EntityFilterParams) => axios.get<PagedResult<EntityInvestment[]>>('investment/entity', { params }).then(responseBody),
    checkSponsorEntity: (entityId: number) => requests.get<PagedResult<EntityInvestment[]>>(`investment/sponsor-entity/${entityId}`),
    getSponsorInvestments: (params: EntityFilterParams) => axios.get<PagedResult<EntityInvestment[]>>('investment/sponsor', { params }).then(responseBody),
    getInvestmentWithFinancial: (entityId: number, investmentId: number) => requests.get<Investment>(`investment/investment-with-financial/${entityId}/${investmentId}`),
    getMonthlyInvestmentFinancial: (entityId: number, investmentId: number, financialId: number) => requests.get<Investment>(`investment/investment-with-financial/${entityId}/${investmentId}/${financialId}`),
    getShareTransactions: (params: InvestmentQueryFilter) => axios.get<PagedResult<ShareHolderTransaction[]>>('investment/share-transactions', { params }).then(responseBody),
    getShareClassifications: (params: InvestmentQueryFilter) => axios.get<PagedResult<ShareClassification[]>>('investment/share-classifications', { params }).then(responseBody),
    getShareClassCapTable: (params: InvestmentQueryFilter) => axios.get<PagedResult<ShareClassification[]>>('investment/share-class-cap-table', { params }).then(responseBody),
    getShareClassLotTransactions: (entityId: number, investmentId: number, shareClass: string) => axios.get<ShareHolderTransaction[]>(`investment/share-classification-lot-transactions/${entityId}/${investmentId}/${shareClass}`).then(responseBody),

    getDistributions: (params: InvestmentQueryFilter) => axios.get<PagedResult<Distribution[]>>('investment/distributions', { params }).then(responseBody),
    uploadInvestmentDocuments: (input: FormData, timeoutInSec: number) => axios.post('investment/upload-investment-documents', input, { timeout: timeoutInSec }),
    getInvestmentDocuments: (params: InvestmentQueryFilter) => axios.get<PagedResult<Document[]>>('investment/investment-documents', { params }).then(responseBody),
    getShareholderDistributions: (params: InvestmentQueryFilter) => axios.get<PagedResult<Distribution[]>>('investment/shareholder-distributions', { params }).then(responseBody),
    deleteInvestmentDocument: (entityId: number, investmentId: number, investmentDocumentId: number) => axios.delete<boolean>(`investment/delete-investment-document/${entityId}/${investmentId}/${investmentDocumentId}`),
    getInvestorEntities: (entityId: number, investmentId: number) => axios.get<KeyValue[]>(`investment/investor-entities/${entityId}/${investmentId}`).then(responseBody),

    getDistributionDetail: (entityId: number, investmentId: number, distributionId: number) => requests.get<Distribution>(`investment/${entityId}/${investmentId}/distribution/${distributionId}`),
    getDistributionShareholders: (params: InvestmentQueryFilter) => axios.get<PagedResult<ShareholderDistribution[]>>('investment/distribution-shareholders', { params }).then(responseBody),
    calculatePreDistribution: (params: CalculateDistribution) => requests.post<PagedResult<ShareholderDistribution[]>>('investment/pre-distribution', params),
    saveDistribution: (distribution: DistributionFormValues) => requests.post<number>(`investment/save-distribution`, distribution),

    saveMonthlyFinancial: (investmentId: number, financial: MonthlyInvestmentFinancialFormValues) => requests.post<number>(`investment/save-monthly-financial/${investmentId}`, financial),
    calculateMonthlyFinancial: (financial: CalculateMonthlyFinancial) => requests.post<InvestmentValuationInfo>('investment/calculate-monthly-financial', financial),

    getInvestmentById: (entityId: number, investmentId: number) => requests.get<Investment>(`investment/${entityId}/${investmentId}`),
    saveInvestment: (investment: InvestmentFormValues) => {
        let formData = new FormData();
        formData.append('investment', JSON.stringify(investment));
        return axios.post<number>('investment/save-investment', formData);
    },

    manageInvestments: (params: EntityFilterParams) => axios.get<PagedResult<EntityInvestment[]>>('investment/manage', { params }).then(responseBody),

    getFundingInfo: (investmentId: number) => requests.get<FundingInfo>(`investment/funding-info/${investmentId}`),
    saveInvestmentFunding: (funding: FundingInfo) => requests.post<FundingInfo>(`investment/save-investment-funding`, funding),

    getDebtInfo: (investmentId: number) => requests.get<NewMonthlyInvestmentFinancialFormValues>(`investment/debt-info/${investmentId}`),
    saveInvestmentDebt: (monthlyFinancial: NewMonthlyInvestmentFinancialFormValues) => {
        let formData = new FormData();
        formData.append('financial', JSON.stringify(monthlyFinancial));
        return axios.post<number>('investment/save-investment-debt', formData);
    },

    getKeyMetrics: (investmentId: number) => requests.get<KeyMetric[]>(`investment/key-metrics/${investmentId}`),
    getKeyMetric: (keyMetricId: number) => requests.get<KeyMetric>(`investment/key-metric/${keyMetricId}`),
    saveInvestmentKeyMetric: (keyMetric: KeyMetric) => requests.post<KeyMetric>(`investment/save-investment-keymetric`, keyMetric),
    updateInvestmentKeyMetricHeader: (keyMetricId: number, showinheader: boolean) => axios.post<boolean>(`investment/save-investment-keymetric-showinheader/${keyMetricId}/${showinheader}`),
    deleteInvestmentKeyMetric: (keyMetricId: number) => requests.del<boolean>(`investment/delete-investment-keymetric/${keyMetricId}`),
    updateInvestmentKeyMetricOrder: (investmentId: number, keyMetrics: KeyMetric[]) => requests.put<boolean>(`investment/update-investment-keymetric-order/${investmentId}`, keyMetrics),

    uploadInvestmentImages: (input: FormData, timeoutInSec: number) => axios.post('investment/upload-investment-Images', input, { timeout: timeoutInSec }),
    getInvestmentImages: (params: InvestmentQueryFilter) => axios.get<PagedResult<Document[]>>('investment/investment-Images', { params }).then(responseBody),
    getInvestmentAllImages: (investmentId: number) => axios.get<Document[], any>(`investment/investment-Images-All/${investmentId}`),

    deleteInvestmentImage: (entityId: number, investmentId: number, investmentImageId: number) => axios.delete<boolean>(`investment/delete-investment-Image/${entityId}/${investmentId}/${investmentImageId}`),
    updateInvestmentImageOrder: (investmentId: number, images: Document[]) => requests.put<boolean>(`investment/update-investment-image-order/${investmentId}`, images),
    saveInvestmentImage: (investmentId: number, image: Document) => {
        let formData = new FormData();
        formData.append('image', JSON.stringify(image));
        return axios.post<number>(`investment/save-investment-image/${investmentId}`, formData);
    },

    saveShareClassification: (investmentId: number, shares: ShareClassification[]) => {
        let formData = new FormData();
        formData.append('investmentShareClassifications', JSON.stringify(shares));
        return axios.post<ShareClassification[]>(`investment/save-share-classification/${investmentId}`, formData);
    },
    getShareClassificationsByInvestment: (investmentId: number) => requests.get<ShareClassification[]>(`investment/investment-share-classification/${investmentId}`),
    getEntityPartners: (entityId: number) => requests.get<Entity[]>(`investment/investment-entity-partners/${entityId}`),
    getInvestmentShareInfo: (investmentId: number) => requests.get<InvestmentShareInfo[]>(`investment/investment-share-info/${investmentId}`),
}

const InvestmentOfferingAgent = {
    getOffering: (entityId: number, investmentOfferingId: number) => axios.get<Offering>(`investmentoffering/offering/${entityId}/${investmentOfferingId}`).then(responseBody),
    getOfferings: (params: InvestmentQueryFilter) => axios.get<PagedResult<Offering[]>>('investmentoffering/offerings', { params }).then(responseBody),
    getOfferingSellEstimation: (params: OfferingSellEstimation) => axios.get<Offering>('investmentoffering/offering-sell-estimations', { params }).then(responseBody),
    getEntityShareCountAndBalance: (entityId: number, investmentId: number) => axios.get<Offering>(`investmentoffering/entity-share-count-and-balance/${entityId}/${investmentId}`).then(responseBody),
    getPendingApprovalOfferings: (params: InvestmentQueryFilter) => axios.get<PagedResult<Offering[]>>('investmentoffering/pending-approval-offerings', { params }).then(responseBody),
    saveOffering: (offering: OfferingFormValues) => requests.post<boolean>('investmentoffering/save-offering', offering),
    processOfferingWorkflow: (input: OfferingInitiate) => requests.post<boolean>('investmentoffering/process-offering-workflow', input),
    deleteOffering: (entityId: number, investmentOfferingId: number) => axios.delete<boolean>(`investmentoffering/delete-investment-offering/${entityId}/${investmentOfferingId}`),
    getOfferingNotification: (entityId: number, investmentId: number) => axios.get<boolean>(`investmentoffering/offering-notification/${entityId}/${investmentId}`).then(responseBody),
    saveOfferingNotificationSubscription: (entityId: number, investmentId: number, subscribe: boolean) => axios.post<boolean>(`investmentoffering/save-offering-notification-subscription/${entityId}/${investmentId}/${subscribe}`),
}

const MarketPlace = {
    getMarketPlaceDashboard: (params: EntityFilterParams) => axios.get<PagedResult<MarketPlaceDashboard[]>>('marketplace/dashboard', { params }).then(responseBody),
    getMarketplaceInvestmentInfo: (investmentId: number) => requests.get<MarketPlaceDashboard>(`marketplace/investmentinfo/${investmentId}`),
}

const Notifications = {

    getEntityTasks: (params: EntityFilterParams) => axios.get<PagedResult<Task[]>>('notification/tasks', { params }).then(responseBody),
    getEntityNotifications: (params: EntityFilterParams) => axios.get<PagedResult<Notification[]>>('notification/notifications', { params }).then(responseBody),
    updateNotificationStatus: (entityId: number, notificationId: number) => requests.put<boolean>(`notification/update-status/${entityId}/${notificationId}`, {}),
}

const Stripe = {
    getStripeAcountInfo: (entityId: number) => requests.get<StripeAccountInfo>(`stripe/stripe-acct-info/${entityId}`),
    onBoardEntity: (entityId: number) => requests.post<string>(`stripe/stripe-onboard-entity/${entityId}`, {}),
    getStripeAccountBalance: (entityId: number) => requests.get<StripeBalance>(`stripe/stripe-get-balance/${entityId}`),
    getDashboardUrl: (entityId: number) => requests.get<string>(`stripe/stripe-get-dashboard/${entityId}`),
    getPaymentUrl: (statementId: number) => requests.get<string>(`stripe/stripe-get-paymenturl/${statementId}`)
}

const Zelle = {
    getZellProfile: (entityId: number) => requests.get<ZelleAccountProfile>(`zelle/zelle-profile/${entityId}`),
    saveZelleProfile: (profile: ZelleAccountProfile) => requests.post<ZelleAccountProfile>('zelle/zelle-profile', profile),
    deleteZelleProfile: (entityId: number) => requests.del<number>(`zelle/zelle-profile/${entityId}`)
}

const WireXfer = {
    getWireXferProfile: (entityId: number) => requests.get<WireXferProfile>(`wireTransfer/wirexfer-profile/${entityId}`),
    saveWireXrerProfile: (profile: WireXferProfile) => requests.post<WireXferProfile>('wireTransfer/wirexfer-profile', profile),
    deleteWireXferProfile: (entityId: number) => requests.del<number>(`wireTransfer/wirexfer-profile/${entityId}`)
}

const PaymentMethods =
{
    getAvailablePaymentMethods: (loanId: number) => requests.get<AvailablePaymmentMethods[]>(`loan/lender-get-payment-methods/${loanId}`)
}

const SponsorContacts =
{
    getSponsorContacts: (params: SponsorContactParams) => axios.get<PagedResult<SponsorContact[]>>('sponsorcontact/get-all', { params }).then(responseBody),
    getSponsorContactById: (entityId: number, sponsorContactId: number) => requests.get<SponsorContact>(`sponsorcontact/${entityId}/${sponsorContactId}`),
    deleteSponsorContact: (entityId: number, id: number) => requests.del<number>(`sponsorcontact/${entityId}/${id}`),
    saveSponsorContact: (sponsorContact: SponsorContact) => requests.post<SponsorContact>('sponsorcontact/save', sponsorContact),
    getIsConnected: (sponsorEntityId: number, entityId: number) => requests.get<boolean>(`sponsorcontact/isconnected/${sponsorEntityId}?entityId=${entityId}`),
    createSponsorConnection: (sponsorEntityId: number, entityId: number, entityType: number) => requests.post<SponsorContactAccess>(`sponsorcontact/connect/${sponsorEntityId}/${entityId}/${entityType}`, {}),
    sendSingleWelcomeEmail: (entityId:number, contactId:number) => requests.post(`sponsorcontact/send-invitation/${entityId}/${contactId}`,{})
}

const CsvFileImport =
{
    submitFile: (input: FormData, timeoutInSec: number) => axios.put('fileimport/submit-file', input, { timeout: timeoutInSec }),
    getLastJobStatus: (entityId: number) => requests.get<FileImportJobStatusDTO>(`fileimport/get-last-job/${entityId}`),
    getJobStatus: (entityId: number, jobId: number) => requests.get(`fileimport/get-job-status/${entityId}/${jobId}`),
    downloadCsvTemplate: () => axios.get(`fileimport/get-csv-template`, { responseType: "blob" }),
}

const EntityProfile =
{
    getProfile: (entityId: number) => requests.get<EntityProfileDTO>(`entity/profile/${entityId}`),
    saveProfile: (profile: FormData) => requests.post('entity/profile', profile),
    getLandingPageProfile: (entityId: number) => requests.get<EntityProfileView>(`gplanding/${entityId}`),
    getLandingPageInsights: (entityId: number) => requests.get<EntityInvestmentInsightsView>(`gplanding/insights/${entityId}`),

    getSponsorReviews: (entityId: number, numberOfReviews: number) => requests.get<EntityReview[]>(`gplanding/reviews/${entityId}?count=${numberOfReviews}`),
    getSponsorReview: (entityId: number) => requests.get<EntityReview>(`gplanding/review/${entityId}`),
    saveSponsorReview: (review: EntityReview) => requests.post('gplanding/review', review),

    getGPInvestmentTrackRecord: (entityId: number) => requests.get<InvestmentSummaryView[]>(`gplanding/trackrecord/${entityId}`),
    getGPInvestmentActiveDeals: (entityId: number) => requests.get<InvestmentSummaryView[]>(`gplanding/activedeals/${entityId}`),
    getGpSponsorshipTeam: (entityId: number) => requests.get<SponsorshipTeamView[]>(`gplanding/sponsorshipteam/${entityId}`)
}

const InvestmentInsights =
{
    getInvestmentOverview: (investmentId:number) => requests.get<InvestmentSummaryView>(`investmentinsights/overview/${investmentId}`),
    getInvestmentDebtFinance: (investmentId:number) => requests.get<InvestmentDebtFinanceView>(`investmentinsights/debt-financing/${investmentId}`),
    getInvestmentTermsFees: (investmentId:number) => requests.get<InvestmentTermsFeesView>(`investmentinsights/terms-fees/${investmentId}`),
    getInvestmentAssetDetails: (investmentId:number) => requests.get<InvestmentAssetDetailsView>(`investmentinsights/asset-details/${investmentId}`),
    getKeyMetricsHeader: (investmentId:number) => requests.get<KeyMetric[]>(`investmentinsights/metrics-header/${investmentId}`),
    getProjectedReturns: (investmentId:number) => requests.get<KeyMetric[]>(`investmentinsights/projected-returns/${investmentId}`),
    getSponsorInvestmentDocuments: (params: InvestmentQueryFilter) => axios.get<PagedResult<Document[]>>('investmentinsights/sponsor-investment-documents', { params }).then(responseBody),
    getSponsorshipTeam: (investmentId:number) => requests.get<SponsorshipTeamView[]>(`investmentinsights/sponsorship-team/${investmentId}`)

}

const agent = {
    Users,
    Entities,
    Common,
    VerificationCode,
    UsersCryptoAddress,
    Crypto,
    TransferAgent,
    Loans,
    LoanDraws,
    MonthlyStatementAgent,
    InvestmentAgent,
    MarketPlace,
    InvestmentOfferingAgent,
    Notifications,
    Stripe,
    Zelle,
    PaymentMethods,
    WireXfer,
    SponsorContacts,
    CsvFileImport,
    EntityProfile,
    InvestmentInsights
}

export default agent;