import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Dropdown, Icon, Menu, Image, Popup, List, Label } from 'semantic-ui-react'
import EntityContext from '../../../context/entityContext';
import { EntityFilterParams } from '../../../models/common/entityFilterParams';
import { Entity } from '../../../models/registration/entity';
import { useStore } from '../../../stores/store';


interface Props {
    showSidebar: () => void,
    logoutHandler: () => void,
    showNotificationBar: () => void,
}

function TopMenu({ showSidebar, logoutHandler, showNotificationBar }: Props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { entityStore, notificationStore } = useStore();
    const { entity, setEntity } = useContext(EntityContext);
    const [entities, setEntities] = useState([new Entity()]);
    const [isEntityLoading, setIsEntityLoading] = useState(true);
    const { isAuthenticated, isLoading, user } = useAuth0();
    const showFullSize = useMediaQuery({ query: "(min-width:576px)" })
    const [totalTasks, setTotalTasks] = useState(0);

    useEffect(() => {
        if (isAuthenticated) {
            entityStore.getUserEntities().then(r => {
                setEntities(r);
                setIsEntityLoading(false);
            });

            const tasks: EntityFilterParams = {
                entityId: entity.id,
                sortIndex: 'Name',
                sortOrder: 'descending',
                pageIndex: 0,
                pageSize: 1
            };

            notificationStore.getEntityTasks(tasks).then(r => {
                setTotalTasks(r.totalRecords);
            });
        }
    }, [entityStore, isAuthenticated, notificationStore, entity]);

    return (
        <Menu fixed='top' inverted borderless size={showFullSize ? 'large' : 'tiny'} className="nav-menu">
            <Icon link name='sidebar' size='large' onClick={showSidebar} className={showFullSize ? 'burgerMenu' : 'burgerMenuSmall'} />
            <Menu.Item header>
                <Image src='/assets/logo white.png' size={showFullSize ? 'small' : 'tiny'} as={NavLink} to='/dashboard' />
            </Menu.Item>
            {showFullSize && !isLoading && isAuthenticated && (
                <>
                    {entity.id > 0 &&
                        <Menu.Item>
                            <Dropdown pointing='top left' text={entity.name} className="nav-sel">
                                <Dropdown.Menu>
                                    {!isLoading && !isEntityLoading && (
                                        entities.map((entity) => (
                                            <Dropdown.Item key={"top_menu_" + entity.id} value={entity.id} text={entity.name}
                                                onClick={() => { setEntity(entity); navigate(location.pathname); }} />
                                        ))
                                    )}
                                    {!isLoading &&
                                        <>
                                            <Dropdown.Divider />
                                            <Dropdown.Item text="Manage Entities"
                                                onClick={() => navigate("/entities")} />
                                        </>
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                    }
                </>
            )}
            <Menu.Menu position='right'>
                <Menu.Item>
                    <Icon inverted name="bell" size='large' onClick={showNotificationBar} style={{ cursor: 'pointer' }} />
                    {totalTasks > 0 && <Label circular color='red' empty className='task-indicator' />}
                </Menu.Item>
                <Menu.Item position="right">
                    <Popup content={
                        <>
                            <List divided relaxed selection size='large'>
                                <List.Item>
                                    <List.Content><b>{user?.name}</b></List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name='setting' verticalAlign='middle' />
                                    <List.Content>
                                        <List.Header onClick={() => navigate('/settings')}>Settings</List.Header>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name='power' verticalAlign='middle' />
                                    <List.Content>
                                        <List.Header onClick={logoutHandler}>Logout</List.Header>
                                    </List.Content>
                                </List.Item>
                            </List>
                        </>
                    }
                        on='click'
                        trigger={<Image src={user?.picture || './assets/user.png'} avatar spaced='right' style={{ cursor: "pointer" }} />}
                    />
                </Menu.Item>
            </Menu.Menu>
        </Menu>
    )
}

export default TopMenu