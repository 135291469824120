export interface InvestmentShareInfo {
    name: string;
    shareType: number;
    typeOfEquity: string;
    minimumInvestment: number;

    split: UpsideSplit[];
}

export class InvestmentShareInfo implements InvestmentShareInfo {
    constructor(init?: InvestmentShareInfo) {
        Object.assign(this, init);
    }
}

export interface UpsideSplit {
    description: string;
    limit: string;
}
export class UpsideSplit implements UpsideSplit {
    constructor(init?: UpsideSplit) {
        Object.assign(this, init);
    }
}