export interface Document {
  id: number;
  originalFileName: string;
  documentName: string;
  fileSizeInKb: number;
  documentType: number;
  created: string;
  createdBy: number;
  isShared: boolean;
  shortLivedBlobUrl: string;
  sharedWithEntityId: number;
  sharedWithEntityName: string;
  file: File;
  fileExtension: string;
  info: string;
  sortOrder: number;
  path: string;
}

export class Document implements Document {
  constructor(init?: Document) {
    Object.assign(this, init);
  }
}