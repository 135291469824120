import { useState } from "react"
import { useIdleTimer } from "react-idle-timer"

interface Props
{
    onIdle: () => void
}

const useIdleTimeout = ({ onIdle }: Props) => {
    const idleTimeInMs = parseInt(process.env.REACT_APP_TIMEOUT_IN_MINUTES ?? "25") * 60000;
    const promptTimeInMs = parseInt(process.env.REACT_APP_PROMPT_BEFORE_TIMEOUT_IN_MINUTES ?? "2") * 60000;
    const [isIdle, setIdle] = useState(false)

    const handleIdle = () => {
        setIdle(true);
    }

    const idleTimer = useIdleTimer({
        timeout: idleTimeInMs,
        promptBeforeIdle: promptTimeInMs,
        onPrompt: onIdle,
        onIdle: handleIdle,
        debounce: 500
    })
    return {
        isIdle,
        setIdle,
        idleTimer
    }
}
export default useIdleTimeout;