import { faLocationDot } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Header, StatisticGroup, StatisticValue, StatisticLabel, Statistic, Segment, Grid, GridColumn, GridRow } from "semantic-ui-react"
import { InvestmentSummaryView } from "../../app/models/GpLanding/InvestmentSummaryView";
import InvestmentImages from "../investment/Details/InvestmentImages";
import { formatCurrency, formatDate } from "../../shared/utils";
import { Link } from "react-router-dom";

interface Params {
    investment: InvestmentSummaryView;
    isConnected: boolean;
}

function GPInvestmentCard({ investment, isConnected }: Params) {
    return (
        <Segment clearing>
            <Grid className='classTable'>
                <GridRow>
                    <GridColumn>
                        <Header as='h3' color='blue' block>
                            {isConnected 
                            ?
                                <Link to={`/investor/investment-insights/${investment.investmentId}`}>{investment.name}</Link>
                            :
                            <>
                                {investment.name}
                            </>
                            }
                        </Header>
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <InvestmentImages headerText={""} investmentId={investment.investmentId} entityId={investment.sponsorEntityId} enableInvestmentLink={isConnected}></InvestmentImages>
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <p>
                            <FontAwesomeIcon size={'2x'} icon={faLocationDot} style={{ marginRight: 8 }} />
                            {investment.city !== '' ?
                                <>
                                    {investment.city}, {investment.stateCode}
                                </>
                                :
                                <>{investment.stateCode}
                                </>
                            }
                        </p>
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <StatisticGroup size="mini" widths='3' color='blue'>
                            <Statistic className='investmentSummaryStatistics' >
                                <StatisticValue >{formatCurrency(investment.minimumInvestment)}</StatisticValue>
                                <StatisticLabel>Minimum Investment</StatisticLabel>
                            </Statistic>
                            <Statistic className='investmentSummaryStatistics'>
                                <StatisticValue>{investment.secType}</StatisticValue>
                                <StatisticLabel>SEC Type</StatisticLabel>
                            </Statistic>
                            <Statistic className='investmentSummaryStatistics'>
                                <StatisticValue>{formatCurrency(investment.totalRaiseAmount)}</StatisticValue>
                                <StatisticLabel>Total Raise Amount</StatisticLabel>
                            </Statistic>
                            <Statistic className='investmentSummaryStatistics'>
                                <StatisticValue>{investment.dealType}</StatisticValue>
                                <StatisticLabel>Deal Type</StatisticLabel>
                            </Statistic>
                            <Statistic className='investmentSummaryStatistics'>
                                <StatisticValue>{formatDate(investment.closeDate)}</StatisticValue>
                                <StatisticLabel>Close Date</StatisticLabel>
                            </Statistic>
                            <Statistic className='investmentSummaryStatistics'>
                                <StatisticValue>{formatCurrency(investment.acquisitionPrice)}</StatisticValue>
                                <StatisticLabel>Acquisition Price</StatisticLabel>
                            </Statistic>
                        </StatisticGroup>
                    </GridColumn>
                </GridRow>
            </Grid>
        </Segment>
    )
}
export default GPInvestmentCard