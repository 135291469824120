import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Modal, Segment, Tab } from 'semantic-ui-react'
import Receive from './receive';
import EntityContext from '../../app/context/entityContext';
import { AddressBalance } from '../../app/models/crypto/AddressBalance';
import { useStore } from '../../app/stores/store';
import { CapBlockTable } from '../../app/models/common/CapblockTable';
import { TransferModel } from '../../app/models/settings/transfer';
import Send from './send';



interface TransferProps {
    show: boolean;
    closeModal: (refreshBalance: boolean) => void;
    hideSend?: boolean;
    hideReceive?: boolean;
    transferModel?: TransferModel
}

function TransferModal({ show, closeModal, hideSend, hideReceive, transferModel }: TransferProps) {

    const { entity } = useContext(EntityContext);
    const navigate = useNavigate();

    const { cryptoStore } = useStore();
    const [userBalance, setUserBalance] = useState(new AddressBalance());
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const senderId = transferModel?.senderTableKeyId ?? entity.id;
        const table = transferModel?.senderCapblockTable ?? CapBlockTable.entity;
        if (senderId === undefined) navigate('/');

        cryptoStore.getUserTokenBalance(table, senderId, transferModel ? "USDC" : "").then((r) => {
            setUserBalance(r);
            setLoading(false);
        });
    }, [entity, navigate, cryptoStore, transferModel]);

    var panes = [
        {
            menuItem: { key: 'Send', content: 'Send' },
            render: () =>
                <Tab.Pane>
                    <Send userBalance={userBalance}
                        closeModal={closeModal}
                        transferModel={transferModel} />
                </Tab.Pane>,
        },
        {
            menuItem: { key: 'Receive', content: 'Receive' },
            render: () =>
                <Tab.Pane>
                    <Receive userBalance={userBalance}
                            closeModal={closeModal}/>
                </Tab.Pane>,
        }
    ];

    if (hideReceive === true) {
        panes = panes.filter(x => x.menuItem.key === "Send");
    }

    if (hideSend === true) {
        panes = panes.filter(x => x.menuItem.key === "Receive");
    }

    return (
        <Modal size="tiny" centered={false} open={show} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Content>
                {loading ? <Segment basic loading /> : <Tab panes={panes} id='tabTransfer' />}
            </Modal.Content>
        </Modal>
    )
}

export default TransferModal