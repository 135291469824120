import { useContext, useEffect, useState } from 'react'
import { Button, Grid, Header, Icon, Segment, Table } from 'semantic-ui-react'
import EmptyGridMessage from '../../../shared/EmptyGridMessage'
import GridFooter from '../../../shared/GridFooter'
import { useStore } from '../../../app/stores/store';
import { useMediaQuery } from 'react-responsive';
import { createGridInitialState, formatFullDate, getFileIcon, getFullSizeWidth, handleGridNavigation, handleGridSort } from '../../../shared/utils';
import { PageParams } from '../../../app/models/common/PagedResult';
import ConfirmButton from '../../../shared/ConfirmButton';
import { toast } from 'react-toastify';
import EntityContext from '../../../app/context/entityContext';
import { Loan } from '../../../app/models/Loan/Loan';
import { LoanQueryFilter } from '../../../app/models/common/loanQueryFilter';
import { Document } from '../../../app/models/common/Document';

interface Props {
    loan: Loan;
    reloadDocuments: boolean;
    showUploadPanel: boolean;
    setShowUploadPanel: (showPanel: boolean) => void;
}

function DocumentsGrid({ loan, reloadDocuments, showUploadPanel, setShowUploadPanel }: Props) {

    const [loading, setLoading] = useState(true);
    const { entity } = useContext(EntityContext);
    const { loanStore } = useStore();
    const [documents, setDocuments] = useState([new Document()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })

    const initialState: LoanQueryFilter = createGridInitialState({
        entityId: entity.id,
        loanId: loan.id,
        sortIndex: 'DocumentName',
        sortOrder: 'ascending'
    });

    const [params, setParams] = useState(initialState);

    useEffect(() => {
        loanStore.getLoanDocuments(params).then(r => {
            setTotalRecords(r.totalRecords);
            setDocuments(r.data);
            setLoading(false);
        });
    }, [loanStore, params, setLoading, reloadDocuments]);


    const handleSort = (clickedColumn: string) => {
        if (documents.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    const deleteDocument = (documentId: number) => {
        loanStore.deleteLoanDocument(entity.id, loan.id, documentId).then(r => {
            setParams(handleGridSort(params, initialState.sortIndex));
        }).catch(err => {
            toast.error("There was an issue deleting selected document", { theme: "colored" });
        });
    }

    const uploadFields = () => {
        setShowUploadPanel(!showUploadPanel)
    }

    return (
        <Segment clearing loading={loading}>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column verticalAlign='middle' textAlign='left' width={showFullSize ? 10 : 6}>
                        <Header>Loan Documents</Header>
                    </Grid.Column>
                    <Grid.Column textAlign='right' verticalAlign='middle' width={showFullSize ? 6 : 10}>
                        {loan.lenderEntityId === entity.id &&
                            <Button onClick={uploadFields} floated='right' primary>{!showUploadPanel ? "Upload Files" : "Hide Upload Panel"}</Button>}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Table sortable>
                {showFullSize &&
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("DocumentName")} sorted={params.sortIndex === "DocumentTitle" && documents.length > 0 ? params.sortOrder : undefined}> Name</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("DocumentType")} sorted={params.sortIndex === "DocumentType" ? params.sortOrder : undefined}>Document Type</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Created")} sorted={params.sortIndex === "Created" ? params.sortOrder : undefined}>Created On</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                }

                <Table.Body>
                    {!loading && documents.map((document, i) => {
                        if (showFullSize) {
                            return <Table.Row key={i}>
                                <Table.Cell textAlign='center'><i aria-hidden="true" className={"icon large " + getFileIcon(document.fileExtension)} /></Table.Cell>
                                <Table.Cell collapsing={true}><a href={document.shortLivedBlobUrl}>{document.documentName}</a></Table.Cell>
                                <Table.Cell textAlign='center'>{document.documentType}</Table.Cell>
                                <Table.Cell textAlign='center'>{formatFullDate(document.created)}</Table.Cell>
                                <Table.Cell textAlign='center'>
                                    {entity.id === loan.lenderEntityId &&
                                        <ConfirmButton id={document.id} color='red' iconName='trash alternate' basic
                                            content={'Delete ' + document.documentName + '?'}
                                            value='' confirmButtonText='Delete'
                                            handleConfirm={() => deleteDocument(document.id)}></ConfirmButton>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        }
                        else if (document.createdBy === loan.lenderEntityId) {
                            return <Table.Row key={i}>
                                <Table.Cell>
                                    {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={5}>
                                                <label onClick={() => handleSort("DocumentName")}>Name:</label><br />
                                                <label style={{whiteSpace: 'nowrap'}} onClick={() => handleSort("DocumentType")}>Document Type:</label><br />
                                                <label style={{whiteSpace: 'nowrap'}} onClick={() => handleSort("Created")}>Created On:</label><br />
                                                <label></label>
                                            </Grid.Column>
                                            <Grid.Column style={{ fontWeight: 'normal' }} >
                                                <div style={{ width: '45vw' }}>
                                                    {<i aria-hidden="true" className={"icon  " + getFileIcon(document.fileExtension)} />}
                                                    <a href={document.shortLivedBlobUrl}>{document.documentName}</a> <br />
                                                    {document.documentType}<br />
                                                    {formatFullDate(document.created)} <br />
                                                    {entity.id === loan.lenderEntityId &&
                                                        <ConfirmButton id={document.id} color='red' iconName='trash alternate' basic
                                                            content={'Delete ' + document.documentName + '?'}
                                                            value='' confirmButtonText='Delete'
                                                            handleConfirm={() => deleteDocument(document.id)}></ConfirmButton>} <br />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                        }
                        else return <></>
                    })}
                    {!loading && documents.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 5 : 1} message='You do not have any documents' />}
                </Table.Body>
                {!loading && documents.length > 0 &&
                    <GridFooter colSpan={showFullSize ? 5 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                }
            </Table>

        </Segment>
    )
}

export default DocumentsGrid