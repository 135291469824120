export interface CheckInfo {
    id: number;
    investmentId: number;
    mailingAddress: string;
    makeOutTo: string;
    memo: string;
    otherInstructions: string;
    deleted: boolean;
}

export class CheckInfo implements CheckInfo {
    id: number = 0;
    investmentId: number = 0;
    mailingAddress: string = '';
    makeOutTo: string = '';
    memo: string = '';
    otherInstructions: string = '';
    deleted: boolean = false;

    constructor(init?: CheckInfo) {
        Object.assign(this, init);
    }
}