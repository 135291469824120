import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react';

interface Props {
    inverted?: Boolean;
    content: string;
}

function Loading({ inverted = true, content = 'Loading...' }: Props) {
    return (
        <Dimmer active={true} inverted={inverted}>
            <Loader content={content} size='large' />
        </Dimmer>
    );
}

export default Loading