import { List, ListContent, ListDescription, ListHeader, ListIcon, ListItem, Segment } from "semantic-ui-react"
import { SponsorshipTeamView } from "../../app/models/GpLanding/sponsorshipTeamView"
import { useStore } from "../../app/stores/store";
import { useState, useEffect } from "react";

interface Params {
    investmentId: number,
    enableHeader: boolean
}
function SponsorshipTeamPanel({ investmentId, enableHeader }: Params) {
    const { investmentInsightsStore } = useStore();
    const [sponsors, setSponsors] = useState([new SponsorshipTeamView()])
    const [loading, setLoading] = useState(true);
    const iconSize = 'large'
    useEffect(() => {
        setLoading(true);
        investmentInsightsStore.getSponsorshipTeam(investmentId)
            .then(r => setSponsors(r))
            .finally(() => setLoading(false));
    }, [investmentId, investmentInsightsStore])

    return (
        <Segment loading={loading} clearing className='classTable' color='blue'>
            <List divided verticalAlign="top" style={{ padding: 10}} >
                {sponsors.map((sponsor, i) => {
                    return(
                    <ListItem key={i}>
                        <ListIcon name='user' size={iconSize} verticalAlign='middle' />
                        <ListContent>
                            <ListHeader><span style={{ fontSize: 'larger' }}>{sponsor.firstName} {sponsor.lastName}</span></ListHeader>
                            <ListDescription></ListDescription>
                        </ListContent>
                    </ListItem>)
                })}

            </List>
        </Segment>
    )
}

export default SponsorshipTeamPanel