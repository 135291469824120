export interface EntityInvestment {
  investmentId: number,
  name: string,
  asofDate: Date,
  amount: number,
  value: number,
  investmentStatus: string,
  totalInvestedAmount: number,
  totalInvestmentValue: number;
}

export class EntityInvestment implements EntityInvestment {
  constructor(init?: EntityInvestment) {
    Object.assign(this, init);
  }
}