import { useField } from "formik";
import React from "react";
import { Form, Label } from "semantic-ui-react";
import InputMask from 'react-input-mask';
import DatePicker from "react-datepicker";
import { addMonths } from "date-fns"
import FieldTitle from "./FieldTitle";

interface Props {
    placeholder: string;
    name: string;
    type?: string;
    maxLength?: number;
    readOnly?: boolean;
    width?: number;
    icon?: any;
    action?: any;
    autoFocus?: boolean;
    showRequired?: boolean,
    mask?: any;
    maskplaceholder?: string;
    alwaysshowMask?: boolean;
    hideTitle?: boolean;
    restrictToday?: boolean;
    showMonthYearPicker?: boolean;
    dateFormat?: string;
    startDate?: Date | null;
    onChange?: (values: any) => void;
    className?: any;
    showMonthYearDropdown?: boolean;
    maxDate?: Date;
}

function DateInput(props: Props) {
    const [field, meta, helpers] = useField(props.name!);
    const {dateFormat, showMonthYearPicker, startDate, hideTitle, showRequired, restrictToday, onChange, showMonthYearDropdown, maxDate, ...rest} = props;
    return (
        <Form.Field error={meta.touched && !!meta.error}>
            {!hideTitle && <FieldTitle name={props.name} placeholder={props.placeholder} showRequired={props.showRequired} />}
            <DatePicker
                placeholderText={props.placeholder}
                popperPlacement="bottom-start"
                showMonthDropdown={props.showMonthYearDropdown ?? true}
                showYearDropdown={props.showMonthYearDropdown ?? true}
                dropdownMode="select"
                minDate={restrictToday ? addMonths(new Date(), 1) : startDate ? new Date(startDate) : null}
                maxDate={props.maxDate}
                readOnly={props.readOnly}
                showMonthYearPicker={showMonthYearPicker}
                dateFormat={dateFormat}
                popperModifiers={[
                    {
                        name: 'arrow',
                        options: {
                            padding: ({ popper }) => ({
                                right: popper.width - 32,
                            }),
                        },
                    },
                ]}
                selected={(field.value && new Date(field.value)) || null}
                onChange={value => {
                    helpers.setValue(value);
                    if (onChange) {
                        onChange(value);
                    }
                }}
                customInput={
                    <InputMask mask={props.mask} {...field} {...rest} maskPlaceholder={props.maskplaceholder} />
                }
            />
            {meta.touched && meta.error ? (
                <Label basic color='red' pointing='above'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    )
}
export default DateInput;