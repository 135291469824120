import { useAuth0 } from "@auth0/auth0-react";
import NavBarAuthenticationRequired from "./NavBarAuthenticationRequired";
import NavBarAuthenticationNotRequired from "./NavBarAuthenticationNotRequired";

export default function NavBarHybrid() {

    const { isAuthenticated, isLoading } = useAuth0();
    return (
        <>
            {!isLoading && isAuthenticated && <NavBarAuthenticationRequired />}
            {!isLoading && !isAuthenticated && <NavBarAuthenticationNotRequired />}
        </>
    )
}
