import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Divider, Header, Icon } from 'semantic-ui-react'
import { useStore } from '../../app/stores/store';
import EntityContext from '../../app/context/entityContext';
import { Task } from '../../app/models/Notifications/task';
import { EntityFilterParams } from '../../app/models/common/entityFilterParams';
import { formatFullDate } from '../../shared/utils';
import Loading from '../../app/layout/Loading';
import { useNavigate } from 'react-router-dom';

function TaskSideBar() {
    const [loading, setLoading] = useState(false);
    const { notificationStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [tasks, setTasks] = useState([new Task()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const navigate = useNavigate();

    const initialState: EntityFilterParams = {
        entityId: entity.id,
        sortIndex: 'Name',
        sortOrder: 'descending',
        pageIndex: 0,
        pageSize: 10
    };

    const [params, setParams] = useState(initialState);

    useEffect(() => {
        notificationStore.getEntityTasks(params).then(r => {
            setTotalRecords(r.totalRecords);
            setTasks(prevTasks =>
                params.pageIndex === 0 ? r.data : [...prevTasks, ...r.data]
            )
            setLoading(false);
        });
    }, [notificationStore, params, setLoading]);

    const cardClick = (task: Task) => {
        navigate(task.actionURL);
    }

    const showMore = () => {
        const paging = { ...params };
        paging.pageIndex++;
        setParams(paging);
    }

    if (loading) return <Loading content='Loading...' />

    return (
        <div className='task-card'>
            <Divider horizontal section>
                <Header as='h4'>
                    {totalRecords === 0 && "No"} Tasks
                </Header>
            </Divider>

            {!loading && totalRecords > 0 && tasks.map((task: Task, index) => (
                <Card key={index} color='blue' link onClick={() => cardClick(task)}>
                    <Card.Content>
                        <Card.Header>{task.taskTypeDescription}
                            <Icon name="warning circle" color='red' style={{ float: "right" }} />
                        </Card.Header>
                        <Card.Meta>{formatFullDate(task.created)}</Card.Meta>
                        <Card.Description>
                            {task.description}
                        </Card.Description>
                    </Card.Content>
                </Card>
            ))}

            {tasks.length < totalRecords && <Button content='Show More' basic color='blue' onClick={showMore} />}
        </div>

    )
}

export default TaskSideBar