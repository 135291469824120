import { Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Button, Form, Icon, Message, Segment } from 'semantic-ui-react';
import TextInput from '../../app/common/form/TextInput';
import * as yup from 'yup';
import { useStore } from '../../app/stores/store';
import { CommonRegularExpressions } from '../../shared/CommonRegularExpressions';
import { UserFormValues } from '../../app/models/registration/user';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CacheNameEnum } from '../../shared/utils';
import EntityProfilePanel from './EntityProfilePanel';

function UserInfo() {
    const commonRegex = CommonRegularExpressions;

    const { userStore } = useStore();
    const { getCurrentUser } = userStore;
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [user, setUser] = useState<UserFormValues>(new UserFormValues());

    useEffect(() => {
        getCurrentUser().then(result => {
            setUser(new UserFormValues(result));
            setLoading(false);
        });
    }, [getCurrentUser, setLoading])

    const validationSchema = yup.object({
        firstName: yup
            .string()
            .required('First Name is required')
            .matches(commonRegex.firstNameOrLastName, 'Special characters not allowed'),
        lastName: yup
            .string()
            .required('Last Name is required')
            .matches(commonRegex.firstNameOrLastName, 'Special characters not allowed'),
        email: yup
            .string()
            .required('Email is required')
            .email('Enter a valid email')
    });

    const handleFormSubmit = (user: UserFormValues, setFieldError: any, setSubmitting: any) => {
        userStore.updateUser(user)
            .then(r => {
                toast.success("User info updated!", { theme: "colored" });
                sessionStorage.removeItem(CacheNameEnum.UserInfo);
                window.location.reload();
            })
            .catch(err => {
                if (err.response.status === 400 && err.response.data === 'Duplicated Email') {
                    setFieldError('email', 'This email already exists')
                }
                else
                {
                    toast.error(err.response.data, { theme: "colored" });
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    return (
        <>
        <Segment clearing loading={loading}>
            <Formik
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={user}
                onSubmit={(values, { setFieldError, setSubmitting }) => {
                    return handleFormSubmit(values, setFieldError, setSubmitting);
                }} >
                {({ handleSubmit, isSubmitting, handleReset }) => (
                    <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                        <Form.Group widths='equal'>
                            <TextInput placeholder='First Name' name='firstName' maxLength={50} />
                            <TextInput placeholder='Middle Name' name='middleName' maxLength={20} />
                            <TextInput placeholder='Last Name' name='lastName' maxLength={50} />
                        </Form.Group>
                        <TextInput placeholder='Email' name='email' maxLength={256} />
                        <Message info>
                            <Icon name='info circle' />
                            Changing email will redirect you to the login page
                        </Message>
                        <Button
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            floated='right'
                            primary
                            type='submit'
                            content='Save'
                        />
                        <Button onClick={() => navigate('/dashboard')} floated='right' type='button' content='Cancel' disabled={isSubmitting}/>
                    </Form>
                )}
            </Formik>
        </Segment>
        <Segment clearing>
            <EntityProfilePanel></EntityProfilePanel>
        </Segment>
        </>
    )
}

export default UserInfo