import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { DropdownItemProps, Grid, Table } from 'semantic-ui-react'
import { PageParams } from '../app/models/common/PagedResult'
import GridPagination from './GridPagination';
import RecordsPerPage from './RecordsPerPage'
import { getFullSizeWidth } from './utils';

interface Props
{
    colSpan: number,
    params: PageParams,
    totalRecords: number,
    onChange: (change: PageParams) => void;
    customOptions?: DropdownItemProps[];
}
function GridFooter({ colSpan, params, totalRecords, onChange, customOptions } : Props) {
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const from = ((params.pageIndex * params.pageSize) + 1).toLocaleString();
    const to = ((params.pageIndex + 1) * params.pageSize).toLocaleString();
    const total = totalRecords?.toLocaleString();
    return (
        <Table.Footer fullWidth>
        <Table.Row>
            <Table.HeaderCell colSpan={colSpan}>
                <Grid stackable style={{border:"0px"}}>
                    <Grid.Row>
                        <Grid.Column textAlign='left' width={5} verticalAlign='middle'>
                            {totalRecords > 0 && totalRecords > params.pageSize &&
                            <label>Showing {from} to {to} of {total} records</label>}
                            {totalRecords > 0 && totalRecords <= params.pageSize &&
                            <label>Showing all ({total}) records</label>}
                        </Grid.Column>
                        <Grid.Column textAlign='right'  width={11}>
                            {totalRecords > 5 && <RecordsPerPage params={params} pageSizeChange={onChange} customOptions={customOptions} />}
                            {showFullSize ? <>&nbsp;</> : <><br /><br /></>}
                            {totalRecords > params.pageSize && <GridPagination pageIndexChange={onChange} params={params} totalRecords={totalRecords ?? 0} />}
                        </Grid.Column>
                    </Grid.Row>
                 </Grid>
            </Table.HeaderCell>
        </Table.Row>
    </Table.Footer>
    )
}

export default GridFooter