import React, { useEffect, useState } from 'react'
import { Grid, Message, Segment, Table } from 'semantic-ui-react'
import { AmortizationTable, LoanFormValues } from '../../../app/models/Loan/Loan';
import { formatCurrency, getFullSizeWidth, handleGridNavigation, yearsOptions } from '../../../shared/utils';
import GridFooter from '../../../shared/GridFooter';
import { PageParams } from '../../../app/models/common/PagedResult';
import { useStore } from '../../../app/stores/store';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react-lite';

interface Props
{
    values: LoanFormValues;
}
function AmortizationView({values} : Props) {
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const [amortizationTable, setAmortizationTable] = useState<any>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const { loanStore } = useStore();

    const initialState: PageParams = {
        sortIndex: '',
        sortOrder: 'ascending',
        pageIndex: 0,
        pageSize: 12
    };
    const [params, setParams] = useState(initialState);

    useEffect(() => {
        setLoading(true);
        loanStore.calculateAmortization(values, params.pageSize, params.pageIndex)
            .then(r => {
                setTotalRecords(r.totalRecords);
                setAmortizationTable(r.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [loanStore, params, setLoading, values]);

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    if (totalRecords === 0) return <></>

    return (
        <Segment textAlign='center' basic loading={loading}>
            <Message compact>
                Amortization Schedule Monthly Summary
            </Message>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={1} />
                    <Grid.Column width={14}>
                        <Table compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Date</Table.HeaderCell>
                                    <Table.HeaderCell>Beginning Balance</Table.HeaderCell>
                                    <Table.HeaderCell>Interest</Table.HeaderCell>
                                    <Table.HeaderCell>Principal</Table.HeaderCell>
                                    <Table.HeaderCell>Payment</Table.HeaderCell>
                                    <Table.HeaderCell>Ending Balance</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {amortizationTable.map((amortization: AmortizationTable, i: number) => (
                                    <Table.Row key={i}>
                                        <Table.Cell>{amortization.formattedDate}</Table.Cell>
                                        <Table.Cell>{formatCurrency(amortization.beginningBalance)}</Table.Cell>
                                        <Table.Cell>{formatCurrency(amortization.interest)}</Table.Cell>
                                        <Table.Cell>{formatCurrency(amortization.principal)}</Table.Cell>
                                        <Table.Cell>{formatCurrency(amortization.payment)}</Table.Cell>
                                        <Table.Cell>{formatCurrency(amortization.endingBalance)}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>

                            {amortizationTable.length > 0 &&
                                <GridFooter colSpan={showFullSize ? 6 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} customOptions={yearsOptions} />
                            }

                        </Table>
                    </Grid.Column>
                    <Grid.Column width={1} />
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

export default observer(AmortizationView)