import { GasInfo } from "./GasInfo";

export interface GasEstimate {
    estimate: number;
    price: number;
    gasPriceWei: number;
    transactionFee: number;
    hasEthBalance: boolean;

    gasInfo: GasInfo;
    gasLowFee: number;
    gasSafeFee: number;
    gasHighFee: number;
    ethPrice: number;
}


export class GasEstimate implements GasEstimate {

    constructor() {
        this.estimate = 0;
        this.price = 0;
        this.gasPriceWei = 0;
        this.transactionFee = 0;
        this.hasEthBalance = false;
        this.gasInfo = new GasInfo();
        this.gasLowFee = 0;
        this.gasSafeFee = 0;
        this.gasHighFee = 0;
    }
}