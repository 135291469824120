import React, { useContext, useEffect, useState } from 'react'
import { Button, Checkbox, Form, Modal, Segment, Table } from 'semantic-ui-react'
import { LoanEscrowValues } from '../../../app/models/Loan/LoanEscrow';
import { useStore } from '../../../app/stores/store';
import Send from '../../transfer/send';
import { AddressBalance } from '../../../app/models/crypto/AddressBalance';
import { Recipient, TransferModel, TransferType } from '../../../app/models/settings/transfer';
import EntityContext from '../../../app/context/entityContext';
import { CapBlockTable } from '../../../app/models/common/CapblockTable';
import { LoanEscrowFilter } from '../../../app/models/Loan/LoanEscrowFilter';
import { FieldArray, Formik } from 'formik';
import TextInput from '../../../app/common/form/TextInput';
import CurrencyInput from '../../../app/common/form/CurrencyInput';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';
import { getFloatValue } from '../../../shared/utils';

interface Props {
    loanId: number;
    show: boolean;
    onCancel: (reload: boolean) => void;
    transferModel?: TransferModel
}

function EscrowAddFundsModal({ loanId, show, onCancel, transferModel }: Props) {
    const { entity } = useContext(EntityContext);
    const [loading, setLoading] = useState(false);
    const [userBalance, setUserBalance] = useState(new AddressBalance());
    const { cryptoStore, loanStore } = useStore();
    const [escrows, setEscrows] = useState([new LoanEscrowValues()]);
    const [paymentModel, setPaymentModel] = useState<TransferModel>();
    const [showTransfer, setShowTransfer] = useState(false);

    const initialState: LoanEscrowFilter = {
        loanId: loanId,
        entityId: entity.id,
        loanEscrowId: 0,
        includeBalance: false,
        sortIndex: 'EscrowTypeName',
        sortOrder: 'ascending',
        pageIndex: 0,
        pageSize: 10
    };

    const [params] = useState(initialState);

    useEffect(() => {
        const senderId = transferModel?.senderTableKeyId ?? entity.id;
        const table = transferModel?.senderCapblockTable ?? CapBlockTable.entity;

        cryptoStore.getUserTokenBalance(table, senderId, "USDC").then((r) => {
            setUserBalance(r);
            setLoading(false);
        });

        setLoading(true);
        loanStore.getLoanEscrows(params).then(r => {
            setEscrows(r.data.map(m => new LoanEscrowValues(m)));
            setLoading(false);
        });
    }, [entity, cryptoStore, transferModel, loanStore, params]);

    const validationSchema = yup.object().shape({
        escrows: yup.array().of(
            yup.object().shape({
                selectedEscrow: yup.boolean(),
                amount: yup
                    .number()
                    .when('selectedEscrow', {
                        is: true,
                        then: yup.number()
                            .transform((_value, originalValue) => originalValue === '' ? '' : getFloatValue(originalValue))
                            .typeError('Total Amount is required')
                            .positive('Total Amount must be greater than 0'),
                        otherwise: yup.number(),
                    }),
            })
        )
    });

    const handleFormSubmit = async (values: any) => {

        const getPaymentModel = (): TransferModel => {
            const checkedFields = values.escrows.filter((escrow: LoanEscrowValues) => escrow.selectedEscrow);
            const sum = checkedFields.reduce((a: number, b: LoanEscrowValues) => a + getFloatValue(b.amount), 0);
            var model = {
                senderCapblockTable: CapBlockTable.entity,
                senderTableKeyId: entity.id,
                transferType: TransferType.escrowFunding,
                amount: sum,
                sourceId: 0,
                toCapblockTable: CapBlockTable.loanEscrow,
                toTableKeyId: 0,
                toName: '',
                recipients: checkedFields.map((m: LoanEscrowValues) => new Recipient(CapBlockTable.loanEscrow, m.id, m.escrowTypeName, Number(m.amount)))
            };
            return model;
        }

        setPaymentModel(getPaymentModel);
        setShowTransfer(true);
    }

    const closeModal = () => { onCancel(true); }

    const callBack = (recipients: Recipient[]) => {
        //Show user's previous selection
        const updatedEscrows = [...escrows];
        recipients.forEach((to) => {
            const targetIndex = updatedEscrows.findIndex((item) => item.id === to.toTableKeyId);
            if (targetIndex !== -1) {
                const updatedEscrow = { ...updatedEscrows[targetIndex] };
                updatedEscrow.selectedEscrow = true;
                updatedEscrow.amount = to.amount;
                updatedEscrows[targetIndex] = updatedEscrow;
            }
        });
        setEscrows(updatedEscrows);
        setShowTransfer(false);
    }

    const EscrowSelection = () => {
        return <Formik
            validationSchema={validationSchema}
            enableReinitialize
            initialValues={{ escrows: escrows }}
            onSubmit={(values) => {
                handleFormSubmit(values);
            }}>
            {({ handleSubmit, values, setFieldValue }) => (
                <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                    <Table size='small'>
                        <FieldArray name="escrows"
                            render={(arrayHelpers) => (
                                <Table.Body>
                                    {values.escrows.map((item, i) => (
                                        <Table.Row key={i}>
                                            <Table.Cell>
                                                <Checkbox key={i} toggle name={`escrows[${i}].selectedEscrow`} checked={item.selectedEscrow} onChange={(e, { checked }) => {
                                                    const updatedFields = [...values.escrows];
                                                    updatedFields[i].selectedEscrow = checked ?? false;
                                                    setFieldValue('escrows', updatedFields);
                                                }} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <TextInput key={i} placeholder='' name={`escrows[${i}].escrowTypeName`} readOnly />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <CurrencyInput placeholder='' name={`escrows[${i}].amount`} maxLength={11} allowNegative={false} thousandSeparator=',' decimalScale={2} className={`${item.selectedEscrow ? '' : 'readOnlyField'} paymentInput`} prefix='$' readOnly={!item.selectedEscrow} />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            )} />
                    </Table>
                    <Segment basic textAlign='center'>
                        <Button onClick={() => onCancel(false)} content='Cancel' />
                        <Button type='submit' content='Continue' primary disabled={!values.escrows.some((field) => field.selectedEscrow)} />
                    </Segment>
                </Form>
            )}
        </Formik>;
    }

    return (
        <Modal centered={false} size="tiny" open={show} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>Add Funds</Modal.Header>
            <Modal.Content>
                Select the escrows you would like to add funds to and the set the amount. Once complete, the funds will be transfered from your wallet to the Escrow.
                <Segment clearing loading={loading} basic>

                    {!showTransfer && <EscrowSelection />}

                    {showTransfer && <Send userBalance={userBalance} closeModal={closeModal} transferModel={paymentModel} callBack={callBack} />}

                </Segment>
            </Modal.Content>
        </Modal>
    )
}

export default observer(EscrowAddFundsModal)