export interface PhoneNumbers {
    primaryPhone: string,
    alternatePhone1: string,
    alternatePhone2: string,
    swapAlternate1: boolean | null,
    removeAlternate1: boolean | null,
}

export class PhoneNumbers implements PhoneNumbers {
    constructor(init?: PhoneNumbersFormValues) {
        Object.assign(this, init);
    }
}

export class PhoneNumbersFormValues {
    primaryPhone: string = '';
    alternatePhone1: string = '';
    alternatePhone2: string = '';
    swapAlternate1: boolean | null = null;
    removeAlternate1: boolean | null = null;

    constructor(phone?: PhoneNumbersFormValues) {
        if (phone) {
            this.primaryPhone = phone.primaryPhone;
            this.alternatePhone1 = phone.alternatePhone1;
            this.alternatePhone2 = phone.alternatePhone2;
        }
    }
}