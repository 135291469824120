import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Grid, Header, Image, Label, Segment, Table } from 'semantic-ui-react'
import EntityContext from '../../app/context/entityContext';
import { AddressBalance } from '../../app/models/crypto/AddressBalance';
import { BalanceResult } from '../../app/models/crypto/BalanceResult';
import { useStore } from '../../app/stores/store';
import { formatCurrency, formatFullDate } from '../../shared/utils';
import TransferModal from '../transfer/transferModal';
import TokenHistoryModal from './TokenHistory/TokenHistoryModal';
import { StripeBalance } from '../../app/models/stripe/balance';

function Dashboard() {
    const [loading, setLoading] = useState(false);
    const [userBalance, setUserBalance] = useState(new AddressBalance());
    const [currentToken, setCurrentToken] = useState(new BalanceResult());
    const { cryptoStore } = useStore();
    const { entity } = useContext(EntityContext);

    const [openTransfer, setOpenTransfer] = useState(false);

    const [openHistoryModal, setOpenHistoryModal] = useState(false);

    const { stripeAccountStore } = useStore();
    const [stripeBalance, setStripeBalance] = useState(new StripeBalance());

    const handleTransferClose = (refreshBalance: boolean) => {
        if (refreshBalance) getUserBalance();
        setOpenTransfer(false);
    }

    const getUserBalance = useCallback(() => {
        setLoading(true);
        cryptoStore.getUserBalance(entity.id).then(r => {
            setUserBalance(r);
            setLoading(false);
        });
    }, [setLoading, cryptoStore, entity]);

    useEffect(() => {
        getUserBalance();
    }, [getUserBalance]);




    const viewHistory = (token: BalanceResult) => {
        setCurrentToken(token);
        setOpenHistoryModal(true);
    }

    const getStripeBalance = useCallback(() => {
        stripeAccountStore.getStripeBalance(entity.id).then(r => {
            setStripeBalance(r);
        });
    }, [stripeAccountStore, entity]);

    useEffect(() => {
        getStripeBalance();
    }, [getStripeBalance, entity.id]);

    const DashboardHeader = () => {
        return <Segment clearing>
            <Grid columns={3} stackable>
                <Grid.Row>
                    <Grid.Column>
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                        <label className='balanceTitle'>Account Balance</label>
                        <Header as='h1' style={{ marginTop: '0px' }}>
                            {formatCurrency(userBalance.balances.reduce((a, b) => a + b.value, 0))}
                        </Header>
                    </Grid.Column>
                    <Grid.Column textAlign='right' verticalAlign='middle'>
                        <Button primary onClick={() => setOpenTransfer(true)}>Transfer</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>;
    }

    const closeModal = () => {
        setOpenHistoryModal(false);
    }

    const AssetsGrid = () => {
        return <Table singleLine>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell># of Tokens</Table.HeaderCell>
                    <Table.HeaderCell>Price</Table.HeaderCell>
                    <Table.HeaderCell>Balance</Table.HeaderCell>
                    <Table.HeaderCell>Available Balance</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {userBalance.balances.map((balance, i) => (
                    <Table.Row key={i}>
                        <Table.Cell>
                            <Header as='h4' image>
                                <Image src={balance.tokenImage} rounded size='mini' />
                                <Header.Content>
                                    {balance.tokenName}
                                    <Header.Subheader>{balance.tokenType}</Header.Subheader>
                                </Header.Content>
                            </Header>
                        </Table.Cell>
                        <Table.Cell>{balance.balance}</Table.Cell>
                        <Table.Cell>{formatCurrency(balance.price)}</Table.Cell>
                        <Table.Cell>{formatCurrency(balance.value)}</Table.Cell>
                        <Table.Cell>{formatCurrency(balance.availableValue)}</Table.Cell>
                        <Table.Cell>
                            <a href="#modal" onClick={(e) => { e.stopPropagation(); viewHistory(balance); }}>History</a>
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    }

    const StripeBalancePanel = () => {
        return <Table singleLine>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>Available Balance</Table.HeaderCell>
                    <Table.HeaderCell>Pending Balance</Table.HeaderCell>
                    <Table.HeaderCell>Reserved Balance</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                <Table.Row>
                    <Table.Cell>Stripe Connect</Table.Cell>
                    <Table.Cell>{formatCurrency(stripeBalance.available)}</Table.Cell>
                    <Table.Cell>{formatCurrency(stripeBalance.pending)}</Table.Cell>
                    <Table.Cell>{formatCurrency(stripeBalance.reserved)}</Table.Cell>
                    <Table.Cell>
                        <a href={stripeBalance.dashboardUrl}>Manage</a>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    }

    return (
        <>
            <DashboardHeader />

            <Segment clearing loading={loading}>
                <Header>Wallet Assets</Header>

                <AssetsGrid />

                <span className='tableFooter'>
                    <Label>
                        Last update:
                        <Label.Detail>{formatFullDate(userBalance.date)}</Label.Detail>
                    </Label>
                    <Button circular basic icon='refresh' size='mini' onClick={getUserBalance} />
                </span>

            </Segment>

            {stripeBalance.hasStripeAccount &&
                <Segment clearing>
                    <Header>Cash</Header>
                    <StripeBalancePanel />
                </Segment>
            }

            {openTransfer && <TransferModal closeModal={handleTransferClose} show={openTransfer} />}

            {openHistoryModal && <TokenHistoryModal token={currentToken} show={openHistoryModal} onCancel={closeModal} />}
        </>
    )
}

export default Dashboard