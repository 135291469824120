import React from 'react'
import { Header, Icon, Modal } from 'semantic-ui-react'
import Send from './send';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faXmark } from '@fortawesome/free-solid-svg-icons';
import { TransferActionEnum, TransferActionParam } from '../../app/models/settings/transfer';



interface TransferProps {
    show: boolean;
    actionParams: TransferActionParam;
    closeModal?: (refreshBalance: boolean) => void;
}

function TransferAction({ show, actionParams, closeModal }: TransferProps) {

    return (
        <Modal size="tiny" centered={false} open={show} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>
                <Header as='h3' image>
                    <Icon>
                        <FontAwesomeIcon icon={actionParams.action === TransferActionEnum.cancel ? faXmark : faRocket} />
                    </Icon>
                    <Header.Content>
                        {actionParams.action === TransferActionEnum.cancel ? "Cancel" : "Speed Up"} Transaction
                        <Header.Subheader>This gas fee will replace the original.</Header.Subheader>
                    </Header.Content>
                </Header>
            </Modal.Header>
            <Modal.Content>
                <Send closeModal={closeModal} actionParams={actionParams} />
            </Modal.Content>
        </Modal>
    )
}

export default TransferAction