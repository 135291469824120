export interface ZelleAccountProfile {
    entityId: number,
    recipientAccount: string,
  }

  export class ZelleAccountProfile implements ZelleAccountProfile {
    constructor() {     
      this.entityId = 0;
      this.recipientAccount = "";
    }
  }