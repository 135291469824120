import { CapBlockTable } from "../common/CapblockTable";
import { VerificationCode } from "../verificationCode/verificationCode";

export interface Transfer {
    senderCapblockTable: number;
    senderTableKeyId: number;
    amount: number,
    amountEth: number,
    toAddress: string,
    toName: string,
    serviceFee: number;
    gasEstimate: number;
    gasPrice: number;
    gasPriceWei: number;
    transactionFee: number;
    tokenType: string;
    totalAmount: number;
    transferType: number;
    transferSourceId: number;
    toCapblockTable: number;
    toTableKeyId: number;
    recipients?: Recipient[];
    hasEthBalance: boolean;
    note: string;
    maxGasFee: number;
    overrideGasFee: boolean;
    ethPrice: number;
  }

  export class Transfer implements Transfer {
    constructor(init?: TransferFormValues) {
      Object.assign(this, init);
    }
  }

  export class TransferFormValues implements VerificationCode {
    senderCapblockTable: number = 1;
    senderTableKeyId: number = 0;
    amount: number = 0;
    amountEth: number = 0;
    formattedAmount: string = '0';
    toAddress: string = '';
    toName: string = '';
    serviceFee: number = 0;
    gasEstimate: number = 0;
    gasPrice: number = 0;
    gasPriceWei: number = 0;
    currentTransactionFee: number = 0;
    transactionFee: number = 0;
    totalAmount: number = 0;
    tokenType: string = 'USDC';
    transferType: number = 0;
    transferSourceId: number = 0;
    toCapblockTable: number = 1;
    toTableKeyId: number = 0;
    recipients?: Recipient[];
    hasEthBalance: boolean = false;
    note: string = '';
    verificationCode: string = '';
    maxGasFee: number = 0;
    overrideGasFee: boolean = false;
    ethPrice: number = 0;
    transferAction?: TransferActionParam;

    constructor(transfer?: TransferFormValues) {
      if (transfer) {
        this.senderTableKeyId = transfer.senderTableKeyId;
        this.amount = transfer.amount;
        this.amountEth = transfer.amountEth;
        this.formattedAmount = transfer.formattedAmount;
        this.toAddress = transfer.toAddress;
        this.toName = transfer.toName;
        this.serviceFee = transfer.serviceFee;
        this.transactionFee = transfer.transactionFee;
        this.totalAmount = transfer.totalAmount;
        this.tokenType = transfer.tokenType;
        this.transferType = transfer.transferType;
        this.transferSourceId = transfer.transferSourceId;
        this.toTableKeyId = transfer.toTableKeyId;
        this.recipients = transfer.recipients;
        this.hasEthBalance = transfer.hasEthBalance;
      }
    }
  }

  export enum TransferType {
    coinTransfer = 1,
    loanDraw = 2,
    statementPayment = 3,
    escrowPayment = 4,
    escrowTransfer = 5,
    offerSellerPayment = 6,
    escrowFunding = 7,
    offerGPPayment = 8,
}

export enum TransferActionEnum {
  speedUp = 1,
  cancel = 2,
}

export interface TransferModel {
    senderCapblockTable: CapBlockTable,
    senderTableKeyId: number,
    transferType: TransferType,
    sourceId: number,
    amount: number;
    toCapblockTable: CapBlockTable,
    toTableKeyId: number,
    toName: string,
    recipients?: Recipient[];
}

export interface Recipient {
  toCapblockTable: CapBlockTable,
  toTableKeyId: number,
  toName: string,
  amount: number;
}

export class Recipient implements Recipient {
  constructor(capblockTable: CapBlockTable, toTableKeyId: number, name: string, amount: number) {
    this.toCapblockTable = capblockTable;
    this.toTableKeyId = toTableKeyId;
    this.toName = name;
    this.amount = amount;
  }
}

export interface TransferActionParam
{
  trxHash: string;
  action: TransferActionEnum;
}

export class TransferActionParam implements TransferActionParam {
  constructor(init?: TransferActionParam) {
      Object.assign(this, init);
  }
}