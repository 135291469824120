import { useEffect, useState } from 'react'
import { Grid, Header, Icon, Segment, Table } from 'semantic-ui-react'
import GridFooter from '../../shared/GridFooter'
import { useStore } from '../../app/stores/store';
import { useMediaQuery } from 'react-responsive';
import { createGridInitialState, formatFullDate, getFileIcon, getFullSizeWidth, handleGridNavigation, handleGridSort } from '../../shared/utils';
import { PageParams } from '../../app/models/common/PagedResult';
import { InvestmentQueryFilter } from '../../app/models/common/InvestmentQueryFilter';
import { Document } from '../../app/models/common/Document';
import EmptyGridMessage from '../../shared/EmptyGridMessage';

interface Props {
    investmentId: number,
    sponsorEntityId: number
}

function InvetmentDocumentsPanel({ investmentId, sponsorEntityId }: Props) {

    const [loading, setLoading] = useState(true);
    const { investmentInsightsStore } = useStore();
    const [documents, setDocuments] = useState([new Document()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` });

    const initialState: InvestmentQueryFilter = createGridInitialState(
        {
            entityId: 0,
            investmentId: investmentId,
            showAll: false,
            sortIndex: 'DocumentName',
            sortOrder: 'ascending'
        });

    const [params, setParams] = useState(initialState);

    useEffect(() => {
        setLoading(true);
        investmentInsightsStore.getSponsorInvestmentDocuments(params)
            .then(r => {
                setTotalRecords(r.totalRecords);
                setDocuments(r.data);
                setLoading(false);
            });
    }, [params, setLoading, investmentInsightsStore, investmentId]);


    const handleSort = (clickedColumn: string) => {
        if (documents.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    return (
        <Segment clearing loading={loading}>
            <Header as='h3' image style={{ marginBottom: 0 }}>
                <Icon name='folder'></Icon>
                <Header.Content>Documents</Header.Content>
            </Header>
            <Table sortable className='investmentMarketingPanel' striped color='blue'>
                {showFullSize &&
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("DocumentName")} sorted={params.sortIndex === "DocumentTitle" && documents.length > 0 ? params.sortOrder : undefined}> Name</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("DocumentType")} sorted={params.sortIndex === "DocumentType" ? params.sortOrder : undefined} textAlign='center'>Document Type</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Created")} sorted={params.sortIndex === "Created" ? params.sortOrder : undefined} textAlign='center'>Created On</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                }

                <Table.Body>
                    {!loading && documents.map((document, i) => {
                        if (showFullSize) {
                            return <Table.Row key={i}>
                                <Table.Cell textAlign='center'><i aria-hidden="true" className={"icon large " + getFileIcon(document.fileExtension)} /></Table.Cell>
                                <Table.Cell collapsing={true}><a href={document.shortLivedBlobUrl}>{document.documentName}</a></Table.Cell>
                                <Table.Cell textAlign='center'>{document.documentType}</Table.Cell>
                                <Table.Cell textAlign='center'>{formatFullDate(document.created)}</Table.Cell>
                            </Table.Row>
                        }
                        else if (document.createdBy === sponsorEntityId || !document.isShared) {
                            return <Table.Row key={i}>
                                <Table.Cell>
                                    {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={6}>
                                                <label onClick={() => handleSort("DocumentName")}>Name:</label><br />
                                                <label style={{ whiteSpace: 'nowrap' }} onClick={() => handleSort("DocumentType")}>Document Type:</label><br />
                                                <label style={{ whiteSpace: 'nowrap' }} onClick={() => handleSort("Created")}>Created On:</label><br />
                                            </Grid.Column>
                                            <Grid.Column style={{ fontWeight: 'normal' }} >
                                                <div style={{ width: '45vw' }}>
                                                    {<i aria-hidden="true" className={"icon  " + getFileIcon(document.fileExtension)} />}
                                                    <a href={document.shortLivedBlobUrl}>{document.documentName}</a> <br />
                                                    {document.documentType}<br />
                                                    {formatFullDate(document.created)} <br />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                        }
                        else return <></>
                    })}
                    {!loading && documents.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 4 : 1} message='No files are available for this investment.' icon={<Icon name='file' size='massive' />} />}
                </Table.Body>
                {!loading && documents.length > 0 &&
                    <GridFooter colSpan={showFullSize ? 7 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                }
            </Table>

        </Segment>
    )
}

export default InvetmentDocumentsPanel