import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { CommonRegularExpressions } from '../../shared/CommonRegularExpressions';
import { useStore } from '../../app/stores/store';
import { useAuth0 } from '@auth0/auth0-react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import Loading from '../../app/layout/Loading';
import { Button, Card, Checkbox, Form, Message, Segment } from 'semantic-ui-react';
import TextInput from '../../app/common/form/TextInput';
import { UserFormValues } from '../../app/models/registration/user';
import { FormContext } from "./registrationForm";
import { InputMasks } from '../../shared/InputMasks';
import { toast } from 'react-toastify';
import { Registration } from '../../app/models/registration/registration';

function UserForm() {

    const commonRegex = CommonRegularExpressions;

    const { userStore, commonStore } = useStore();
    const { getUserFromAuth } = userStore;
    const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const { userData, setUserData } = useContext(FormContext);
    const [capBlockUser, setCapBlockUser] = useState<UserFormValues>(new UserFormValues());

    useEffect(() => {
        if (!isLoading && isAuthenticated) {

            if (user!["capblock_userid"] > 0) navigate("/");

            if (userData.firstName) {
                setCapBlockUser(new UserFormValues(userData));
                setLoading(false);
                return;
            }

            getAccessTokenSilently().then(() => {
                getUserFromAuth().then(result => {
                    setCapBlockUser(new UserFormValues(result));
                    setLoading(false);
                });
            });
        }
    }, [commonStore.getStates, isAuthenticated, isLoading, getUserFromAuth, navigate, setLoading, getAccessTokenSilently, user, userData])

    const validationSchema = yup.object({
        firstName: yup
            .string()
            .required('First Name is required')
            .matches(commonRegex.firstNameOrLastName, 'Special characters not allowed'),
        lastName: yup
            .string()
            .required('Last Name is required')
            .matches(commonRegex.firstNameOrLastName, 'Special characters not allowed'),
        email: yup
            .string()
            .required('Email is required')
            .email('Enter a valid email'),
        phoneNumber: yup
            .string()
            .required('Phone Number is required')
            .matches(commonRegex.phoneNumber, 'Invalid Phone Number')
    });

    const handleFormSubmit = (user: UserFormValues, setFieldError: any, setSubmitting: any) => {
        const data = { ...userData, ...user };
        setUserData(data);
        const registration = new Registration();
        registration.user = user;
        userStore.createUser(registration)
            .then(async response => {
                if (response !== null) {
                    toast.success("Success!", { theme: "colored" });
                    getAccessTokenSilently({ ignoreCache: true }).then(() => {
                        window.location.href = '/'; //Use window.location to refresh the page and update the token in Axios interceptor
                    });
                }
            })
            .catch(err => {
                toast.error("There was an issue completing the registration. Please try again later.", { theme: "colored" });
                setSubmitting(false);
            });
    }

    if (loading) return <Loading content='Loading user info...' />;

    return (

        <>
            <Card.Content>
                <Message positive icon='info circle' header='Account Details' content='The account information below will be used for account authentication and recovery. Complete the required information below to proceed' />
            </Card.Content>
            <Card.Content>
                <Segment clearing>
                    <Formik
                        validationSchema={validationSchema}
                        enableReinitialize
                        initialValues={capBlockUser}
                        onSubmit={(values, { setFieldError, setSubmitting }) => {
                            return handleFormSubmit(values, setFieldError, setSubmitting);
                        }} >
                        {({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => (
                            <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                                <Form.Group widths='equal'>
                                    <TextInput placeholder='First Name' name='firstName' maxLength={50} />
                                    <TextInput placeholder='Middle Name' name='middleName' maxLength={20} />
                                    <TextInput placeholder='Last Name' name='lastName' maxLength={50} />
                                </Form.Group>
                                <TextInput placeholder='Email' name='email' readOnly maxLength={256} />
                                <TextInput placeholder='Phone Number' name='phoneNumber' mask={InputMasks.phoneNumber} />
                                <Checkbox name='optInSMS' checked={values.optInSMS} label="Yes, I want to enable SMS-based Two-Factor Authentication (2FA)" onChange={(e, { checked }) => { setFieldValue("optInSMS", checked ?? false) }} style={{ fontWeight: 'bold' }} />
                                <Button
                                    disabled={!isValid}
                                    loading={isSubmitting}
                                    floated='right'
                                    primary
                                    type='submit'
                                    content='Save'
                                />
                            </Form>
                        )}
                    </Formik>
                </Segment>
            </Card.Content>
        </>
    );
}

export default observer(UserForm)