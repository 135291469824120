import { useContext } from "react";
import { Table } from "semantic-ui-react";
import { Offering, OfferingAction, OfferingStatus } from "../../../../app/models/Investment/InvestmentOffering";
import EntityContext from "../../../../app/context/entityContext";
import { formatCurrency } from "../../../../shared/utils";
import { Investment } from "../../../../app/models/Investment/Investment";

interface SellEstimatesInput {
    investment: Investment,
    offering: Offering;
    isValid: () => boolean;
}
export const SellEstimates = ({ investment, offering, isValid}: SellEstimatesInput) => {
    const { entity } = useContext(EntityContext);
    const isSponsor = (investment.sponsorEntityId === entity.id);

    const isEntitySeller = (entityId: number) => {
        if (offering.action === OfferingAction.Sell && entityId === offering.entityID)
            return true;
        if (offering.action === OfferingAction.Buy && offering.status === OfferingStatus.Active && entityId !== offering.entityID)
            return true;
        if (offering.action === OfferingAction.Buy && offering.activeResponderEntityId && entityId === offering.activeResponderEntityId)
            return true;
        return false;
    }

    const showBreakdownEstimation = () => {
        if (offering.id === 0 || isEntitySeller(entity.id) || isSponsor)
            return true;

        return false;
    }

    const showTotal = () => {
        let totalValue = 0;

        if (isEntitySeller(entity.id) || isSponsor) {
            totalValue = offering.totalDueSeller ?? 0;
        }
        else {
            totalValue = offering.saleProceeds;
        }

        return formatCurrency(totalValue);
    }

    return (
        <Table definition selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Estimated Settlement Details</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Amount</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            {showBreakdownEstimation() &&
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Sale Proceeds</Table.Cell>
                        <Table.Cell textAlign="right">{isValid() && formatCurrency(offering.saleProceeds ?? 0)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Cost Basis</Table.Cell>
                        <Table.Cell textAlign="right">{isValid() && formatCurrency(offering.costBasis ?? 0)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Capital Gains</Table.Cell>
                        <Table.Cell textAlign="right">{isValid() && formatCurrency(offering.capitalGains ?? 0)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>GP Override</Table.Cell>
                        <Table.Cell textAlign="right">{isValid() && formatCurrency(offering.gpOverride ?? 0)}</Table.Cell>
                    </Table.Row>
                </Table.Body>}
            <Table.Footer fullWidth>
                <Table.Row>
                    <Table.Cell>{isEntitySeller(entity.id) ? "Total Due Seller" : "Estimated Total Due"}</Table.Cell>
                    <Table.HeaderCell textAlign="right"><b>{isValid() && showTotal()}</b></Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
}
