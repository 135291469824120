import { useState } from 'react'
import { Button, Grid, GridColumn, GridRow, Header, Modal, Checkbox, Label } from 'semantic-ui-react';

interface Params {
    show: boolean;
    entityId: number;
    sponsorName: string;
    supportEmail: string;
    onAccept: () => void;
    onCancel: () => void;
}

function GpConnectOptInModal({ show, entityId, sponsorName, supportEmail, onAccept, onCancel }: Params) {

    const [optinChecked, setOptinChecked] = useState(false);
    return (
        <Modal centered={false} size="large" open={show} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>Connect to {sponsorName}</Modal.Header>
            <Modal.Content>
                <Grid className='landingPageBackground'>
                    <GridRow>
                        <GridColumn verticalAlign='top'>
                            <p>
                                Welcome to a world of exclusive investment opportunities.
                                By connecting with {sponsorName}, you're taking the first step
                                towards innovative investment solutions tailored for investors like yourself.
                            </p>
                            <Header as='h4'>
                                <Header.Content>
                                    Opt-in confirmation
                                </Header.Content>
                            </Header>
                            <Label>
                                <Checkbox checked={optinChecked} onChange={(e, data) => setOptinChecked(data.checked === true)}
                                    label={`I understand that by clicking Connect, I am opting in to
                                                  receive communications from ${sponsorName} via CapBlock. These may include
                                                  investment overviews, updates, and other related information.`}>

                                </Checkbox>
                            </Label>
                            <p style={{ margin: 10 }}>
                                CapBlock respects your privacy and is committed to protecting your personal information.
                                All communication and information sharing are conducted in compliance with
                                SEC regulatiion and privacy standards.
                            </p>
                            <p style={{ margin: 10 }}>
                                You may modify your communication preferences or opt-out of receiving investment information
                                from {sponsorName} at any time through your CapBlock account settings.
                            </p>
                        </GridColumn>
                    </GridRow>
                </Grid>
            </Modal.Content>
            <Modal.Actions align='center' >
                <Button onClick={onCancel} content='Close' />
                <Button primary disabled={!optinChecked} onClick={onAccept} content='Connect' />
            </Modal.Actions>
            <Modal.Content>
                <p>For further inquiries or assistance, please contact <a href={`mailTo:${supportEmail}?subject=Connect Opt-In Help`}>{supportEmail}</a> or visit our support portal.</p>
            </Modal.Content>
        </Modal>
    )
}

export default GpConnectOptInModal