import { LoanPayment } from "./LoanPayment";

export interface LoanPaymentProcessing {
    loanId: number;
    monthlyStatementId: number;
    paymentProcessingType: number;
    statementDueDate: string;
    paymentDate: Date;
    amount: number;
    principal: number;
    interest: number;
    totalEscrow: number;
    lateFees: number;
    status: string;
    statementAmount: number;
    creditAmount: number;
    creditDate: Date;
    feeAmount: number;
    feeDate: Date | null;
    feeType: number;
    feePrePaid: boolean;
    outstandingBalance: number;
}

export class LoanPaymentProcessing implements LoanPayment {
    verificationCode: string = '';
    paymentType: string = '0';
    constructor(init?: LoanPaymentProcessing) {
        Object.assign(this, init);
    }
}