export interface InvestmentTermsFeesView 
{
    acquisitionFee:number,
    assetManagementFee:number,
    dispositionFee:number,
    refinanceFee:number,
    profitSharing:string,
}

export class InvestmentTermsFeesView implements InvestmentTermsFeesView
{
    constructor(init?: InvestmentTermsFeesView) {
        Object.assign(this, init);
      }
}