import { VerificationCode } from "../verificationCode/verificationCode";

export interface LoanPayment {
    loanId: number;
    monthlyStatementId: number;
    statementDueDate: string;
    paymentDate: Date;
    amount: number;
    principal: number;
    interest: number;
    totalEscrow: number;
    lateFees: number;
    status: string;
    statementAmount: number;
    paymentType: string;
}

export class LoanPayment implements LoanPayment, VerificationCode {
    verificationCode: string = '';
    constructor(init?: LoanPayment) {
        Object.assign(this, init);
    }
}