import { User } from "@auth0/auth0-react";

export interface Registration {
    user: User;
}

export class Registration implements Registration {
    constructor() {
        this.user = new User();
    }
}