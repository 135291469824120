export interface MarketPlaceDashboard {
    investmentId: number,
    investmentName: string,
    sponsorName: string,
    ownedSince: Date,
    price: number,
    totalChange: number,
    totalDistribution: number,
    valuation: number,
    equity: number,
    sellOffer: number,
    buyOffer: number,
    shareCount: number,
    totalRecords: number,
  }
  
  export class MarketPlaceDashboard implements MarketPlaceDashboard {
    constructor(init?: MarketPlaceDashboard) {
      Object.assign(this, init);
    }
  }