import { Icon, Menu, Segment, Sidebar } from 'semantic-ui-react';
import { MenuItems } from './MenuItems';
import TaskSideBar from '../../../../features/notification/TaskSideBar';
import NotificationSideBar from '../../../../features/notification/NotificationSideBar';

interface Props {
    sidebarOn: boolean,
    iconsOnly: boolean,
    showExpandedSidebar: () => void,
    logoutHandler: () => void,
    notificationOn: boolean,
}

function SideBar({ sidebarOn, iconsOnly, showExpandedSidebar, logoutHandler, notificationOn }: Props) {

    const selectedMenu = window.location.pathname.split('/')[1].toLowerCase();

    const RenderSidebar = () => {
        return (
            <>
                <Sidebar as={Menu} animation="push"
                    fluid
                    width={iconsOnly ? 'very thin' : 'thin'}
                    className={iconsOnly ? 'sidebar-menu-thin' : 'sidebar-menu'}
                    visible={sidebarOn}
                    icon="labeled" vertical inverted
                >
                    {MenuItems.map((item, index) => {

                        return (
                            <Menu.Item key={index} link href={item.path}
                                className={
                                    (iconsOnly ? 'sidebar-menu-item-thin' : '') +
                                    (item.key.includes(selectedMenu) ? ' sidebar-menu-item-sel' : '')
                                }>
                                {item.icon}
                                {!iconsOnly && item.title}
                            </Menu.Item>
                        );
                    })}

                    <Menu.Item link href={'/switch'}
                        className={iconsOnly ? 'sidebar-menu-item-thin' : ''}>
                        <Icon name='building outline' size='large' />
                        {!iconsOnly && "Switch Entity"}
                    </Menu.Item>

                    <Menu.Item link onClick={logoutHandler}
                        className={iconsOnly ? 'sidebar-menu-item-thin' : ''}>
                        <Icon name='power' size='large' />
                        {!iconsOnly && "Logout"}
                    </Menu.Item>

                    <Menu.Item link onClick={showExpandedSidebar}
                        className={iconsOnly ? 'sidebar-menu-item-bottom-thin' : 'sidebar-menu-item-bottom'}>
                        <Icon name={iconsOnly ? 'angle double right' : 'angle double left'} size='large' />
                    </Menu.Item>
                </Sidebar>


                <Sidebar
                    as={Segment}
                    animation='push'
                    direction='right'
                    vertical
                    visible={notificationOn}
                    className='rightbar'
                >
                    {notificationOn && <TaskSideBar />}

                    {notificationOn && <NotificationSideBar />}

                </Sidebar>
            </>
        );
    };

    return (
        <RenderSidebar />
    );
}

export default SideBar