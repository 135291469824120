import React, { useContext, useEffect, useState } from 'react'
import { Grid, Header, Icon, Segment, Table } from 'semantic-ui-react'
import EmptyGridMessage from '../../shared/EmptyGridMessage'
import GridFooter from '../../shared/GridFooter'
import { useStore } from '../../app/stores/store';
import EntityContext from '../../app/context/entityContext';
import { useMediaQuery } from 'react-responsive';
import { createGridInitialState, formatCurrency, formatMonthYearDate, getFullSizeWidth, handleGridNavigation, handleGridSort } from '../../shared/utils';
import { useNavigate } from 'react-router-dom';
import { PageParams } from '../../app/models/common/PagedResult';
import { EntityFilterParams } from '../../app/models/common/entityFilterParams';
import { EntityInvestment } from '../../app/models/Investment/EntityInvestment';

function MyInvestmentsGrid() {

    const [loading, setLoading] = useState(true);
    const { investmentStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [investments, setInvestments] = useState([new EntityInvestment()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const navigate = useNavigate();

    const initialState: EntityFilterParams = createGridInitialState({
        entityId: entity.id,
        sortIndex: 'Name',
        sortOrder: 'ascending'
    });

    const [params, setParams] = useState(initialState);

    useEffect(() => {
        investmentStore.getEntityInvestments(params).then(r => {
            setTotalRecords(r.totalRecords);
            setInvestments(r.data);
            setLoading(false);
        });
    }, [investmentStore, params, setLoading]);


    const handleSort = (clickedColumn: string) => {
        if (investments.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    const viewInvestment = (id: number) => {
        navigate(`/investment/${id}`);
    }

    return (
        <Segment clearing loading={loading}>
            <Header>My Investments</Header>
            <Table singleLine sortable selectable>
                {showFullSize &&
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={() => handleSort("Name")} sorted={params.sortIndex === "Name" && investments.length > 0 ? params.sortOrder : undefined}> Name</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Amount")} sorted={params.sortIndex === "Amount" && investments.length > 0 ? params.sortOrder : undefined}>Invested</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Value")} sorted={params.sortIndex === "Value" ? params.sortOrder : undefined}>Value</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("AsofDate")} sorted={params.sortIndex === "AsofDate" ? params.sortOrder : undefined}>As of</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("InvestmentStatus")} sorted={params.sortIndex === "InvestmentStatus" ? params.sortOrder : undefined}>Status</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                }

                <Table.Body>
                    {!loading && investments.map((investment, i) => {
                        if (showFullSize) {
                            return <Table.Row key={i} style={{ cursor: 'pointer' }} onClick={() => viewInvestment(investment.investmentId)}>
                                <Table.Cell>{investment.name}</Table.Cell>
                                <Table.Cell textAlign='right'>{formatCurrency(investment.amount)}</Table.Cell>
                                <Table.Cell textAlign='right'>{formatCurrency(investment.value)}</Table.Cell>
                                <Table.Cell textAlign='center'>{formatMonthYearDate(investment.asofDate)}</Table.Cell>
                                <Table.Cell>{investment.investmentStatus}</Table.Cell>
                            </Table.Row>
                        }
                        else {
                            return <Table.Row key={i}>
                                <Table.Cell>
                                    {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                    <Grid>
                                        <Grid.Row style={{ cursor: 'pointer' }}>
                                            <Grid.Column width={6}>
                                                <label onClick={() => handleSort("Name")}>Name:</label><br />
                                                <label onClick={() => handleSort("Amount")}>Invested:</label><br />
                                                <label onClick={() => handleSort("Value")}>Value:</label><br />
                                                <label onClick={() => handleSort("AsofDate")}>As of Date:</label><br />
                                                <label onClick={() => handleSort("InvestmentStatus")}>Status:</label><br />
                                            </Grid.Column>
                                            <Grid.Column style={{ fontWeight: 'normal' }} >
                                                <div style={{ width: '45vw' }} onClick={() => viewInvestment(investment.investmentId)}>
                                                    {investment.name}<br />
                                                    {formatCurrency(investment.amount)}<br />
                                                    {formatCurrency(investment.value)}<br />
                                                    {formatMonthYearDate(investment.asofDate)}<br />
                                                    {investment.investmentStatus}<br />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                        }
                    })}
                    {!loading && investments.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 5 : 1} message='You do not hold any investments on CapBlock' />}
                </Table.Body>
                {!loading && investments.length > 0 &&
                    <>
                        <GridFooter colSpan={showFullSize ? 5 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan={2} textAlign='right'>
                                    <b>Total {formatCurrency(investments[0].totalInvestedAmount)}</b>
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign='right'>
                                    <b>Total {formatCurrency(investments[0].totalInvestmentValue)}</b>
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign='right' colSpan={2} />
                            </Table.Row>
                        </Table.Footer>
                    </>
                }
            </Table>

        </Segment>
    )
}

export default MyInvestmentsGrid