import { TabPane } from "semantic-ui-react";
import { Investment } from "../../../../app/models/Investment/Investment";
import { useContext, useState } from "react";
import EntityContext from "../../../../app/context/entityContext";
import ImagesGrid from "./ImagesGrid";
import UploadImages from "./UploadImages";

interface Props {
    investment: Investment;
}

function InvestmentImages({ investment }: Props) {
    const { entity } = useContext(EntityContext);
    const [reloadImages, setReloadImages] = useState(0);
    const [showUploadPanel, setShowUploadPanel] = useState(false);

    return (
        <TabPane>
            <ImagesGrid investment={investment} reloadImages={reloadImages} showUploadPanel={showUploadPanel} setShowUploadPanel={setShowUploadPanel} />
            {investment.sponsorEntityId === entity.id &&
                <UploadImages investment={investment} setReloadImages={setReloadImages} showUploadPanel={showUploadPanel} setShowUploadPanel={setShowUploadPanel} />
            }
        </TabPane>
    );
}

export default InvestmentImages;