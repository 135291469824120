import React, { useContext, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { Segment, Header, Table, Icon, Grid } from 'semantic-ui-react';
import EntityContext from '../../../app/context/entityContext';
import { EntityInvestment } from '../../../app/models/Investment/EntityInvestment';
import { PageParams } from '../../../app/models/common/PagedResult';
import { EntityFilterParams } from '../../../app/models/common/entityFilterParams';
import { useStore } from '../../../app/stores/store';
import EmptyGridMessage from '../../../shared/EmptyGridMessage';
import GridFooter from '../../../shared/GridFooter';
import { getFullSizeWidth, createGridInitialState, handleGridSort, handleGridNavigation } from '../../../shared/utils';

function InvestmentsGrid() {
    const [loading, setLoading] = useState(true);
    const { investmentStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [investments, setInvestments] = useState([new EntityInvestment()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const navigate = useNavigate();

    const initialState: EntityFilterParams = createGridInitialState({
        entityId: entity.id,
        sortIndex: 'Name',
        sortOrder: 'ascending'
    });

    const [params, setParams] = useState(initialState);

    useEffect(() => {
        investmentStore.getMangeInvestments(params).then(r => {
            setTotalRecords(r.totalRecords);
            setInvestments(r.data);
            setLoading(false);
        });
    }, [investmentStore, params, setLoading]);


    const handleSort = (clickedColumn: string) => {
        if (investments.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    const manageInvestment = (id: number) => {
        navigate(`/investment/manage/${id}`);
    }

    return (
        <Segment clearing loading={loading}>
            <Header>Manage Investments</Header>
            <Table singleLine sortable selectable>
                {showFullSize &&
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={() => handleSort("Name")} sorted={params.sortIndex === "Name" && investments.length > 0 ? params.sortOrder : undefined}> Name</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("InvestmentStatus")} sorted={params.sortIndex === "InvestmentStatus" ? params.sortOrder : undefined}>Status</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                }

                <Table.Body>
                    {!loading && investments.map((investment, i) => {
                        if (showFullSize) {
                            return <Table.Row key={i} style={{ cursor: 'pointer' }} onClick={() => manageInvestment(investment.investmentId)}>
                                <Table.Cell>{investment.name}</Table.Cell>
                                <Table.Cell>{investment.investmentStatus}</Table.Cell>
                            </Table.Row>
                        }
                        else {
                            return <Table.Row key={i}>
                                <Table.Cell>
                                    {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                    <Grid>
                                        <Grid.Row style={{ cursor: 'pointer' }}>
                                            <Grid.Column width={6}>
                                                <label onClick={() => handleSort("Name")}>Name:</label><br />
                                                <label onClick={() => handleSort("InvestmentStatus")}>Status:</label><br />
                                            </Grid.Column>
                                            <Grid.Column style={{ fontWeight: 'normal' }} >
                                                <div style={{ width: '45vw' }} onClick={() => manageInvestment(investment.investmentId)}>
                                                    {investment.name}<br />
                                                    {investment.investmentStatus}<br />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                        }
                    })}
                    {!loading && investments.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 2 : 1} message='No investments to manage' />}
                </Table.Body>
                {!loading && investments.length > 0 &&
                    <GridFooter colSpan={showFullSize ? 2 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                }
            </Table>
        </Segment>
    )
}

export default InvestmentsGrid