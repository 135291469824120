import React, { useEffect, useState } from "react";
import { Button, Grid, GridColumn, Header } from "semantic-ui-react";
import PhotoWidgetDropzone from "./PhotoWidgetDropzone";
import PhotoWidgetCropper from "./photoWidgetCropper";
import { observer } from "mobx-react-lite";

interface Props {
    loading: boolean;
    uploadPhoto: (file: Blob) => void;
    currentImage: Blob | null;
}

export default observer(function PhotoUploadWidget({ loading, uploadPhoto, currentImage }: Props) {
    const [files, setFiles] = useState<any>([]);
    const [cropper, setCropper] = useState<Cropper>();

    const onCrop = () => {
        if (cropper) {
            cropper.getCroppedCanvas().toBlob(blob => uploadPhoto(blob!));
        }
    }

    useEffect(() => {
        return () => {
            files.forEach((file: any) => URL.revokeObjectURL(file.preview));
        }
    }, [files])

    return (
        <>
            <Grid columns='equal' style={{ "marginBottom": "5px" }}>
                <GridColumn>
                    <Header sub color='teal' content='Step 1 = Upload Document' />
                    <PhotoWidgetDropzone setFiles={setFiles} />
                </GridColumn>
                <GridColumn>
                    <Header sub color='teal' content='Step 2 = Resize Image' />
                    {files && files.length > 0 && (
                        <PhotoWidgetCropper setCropper={setCropper} imagePreview={files[0].preview} />
                    )}
                </GridColumn>
                <GridColumn>
                    <Header sub color='teal' content='Step 3 = Preview' />
                    {currentImage !== null && files && files.length === 0 &&
                        <img alt="" src={URL.createObjectURL(currentImage)} style={{ maxWidth: 250, minHeight: 100, overflow: 'hidden' }}  />
                    }
                    {files && files.length > 0 && (
                        <>
                            <div className="img-preview" style={{ minHeight: 200, overflow: 'hidden' }} />
                            <Button.Group widths={2} style={{display: 'none'}}>
                                <Button type="button" loading={loading} onClick={onCrop} positive icon='check' id="confirmImage"/>
                                <Button disabled={loading} onClick={() => setFiles([])} icon='close' />
                            </Button.Group>
                        </>
                    )}
                </GridColumn>
            </Grid>
        </>
    )
})