import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Form, Grid, Header, Icon, Modal } from 'semantic-ui-react';
import TextInput from '../app/common/form/TextInput';
import { useStore } from '../app/stores/store';
import * as Yup from 'yup';
import { CodeType, VerificationCodeFormValues } from '../app/models/verificationCode/verificationCode';
import { CommonRegularExpressions } from './CommonRegularExpressions';
import { toast } from 'react-toastify';
import { maskPhone } from './utils';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
    show: boolean;
    onCancel: () => void;
    onValidCode: (verificationCode: string) => void;
    alternatePhone?: string;
    userOptIn: boolean;
}

function VerificationCodeModal({ show, onCancel, onValidCode, alternatePhone, userOptIn }: Props) {

    const { verificationCodeStore } = useStore();
    const [verificationCode] = useState<VerificationCodeFormValues>(new VerificationCodeFormValues());
    const [loading, setLoading] = useState(false);
    const [resend, setResend] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const [defaultDeliveryMethod] = useState(userOptIn ? CodeType.Mobile : CodeType.Email);

    const getVerificationCode = useCallback((resend: boolean, deliveryMethod: CodeType) => {
        setLoading(true);
        if (resend) {
            setResend(true);
        }

        getAccessTokenSilently().then(() => {
            verificationCodeStore.getCode(deliveryMethod, alternatePhone).then(r => {
                setResend(false);
                if (resend) {
                    toast.success("Verification code sent!", { theme: "colored" });
                }
            }).finally(() => { setLoading(false); });
        })
    }, [verificationCodeStore, alternatePhone, getAccessTokenSilently]);

    useEffect(() => {
        getVerificationCode(false, defaultDeliveryMethod);
    }, [getVerificationCode, defaultDeliveryMethod]);

    const handleFormSubmit = (values: VerificationCodeFormValues, setFieldError: any) => {
        setLoading(true);

        verificationCodeStore.verifyCode(values.verificationCode)
            .then(valid => {
                if (valid) {
                    onValidCode(values.verificationCode);
                }
            })
            .catch(err => {
                setFieldError('verificationCode', err.response.data)
            })
            .finally(() => setLoading(false));
    }

    return (
        <Modal centered={false} size="mini" open={show} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>Verification Code</Modal.Header>
            <Modal.Content>
                <Header>Enter the 2-step verification code we sent to your {alternatePhone ? `number ${maskPhone(alternatePhone)}` : defaultDeliveryMethod === CodeType.Mobile ? 'phone' : 'email'}</Header>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={3} verticalAlign='middle' >
                            <Icon name="mobile" size="huge" />
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <Formik
                                validationSchema={Yup.object({
                                    verificationCode: Yup
                                        .string()
                                        .required("Verification Code is required")
                                        .min(6)
                                        .matches(CommonRegularExpressions.verificationCode, "Verification Code must be a number")
                                })}
                                enableReinitialize
                                initialValues={verificationCode}
                                onSubmit={(values, { setFieldError }) => {
                                    handleFormSubmit(values, setFieldError);
                                }} >
                                {({ handleSubmit }) => (
                                    <Form id='codeForm' className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                                        <TextInput placeholder='Verification Code' name='verificationCode' mask='999999' maskplaceholder='0' autoFocus />
                                    </Form>
                                )}
                            </Formik>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row textAlign='center'>
                        <Grid.Column>
                            Didn't receive the code?
                            {userOptIn &&
                                <>
                                    <br />
                                    <Button compact basic color='blue' content='Re-Send SMS' onClick={() => getVerificationCode(true, CodeType.Mobile)} loading={resend} disabled={resend} />
                                </>
                            }
                            <Button compact basic color='blue' content='Send Code to Email' onClick={() => getVerificationCode(true, CodeType.Email)} loading={resend} disabled={resend} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onCancel} content='Cancel' disabled={loading} />
                <Button form="codeForm" primary type='submit' content='Submit' loading={loading} disabled={loading} />
            </Modal.Actions>
        </Modal>
    )
}

export default VerificationCodeModal