import React, { useCallback, useEffect, useState } from 'react'
import { Button, Confirm, Header, Icon, Item, Modal } from 'semantic-ui-react'
import { useStore } from '../../app/stores/store';
import VerificationCodeModal from '../../shared/VerificationCodeModal';
import { observer } from 'mobx-react-lite';
import { maskPhone, sleep } from '../../shared/utils';
import { PhoneNumbers, PhoneNumbersFormValues } from '../../app/models/settings/phoneNumbers';
import AddPhoneNumberModal from './addPhoneNumberModal';
import { toast } from 'react-toastify';
import { User } from '../../app/models/registration/user';

interface Props {
    show: boolean;
    onCancel: () => void;
    userPhones: PhoneNumbers;
    user: User;
}

function ChangePhoneNumbersModal({ show, onCancel, userPhones, user }: Props) {
    const { userStore } = useStore();
    const [showVerification, setShowVerification] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updatedPhones, setUpdatedPhones] = useState(userPhones);
    const [state, setState] = useState({ open: false, alternateNumber1: false });
    const handleCloseConfirmation = () => setState({ open: false, alternateNumber1: false })
    const [openAddPhoneNumber, setOpenAddPhoneNumber] = useState(false);

    const submitForm = async () => {
        setShowVerification(false);
        await sleep(100);
        updatePhones(updatedPhones);
        toast.success("Success!", { theme: "colored" });
    }

    const swapNumbers = (alternateNumber1: boolean) => {
        const phone = { ...updatedPhones };
        const primary = updatedPhones.primaryPhone;
        phone.swapAlternate1 = alternateNumber1;
        phone.removeAlternate1 = null;
        phone.primaryPhone = alternateNumber1 ? updatedPhones.alternatePhone1 : updatedPhones.alternatePhone2;
        if (alternateNumber1) {
            phone.alternatePhone1 = primary;
        }
        else {
            phone.alternatePhone2 = primary;
        }

        setUpdatedPhones(phone);
        setShowVerification(true);
    }

    const removeNumber = () => {
        const phone = { ...updatedPhones };
        phone.removeAlternate1 = state.alternateNumber1;
        phone.swapAlternate1 = null;

        if (state.alternateNumber1) {
            phone.alternatePhone1 = phone.alternatePhone2 || '';
            phone.alternatePhone2 = '';
        } else {
            phone.alternatePhone2 = '';
        }
        updatePhones(phone);
        handleCloseConfirmation();
    }

    const updatePhones = (phones: PhoneNumbersFormValues) => {
        userStore.updateUserPhones(phones).then(r => {
            userPhones.primaryPhone = phones.primaryPhone;
            userPhones.alternatePhone1 = phones.alternatePhone1;
            userPhones.alternatePhone2 = phones.alternatePhone2;
            userPhones.removeAlternate1 = phones.removeAlternate1 = null;
            userPhones.swapAlternate1 = phones.swapAlternate1 = null;

            setUpdatedPhones(phones);
            setLoading(false);
        })
    }

    const addMoreButtonCheck = useCallback(() => {
        return updatedPhones.alternatePhone2 === '' || updatedPhones.alternatePhone2 === null;
    }, [updatedPhones])

    const [showAddMoreButton, setShowAddMoreButton] = useState(addMoreButtonCheck);

    const handleAddPhoneNumberClose = () => {
        setShowAddMoreButton(addMoreButtonCheck);
        setOpenAddPhoneNumber(false);
    }

    useEffect(() => {
        setShowAddMoreButton(addMoreButtonCheck);
    }, [updatedPhones, setShowAddMoreButton, addMoreButtonCheck])

    interface ITest {
        isAlternateNumber1: boolean
    }
    const AlternatePhone = (props: ITest) => {
        return <Item>
            <Item.Content verticalAlign='middle'>
                <Icon size='big' name='mobile' />
                <Item.Header>{maskPhone(props.isAlternateNumber1 ? updatedPhones.alternatePhone1 : updatedPhones.alternatePhone2)}</Item.Header>
                &nbsp;<Button primary size='small' onClick={() => swapNumbers(props.isAlternateNumber1)} loading={loading} disabled={loading} >Set as Primary</Button>
                <Button negative size='small' onClick={() => setState({ open: true, alternateNumber1: props.isAlternateNumber1 })}>Remove</Button>
            </Item.Content>
        </Item>;
    }

    return (
        <Modal size="tiny" centered={false} open={show} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>Manage Phone Numbers</Modal.Header>
            <Modal.Content>
                <Header as='h5'>To remove the primary phone, set a different number as primary first.</Header>
                <Item.Group>
                    <Item>
                        <Item.Content verticalAlign='middle'>
                            <Icon size='big' name='mobile' />
                            <Item.Header>{maskPhone(updatedPhones.primaryPhone)}</Item.Header>
                            &nbsp;<Button basic color='blue' size='small'>Primary</Button>
                        </Item.Content>
                    </Item>
                    {updatedPhones.alternatePhone1 && <AlternatePhone isAlternateNumber1={true} />}

                    {updatedPhones.alternatePhone2 && <AlternatePhone isAlternateNumber1={false} />}

                </Item.Group>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onCancel} content='Cancel' />
                {showAddMoreButton && <Button primary onClick={() => setOpenAddPhoneNumber(true)}>Add another phone number</Button>}
            </Modal.Actions>

            {showVerification && <VerificationCodeModal show={showVerification} onCancel={() => { setShowVerification(false); }} onValidCode={submitForm} userOptIn={user.optInSMS} />}

            {openAddPhoneNumber && <AddPhoneNumberModal onCancel={handleAddPhoneNumberClose} show={openAddPhoneNumber} userPhones={updatedPhones} user={user} />}

            <Confirm centered={false} header='Confirm action' content='Remove Phone Number?' open={state.open} onCancel={handleCloseConfirmation} onConfirm={removeNumber} size='mini' confirmButton="Remove" />

        </Modal>
    )
}

export default observer(ChangePhoneNumbersModal)