export interface InvestmentSummaryView {
    id: number,
    investmentId: number;
    sponsorEntityId: number,
    name:string,
    description: string,
    address1:string,
    address2:string,
    city:string,
    stateCode:string,
    zipCode:string,
    secType:string,
    closeDate:Date,
    acquisitionPrice:number,
    dealType:string,
    minimumInvestment:number,
    totalRaiseAmount:number

  }
  
  export class InvestmentSummaryView implements InvestmentSummaryView {
    constructor(init?: InvestmentSummaryView) {
      Object.assign(this, init);
    }
  }