import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { EntityReview } from "../models/GpLanding/entityReview";

export default class EntityProfileStore
{
    constructor() {
        makeAutoObservable(this)
    }
    getProfile = async (entityId:number) => {
        return agent.EntityProfile.getProfile(entityId);
    }

    saveProfile = async (profile:FormData) => {
        return agent.EntityProfile.saveProfile(profile);
    }

    getEntityProfileView = async(entityId:number) =>
    {
        return agent.EntityProfile.getLandingPageProfile(entityId);
    }

    getProfileInsights = async(entityId:number) =>
    {
        return agent.EntityProfile.getLandingPageInsights(entityId);
    }

    getSponsorReviews = async(entityId:number, numberOfReviews:number) =>
    {
        return agent.EntityProfile.getSponsorReviews(entityId, numberOfReviews);
    }

    getUserSponsorReview = async(entityId:number) =>
    {
        return agent.EntityProfile.getSponsorReview(entityId);
    }

    saveUserSponsorRewiew = async(review:EntityReview) =>
    {
        return agent.EntityProfile.saveSponsorReview(review);
    }

    getGpInvestmentTrackRecord = async(entityId:number) =>
    {
        return agent.EntityProfile.getGPInvestmentTrackRecord(entityId);
    }

    getGpInvestmentActiveDeals = async(entityId:number) =>
    {
            return agent.EntityProfile.getGPInvestmentActiveDeals(entityId);
    }

    getGpSponsorshipTeam = async(entityId:number) =>
    {
        return agent.EntityProfile.getGpSponsorshipTeam(entityId);
    }
}