import { CapBlockTable } from "../common/CapblockTable";

export interface Task {
    id: number;
    entityId: number;
    capblockTable: CapBlockTable,
    tableKeyId: number,
    taskType: number;
    taskTypeDescription: number;
    status: number;
    statusDescription: string;
    description: string;
    actionURL: string;
    isDeleted: boolean;
    created: Date;
}

export class Task implements Task {
  constructor(init?: Task) {
    Object.assign(this, init);
  }
}