import React, { useEffect, useState } from 'react'
import { Button, Grid, Header, Icon, Label, Segment, Table } from 'semantic-ui-react';
import EmptyGridMessage from '../../../shared/EmptyGridMessage';
import GridFooter from '../../../shared/GridFooter';
import { useMediaQuery } from 'react-responsive';
import { createGridInitialState, downloadFile, formatDate, getFullSizeWidth, handleGridNavigation, handleGridSort } from '../../../shared/utils';
import { LoanParams } from '../../../app/models/Loan/LoanParams';
import { PageParams } from '../../../app/models/common/PagedResult';
import { LoanPayment } from '../../../app/models/Loan/LoanPayment';
import { formatCurrency } from '../../../shared/utils';
import { useStore } from '../../../app/stores/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';

interface Props {
    loanId: number;
}

function LoanPaymentHistoryGrid({ loanId }: Props) {
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const [totalRecords, setTotalRecords] = useState(0);
    const [payments, setPayments] = useState([new LoanPayment()]);
    const [loading, setLoading] = useState(false);
    const { loanStore, monthlyStatementStore } = useStore();
    const [downloading, setDownloading] = useState<number[]>([]);
    const [downloadingHistory, setDownloadingHistory] = useState(false);

    const initialState: LoanParams = createGridInitialState({
        loanId: loanId,
        sortIndex: 'PaymentDate',
        sortOrder: 'descending',
    });

    const [params, setParams] = useState(initialState);

    const handleSort = (clickedColumn: string) => {
        if (payments.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    useEffect(() => {
        setLoading(true);
        loanStore.getLoanPaymentHistory(params).then(r => {
            setTotalRecords(r.totalRecords);
            setPayments(r.data);
            setLoading(false);
        });
    }, [loanStore, params]);

    const downloadStatement = (statementId: number, index: number) => {
        setDownloading([...downloading, index]);
        monthlyStatementStore.downloadMonthlyStatement(loanId, statementId)
            .then(response => {
                downloadFile(response);
            })
            .finally(() => setDownloading((prevDisabledButtons) => prevDisabledButtons.filter((i) => i !== index))
          );
    }

    const downloadPayoff = (index: number) => {
        setDownloading([...downloading, index]);
        loanStore.downloadPayoffPayment(loanId)
            .then(response => {
                downloadFile(response);
            })
            .finally(() => setDownloading((prevDisabledButtons) => prevDisabledButtons.filter((i) => i !== index))
          );
    }

    const DownloadStatement = ({statementId, index}: any) => {
        const downloadClick = downloading.includes(index);
        return <Icon size='large' link={!downloadClick} color='blue' onClick={() => downloadStatement(statementId, index)} alt='Download Statement' disabled={downloadClick} loading={downloadClick}>
                <FontAwesomeIcon icon={downloadClick ? faSpinner : faDownload} />
            </Icon>;
    }

    const DownloadPayoff = ({index}: any) => {
        const downloadClick = downloading.includes(index);
        return <Icon size='large' link={!downloadClick} color='blue' onClick={() => downloadPayoff(index)} alt='Download Payoff' disabled={downloadClick} loading={downloadClick}>
                <FontAwesomeIcon icon={downloadClick ? faSpinner : faDownload} />
            </Icon>;
    }

    const downloadHistory = () => {
        setDownloadingHistory(true);
        loanStore.getLoanPaymentHistoryExport(params)
            .then(response => {
                downloadFile(response);
            })
            .finally(() => setDownloadingHistory(false))
    }

    return (
        <Segment clearing>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column verticalAlign='middle' textAlign='left' width={showFullSize ? 10 : 6}>
                        <Header>Transaction history</Header>
                    </Grid.Column>
                    <Grid.Column textAlign='right' verticalAlign='middle' width={showFullSize ? 6 : 10}>
                        <Button floated='right' primary onClick={downloadHistory} loading={downloadingHistory} disabled={downloadingHistory}>
                            <FontAwesomeIcon icon={faDownload} /> CSV
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Table singleLine sortable>
                {showFullSize &&
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={() => handleSort("PaymentDate")} sorted={params.sortIndex === "PaymentDate" && payments.length > 0 ? params.sortOrder : undefined}>Date</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Amount")} sorted={params.sortIndex === "Amount" && payments.length > 0 ? params.sortOrder : undefined}>Amount</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Principal")} sorted={params.sortIndex === "Principal" && payments.length > 0 ? params.sortOrder : undefined}>Principal</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Interest")} sorted={params.sortIndex === "Interest" && payments.length > 0 ? params.sortOrder : undefined}>Interest</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("TotalEscrow")} sorted={params.sortIndex === "TotalEscrow" && payments.length > 0 ? params.sortOrder : undefined}>Escrow</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Status")} sorted={params.sortIndex === "Status" && payments.length > 0 ? params.sortOrder : undefined}>Status</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("PaymentDescription")} sorted={params.sortIndex === "PaymentDescription" && payments.length > 0 ? params.sortOrder : undefined}>Type</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                }

                <Table.Body>
                    {payments.map((payment, i) => {
                        function getStyle(payment: LoanPayment): string {
                            const typeMap: Record<string, string> = {
                              Credit: 'creditAmount',
                              Fees: 'feeAmount',
                              // Add more mappings when needed
                            };
                            return typeMap[payment.paymentType] || '';
                          }

                        if (showFullSize) {
                            return <Table.Row key={i}>
                                <Table.Cell>{formatDate(payment.paymentDate)}</Table.Cell>
                                <Table.Cell className={getStyle(payment)}>
                                    {formatCurrency(payment.amount)}
                                </Table.Cell>
                                <Table.Cell>{formatCurrency(payment.principal)}</Table.Cell>
                                <Table.Cell>{formatCurrency(payment.interest)}</Table.Cell>
                                <Table.Cell>{formatCurrency(payment.totalEscrow)}</Table.Cell>
                                <Table.Cell>
                                    <Label circular color={payment.status === "Paid" ? 'blue' : payment.status === "Canceled" ? 'red' : undefined}>{payment.status}</Label>
                                </Table.Cell>
                                <Table.Cell>{payment.paymentType}</Table.Cell>
                                <Table.Cell>
                                    {payment.monthlyStatementId > 0 &&
                                        <DownloadStatement statementId={payment.monthlyStatementId} index={i} />
                                    }
                                    {payment.paymentType === "Payoff" &&
                                        <DownloadPayoff index={i}/>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        }
                        else {
                            return <Table.Row key={i}>
                                <Table.Cell>
                                    {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                    <Grid>
                                        <Grid.Row style={{ cursor: 'pointer' }}>
                                            <Grid.Column width={6}>
                                                <label onClick={() => handleSort("PaymentDate")}>Date:</label><br />
                                                <label onClick={() => handleSort("Amount")}>Amount:</label><br />
                                                <label onClick={() => handleSort("Principal")}>Principal:</label><br />
                                                <label onClick={() => handleSort("Interest")}>Interest:</label><br />
                                                <label onClick={() => handleSort("TotalEscrow")}>Escrow:</label><br />
                                                <label onClick={() => handleSort("Status")}>Status:</label><br />
                                                <label onClick={() => handleSort("PaymentDescription")}>Type:</label><br />
                                                <label>Actions:</label><br />
                                            </Grid.Column>
                                            <Grid.Column style={{ fontWeight: 'normal' }} >
                                                {formatDate(payment.paymentDate)}<br />
                                                <label className={getStyle(payment)} onClick={() => handleSort("Amount")}>{formatCurrency(payment.amount)}</label><br />
                                                {formatCurrency(payment.principal)}<br />
                                                {formatCurrency(payment.interest)}<br />
                                                {formatCurrency(payment.totalEscrow)}<br />
                                                {payment.status} <br />
                                                {payment.paymentType} <br />
                                                {payment.monthlyStatementId > 0 &&
                                                    <DownloadStatement statementId={payment.monthlyStatementId} index={i} />
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                        }
                    })}
                    {!loading && payments.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 8 : 1} message='You do not have any transaction' />}
                </Table.Body>
                {!loading && payments.length > 0 &&
                    <GridFooter colSpan={showFullSize ? 8 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                }
            </Table>
        </Segment>
    )
}

export default LoanPaymentHistoryGrid