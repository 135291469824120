import { getFloatValue } from "../../../shared/utils";

export enum OfferingAction {
  Unknown = 0,
  Buy = 1,
  Sell = 2
}

export enum OfferingStatus {
  Unknown = 0,
  Active = 1,
  PendingApproval = 2,
  PendingSponsorApproval = 3,
  PendingSettlement = 4,
  AwaitingConfirmation = 5,
  SettlementCompleted = 6,
  Expired = 7,
  Deleted = 8
}

export enum OfferingWorkflow {
  Unknown = 0,
  CreatedSellOffering = 1,
  CreatedBuyOffering = 2,
  InitiatedBuyOffering = 3,
  InitiatedSellOffering = 4,
  ApprovedByCreator = 5,
  RejectedByCreator = 6,
  ApprovedBySponsor = 7,
  RejectedBySponsor = 8,
  InitiatedFundsTransfer = 9,
  Settled = 10,
  Expired = 11
}

export interface Offering {
    id: number;
    investmentId: number;
    expiryDuration : number;
    entityID : number;
    action : OfferingAction;
    shareCount : number;
    sharePrice : number;
    amount : number;
    status : OfferingStatus;
    offeringStatusDesc: string;
    createdBy : number;
    created : Date;
    offeringEntityName : string;
    expiryDate: Date;
    actionMsg: string;
    costBasis: number;
    capitalGains: number;
    saleProceeds: number;
    gpOverride: number;
    totalDueSeller: number;
    workflow: OfferingWorkflow;
    activeResponderEntityName: string;
    activeResponderEntityId: number;
    buyerEntityName: string;
    sellerEntityName: string;
    availableEntityShareCount : number;
    entityShareValue: number;
}

export class Offering implements Offering {
    constructor(init?: OfferingFormValues) {
      this.id = 0;
      this.action = OfferingAction.Sell;
      this.status = OfferingStatus.Active;
      this.shareCount = 0;
      this.sharePrice = 0;
      Object.assign(this, init);
    }
}

  export class OfferingFormValues {
    id: number = 0;
    investmentId: number = 0;
    expiryDuration : number = 2;
    expiryDate : Date;
    entityID : number = 0;
    action :number = OfferingAction.Sell;
    shareCount : number = 0;
    sharePrice : number = 0;
    amount : number = 0;
    status : number = 0;

    constructor(investmentOffering?: OfferingFormValues) {
      this.expiryDate = new Date();
        if (investmentOffering) {
            this.id = investmentOffering.id;
            this.investmentId = investmentOffering.investmentId;
            this.expiryDuration = investmentOffering.expiryDuration;
            this.expiryDate = investmentOffering.expiryDate;
            this.entityID = investmentOffering.entityID;
            this.action = investmentOffering.action;
            this.shareCount = getFloatValue(investmentOffering.shareCount);
            this.sharePrice = getFloatValue(investmentOffering.sharePrice);
            this.amount = investmentOffering.amount;
            this.status = investmentOffering.status;
        }
    }
  }

export interface OfferingSellEstimation {
    investmentId: number;
    entityId : number;
    shareCount : number;
    sharePrice : number;
}

export interface EntityShareCountAndBalance {
  investmentId: number;
  entityId : number;
  availableEntityShareCount : number;
  entityShareValue : number;
}

export interface OfferingInitiate {
  entityId : number;
  offeringId: number;
  shareCount : number;
  sharePrice : number;
  workflow: OfferingWorkflow;
}
