import { useField } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { Form, Label } from "semantic-ui-react";
import FieldTitle from "./FieldTitle";
import { Dropdown } from "primereact/dropdown";

interface Props {
    placeholder: string;
    name: string;
    options: any;
    showRequired?: boolean;
    hidetitle?: boolean;
    className?: any;
    onChange?: (e: any) => void;
    disabled?: any;
    value?: string;
    searchable?: boolean;
    maxLength?: number;
}

function EditableDropdown(props: Props) {
    const [field, meta, helpers] = useField(props.name);
    return (
        <Form.Field error={meta.touched && !!meta.error}>
            {!props.hidetitle && <FieldTitle name={props.name} placeholder={props.placeholder} showRequired={props.showRequired} />}
            <Dropdown
                id={props.name}
                value={field.value}
                onChange={(e) => {
                    helpers.setValue(e.value);
                    if (props.onChange)
                    {
                        props.onChange(e);
                    }
                }}

                options={props.options}
                optionLabel="text"
                optionValue="value"
                editable
                placeholder={props.placeholder}
                disabled={props.disabled}
                style={{width: '100%'}}
                maxLength={props.maxLength}
            />

            {meta.touched && meta.error ? (
                <Label basic color='red' pointing='above'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    )
}

export default observer(EditableDropdown);