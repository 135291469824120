import { Formik } from 'formik';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Image, Form, Grid, Header, Segment, Button, Message, GridColumn, Popup, Icon, Confirm } from 'semantic-ui-react'
import * as yup from 'yup';
import CurrencyInput from '../../app/common/form/CurrencyInput';
import { useStore } from '../../app/stores/store';
import EntityContext from '../../app/context/entityContext';
import { formatCurrency, getFloatValue, getRandomNumber } from '../../shared/utils';
import { TransferActionEnum, TransferFormValues, TransferModel, TransferType } from '../../app/models/settings/transfer';
import { toast } from 'react-toastify';
import { CapBlockTable } from '../../app/models/common/CapblockTable';
import VerificationCodeModal from '../../shared/VerificationCodeModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { faCircleQuestion, faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { GasEstimate } from '../../app/models/crypto/GasEstimate';
import { useDebounce } from '../../shared/useDebounce';
import { TransferFormContext } from './send';

interface Props {
    closeModal: (refreshBalance: boolean) => void;
    transferModel?: TransferModel;
}

function SendGasEstimate({ closeModal, transferModel }: Props) {

    const { activeStepIndex, setActiveStepIndex, transfer, setTransfer, GetAddressInfo, currentToken } = useContext(TransferFormContext);
    const [loading, setLoading] = useState(true);
    const [transferring, setTransferring] = useState(false);

    const showTransferInfo = transfer.transferAction === undefined;
    const { cryptoStore, transferStore, userStore } = useStore();

    const { entity } = useContext(EntityContext);
    const [twoStepVerificationEnabled, setTwoStepVerificationEnabled] = useState(false);
    const [optInSMS, setOptInSMS] = useState(false);
    const [configGas, setConfigGas] = useState(false);
    const [lowGas, setLowGas] = useState(false);
    const [gasEstimate, setGasEstimate] = useState(new GasEstimate());
    const [loadingGasConfig, setLoadingGasConfig] = useState(false);
    const formikRef = useRef<any>();

    const [showVerification, setShowVerification] = useState(false);
    const [formValues, setFormValues] = useState(new TransferFormValues());
    const [maxGasFeeValue, setMaxGasFeeValue] = useState<number>(0);
    const debounceMaxGasFeeValue = useDebounce<number>(maxGasFeeValue, 250);

    const [transferCopy] = useState({...transfer});
    const [resetTransfer, setResetTransfer] = useState({...transfer});
    const isCancelTransfer = transfer.transferAction?.action === TransferActionEnum.cancel;
    const currentTransactionFee = transfer.currentTransactionFee;
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const getEstimate = useCallback(() => {
        cryptoStore.getGasEstimate(transferCopy.senderCapblockTable, transferCopy.senderTableKeyId, transferCopy)
            .then(r => {
                const update = {...transferCopy};
                update.ethPrice = r.ethPrice;
                update.gasEstimate = r.estimate;
                update.gasPrice = r.price;
                update.gasPriceWei = r.gasPriceWei;
                update.transactionFee = r.transactionFee;
                update.totalAmount = update.amount + update.transactionFee;
                update.hasEthBalance = r.hasEthBalance;
                update.maxGasFee = update.transactionFee;
                setTransfer(update);
                setResetTransfer(update);
                setLoading(false);
            })
            .catch(() => setShowCloseButton(true));
      }, [cryptoStore, transferCopy, setTransfer, setLoading, setResetTransfer])

    useEffect(() => {

        userStore.getCurrentUser().then(user => {
            setTwoStepVerificationEnabled(user.require2StepVerification)
            setOptInSMS(user.optInSMS);
        });

        getEstimate();

    }, [userStore, setTwoStepVerificationEnabled, setOptInSMS, getEstimate ]);

    useEffect(() => {
        if (debounceMaxGasFeeValue > 0) {
            const formikContext = formikRef.current;
            const values = formikContext.values;

            if (values.maxGasFee === "") return;

            values.totalAmount = getFloatValue(values.amount) + getFloatValue(values.maxGasFee);

            setLowGas(values.maxGasFee < gasEstimate.gasLowFee);

            const update = {...resetTransfer}
            update.totalAmount = values.totalAmount;
            update.maxGasFee = values.maxGasFee;
            update.ethPrice = gasEstimate.ethPrice;
            update.gasEstimate = gasEstimate.estimate;
            update.overrideGasFee = true;
            update.hasEthBalance = gasEstimate.hasEthBalance;
            setTransfer(update);
        }
    }, [debounceMaxGasFeeValue, formikRef, gasEstimate, resetTransfer, setTransfer])

    const validationSchema = yup.object({
        maxGasFee: yup
            .number()
            .when('hasGasConfig', {
                is: () => configGas,
                then: yup.number()
                    .transform((_value, originalValue) => getFloatValue(originalValue))
                    .required('Fee is required')
                    .positive('Fee must be greater than 0')
                    .test(
                        'is-greater-or-equal',
                        `Fee must be lower than ${formatCurrency(gasEstimate.gasHighFee)}`,
                        function (value) {
                            return Number(formatCurrency(gasEstimate.gasHighFee, true)) >= value!;
                        }
                    )
                    .test(
                        'is-greater-than-current',
                        `Fee must be 10% greater than current fee ${formatCurrency(currentTransactionFee)}`,
                        function (value) {
                            return value! > (Number(formatCurrency(currentTransactionFee, true)) * 1.1);
                        }
                    ),
            })
            .nullable(),
    });

    const openGasConfig = () => {
        setConfigGas(true);
        setLoadingGasConfig(true);

        const senderId = transferModel?.senderTableKeyId ?? entity.id;
        const table = transferModel?.senderCapblockTable ?? CapBlockTable.entity;

        cryptoStore.getGasEstimate(table, senderId, transfer)
            .then(r => setGasEstimate(r))
            .finally(() => setLoadingGasConfig(false));
    }

    const cancelGasConfig = () => {
        setTransfer(resetTransfer);
        setLowGas(false);
        setConfigGas(false);
    }

    const handleBack = () => {
        setConfigGas(false);
        setActiveStepIndex(activeStepIndex - 1);
    }

    const completeTransfer = (verificationCode: string) => {
        setShowVerification(false);
        const updatedFormValues = {
            ...formValues,
            verificationCode: verificationCode,
        };
        createTransfer(updatedFormValues);
    }

    const cancelVerification = () => {
        setTransferring(false);
        setShowVerification(false);
    }

    const createTransfer = (transfer: TransferFormValues) => {
        let transferCall = null;

        if (transfer.transferAction === undefined) {
            transferCall = transferStore.transfer(transfer);
        }
        else {
            transferCall = transferStore.transferAction(transfer);
        }

        transferCall
            .then(r => {
                if (r !== null) {
                    toast.success("Success!", { theme: "colored" });
                    closeModal(true);
                }
            })
            .catch(err => {
                toast.error(err.response.data, { theme: "colored" });

                if (err.response.data.startsWith('This transaction has been mined')) {
                    closeModal(true);
                }
            })
            .finally(() => setTransferring(false));
    }


    const handleFormSubmit = async (values: TransferFormValues) => {
        if (transferModel && transferModel.transferType !== TransferType.coinTransfer) {
            transfer.toTableKeyId = transferModel?.toTableKeyId;
            transfer.transferType = transferModel.transferType;
            transfer.transferSourceId = transferModel.sourceId;
            transfer.amount = transferModel.amount > 0 ? transferModel.amount : getFloatValue(values.amount);
        }
        setTransferring(true);
        values.maxGasFee = getFloatValue(values.maxGasFee);

        if (transfer.transferAction !== undefined && transfer.transferAction.action === TransferActionEnum.cancel && !openConfirm)
        {
            setOpenConfirm(true);
            return;
        }

        if (twoStepVerificationEnabled) {
            setFormValues(transfer);
            setShowVerification(true);
        }
        else {
            createTransfer(transfer);
        }
    }

    return (
        <>
        <Segment basic loading={loading}>
            <Formik
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={transfer}
                onSubmit={(values) => {
                    handleFormSubmit(values);
                }}
                innerRef={formikRef}
            >
                {({ handleSubmit, values }) => (
                    <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                        <Grid textAlign='center'>
                            <Grid.Row>
                                <Grid.Column>
                                    {showTransferInfo && <>
                                        <Segment basic>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column width={2}>
                                                    </Grid.Column>
                                                    <Grid.Column width={7}>
                                                        <Header as='h1'>{formatCurrency(transfer.amount)}</Header>
                                                    </Grid.Column>
                                                    <Grid.Column width={4} textAlign='left' verticalAlign='middle'>
                                                        <Header as='h1' color='blue'>{currentToken.tokenType}</Header>
                                                    </Grid.Column>
                                                    <Grid.Column width={2}></Grid.Column>
                                                </Grid.Row>

                                            </Grid>
                                        </Segment>
                                        <Grid celled columns={2}>
                                            <Grid.Row>
                                                <Grid.Column width={5}>
                                                    Transfer:
                                                </Grid.Column>
                                                <Grid.Column textAlign='left'>
                                                    <Header as='h4'>
                                                        <Image src={currentToken.tokenImage} rounded size='mini' />
                                                        <Header.Content>
                                                            {currentToken.tokenType}
                                                        </Header.Content>
                                                    </Header>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={5}>
                                                    To:
                                                </Grid.Column>
                                                <Grid.Column width={11} textAlign='left'>
                                                    {transferModel
                                                        ? GetAddressInfo()
                                                        : <>{transfer.toName}</>
                                                    }
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </>
                                    }
                                    <Grid columns={2} className='transferInfo'>
                                        {showTransferInfo && <Grid.Row>
                                            <Grid.Column textAlign='left'>
                                                CapBlock Fee
                                            </Grid.Column>
                                            <Grid.Column textAlign='right'>
                                                {formatCurrency(transfer.serviceFee)}
                                            </Grid.Column>
                                        </Grid.Row>}

                                        {!showTransferInfo && <Grid.Row>
                                            <Grid.Column textAlign='left'>
                                                Current Transaction Fee
                                            </Grid.Column>
                                            <Grid.Column textAlign='right'>
                                                {formatCurrency(currentTransactionFee)}
                                            </Grid.Column>
                                        </Grid.Row>}

                                        <Grid.Row>
                                            <Grid.Column textAlign='left'>
                                                Estimated Network Fee
                                            </Grid.Column>
                                            <Grid.Column textAlign='right'>
                                                <Button type='button' circular color='blue' size='mini'
                                                    onClick={openGasConfig}
                                                    icon={<FontAwesomeIcon icon={faPen} />} />
                                                ${formatCurrency(transfer.transactionFee, true)}
                                            </Grid.Column>
                                        </Grid.Row>
                                        {configGas &&
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <Segment loading={loadingGasConfig}>
                                                        <Grid textAlign='center'>
                                                            <Grid.Row>
                                                                <Grid.Column width={13}>
                                                                    <Grid celled divided columns={3}>
                                                                        <GridColumn>
                                                                            <Header as='h5' color='blue'>Low: {formatCurrency(gasEstimate.gasLowFee)}</Header>
                                                                        </GridColumn>
                                                                        <GridColumn style={{ boxShadow: "0 0" }}>
                                                                            <Header as='h5' color='green'>Safe: {formatCurrency(gasEstimate.gasSafeFee)}</Header>
                                                                        </GridColumn>
                                                                        <GridColumn style={{ boxShadow: "0 0" }}>
                                                                            <Header as='h5' color='red'>High: {formatCurrency(gasEstimate.gasHighFee)}</Header>
                                                                        </GridColumn>
                                                                    </Grid>
                                                                </Grid.Column>
                                                                <GridColumn>
                                                                    <Popup
                                                                        trigger={<Icon size='large' style={{ marginTop: '25px' }}><FontAwesomeIcon icon={faCircleQuestion} /></Icon>}
                                                                        content={<p>These are the current gas rates the blockchain is experiencing. Reducing the maximum gas below the safe value can signficiantly slow the transaction speed.</p>}
                                                                        position='bottom center'
                                                                    />
                                                                </GridColumn>
                                                            </Grid.Row>
                                                        </Grid>
                                                        <Grid columns={3} style={{ marginTop: '-30px' }}>
                                                            <Grid.Column width={9} textAlign='right' verticalAlign='middle'>
                                                                Set maximum gas fee in USD
                                                            </Grid.Column>
                                                            <Grid.Column width={5} style={{ whiteSpace: 'nowrap !important' }}>
                                                                <CurrencyInput placeholder='Amount' name='maxGasFee' className={"paymentInput"}
                                                                    hidetitle={'true'} thousandSeparator=',' decimalScale={2} allowNegative={false} maxLength={6}
                                                                    onChange={() => setMaxGasFeeValue(getRandomNumber)}
                                                                />
                                                            </Grid.Column>
                                                            <Grid.Column width={1}>
                                                                <Icon link size='large' onClick={cancelGasConfig} style={{ marginTop: '10px' }}>
                                                                    <FontAwesomeIcon icon={faCircleXmark} />
                                                                </Icon>
                                                            </Grid.Column>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                        }
                                        {showTransferInfo ?
                                            <Grid.Row>
                                                <Grid.Column textAlign='left'>
                                                    Total
                                                </Grid.Column>
                                                <Grid.Column textAlign='right'>
                                                    ${formatCurrency(transfer.totalAmount, true)}
                                                </Grid.Column>
                                            </Grid.Row>
                                            :
                                            <Grid.Row />
                                        }
                                    </Grid>
                                    {!loading && !transfer.hasEthBalance &&
                                        <>
                                            <Grid padded={false} textAlign='left'>
                                                <Grid.Row>
                                                    <GridColumn>
                                                        <Message negative icon='info circle' header='Insufficient balance' content='You need more ETH to complete this transaction' />
                                                    </GridColumn>
                                                </Grid.Row>
                                            </Grid>
                                            <br />
                                        </>
                                    }

                                    {lowGas &&
                                        <>
                                            <Grid padded={false} textAlign='left'>
                                                <Grid.Row>
                                                    <GridColumn>
                                                        <Message color='yellow' icon='warning sign' header='Low network fee' content='Gas fee is low for current network conditions' />
                                                    </GridColumn>
                                                </Grid.Row>
                                            </Grid>
                                            <br />
                                        </>
                                    }

                                    {showTransferInfo && <Button type='button' content='Back' onClick={handleBack} disabled={transferring} />}

                                    {transfer.hasEthBalance && <Button id='btnSubmit' color={isCancelTransfer ? 'red' : 'blue'} type='submit' content='Send Now' disabled={transferring} loading={transferring} />}

                                    <Button type='button' onClick={() => closeModal(false)} content='Cancel' disabled={transferring} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Confirm open={openConfirm} centered={false} header="Confirm Action" value='Send now' content='Cancel Transfer?' color='red' 
                        onConfirm={() => { setOpenConfirm(false); handleFormSubmit(values); }} disabled={transferring} size='mini'
                        confirmButton="Send Now" onCancel={() => { setOpenConfirm(false); setTransferring(false);}} />
                    </Form>
                )}
            </Formik>

            {showVerification && <VerificationCodeModal show={showVerification} onCancel={cancelVerification} onValidCode={completeTransfer} userOptIn={optInSMS} />}

        </Segment>
        {showCloseButton && <Button type='button' floated='right' onClick={() => closeModal(false)} content='Close' />}

        </>
    )
}

export default SendGasEstimate