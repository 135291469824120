import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { Segment } from 'semantic-ui-react'
import { HistoricFinancialInfo } from '../../../../app/models/Investment/HistoricFinancialInfo';
import { formatCurrency } from '../../../../shared/utils';

export interface Props {
  data: HistoricFinancialInfo[];
}

function FinancialGraph({ data }: Props) {
  const sortedDate = data.sort((a, b) => {
    if (a.year === b.year) {
      return a.month - b.month;
    }
    return a.year - b.year;
  })

  const series: any = [{
    name: "Valuation",
    data: sortedDate.map(s => s.valuation)
  },
  {
    name: "Equity",
    data: sortedDate.map(s => s.equity)
  },
  {
    name: "Share Value",
    data: sortedDate.map(s => s.shareValue)
  },
  ];
  const options: any = {
    chart: {
      height: 150,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    markers: {
      size: 5,
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: 'Valuation History',
      align: 'center'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    yaxis: {
      labels: {
        formatter: function (value: any) {
          return formatCurrency(value);
        }
      },
    },
    xaxis: {
      categories: sortedDate.map(s => { return s.asofDate; }),
    }
  };

  return (
    <Segment textAlign='center' basic>
      <ReactApexChart options={options} series={series} type="line" height={350} />
    </Segment>
  )
}

export default FinancialGraph