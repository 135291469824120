import { InvestmentQueryFilter } from "../common/InvestmentQueryFilter";
import { DistributionFormValues } from "./Distribution";

export interface CalculateDistribution {
    filter: InvestmentQueryFilter;
    distribution: DistributionFormValues;
}

export class CalculateDistribution implements CalculateDistribution {
    constructor() {
        this.filter = new InvestmentQueryFilter();
        this.distribution = new DistributionFormValues();
    }
}