import { Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Form, Button, Icon, Header } from 'semantic-ui-react';
import CurrencyInput from '../../../../app/common/form/CurrencyInput';
import { useStore } from '../../../../app/stores/store';
import SelectInput from '../../../../app/common/form/SelectInput';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { booleanOptions, getEnumValue, getFloatValue } from '../../../../shared/utils';
import { KeyMetric, KeyMetricVisualizationSignEnum } from '../../../../app/models/Investment/KeyMetric';

interface Props {
    investmentId: number;
    keyMetricId: number;
    onCancel: (refresh: boolean) => void;
}

function KeyMetricsForm({ investmentId, keyMetricId, onCancel }: Props) {
    const emptyOption = { key: '', defaultsign: 0 };
    const [returnTypeOptions, setReturnTypeOptions] = useState([emptyOption]);
    const [keyMetricSignOptions, setkeyMetricSignOptions] = useState([emptyOption]);
    const { commonStore, investmentStore } = useStore();
    const [keyMetric, setKeyMetric] = useState(new KeyMetric());
    const [, setLoading] = useState(false);

    useEffect(() => {
        commonStore.getInvestmentEnums().then(r => {
            setReturnTypeOptions(r.returnType.map((s: any) => ({ key: s.id, value: s.id, text: s.description, defaultsign: s.defaultVisualizationSign })));
            setkeyMetricSignOptions(r.keyMetricVisualisationSign);
        })

        if (keyMetricId > 0) {
            setLoading(true);
            investmentStore.getKeyMetric(keyMetricId).then((r) => {
                r.showInHeader = r.showInHeader === true ? 1 : 0;
                r.visualizationSign = getEnumValue(KeyMetricVisualizationSignEnum, r.visualizationSign);
                setKeyMetric(new KeyMetric(r));
            })
                .finally(() => setLoading(false));
        }
    }, [investmentId, setKeyMetric, investmentStore, setLoading, commonStore, setReturnTypeOptions, keyMetricId]);

    const validationSchema = yup.object({
        returnTypeId: yup
            .number()
            .min(1, 'Return Type is required'),
        showInHeader: yup
            .number()
            .min(0, 'Show In Header is required'),
        low: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Low metric is required')
            .positive('Low metric must be greater than 0')
            .max(1000, 'Number must be lower than 1000'),
        high: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('High metric is required')
            .positive('High metric must be greater than 0')
            .max(1000, 'Number must be lower than 1000'),
        target: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Target metric is required')
            .positive('Target metric must be greater than 0')
            .max(1000, 'Number must be lower than 1000'),
    });

    const handleFormSubmit = async (values: any, setSubmitting: any) => {
        values.investmentId = investmentId;
        values.showInHeader = values.showInHeader === 1 ? true : false;
        const keyMetric = new KeyMetric(values);
        investmentStore.saveInvestmentKeyMetric(keyMetric)
            .then(r => {
                toast.success("Success!", { theme: "colored" });
                onCancel(true);
            })
            .catch(err => {
                toast.error("There was an issue saving the key metric.", { theme: "colored" });
            })
            .finally(() => setSubmitting(false));
    }

    const setDefaultReturnTypeSign = (selectedReturnType: number) => {
        var returnTypeSign = returnTypeOptions.find(f => Number(f.key) === selectedReturnType);
        setKeyMetric(prevState => ({
            ...prevState,
            returnTypeId: selectedReturnType,
            visualizationSign: returnTypeSign!.defaultsign
        }));
    }

    const getVisualizationIcon = (visualizationSign: KeyMetricVisualizationSignEnum) => {
        return visualizationSign === KeyMetricVisualizationSignEnum.Percent ? 'percent' : 'dollar';
    }

    return (
        <>
            <Header as='h3' color='blue'>
                {keyMetricId === 0 ? "Add" : "Edit"} Projected Return
            </Header>
            <Formik
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={keyMetric}
                onSubmit={(values, { setSubmitting }) => {
                    handleFormSubmit(values, setSubmitting);
                }}
            >
                {({ values, handleSubmit, isSubmitting }) => (
                    <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                        <Form.Group widths='equal'>
                            <SelectInput options={returnTypeOptions} placeholder='Return Type' name='returnTypeId' showRequired onChange={(_, data) => setDefaultReturnTypeSign(data.value)} />
                            <SelectInput options={keyMetricSignOptions} placeholder='Metric Sign' name='visualizationSign' showRequired />
                            <SelectInput options={booleanOptions} placeholder='Show in Investment Marketplace Header' name='showInHeader' showRequired />
                        </Form.Group>

                        <Form.Group widths='equal'>
                            <CurrencyInput placeholder='Low' name='low' maxLength={6} icon={<Icon name={getVisualizationIcon(values.visualizationSign)} />} showRequired />
                            <CurrencyInput placeholder='High' name='high' maxLength={6} icon={<Icon name={getVisualizationIcon(values.visualizationSign)} />} showRequired />
                            <CurrencyInput placeholder='Target' name='target' maxLength={6} icon={<Icon name={getVisualizationIcon(values.visualizationSign)} />} showRequired />
                        </Form.Group>

                        <br />
                        <Button disabled={isSubmitting} loading={isSubmitting} floated='right' primary type='submit' content='Save' />
                        <Button floated='right' onClick={() => onCancel(false)} content='Cancel' />
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default KeyMetricsForm