import { makeAutoObservable } from "mobx";
import agent from "../api/agent";

export default class CsvFileImportStore
{
    constructor() {
        makeAutoObservable(this)
    }
    submitCsvFile = async (input: FormData, timeoutInSec: number) => {
        return agent.CsvFileImport.submitFile(input,timeoutInSec);
    }

    getLastJobStatus = async (entityId: number) => {
        return agent.CsvFileImport.getLastJobStatus(entityId);
    }

    getJobStatus = async(entityId:number, jobId:number) => {
        return agent.CsvFileImport.getJobStatus(entityId, jobId);
    }
    downloadCsvTemplate = async() =>{
        return agent.CsvFileImport.downloadCsvTemplate();
    }
}