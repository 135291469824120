import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { LoanFormValues } from "../models/Loan/Loan";
import { BorrowerLoanParams, LenderLoanParams, LoanParams } from "../models/Loan/LoanParams";
import { LoanEscrow } from "../models/Loan/LoanEscrow";
import { LoanEscrowFilter } from "../models/Loan/LoanEscrowFilter";
import { LoanQueryFilter } from "../models/common/loanQueryFilter";
import { LoanPaymentProcessing } from "../models/Loan/LoanPaymentProcessing";
import { PayoffInput } from "../models/Loan/PayoffInput";

export default class LoanStore {

    constructor() {
        makeAutoObservable(this)
    }

    getLenderLoans = async (params: LenderLoanParams) => {
        return agent.Loans.getLenderLoans(params)
    }

    getBorrowerLoans = async (params: BorrowerLoanParams) => {
        return agent.Loans.getBorrowerLoans(params)
    }

    save = async (loan: LoanFormValues) => {
        return agent.Loans.save(loan)
    }

    getLoan = async (loanId: number) => {
        return agent.Loans.get(loanId);
    }

    deleteLoan = async (loanId: number) => {
        return await agent.Loans.delete(loanId);
    }

    finalizeLoan = async (loanId: number) => {
        return await agent.Loans.finalize(loanId);
    }

    calculatePrePayment = async (loan: LoanFormValues) => {
        return agent.Loans.calculatePrePayment(loan);
    }

    getLoanPaymentHistory = async (params: LoanParams) => {
        return agent.Loans.getLoanPaymentHistory(params);
    }

    calculateAmortization = async (loan: LoanFormValues, pageSize: number, pageIndex: number) => {
        return agent.Loans.calculateAmortization(loan, pageSize, pageIndex);
    }

    processPayment = async (entityId: number, loanPayment: LoanPaymentProcessing) => {
        return agent.Loans.processPayment(entityId, loanPayment);
    }

    getLoanEscrowById = async (loanId: number, loanEscrowId: number) => {
        return agent.Loans.getLoanEscrowById(loanId, loanEscrowId);
    }

    getLoanEscrows = async (params: LoanEscrowFilter) => {
        return agent.Loans.getLoanEscrows(params);
    }

    saveLoanEscrow = async (loanEscrow: LoanEscrow) => {
        return agent.Loans.saveLoanEscrow(loanEscrow);
    }

    getLoanEscrowHistory = async (params: LoanEscrowFilter) => {
        return agent.Loans.getLoanEscrowHistory(params);
    }

    getLoanDocuments = async (params: LoanQueryFilter) => {
        return agent.Loans.getLoanDocuments(params);
    }

    deleteLoanDocument = async (entityId: number, investmentId: number, documentId: number) => {
        return agent.Loans.deleteLoanDocument(entityId, investmentId, documentId);
    }

    uploadLoanDocuments = async (input: FormData, timeoutInSec: number) => {
        return agent.Loans.uploadLoanDocuments(input,timeoutInSec);
    }

    getPayoffStatement = async (payoffInput: PayoffInput) => {
        return agent.Loans.getPayoffStatement(payoffInput);
    }

    downloadPayoffStatement = async (payoffInput: PayoffInput) => {
        return agent.Loans.downloadPayoffStatement(payoffInput);
    }

    processPayoff = async (payoffInput: PayoffInput) => {
        return agent.Loans.processPayoff(payoffInput);
    }

    downloadPayoffPayment = async (loanId: number) => {
        return agent.Loans.downloadPayoffPayment(loanId);
    }

    downloadLenderBreakdown = async (loanId: number) => {
        return agent.Loans.downloadLenderBreakdown(loanId);
    }

    getLoanPaymentHistoryExport = async (params: LoanParams) => {
        return agent.Loans.getLoanPaymentHistoryExport(params);
    }
}
