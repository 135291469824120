export interface ShareHolderTransaction {
  transactionDate: Date,
  investorName: string,
  shareClass: string,
  shareCount: number,
  amount: number,
  action: string,
  costBasis: number,
  totalValue: number
}

export class ShareHolderTransaction implements ShareHolderTransaction {
  constructor(init?: ShareHolderTransaction) {
    Object.assign(this, init);
  }
}