import React from 'react'
import { Button, Grid, GridColumn, GridRow, Header, Modal, Popup } from 'semantic-ui-react';
import { Loan } from '../../../app/models/Loan/Loan';
import { LoanEscrow } from '../../../app/models/Loan/LoanEscrow';
import EscrowHistory from './EscrowHistory';
import { formatCryptoAddress, formatCurrency, getFullSizeWidth } from '../../../shared/utils';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';

interface Props {
    loan: Loan;
    escrow: LoanEscrow;
    show: boolean;
    onCancel: (reload: boolean) => void;
}

function EscrowHistoryModal({ loan, escrow, show, onCancel }: Props) {

    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })

    const copy = async () => {
        await navigator.clipboard.writeText(escrow.address);
        toast.info('Copied!', {
            position: "bottom-right",
            autoClose: 3000,
            closeOnClick: true,
            theme: "light",
        });
    }

    return (
        <Modal centered={false} size="large" open={show} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>Transaction History</Modal.Header>
            <Modal.Content>
                <Grid>
                    {showFullSize ?
                        <GridRow>
                            <GridColumn width={5} verticalAlign='middle'><Header as={'h2'}>{escrow.escrowTypeName}</Header></GridColumn>
                            <GridColumn width={6}>
                                <div className='accountAddress' style={{ fontSize: "18pt" }}>
                                    <Popup
                                        trigger={<label>{formatCryptoAddress(escrow.address)}</label>}
                                        content={escrow.address}
                                        position='bottom center'
                                    />
                                    &nbsp;<Button icon='copy outline' basic size='small' circular onClick={copy} />
                                </div>
                            </GridColumn>
                            <GridColumn width={5} textAlign='right'>Current Balance: <Header as={'h2'}>{formatCurrency(escrow.currentEscrowBalance)}</Header></GridColumn>
                        </GridRow>
                        :
                        <>
                            <GridRow>
                                <GridColumn width={10} verticalAlign='middle'>
                                    <Header as={'h2'}>{escrow.escrowTypeName}</Header>
                                </GridColumn>
                                <GridColumn width={6} textAlign='right'>Current Balance: <Header as={'h2'}>{formatCurrency(escrow.currentEscrowBalance)}</Header></GridColumn>
                            </GridRow>
                            <GridRow>
                                <GridColumn width={16} verticalAlign='middle'>
                                    <div className='accountAddress' style={{ fontSize: "18pt" }}>
                                        <Popup
                                            trigger={<label>{formatCryptoAddress(escrow.address)}</label>}
                                            content={escrow.address}
                                            position='bottom center'
                                        />
                                        &nbsp;<Button icon='copy outline' basic size='small' circular onClick={copy} />
                                    </div>
                                </GridColumn>
                            </GridRow>
                        </>
                    }
                </Grid>
                <EscrowHistory escrow={escrow} loanId={loan.id} />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => onCancel(false)} content='Close' />
            </Modal.Actions>
        </Modal>
    )
}

export default EscrowHistoryModal