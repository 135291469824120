import React, { useContext, useEffect, useState } from 'react'
import { Checkbox, Grid, Header, Icon, Label, Segment, Table } from 'semantic-ui-react'
import EmptyGridMessage from '../../../shared/EmptyGridMessage'
import GridFooter from '../../../shared/GridFooter'
import { useStore } from '../../../app/stores/store';
import EntityContext from '../../../app/context/entityContext';
import { useMediaQuery } from 'react-responsive';
import { createGridInitialState, formatCurrency, getFullSizeWidth, handleGridNavigation, handleGridSort } from '../../../shared/utils';
import { PageParams } from '../../../app/models/common/PagedResult';
import { ShareClassification } from '../../../app/models/Investment/ShareClassification';
import ShareClassCapTableGrid from './ShareClassCapTableGrid';
import { InvestmentQueryFilter } from '../../../app/models/common/InvestmentQueryFilter';
import { Investment, ParticipationType } from '../../../app/models/Investment/Investment';
import ShareClassTransactionLotGrid from './ShareClassTransactionLotGrid';

interface Props {
    investment: Investment;
}

function ShareClassificationsGrid({ investment }: Props) {
    const [loading, setLoading] = useState(true);
    const { investmentStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [classifications, setClassifications] = useState([new ShareClassification()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` });
    const [showAll, setShowAll] = useState(investment.entityParticipation !== ParticipationType.shareholder);
    const [shareClass, setShareClass] = useState("");
    const participant = [ParticipationType.shareholder, ParticipationType.sponsor].includes(investment.entityParticipation);
    const shouldRenderLotGrid = !loading && shareClass !== '' && shareClass !== 'B' && participant && showAll === false;

    const initialState: InvestmentQueryFilter = createGridInitialState({
        entityId: entity.id,
        investmentId: investment.id,
        showAll: showAll,
        sortIndex: 'ShareClass',
        sortOrder: 'ascending'
    });
    const [params, setParams] = useState(initialState);

    useEffect(() => {
        setLoading(true);
        params.showAll = showAll;
        investmentStore.getShareClassifications(params).then(r => {
            setTotalRecords(r.totalRecords);
            setClassifications(r.data);
            setLoading(false);
        });
    }, [investmentStore, params, showAll]);


    const handleSort = (clickedColumn: string) => {
        if (classifications.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    return (
        <Segment clearing loading={loading}>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column width={showFullSize ? 10 : 6} verticalAlign='middle'>
                        <Header>{showAll === true ? "All Shares" : "My Shares"}</Header>
                    </Grid.Column>
                    <Grid.Column textAlign='right' verticalAlign='middle' width={showFullSize ? 6 : 10}>
                        {investment.entityParticipation === ParticipationType.shareholder &&
                            <Checkbox toggle label="Show All" style={{ fontWeight: 'bold' }}
                                defaultChecked={showAll}
                                onChange={(e, { checked }) => setShowAll(checked ?? false)} />
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Table singleLine sortable selectable>
                {showFullSize &&
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign='center' onClick={() => handleSort("ShareClass")} sorted={params.sortIndex === "ShareClass" && classifications.length > 0 ? params.sortOrder : undefined}>Class</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' onClick={() => handleSort("ShareCount")} sorted={params.sortIndex === "ShareCount" ? params.sortOrder : undefined}>Quantity</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' onClick={() => handleSort("InvestedAmount")} sorted={params.sortIndex === "InvestedAmount" ? params.sortOrder : undefined}>Invested</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' onClick={() => handleSort("TotalValue")} sorted={params.sortIndex === "TotalValue" ? params.sortOrder : undefined}>Total Value</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                }

                <Table.Body>
                    {!loading && classifications.map((classification, i) => {
                        if (showFullSize) {
                            return (<Table.Row key={i} style={{ cursor: participant ? 'pointer' : '' }} onClick={() => setShareClass(classification.shareClass)}>
                                <Table.Cell textAlign='center' >{classification.shareClass}</Table.Cell>
                                <Table.Cell textAlign='right'>{classification.shareCount}</Table.Cell>
                                <Table.Cell textAlign='right'>{formatCurrency(classification.investedAmount ?? 0)}</Table.Cell>
                                <Table.Cell textAlign='right' >{formatCurrency(classification.totalValue ?? 0)}</Table.Cell>
                            </Table.Row>
                            )
                        }
                        else {
                            return <Table.Row key={i}>
                                <Table.Cell>
                                    {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                    <Grid>
                                        <Grid.Row style={{ cursor: 'pointer' }}>
                                            <Grid.Column width={6}>
                                                <label onClick={() => handleSort("ShareClass")}>Class:</label><br />
                                                <label onClick={() => handleSort("ShareCount")}>Quantity:</label><br />
                                                <label onClick={() => handleSort("InvestedAmount")}>Invested:</label><br />
                                                <label onClick={() => handleSort("TotalValue")}>Total Value:</label><br />
                                            </Grid.Column>
                                            <Grid.Column style={{ fontWeight: 'normal' }} >
                                                <div style={{ width: '45vw' }} onClick={() => setShareClass(classification.shareClass)}>
                                                    {classification.shareClass}<br />
                                                    {classification.shareCount} <br />
                                                    {formatCurrency(classification.costBasis ?? 0)}<br />
                                                    {formatCurrency(classification.totalValue ?? 0)}<br />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                        }
                    })}
                    {!loading && classifications.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 6 : 1} message='You do not hold any Shares on CapBlock' />}
                </Table.Body>
                {!loading && classifications.length > 0 &&
                    <GridFooter colSpan={showFullSize ? 6 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                }
            </Table>
            {!loading && classifications.length > 0 && participant &&
                <Label>Note: Select Share Class Row to see additional information.</Label>
            }

            <br />
            <br />
            {shouldRenderLotGrid &&
                <>
                    <ShareClassTransactionLotGrid investment={investment} shareClass={shareClass} />
                    <br /><br />
                </>
            }
            {!loading && shareClass !== '' && participant &&
                <ShareClassCapTableGrid entityId={entity.id} investmentId={investment.id} shareClass={shareClass} />
            }
        </Segment>
    )
}

export default ShareClassificationsGrid