import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Form, Grid, GridColumn, Header, Message, Segment, Table } from 'semantic-ui-react';
import DateInput from '../../../app/common/form/DateInput';
import { InputMasks } from '../../../shared/InputMasks';
import * as yup from 'yup';
import CheckboxInput from '../../../app/common/form/CheckboxInput';
import { PayoffInput } from '../../../app/models/Loan/PayoffInput';
import { Loan } from '../../../app/models/Loan/Loan';
import EntityContext from '../../../app/context/entityContext';
import { useStore } from '../../../app/stores/store';
import { toast } from 'react-toastify';
import { downloadFile, formatCurrency, getMonthAndDay, getMonthName, getRandomNumber } from '../../../shared/utils';
import { PayoffInfo } from '../../../app/models/Loan/PayoffInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useDebounce } from '../../../shared/useDebounce';

interface Props {
    loan: Loan;
    onCancel: (boolean: boolean) => void;
}
function PayoffView({ loan, onCancel }: Props) {
    const { entity } = useContext(EntityContext);
    const [showPayoff, setShowPayoff] = useState(false);
    const { loanStore } = useStore();

    const init = new PayoffInput();
    init.entityId = entity.id;
    init.loanId = loan.id;
    init.payoffDate = new Date()
    init.includeEscrows = true;
    init.includeLoanInfo = false;
    const [payoffInput, setPayoffInput] = useState(new PayoffInput(init));
    const [payoffInfo, setPayoffInfo] = useState(new PayoffInfo());
    const [downloading, setDownloading] = useState(false);
    var balance = 0;

    const [payoffDate, setPayoffDate] = useState<number>(0);
    const debouncePayoffDate = useDebounce<number>(payoffDate, 250);
    const [includeEscrows, setIncludeEscrows] = useState<number>(0);
    const debounceIncludeEscrows = useDebounce<number>(includeEscrows, 250);
    const [isLoading, setIsLoading] = useState(false);
    const formikRef = useRef<any>();

    const validationSchema = yup.object({
        payoffDate: yup
            .date()
            .typeError("Please enter a valid date")
            .required("Payoff Date is required"),
    });


    useEffect(() => {
        const formikContext = formikRef.current;
        const values = formikContext.values;

        values.payoffDate = values.payoffDate == null ? new Date() : new Date(values.payoffDate.toDateString());
        setIsLoading(true);
        setShowPayoff(false);
        setPayoffInput(values);
        values.payoffDate = new Date(values.payoffDate.toDateString());
        loanStore.getPayoffStatement(values)
        .then(response => {
            if (response !== null) {
                setPayoffInfo(response);
                setShowPayoff(true);
            }
        })
        .catch(err => {
            toast.error(err.response.data, { theme: "colored" });
        })
        .finally(() => setIsLoading(false));

    }, [debouncePayoffDate, debounceIncludeEscrows, loanStore])

    const addMonths = (date: any, quantity: number) => {
        const newDate = new Date(date);
        newDate.setMonth((newDate.getMonth() + 1) + quantity);
        newDate.setDate(0); // Set to the last day of the previous month
        return newDate;
    }
    const endDate = addMonths(new Date(), loan.loanType === 1 ? 2 : 0);

    const downloadStatement = () => {
        setDownloading(true);
        const updatePayoffInput = { ...payoffInput };
        updatePayoffInput.includeLoanInfo = true;
        loanStore.downloadPayoffStatement(updatePayoffInput)
            .then(response => {
                downloadFile(response);
            })
            .catch(err => {
                toast.error(err.response.data, { theme: "colored" });
            })
            .finally(() => setDownloading(false));
    }

    const hideGrid = () => {
        setShowPayoff(false);
    }

    return (
        <Segment basic clearing>
            <Header>Payoff Statement</Header>
            <Formik
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={payoffInput}
                onSubmit={() => {}}
                innerRef={formikRef}
            >
                {({ handleSubmit, values }) => (
                    <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>

                        <Message info icon='info circle' header='Easily determine your loan payoff'
                            list={[
                                'Input the desired payoff date in the provided date field.',
                                'Choose whether to include escrow amounts for a more comprehensive payoff estimate.',
                                'Receive an instant calculation of your estimated payoff amount, empowering you with the information needed to make financial decisions.'
                            ]} />
                        <br />
                        <Grid columns={3}>
                            <GridColumn>
                                <DateInput placeholder='Payoff Date' name='payoffDate' startDate={new Date()} maxDate={endDate} mask={InputMasks.date} showRequired showMonthYearDropdown={loan.loanType === 1} onChange={() => { hideGrid(); setPayoffDate(getRandomNumber) }} />
                            </GridColumn>
                            <GridColumn>
                                <CheckboxInput placeholder='Include Escrows' name='includeEscrows' toggle showRequired defaultChecked={values.includeEscrows}
                                    onChange={(e, { checked }) => {
                                        const updateFields = { ...values };
                                        updateFields.includeEscrows = checked ?? false;
                                        setPayoffInput(updateFields);
                                        hideGrid();

                                        setIncludeEscrows(getRandomNumber);
                                    }}
                                />
                            </GridColumn>
                        </Grid>

                        {isLoading && <Segment loading={true} basic style={{ marginTop: '50px' }} />}

                        {showPayoff &&
                            <>
                                <Table compact>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='3'>Description</Table.HeaderCell>
                                            <Table.HeaderCell colSpan='2' textAlign='center'>Amount</Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center'>Balance</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell colSpan='3'>Principal Balance Payoff</Table.Cell>
                                            <Table.Cell colSpan='2' textAlign='right'>{formatCurrency(payoffInfo.principalBalance)}</Table.Cell>
                                            <Table.Cell textAlign='right'>{formatCurrency(balance = payoffInfo.principalBalance)}</Table.Cell>
                                        </Table.Row>

                                        {payoffInfo.outstandingStatements.map((item, i) => {
                                            balance += item.interestDue
                                            return <Table.Row key={i}>
                                                <Table.Cell colSpan='3'>{item.monthInterest} Interest due</Table.Cell>
                                                <Table.Cell colSpan='2' textAlign='right'>{formatCurrency(item.interestDue)}</Table.Cell>
                                                <Table.Cell textAlign='right'>{formatCurrency(balance)}</Table.Cell>
                                            </Table.Row>
                                        })}

                                        <Table.Row >
                                            <Table.Cell>Daily Per Diem interest for {getMonthName(payoffInfo.perDiem.start)}</Table.Cell>
                                            <Table.Cell textAlign='right'>{formatCurrency(payoffInfo.perDiem.totalPerDay, false, 4)}</Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell textAlign='right'>{formatCurrency(balance)}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.Cell>Interest for {getMonthAndDay(payoffInfo.perDiem.start)} through {getMonthAndDay(payoffInfo.perDiem.end)}</Table.Cell>
                                            <Table.Cell textAlign='right'>{payoffInfo.perDiem.days}</Table.Cell>
                                            <Table.Cell>days</Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell textAlign='right'>{formatCurrency(payoffInfo.perDiem.totalAmount)}</Table.Cell>
                                            <Table.Cell textAlign='right'>{formatCurrency(balance += payoffInfo.perDiem.totalAmount)}</Table.Cell>
                                        </Table.Row>

                                        {payoffInfo.fees.map((item, i) => {
                                            balance += item.amount
                                            return <Table.Row key={i}>
                                                <Table.Cell colSpan='3'>{item.feeType} {getMonthAndDay(item.feeDate)}</Table.Cell>
                                                <Table.Cell colSpan='2' textAlign='right'>{formatCurrency(item.amount)}</Table.Cell>
                                                <Table.Cell textAlign='right'>{formatCurrency(balance)}</Table.Cell>
                                            </Table.Row>
                                        })}

                                        {payoffInfo.totalCredits > 0 &&
                                            <Table.Row>
                                                <Table.Cell colSpan='3'>Credits</Table.Cell>
                                                <Table.Cell colSpan='2' textAlign='right'>-{formatCurrency(payoffInfo.totalCredits)}</Table.Cell>
                                                <Table.Cell textAlign='right'>{formatCurrency(balance -= payoffInfo.totalCredits)}</Table.Cell>
                                            </Table.Row>
                                        }

                                        {payoffInfo.escrows.map((item, i) => {
                                            balance -= item.currentBalance
                                            return <Table.Row key={i}>
                                                <Table.Cell colSpan='3'>{item.description} Escrow credit</Table.Cell>
                                                <Table.Cell colSpan='2' textAlign='right'>-{formatCurrency(item.currentBalance)}</Table.Cell>
                                                <Table.Cell textAlign='right'>{formatCurrency(balance)}</Table.Cell>
                                            </Table.Row>
                                        })}

                                    </Table.Body>

                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='3'></Table.HeaderCell>
                                            <Table.HeaderCell colSpan='2' textAlign='right'><b style={{ fontSize: '12pt' }}>Total</b></Table.HeaderCell>
                                            <Table.HeaderCell textAlign='right'><b style={{ fontSize: '12pt' }}>{formatCurrency(payoffInfo.totalPayoff)}</b></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                                <Segment basic textAlign='center'>
                                    <Button icon primary disabled={downloading} loading={downloading} onClick={downloadStatement}>
                                        <FontAwesomeIcon icon={faDownload} /> Download Payoff Statement
                                    </Button>
                                </Segment>
                            </>
                        }
                        <Button onClick={() => onCancel(false)} floated='right' type='button' content='Back' />
                    </Form>
                )}
            </Formik>
        </Segment>
    )
}
export default PayoffView