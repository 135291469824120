export enum RecordState
{
    Added = 1,
    Updated = 2,
    Deleted = 3
}

export interface LoanEscrow {
    id: number;
    loanId: number;
    escrowTypeId: number;
    escrowTypeName: string;
    amount: number;
    currentEscrowBalance: number;
    statusId: number;
    tokenAddress: string;
    address: string;
    selectedEscrow: boolean;
    recordState: RecordState;
    startingBalance: number;
    deleted: boolean;
}

export class LoanEscrow implements LoanEscrow {
    constructor(init?: LoanEscrow) {
        Object.assign(this, init);
    }
}

export class LoanEscrowValues {
    id: number = 0;
    loanId: number = 0;
    escrowTypeId: number = 0;
    escrowTypeName: string = '';
    amount?: number;
    currentEscrowBalance: number = 0;
    statusId: number = 1;
    tokenAddress: string = '';
    address: string = '';
    selectedEscrow: boolean = false;
    startingBalance?: number;
    deleted: boolean = false;

    constructor(escrow?: LoanEscrowValues) {
      if (escrow) {
        this.id = escrow.id;
        this.loanId = escrow.loanId;
        this.escrowTypeId = escrow.escrowTypeId;
        this.escrowTypeName = escrow.escrowTypeName;
        this.amount = escrow.amount;
        this.currentEscrowBalance = escrow.currentEscrowBalance;
        this.statusId = escrow.statusId;
        this.selectedEscrow = escrow.selectedEscrow ?? false;
        this.startingBalance = escrow.startingBalance;
        this.deleted = escrow.deleted;
      }
    }
  }

export interface EscrowType {
    id: number;
    description: string;
}

export class EscrowType implements EscrowType {
    constructor(init?: EscrowType) {
        Object.assign(this, init);
    }
}