export interface StripeBalance {
    hasStripeAccount: boolean,
    available: number,
    reserved: number,
    pending: number,
    dashboardUrl: string,
  }

  export class StripeBalance implements StripeBalance {
    constructor() {     
      this.hasStripeAccount = false;
      this.available = 0;
      this.reserved = 0;
      this.pending = 0;
      this.dashboardUrl = "";
    }
  }