import { Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'semantic-ui-react'
import * as yup from 'yup';
import { LoanEscrowValues } from '../../../app/models/Loan/LoanEscrow';
import SelectInput from '../../../app/common/form/SelectInput';
import CurrencyInput from '../../../app/common/form/CurrencyInput';
import { getFloatValue } from '../../../shared/utils';
import { useStore } from '../../../app/stores/store';
import { Loan } from '../../../app/models/Loan/Loan';
import { toast } from 'react-toastify';

interface Props {
    loan: Loan;
    id?: number;
    show: boolean;
    onCancel: (reload: boolean) => void;
}

function EscrowModal({ loan, id, show, onCancel }: Props) {
    const [loading, setLoading] = useState(false);
    const [loanEscrow, setLoanEscrow] = useState(new LoanEscrowValues());
    const emptyOption = { key: '' };
    const [typeOptions, setTypeOptions] = useState([emptyOption]);
    const { commonStore, loanStore } = useStore();

    const validationSchema = yup.object({
        escrowTypeId: yup
            .number()
            .min(1, 'Escrow Type is required'),
        amount: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Amount is required')
            .min(0, 'Amount cannot be negative'),
    });

    useEffect(() => {
        commonStore.getEscrowTypes().then(r => setTypeOptions(r));

        if (id)
        {
            loanStore.getLoanEscrowById(loan.id, id).then(r => setLoanEscrow(r));
        }

    }, [commonStore, loanStore, setTypeOptions, setLoanEscrow, id, loan.id])

    const handleFormSubmit = (values: any, setFieldError: any) => {
        setLoading(true);
        values.loanId = loan.id;
        loanStore.saveLoanEscrow(values)
            .then(r => {
                toast.success("Success!", { theme: "colored" });
                onCancel(true);
            })
            .catch(err => {
                toast.error("There was an issue saving the loan escrow.", { theme: "colored" });
            })
            .finally(() => setLoading(false));
    }
    return (
        <Modal centered={false} size="mini" open={show} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>{id === 0 ? "Add" : "Edit"} Escrow Item</Modal.Header>
            <Modal.Content>
                <Formik
                    validationSchema={validationSchema}
                    enableReinitialize
                    initialValues={loanEscrow}
                    onSubmit={(values, { setFieldError }) => {
                        handleFormSubmit(values, setFieldError);
                    }} >
                    {({ handleSubmit }) => (
                        <Form id='escrowForm' className="ui form" onSubmit={handleSubmit} autoComplete='Off'>

                            <SelectInput options={typeOptions} placeholder='Escrow Type' name='escrowTypeId' showRequired={true} />
                            <CurrencyInput placeholder='Amount' name='amount' maxLength={12} thousandSeparator=',' decimalScale={2} allowNegative={false} showRequired={true} />

                        </Form>
                    )}
                </Formik>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => onCancel(false)} content='Cancel' />
                <Button form="escrowForm" primary type='submit' content='Confirm' loading={loading} disabled={loading} />
            </Modal.Actions>
        </Modal>
    )
}

export default EscrowModal