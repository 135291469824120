import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { AxiosInterceptorProvider } from './AxiosInterceptorContext';

function AuthProvider({ children }: any) {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN as string;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE as string;

  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.redirectTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      useRefreshTokens
      cacheLocation='localstorage'
    >
      <AxiosInterceptorProvider>
        {children}
      </AxiosInterceptorProvider>
    </Auth0Provider>
  );
}

export default AuthProvider