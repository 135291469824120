import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { toast } from 'react-toastify';
import { Image, Grid, Header, Button, Segment, Popup } from 'semantic-ui-react'
import { AddressBalance } from '../../app/models/crypto/AddressBalance';
import CurrentBalance from './currentBalance';
import { formatCryptoAddress } from '../../shared/utils';
import { BalanceResult } from '../../app/models/crypto/BalanceResult';

interface Props {
    userBalance: AddressBalance;
    closeModal: (refreshBalance: boolean) => void;
}

function Receive({userBalance, closeModal}: Props) {
    const [currentToken, setCurrentToken] = useState<BalanceResult>(userBalance.balances[0]);
    useEffect(() => {
        if (userBalance.balances.length > 1) {
            setCurrentToken(userBalance.balances.filter(token => token.tokenType === "USDC")[0]);
        }
    }, [userBalance]);

    const copy = async () => {
        await navigator.clipboard.writeText(userBalance.address);
        toast.info('Copied!', {
            position: "bottom-right",
            autoClose: 3000,
            closeOnClick: true,
            theme: "light",
        });
    }

    return (
        <Segment basic>
            {userBalance && userBalance.balances.length > 0 &&
                <Grid textAlign='center'>
                    <Grid.Row>
                        <Grid.Column>
                            <QRCode value={userBalance.address} size={200} />
                            <Grid celled columns={2}>
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        Asset:
                                    </Grid.Column>
                                    <Grid.Column textAlign='left'>
                                        <Header as='h4' image>
                                            <Image src={currentToken.tokenImage} rounded size='mini' />
                                            <Header.Content>
                                                {currentToken.tokenType}
                                            </Header.Content>
                                        </Header>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16} textAlign='left'>
                                        {currentToken.tokenType} Address:
                                        <div className='accountAddress'>
                                            <Popup
                                                trigger={<label>{formatCryptoAddress(userBalance.address)}</label>}
                                                content={userBalance.address}
                                                position='bottom center'
                                            />
                                            &nbsp;<Button icon='copy outline' basic size='small' circular onClick={copy} />
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            Only send {currentToken.tokenType} (ERC-20) to this address <br /><br />
                            <CurrentBalance balance={currentToken} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                        <Button onClick={() => closeModal(false)} content='Cancel' />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </Segment>
    )
}

export default Receive