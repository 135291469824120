import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import { Button, Header, Segment } from 'semantic-ui-react'
import BorrowerGrid from './BorrowerGrid';
import LenderGrid from './LenderGrid';
import EntityContext from '../../app/context/entityContext';
import { Role } from '../../app/models/registration/entity';

function LoanDashboard() {
    const { entity } = useContext(EntityContext);
    const hasAccessToCreateLoan = () => {
        var userRole = entity?.userRoles.filter(x => x === Role[Role.LoanCreation]);
        return userRole?.length === 1;
    }

    return (
        <>
            <LenderGrid />

            <BorrowerGrid />

            {hasAccessToCreateLoan() &&
                <Segment basic textAlign='center' >
                    <Header>
                        Are you currently lending to anyone? Manage your private lending here
                    </Header>
                    <Button as={NavLink} to='/loan' type='button' content='Create Loan' primary />
                </Segment >
            }
        </>
    )
}

export default LoanDashboard