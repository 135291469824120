import { useField } from "formik";
import React from "react";
import { Form, Label } from "semantic-ui-react";
import FieldTitle from "./FieldTitle";

interface Props {
    placeholder: string;
    rows: number;
    name: string;
    readOnly?: boolean;
    maxLength?: number;
    hideTitle?: boolean;
    showRequired?: boolean,
}

function TextArea(props: Props) {
    const [field, meta] = useField(props.name);
    const {showRequired, hideTitle, ...rest} = props;
    return (
        <Form.Field error={meta.touched && !!meta.error}>
            {!hideTitle && <FieldTitle name={props.name} placeholder={props.placeholder} showRequired={props.showRequired} />}
            <textarea {...field} {...rest} />
            {meta.touched && meta.error ? (
                <Label basic color='red' pointing='above'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    )
}

export default TextArea;