import { useField } from "formik";
import { Form, Input, Label } from "semantic-ui-react";
import { NumericFormat } from "react-number-format";
import FieldTitle from "./FieldTitle";

interface Props {
    showRequired?: any;
    placeholder: string;
    name: string;
    maxLength?: number;
    autoFocus?: boolean;
    className?: any;
    hidetitle?: string;
    allowNegative?: boolean;
    thousandSeparator?: string;
    decimalScale?: number;
    fixedDecimalScale?: boolean;
    prefix?: string;
    readOnly?: boolean;
    disabled?: boolean;
    value?: any;
    size?:any;
    onChange?: (values: any) => void;
    icon?: any;
    iconPosition?: any;
    fieldClassName?: string;
}

function CurrencyInput(props: Props) {
    const [field, meta, helpers] = useField(props.name);
    const {onChange, hidetitle, showRequired, fieldClassName, ...rest} = props;

    const handleChange = (values: any) => {
        helpers.setValue(values.value);
        if (onChange) {
            onChange(values.value);
        }
        // Trigger validation check manually as onValueChange may happen before the validation check takes place.
        setTimeout(() => { helpers.setValue(values.value); }, 0);
    };

    return (
        <Form.Field error={meta.touched && !!meta.error} className={props.fieldClassName}>
            {hidetitle !== 'true' && <FieldTitle name={props.name} placeholder={props.placeholder} showRequired={props.showRequired} />}

            <NumericFormat id={props.name} customInput={Input}
                value={field.value} {...rest} valueIsNumericString={true}
                onValueChange={handleChange} />

            {meta.touched && meta.error ? (
                <Label basic color='red' pointing='above'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    )
}
export default CurrencyInput;