export interface PayoffInfo {
    principalBalance: number;
    outstandingStatements: OutstandingStatement[];
    fees: Fee[];
    escrows: Escrow[];
    perDiem: PerDiem;
    totalPayoff: number;
    totalCredits: number;
}

export interface OutstandingStatement {
    interestDue: number;
    dueDate: Date;
    monthInterest: string;
}

export interface Escrow {
    description: string;
    currentBalance: number;
}

export interface Fee {
    amount: number;
    feeDate: Date;
    feeType: string;
}

export interface PerDiem {
    totalAmount: number;
    days: number;
    start: Date;
    end: Date;
    totalPerDay: number;
}

export class PayoffInfo implements PayoffInfo {
    constructor(init?: PayoffInfo) {
        Object.assign(this, init);
    }
}