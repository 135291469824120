export interface MonthlyStatement {
    id: number;
    amount: number;
    status: string;
    dueDate: Date;
    startDate: Date;
    endDate: Date;
    loanId: number;
    details: MonthlyStatementDetail;
    monthRef: string;
}

export class MonthlyStatement implements MonthlyStatement {
    constructor() {
        this.id = 0;
        this.amount = 0;
        this.status = '';
    }
}

export interface MonthlyStatementDetail {
    amountDue: number;
    principalDue: number;
    interestDue: number;
    totalEscrow: number;
    totalCredits: number;
    totalFees: number;
    outstandingBalance: number;
    loan: {
        lastStatement: boolean;
    };
}

export class MonthlyStatementDetail implements MonthlyStatementDetail {
    constructor(init?: MonthlyStatementDetail) {
        Object.assign(this, init);
    }
}