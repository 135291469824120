export interface Company {
    id: number;
    entityId: number;
    ein: string;
    description: string;
}

export class Company implements Company {
    constructor() {
        this.id = 0;
        this.entityId = 0;
        this.ein = "";
        this.description = "";
    }
}