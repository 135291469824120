import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { EntityFilterParams } from "../models/common/entityFilterParams";
import { InvestmentQueryFilter } from "../models/common/InvestmentQueryFilter";
import { CalculateDistribution } from "../models/Investment/CalculateDistribution";
import { CalculateMonthlyFinancial } from "../models/Investment/CalculateMonthlyFinancial";
import { DistributionFormValues } from "../models/Investment/Distribution";
import { InvestmentFormValues } from "../models/Investment/Investment";
import { FundingInfo } from "../models/Investment/FundingInfo";
import { NewMonthlyInvestmentFinancialFormValues } from "../models/Investment/Manage/NewMonthlyInvestmentFinancial";
import { MonthlyInvestmentFinancialFormValues } from "../models/Investment/MonthlyInvestmentFinancial";
import { KeyMetric } from "../models/Investment/KeyMetric";
import { Document } from "../models/common/Document";
import { ShareClassification } from "../models/Investment/ShareClassification";

export default class InvestmentStore {
    constructor() {
        makeAutoObservable(this)
    }

    getEntityInvestments = async (params: EntityFilterParams) => {
        return agent.InvestmentAgent.getEntityInvestments(params);
    }

    getSponsorInvestments = async (params: EntityFilterParams) => {
        return agent.InvestmentAgent.getSponsorInvestments(params);
    }

    getInvestmentWithFinancial = async (entityId: number, investmentId: number) => {
        return agent.InvestmentAgent.getInvestmentWithFinancial(entityId, investmentId);
    }

    getMonthlyInvestmentFinancial = async (entityId: number, investmentId: number, financialId: number) => {
        return agent.InvestmentAgent.getMonthlyInvestmentFinancial(entityId, investmentId, financialId);
    }
    getShareTransactions = async (filter: InvestmentQueryFilter) => {
        return agent.InvestmentAgent.getShareTransactions(filter);
    }

    getShareClassifications = async (filter: InvestmentQueryFilter) => {
        return agent.InvestmentAgent.getShareClassifications(filter);
    }

    getShareClassCapTable = async (filter: InvestmentQueryFilter) => {
        return agent.InvestmentAgent.getShareClassCapTable(filter);
    }

    getShareClassLotTransactions = async (entityId: number, investmentId: number, shareClass: string) => {
        return agent.InvestmentAgent.getShareClassLotTransactions(entityId, investmentId, shareClass);
    }

    getDistributions = async (filter: InvestmentQueryFilter) => {
        return agent.InvestmentAgent.getDistributions(filter);
    }

    getShareholderDistributions = async (filter: InvestmentQueryFilter) => {
        return agent.InvestmentAgent.getShareholderDistributions(filter);
    }

    getDistributionDetail = async (entityId: number, investmentId: number, distributionId: number) => {
        return agent.InvestmentAgent.getDistributionDetail(entityId, investmentId, distributionId);
    }

    getDistributionShareholders = async (filter: InvestmentQueryFilter) => {
        return agent.InvestmentAgent.getDistributionShareholders(filter);
    }

    calculatePreDistribution = async (params: CalculateDistribution) => {
        return agent.InvestmentAgent.calculatePreDistribution(params);
    }

    saveDistribution = async (distribution: DistributionFormValues) => {
        return agent.InvestmentAgent.saveDistribution(distribution);
    }

    checkSponsorEntity = async (entityId: number) => {
        return agent.InvestmentAgent.checkSponsorEntity(entityId);
    }

    uploadInvestmentDocuments = async (input: FormData, timeoutInSec: number) => {
        return agent.InvestmentAgent.uploadInvestmentDocuments(input, timeoutInSec);
    }

    getInvestmentDocuments = async (filter: InvestmentQueryFilter) => {
        return agent.InvestmentAgent.getInvestmentDocuments(filter);
    }

    deleteInvestmentDocument = async (entityId: number, investmentId: number, investmentDocumentId: number) => {
        return agent.InvestmentAgent.deleteInvestmentDocument(entityId, investmentId, investmentDocumentId);
    }

    getInvestorEntities = async (entityId: number, investmentId: number) => {
        return agent.InvestmentAgent.getInvestorEntities(entityId, investmentId);
    }

    saveMonthlyFinancial = async (investmentId: number, financial: MonthlyInvestmentFinancialFormValues) => {
        return agent.InvestmentAgent.saveMonthlyFinancial(investmentId, financial);
    }

    calculateMonthlyFinancial = async (financial: CalculateMonthlyFinancial) => {
        return agent.InvestmentAgent.calculateMonthlyFinancial(financial);
    }

    getInvestmentById = async (entityId: number, investmentId: number) => {
        return agent.InvestmentAgent.getInvestmentById(entityId, investmentId);
    }

    saveInvestment = async (investment: InvestmentFormValues) => {
        return agent.InvestmentAgent.saveInvestment(investment);
    }

    getMangeInvestments = async (params: EntityFilterParams) => {
        return agent.InvestmentAgent.manageInvestments(params);
    }

    getFundingInfo = async (investmentId: number) => {
        return agent.InvestmentAgent.getFundingInfo(investmentId);
    }

    saveInvestmentFunding = async (funding: FundingInfo) => {
        return agent.InvestmentAgent.saveInvestmentFunding(funding);
    }

    getDebtInfo = async (investmentId: number) => {
        return agent.InvestmentAgent.getDebtInfo(investmentId);
    }

    saveInvestmentDebt = async (monthlyFinancial: NewMonthlyInvestmentFinancialFormValues) => {
        return agent.InvestmentAgent.saveInvestmentDebt(monthlyFinancial);
    }

    getKeyMetrics = async (investmentId: number) => {
        return agent.InvestmentAgent.getKeyMetrics(investmentId);
    }

    getKeyMetric = async (keyMetricId: number) => {
        return agent.InvestmentAgent.getKeyMetric(keyMetricId);
    }

    saveInvestmentKeyMetric = async (keyMetric: KeyMetric) => {
        return agent.InvestmentAgent.saveInvestmentKeyMetric(keyMetric);
    }

    updateInvestmentKeyMetricHeader = async (keyMetricId: number, showinheader: boolean) => {
        return agent.InvestmentAgent.updateInvestmentKeyMetricHeader(keyMetricId, showinheader);
    }

    deleteInvestmentKeyMetric = async (keyMetricId: number) => {
        return agent.InvestmentAgent.deleteInvestmentKeyMetric(keyMetricId);
    }

    updateInvestmentKeyMetricOrder = async (investmentId: number, keyMetrics: KeyMetric[]) => {
        return agent.InvestmentAgent.updateInvestmentKeyMetricOrder(investmentId, keyMetrics);
    }

    uploadInvestmentImages = async (input: FormData, timeoutInSec: number) => {
        return agent.InvestmentAgent.uploadInvestmentImages(input, timeoutInSec);
    }

    getInvestmentImages = async (filter: InvestmentQueryFilter) => {
        return agent.InvestmentAgent.getInvestmentImages(filter);
    }

    getInvestmentAllImages = async (investmentId: number) => {
        return agent.InvestmentAgent.getInvestmentAllImages(investmentId);
    }

    deleteInvestmentImage = async (entityId: number, investmentId: number, investmentImageId: number) => {
        return agent.InvestmentAgent.deleteInvestmentImage(entityId, investmentId, investmentImageId);
    }

    updateInvestmentImageOrder = async (investmentId: number, images: Document[]) => {
        return agent.InvestmentAgent.updateInvestmentImageOrder(investmentId, images);
    }

    saveInvestmentImage = async (investmentId: number, image: Document) => {
        return agent.InvestmentAgent.saveInvestmentImage(investmentId, image);
    }

    getShareClassificationsByInvestment = async (investmentId: number) => {
        return agent.InvestmentAgent.getShareClassificationsByInvestment(investmentId);
    }

    saveShareClassification = async (investmentId: number, shares: ShareClassification[]) => {
        return agent.InvestmentAgent.saveShareClassification(investmentId, shares);
    }

    getEntityPartners = async (entityId: number) => {
        return agent.InvestmentAgent.getEntityPartners(entityId);
    }

    getInvestmentShareInfo = async (investmentId: number) => {
        return agent.InvestmentAgent.getInvestmentShareInfo(investmentId);
    }
}
