import React from 'react'
import { Header, Segment, Table } from 'semantic-ui-react'

interface Props {
    colSpan: number,
    message: string,
    icon?: React.ReactNode;
}

function EmptyGridMessage({ colSpan, message, icon }: Props) {
    return (
        <Table.Row>
            <Table.Cell colSpan={colSpan} textAlign='center'>
                <Segment basic>
                    <Header as='h3' icon={!!icon}>
                        {icon}
                        {message}
                    </Header>
                </Segment>
            </Table.Cell>
        </Table.Row>
    )
}

export default EmptyGridMessage