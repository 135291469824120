import { Formik } from 'formik';
import React from 'react'
import { Form, Button, Header } from 'semantic-ui-react';
import { useStore } from '../../../../app/stores/store';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Document } from '../../../../app/models/common/Document';
import TextInput from '../../../../app/common/form/TextInput';

interface Props {
    investmentId: number;
    image: Document;
    onCancel: (refresh: boolean) => void;
}

function InvestmentImageForm({ investmentId, image, onCancel }: Props) {
    const { investmentStore } = useStore();

    const validationSchema = yup.object({
        documentName: yup
            .string()
            .required('Document Name is required'),
    });

    const handleFormSubmit = async (values: any, setSubmitting: any) => {
        investmentStore.saveInvestmentImage(investmentId, values)
            .then(r => {
                toast.success("Success!", { theme: "colored" });
                onCancel(true);
            })
            .catch(err => {
                toast.error("There was an issue saving the investment image.", { theme: "colored" });
            })
            .finally(() => setSubmitting(false));
    }

    return (
        <>
            <Header as='h3' color='blue'>
                Edit Photo Info
            </Header>
            <Formik
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={image}
                onSubmit={(values, { setSubmitting }) => {
                    handleFormSubmit(values, setSubmitting);
                }}
            >
                {({ values, handleSubmit, isSubmitting }) => (
                    <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                        <Form.Group widths='equal'>
                            <TextInput placeholder='Name' name='documentName' maxLength={100} showRequired />
                            <TextInput placeholder='Info' name='info' maxLength={100} />
                        </Form.Group>

                        <br />
                        <Button disabled={isSubmitting} loading={isSubmitting} floated='right' primary type='submit' content='Save' />
                        <Button floated='right' onClick={() => onCancel(false)} content='Cancel' />
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default InvestmentImageForm