import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react'
import { Segment, Header, Form, Grid, GridColumn, Button, Table, Icon, Dropdown, Checkbox } from 'semantic-ui-react';
import { createGridInitialState, downloadFile, formatCurrency, formatDate, formatFullDate, getFullSizeWidth, getMonthName, handleGridNavigation, handleGridSort } from '../../../shared/utils';
import * as yup from 'yup';
import { Loan } from '../../../app/models/Loan/Loan';
import EntityContext from '../../../app/context/entityContext';
import { useMediaQuery } from 'react-responsive';
import EmptyGridMessage from '../../../shared/EmptyGridMessage';
import GridFooter from '../../../shared/GridFooter';
import { PageParams } from '../../../app/models/common/PagedResult';
import { useStore } from '../../../app/stores/store';
import { MonthlyStatementFilter } from '../../../app/models/MonthlyStatement/MonthlyStatementFilter';
import { MonthlyStatement } from '../../../app/models/MonthlyStatement/MonthlyStatement';
import ConfirmButton from '../../../shared/ConfirmButton';
import { toast } from 'react-toastify';

interface Props {
    loan: Loan;
    onCancel: (boolean: boolean) => void;
    processPayment: (statementId: MonthlyStatement) => void;
}
function ManageStatement({ loan, onCancel, processPayment }: Props) {

    const { entity } = useContext(EntityContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const [statements, setStatements] = useState([new MonthlyStatement()]);
    const [loading, setLoading] = useState(false);
    const { monthlyStatementStore } = useStore();
    const [totalRecords, setTotalRecords] = useState(0);
    const [statementId, setStatementId] = useState(0);

    const initialState: MonthlyStatementFilter = createGridInitialState({
        loanId: loan.id,
        entityId: entity.id,
        showAll: false,
        sortIndex: 'DueDate',
        sortOrder: 'descending'
    });

    const [params, setParams] = useState(initialState);

    const handleSort = (clickedColumn: string) => {
        if (statements.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    useEffect(() => {
        setLoading(true);
        monthlyStatementStore.getStatements(params).then(r => {
            setTotalRecords(r.totalRecords);
            setStatements(r.data);
            setLoading(false);
        });
    }, [monthlyStatementStore, params]);


    const validationSchema = yup.object({
        payoffDate: yup
            .date()
            .typeError("Please enter a valid date")
            .required("Payoff Date is required"),
    });

    const handleFormSubmit = async (values: any) => {
        console.log("Submit!");
    }

    const showAll = (showAll: boolean) => {
        const updateParams = { ...params };
        updateParams.showAll = showAll;
        setParams(updateParams);
    }

    const downloadStatement = (statementId: number, index: number) => {
        monthlyStatementStore.downloadMonthlyStatementJSON(loan.id, statementId)
            .then(response => {
                downloadFile(response);
            })
    }

    const showDeleteConfirmation = (statemenId: number) => {
        setStatementId(statemenId);
        document.getElementById("btnDelete")?.click();
    }

    const showRegenerateConfirmation = (statemenId: number) => {
        setStatementId(statemenId);
        document.getElementById("btnReGenerate")?.click();
    }

    const handleConfirmDelete = () => {
        monthlyStatementStore.deleteMonthlyStatement(entity.id, loan.id, statementId)
            .then(response => {
                if (response !== null) {
                    toast.success("Statement deleted successfully!", { theme: "colored" });
                    loan.nextDue = formatFullDate(response.data);
                    const updateParams = { ...params };
                    updateParams.search = '';
                    setParams(updateParams);
                }
            })
            .catch(err => {
                toast.error(err.response.data, { theme: "colored" });
            })
    }

    const handleConfirmRegenerate = () => {
        monthlyStatementStore.regenerateStatement(entity.id, loan.id, statementId)
            .then(response => {
                if (response !== null) {
                    toast.success("Statement regenerated!", { theme: "colored" });
                    const updateParams = { ...params };
                    updateParams.search = '';
                    setParams(updateParams);
                }
            })
            .catch(err => {
                toast.error(err.response.data, { theme: "colored" });
            })
    }

    const handleConfirmNextStatement = () => {
        setIsSubmitting(true);
        monthlyStatementStore.generateNextStatement(entity.id, loan.id)
            .then(response => {
                if (response !== null) {
                    toast.success("New statement created successfully!", { theme: "colored" });
                    loan.nextDue = formatFullDate(response.data);
                    const updateParams = { ...params };
                    updateParams.search = '';
                    setParams(updateParams);
                }
            })
            .catch(err => {
                toast.error(err.response.data, { theme: "colored" });
            })
            .finally(() => setIsSubmitting(false));
    }

    function actionDropdown(statement: MonthlyStatement, i: number) {
        return <Dropdown text='Actions'>
            <Dropdown.Menu>
                {statement.status === "Overdue" &&
                    <>
                        <Dropdown.Item text='Delete' value='1' icon='delete' onClick={() => showDeleteConfirmation(statement.id)} />
                        <Dropdown.Item text='Re-Generate' value='2' icon='redo' onClick={() => showRegenerateConfirmation(statement.id)} />
                        <Dropdown.Item text='Process Payment' value='3' icon='payment' onClick={() => processPayment(statement)} />
                    </>}
                <Dropdown.Item text='Download JSON' value='4' icon='download' onClick={() => downloadStatement(statement.id, i)} />
            </Dropdown.Menu>
        </Dropdown>;
    }

    return (
        <Segment basic clearing>
            <Header>Manage Statements</Header>
            <Formik
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={{}}
                onSubmit={(values) => {
                    handleFormSubmit(values);
                }}
            >
                {({ handleSubmit, values }) => (
                    <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>

                        <Grid columns={2}>
                            <GridColumn verticalAlign='bottom'>
                                <ConfirmButton disabled={isSubmitting} loading={isSubmitting} id={statementId} color='blue' value='Generate' content={`Generate statement for ${getMonthName(loan.nextDue)}/${new Date(loan.nextDue!).getFullYear()}?`} handleConfirm={handleConfirmNextStatement} /> Statement for <b>{getMonthName(loan.nextDue)}/{new Date(loan.nextDue!).getFullYear()}</b>
                            </GridColumn>
                            <GridColumn textAlign='right' verticalAlign='bottom'>
                                <Checkbox toggle label='Show all' onChange={(e, { checked }) => showAll(checked ?? false)} />
                            </GridColumn>
                        </Grid>

                        <Table singleLine sortable>
                            {showFullSize &&
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell onClick={() => handleSort("DueDate")} sorted={params.sortIndex === "DueDate" && statements.length > 0 ? params.sortOrder : undefined}>Due Date</Table.HeaderCell>
                                        <Table.HeaderCell onClick={() => handleSort("Amount")} sorted={params.sortIndex === "Amount" && statements.length > 0 ? params.sortOrder : undefined}>Month Ref</Table.HeaderCell>
                                        <Table.HeaderCell onClick={() => handleSort("Principal")} sorted={params.sortIndex === "Principal" && statements.length > 0 ? params.sortOrder : undefined}>Amount</Table.HeaderCell>
                                        <Table.HeaderCell onClick={() => handleSort("Principal")} sorted={params.sortIndex === "Principal" && statements.length > 0 ? params.sortOrder : undefined}>Status</Table.HeaderCell>
                                        <Table.HeaderCell>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                            }

                            <Table.Body>
                                {statements.map((statement, i) => {
                                    if (showFullSize) {
                                        return <Table.Row key={i}>
                                            <Table.Cell textAlign='center'>{formatDate(statement.dueDate)}</Table.Cell>
                                            <Table.Cell textAlign='center'>{statement.monthRef}</Table.Cell>
                                            <Table.Cell textAlign='right'>{formatCurrency(statement.amount)}</Table.Cell>
                                            <Table.Cell textAlign='center'>{statement.status}</Table.Cell>
                                            <Table.Cell>
                                                {actionDropdown(statement, i)}
                                            </Table.Cell>
                                        </Table.Row>
                                    }
                                    else {
                                        return <Table.Row key={i}>
                                            <Table.Cell>
                                                {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                                <Grid>
                                                    <Grid.Row style={{ cursor: 'pointer' }}>
                                                        <Grid.Column width={6}>
                                                            <label onClick={() => handleSort("PaymentDate")}>Due Date:</label><br />
                                                            <label onClick={() => handleSort("Amount")}>Month Ref:</label><br />
                                                            <label onClick={() => handleSort("Principal")}>Amount:</label><br />
                                                            <label onClick={() => handleSort("Principal")}>Status:</label><br />
                                                            <label>Actions:</label><br />
                                                        </Grid.Column>
                                                        <Grid.Column style={{ fontWeight: 'normal' }} >
                                                            {formatDate(statement.dueDate)}<br />
                                                            {statement.monthRef}<br />
                                                            {formatCurrency(statement.amount)}<br />
                                                            {statement.status}<br />
                                                            {actionDropdown(statement, i)}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Table.Cell>
                                        </Table.Row>
                                    }
                                })}
                                {!loading && statements.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 5 : 1} message='You do not have any statement' />}
                            </Table.Body>
                            {!loading && statements.length > 0 &&
                                <GridFooter colSpan={showFullSize ? 5 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                            }
                        </Table>
                        <Button onClick={() => onCancel(false)} floated='right' type='button' content='Back' disabled={isSubmitting} />
                    </Form>
                )}
            </Formik>
            <ConfirmButton id={1} buttonId='btnDelete' value='Delete Statement' content='Delete Statement?' handleConfirm={handleConfirmDelete} hideButton />
            <ConfirmButton id={2} buttonId='btnReGenerate' value='Re-Generate Statement' content='Re-Generate Statement?' handleConfirm={handleConfirmRegenerate} hideButton />
        </Segment>
    )
}

export default ManageStatement