import React, { useCallback, useContext, useEffect, useState } from 'react'
import VerificationCodeModal from './VerificationCodeModal';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CacheNameEnum, clearCache, getTwoStepCacheData } from './utils';
import { useStore } from '../app/stores/store';
import EntityContext from '../app/context/entityContext';
import Loading from '../app/layout/Loading';
import { observer } from 'mobx-react-lite';

function TwoStepLogin() {
  var Buffer = require('buffer/').Buffer
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [showVerification, setshowVerification] = useState(false);
  const { userStore, entityStore } = useStore();
  const [optInSMS, setOptInSMS] = useState(false);
  const { setEntity } = useContext(EntityContext);
  const [urlParams] = useSearchParams();
  const redirect = urlParams.get("redirectURL") !== null ? urlParams.get("redirectURL")! : "/";
  const content = Buffer.from(redirect, 'base64').toString('ascii');
  const [params] = useSearchParams(content.toString());
  const cachedData = getTwoStepCacheData();

  const redirectToUrl = useCallback((url: string) => {
    if (urlParams.get("redirectURL") !== null) {
      entityStore.getEntity(Number(params.get("entityId")))
        .then(entity => setEntity(entity))
        .finally(() => navigate(`/${params.get("redirect")}`));
    }
    else navigate(url);
  },
    [urlParams, entityStore, setEntity, params, navigate],
  )


  useEffect(() => {
    if (user && user!["capblock_userid"] === 0)
      navigate(`/register`);
    else {
      if (cachedData === null) {
        getAccessTokenSilently().then(() => {
          userStore.getCurrentUser()
            .then(user => setOptInSMS(user.optInSMS))
            .finally(() => setshowVerification(true));
        })
      }
    }
  }, [user, navigate, setOptInSMS, userStore, getAccessTokenSilently, cachedData, redirectToUrl]);

  useEffect(() => {
    if (cachedData !== null) {
      getAccessTokenSilently().then(() => {
        redirectToUrl('/');
      })
    }
  }, [cachedData, redirectToUrl, getAccessTokenSilently]);

  const cancel = () => {
    clearCache();
    logout({ returnTo: window.location.origin });
  }

  const setStorage = async () => {
    const newData = {
      timestamp: new Date().getTime(),
      data: true
    };

    localStorage.setItem(CacheNameEnum.TwoStep, JSON.stringify(newData));

    redirectToUrl('/');
  }

  return (
    <>
      {!showVerification && <Loading content='Loading...' />}
      {showVerification && <VerificationCodeModal show={true} onCancel={cancel} onValidCode={setStorage} userOptIn={optInSMS} />}
    </>
  )
}

export default observer(TwoStepLogin)