export interface EntityProfileView {
    id: number,
    entityId: number,
    name:string,
    description: string,
    city:string,
    stateCode:string,
    identityVerified:boolean,
    profileImageUrl: string,
    profileUrlLinkedIn: string,
    profileUrlFacebook: string,
    profileUrlTwitterX: string,
    profileUrlOther: string,
    contacts:number,
    followers:number,
    investors:number,
    activeDeals:number
  }
  
  export class EntityProfileView implements EntityProfileView {
    constructor(init?: EntityProfileView) {
      Object.assign(this, init);
    }
  }