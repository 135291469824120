export interface VerificationCode {
  verificationCode: string,
}

export class VerificationCode implements VerificationCode {
  constructor(init?: VerificationCodeFormValues) {
    Object.assign(this, init);
  }
}

export class VerificationCodeFormValues {
  verificationCode: string = '';

  constructor(verificationCode?: VerificationCodeFormValues) {
    if (verificationCode) {
      this.verificationCode = verificationCode.verificationCode;
    }
  }
}

export enum CodeType
{
  Unknown = 0,
  Mobile = 1,
  Email = 2
}