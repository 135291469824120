import { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Container, Form, Header, Icon, Label, List, Segment, Table } from 'semantic-ui-react';
import { useStore } from '../../../../app/stores/store';
import { Investment } from '../../../../app/models/Investment/Investment';
import { toast } from 'react-toastify';
import { getFileSize, getFullSizeWidth, getRandomNumber, maxFiles, maxSize, minSize } from '../../../../shared/utils';
import { observer } from 'mobx-react-lite';
import { FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import TextInput from '../../../../app/common/form/TextInput';
import EntityContext from '../../../../app/context/entityContext';
import { Document } from '../../../../app/models/common/Document';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { DndContext, closestCenter, useSensor, useSensors, PointerSensor, DragEndEvent, TouchSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy, } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useMediaQuery } from 'react-responsive';
import React from 'react';

interface Props {
  investment: Investment;
  setReloadImages: (reload: number) => void;
  showUploadPanel: boolean;
  setShowUploadPanel: (showPanel: boolean) => void;
}

const SortableItem = React.forwardRef<HTMLTableRowElement, { item: Document, showFullSize: boolean, children: React.ReactNode }>(({ item, showFullSize, children }, ref) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef, } = useSortable({ id: item.id });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style} {...attributes}>
      <td>
        <span ref={setActivatorNodeRef} style={showFullSize ? { cursor: 'grab', padding: '0 30px' } : { cursor: 'grab' }} {...listeners}>
          <FontAwesomeIcon icon={faBars} size='lg' />
        </span>
      </td>
      {children}
    </tr>
  );
});

function UploadImages({ investment, setReloadImages, showUploadPanel, setShowUploadPanel }: Props) {

  const { entity } = useContext(EntityContext);
  const [fileLimitReached, setFileLimitReached] = useState(false);
  const [validFiles, setValidFiles] = useState(new Array<Document>());
  const [sortOrder, setSortOrder] = useState(0);

  const onDrop = useCallback((uploadedFiles: any) => {
    const files = [...validFiles];
    let order = sortOrder;
    uploadedFiles.forEach((file: File) => {
      order += 1;
      if (!fileLimitReached) {
        if (validFiles.findIndex((vf: Document) => vf.documentName === file.name) === -1) {
          var validFile = new Document();
          validFile.originalFileName = file.name;
          validFile.documentName = file.name;
          validFile.fileExtension = file.name.substring(file.name.lastIndexOf('.'));
          validFile.fileSizeInKb = parseFloat((file.size / 1024).toFixed(2));
          validFile.sharedWithEntityId = 0;
          validFile.info = '';
          validFile.id = order;
          validFile.sortOrder = order;
          validFile.path = URL.createObjectURL(file);
          validFile.file = file;

          files.push(validFile);
          if (validFiles.length === maxFiles) setFileLimitReached(true);
        }
      }
      else {
        setErrorMsg(`You cannot upload more than ${maxFiles} files`);
      }
    });
    setValidFiles(files);
    setSortOrder(order);
  }, [fileLimitReached, validFiles, sortOrder]);


  const dropZoneConfig = {
    maxFiles: maxFiles,
    maxSize: maxSize,
    minSize: minSize,
    multiple: true,
    accept: {
      'image/*': [],
    },
    onDrop: onDrop
  };

  const [loading, setLoading] = useState(false);
  const { investmentStore } = useStore();
  const { getRootProps, getInputProps } = useDropzone(dropZoneConfig);
  const [errorMsg, setErrorMsg] = useState("");
  const investmentId = investment.id;
  const entityId = entity.id;
  const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })

  const removeFile = (index: number, files: Document[]) => {
    let data = [...files];
    data.splice(index, 1);
    setValidFiles(data);
    setFileLimitReached(false);
  }

  const uploadImages = (files: Document[]) => {
    if (files.length === 0) {
      setErrorMsg("please add at least one image to continue");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('investmentId', investmentId.toString());
    formData.append('entityId', entityId.toString());

    for (let i = 0; i < files.length; i++) {
      formData.append(`uploadedImages[${i}].originalFileName`, files[i].originalFileName);
      formData.append(`uploadedImages[${i}].documentName`, files[i].documentName);
      formData.append(`uploadedImages[${i}].fileSizeInKb`, files[i].fileSizeInKb.toString());
      formData.append(`uploadedImages[${i}].info`, files[i].info);
      formData.append(`uploadedImages[${i}].file`, files[i].file);
    }

    const timeoutInSec = 1000 * 60 * 5; //5 min
    investmentStore.uploadInvestmentImages(formData, timeoutInSec).then((r) => {
      setValidFiles(new Array<Document>());
      setFileLimitReached(false);
      setErrorMsg("");
      setLoading(false);
      setShowUploadPanel(false);
      toast.success("Investment Images uploaded successfully.", { theme: "colored" });
      setReloadImages(getRandomNumber());
    }).catch(err => {
      setLoading(false);
      toast.error("There was an issue uploading Investment Images.", { theme: "colored" });
    });
  };

  const validationSchema = yup.object().shape({
    files: yup.array().of(
      yup.object().shape({
        documentName: yup.string().required('Name is required')
      })
    )
  });

  const sensors = useSensors(
    useSensor(PointerSensor)
  );

  const mobileSensors = useSensors(
    useSensor(TouchSensor)
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      setValidFiles((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        const newItems = arrayMove(items, oldIndex, newIndex).map((item, index) => ({
          ...item,
          sortOrder: index + 1,
        }));

        return newItems;
      });
    }
  };

  useEffect(() => {
    setValidFiles((items) =>
      items.map((item, index) => ({
        ...item,
        sortOrder: index + 1,
      }))
    );
  }, []);

  return (
    <>
      {showUploadPanel &&
        <>
          <Segment>
            <Header>Upload Investment Photos</Header>
            <List>
              <List.Item>Images to upload should be legal, valid, and virus-free</List.Item>
              <List.Item>Each File cannot be larger than &nbsp;
                {getFileSize(maxSize)}</List.Item>
              <List.Item>Up to {maxFiles} Files are allowed to upload</List.Item>
              <List.Item>Allowed Files Types are: PNG, JPG, GIF</List.Item>
            </List>
          </Segment>
          <Segment>
            <div {...getRootProps({ className: 'dropzone' })}
              style={{
                height: 200, width: "80%", margin: 'auto',
                borderStyle: "dashed", textAlign: 'center', paddingTop: '20px',
                verticalAlign: "middle",
                cursor: (validFiles.length < maxFiles) ? "copy" : "no-drop"
              }}>
              <input type="file" {...getInputProps()} disabled={fileLimitReached} />
              <br /><br />
              <p>Drag and Drop up to {maxFiles} allowed images (files) here
                <br />
                OR
                <br />
                Click in this zone to select Images (files) from your local file system</p>
            </div>
            {errorMsg && <Label color='red'>{errorMsg}</Label>}
          </Segment>

          {validFiles?.length > 0 && <Segment>
            <h4>List of Images To Upload:</h4>
            <Formik
              validationSchema={validationSchema}
              enableReinitialize
              initialValues={{ files: validFiles }}
              onSubmit={(values) => {
                uploadImages(values.files);
              }}
            >
              {({ values, handleSubmit }) => (
                <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                  <DndContext sensors={showFullSize ? sensors : mobileSensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                    <SortableContext items={validFiles} strategy={verticalListSortingStrategy}>
                      <Segment>
                      <Table celled padded>
                        {showFullSize &&
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell singleLine></Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "400px" }}>Name</Table.HeaderCell>
                              <Table.HeaderCell>Info</Table.HeaderCell>
                              <Table.HeaderCell>Size</Table.HeaderCell>
                              <Table.HeaderCell>Preview</Table.HeaderCell>
                              <Table.HeaderCell>Remove</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                        }
                        <FieldArray name="files" render={(arrayHelpers) => (
                          <Table.Body>
                            {values.files.map((doc, i) => (
                              <SortableItem key={doc.id} item={doc} showFullSize={showFullSize} >
                                <Table.Cell>
                                  <TextInput key={i} placeholder={''} name={`files[${i}].documentName`} maxLength={100} />
                                </Table.Cell>
                                <Table.Cell>
                                  <TextInput key={i} placeholder={''} name={`files[${i}].info`} maxLength={100} />
                                </Table.Cell>
                                <Table.Cell textAlign='right' width={2}>{getFileSize(doc.file.size)}</Table.Cell>
                                <Table.Cell textAlign='center'>
                                  <img width='50%' alt='' src={doc.path} />
                                </Table.Cell>
                                <Table.Cell textAlign={showFullSize ? 'center' : 'left'}>
                                  <Icon link name='delete' onClick={() => removeFile(i, values.files)} />
                                </Table.Cell>
                              </SortableItem>
                            ))}
                          </Table.Body>
                        )}
                        />
                      </Table>
                      </Segment>
                    </SortableContext>
                  </DndContext>
                  <Container textAlign='right'>
                    <Button primary type='submit' loading={loading} disabled={validFiles.length === 0 || loading}>Upload Images</Button>
                  </Container>
                </Form>
              )}
            </Formik>
          </Segment>
          }
        </>}
    </>
  )
}

export default observer(UploadImages);

