export interface SponsorContact
{
    id:number,
    sponsorEntityId:number,
    businessName:string,
    firstName:string,
    lastName:string,
    addressLine1:string,
    addressLine2:string,
    city:string,
    stateCode:string,
    zip:string,
    workPhone:string,
    cellPhone:string,
    email:string,
    unsubscribed:boolean,
    statusId: SponsorContactStatus,
    accreditationStatusId:AccreditationStatus
    
}
export enum SponsorContactStatus
{
    Added = 0,
    Imported = 1,
    Invited = 2,
    Onboarded = 3,
    Connected = 4
}

export enum AccreditationStatus
{
    Pending = 0,
    None,
    Accredited,
    Sophisticated
}

export class SponsorContact implements SponsorContact
{
    id: number = 0;
    sponsorEntityId: number = 0;
    businessName: string = '';
    firstName: string = '';
    lastName: string = '';
    addressLine1: string = '';
    addressLine2: string = '';
    city: string = '';
    stateCode: string = '';
    zip: string = '';
    workPhone: string = '';
    cellPhone: string = '';
    email: string = '';
    unsubscribed: boolean = false;
    statusId: SponsorContactStatus = SponsorContactStatus.Added;
    accreditationStatusId: AccreditationStatus = AccreditationStatus.None;

    constructor(init?: SponsorContact)
    {
        Object.assign(this, init); 
    }
}