import { getFloatValue } from "../../../shared/utils";

export interface MonthlyInvestmentFinancial {
  annualDebtService: number;
  asofDate: Date;
  debtCoverage: number;
  id: number;
  interestRate: number;
  interestType: number;
  investmentId: number;
  lender: string;
  loanToValue: number;
  month: number;
  year: number;
  netChange: number;
  netIncome: number;
  noi: number;
  annualizedNetIncome: number;
  annualizedNOI: number;
  occupancy: number;
  ownedSince: string;
  term: number;
  totalLoan: number;
  valuation: number;
  interestOnly: number;
  interestYears: number;
  shareValue: number;
  triggerChange: Date | null;
  capRate: number;
}

export class MonthlyInvestmentFinancial implements MonthlyInvestmentFinancial {
  constructor(init?: MonthlyInvestmentFinancial) {
    Object.assign(this, init);
  }
}

export class MonthlyInvestmentFinancialFormValues {
  annualDebtService: number = 0;
  asofDate: Date = new Date();
  debtCoverage: number = 0;
  id: number = 0;
  interestRate: number = 0;
  interestType: number = 0;
  investmentId: number = 0;
  lender: string = '';
  loanToValue: number = 0;
  month: number = 0;
  year: number = 0;
  netChange: number = 0;
  netIncome: number = 0;
  noi: number = 0;
  annualizedNetIncome: number = 0;
  annualizedNOI: number = 0;
  occupancy: number = 0;
  ownedSince: string = '';
  term: number = 0;
  totalLoan: number = 0;
  valuation: number = 0;
  interestOnly: number = 0;
  interestYears: number = 0;
  shareValue: number = 0;
  triggerChange: Date | null = null;
  capRate: number = 0;

  get formattedAsofDate(): string {
    return `${this.month.toString().padStart(2, '0')}/${this.year}`;
  }

  constructor(financial?: MonthlyInvestmentFinancialFormValues) {
    if (financial) {
      this.id = financial.id;
      this.investmentId = financial.investmentId;
      this.annualDebtService = getFloatValue(financial.annualDebtService);
      this.asofDate = financial.asofDate;
      this.debtCoverage = getFloatValue(financial.debtCoverage);
      this.interestRate = financial.interestRate;
      this.interestType = financial.interestType;
      this.lender = financial.lender;
      this.loanToValue = getFloatValue(financial.loanToValue);
      this.month = financial.month;
      this.year = financial.year;
      this.netChange = getFloatValue(financial.netChange);
      this.netIncome = getFloatValue(financial.netIncome);
      this.noi = getFloatValue(financial.noi);
      this.annualizedNetIncome = financial.annualizedNetIncome;
      this.annualizedNOI = financial.annualizedNOI;
      this.occupancy = financial.occupancy
      this.ownedSince = financial.ownedSince;
      this.term = financial.term;
      this.totalLoan = getFloatValue(financial.totalLoan);
      this.valuation = getFloatValue(financial.valuation);
      this.interestOnly = financial.interestOnly;
      this.interestYears = financial.interestYears;
      this.shareValue = getFloatValue(financial.shareValue);
      this.capRate = financial.capRate;
    }
  }
}