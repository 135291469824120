import { TabPane } from "semantic-ui-react";
import { Investment } from "../../../app/models/Investment/Investment";
import UploadDocuments from "./UploadDocuments";
import DocumentsGrid from "./DocumentsGrid";
import { useContext, useState } from "react";
import EntityContext from "../../../app/context/entityContext";

interface Props {
    investment: Investment;
}

function InvestmentDocuments({ investment }: Props) {
    const { entity } = useContext(EntityContext);
    const [reloadDocuments, setReloadDocuments] = useState(false);
    const [showUploadPanel, setShowUploadPanel] = useState(false);

    return (
        <TabPane>
            <DocumentsGrid investment={investment} reloadDocuments={reloadDocuments} showUploadPanel={showUploadPanel} setShowUploadPanel={setShowUploadPanel} />
            {investment.sponsorEntityId === entity.id &&
                <UploadDocuments investment={investment} setReloadDocuments={setReloadDocuments} showUploadPanel={showUploadPanel} setShowUploadPanel={setShowUploadPanel} />
            }
        </TabPane>
    );
}

export default InvestmentDocuments;