import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosRequestConfig } from 'axios';
import React, { useEffect, useState } from 'react';

const AxiosInterceptorContext = React.createContext<{ isTokenSet: boolean }>({ isTokenSet: false });

const setAxiosTokenInterceptor = async (accessToken: string): Promise<void> => {
  axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
    if (accessToken) {
      if (config.headers) {
        config.headers['Authorization'] = `Bearer ${accessToken}`
      } else {
        config.headers = {
          'Authorization': `Bearer ${accessToken}`
        }
      }
    }
    return config;
  });
};

type AxiosInterceptorProviderProps = { children: React.ReactNode }

export const AxiosInterceptorProvider = ({ children }: AxiosInterceptorProviderProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [isTokenSet, setIsTokenSet] = useState(false);

  useEffect(() => {
    const getAccessToken = async () => {
      await setAxiosTokenInterceptor(await getAccessTokenSilently({ ignoreCache: true }));
      setIsTokenSet(true);
    };
    getAccessToken();
  }, [getAccessTokenSilently]);

  return (
    <AxiosInterceptorContext.Provider value={{ isTokenSet }}>
      {children}
    </AxiosInterceptorContext.Provider>
  );
};