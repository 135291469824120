import { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Checkbox, Form, Header, Icon, Label, List, Segment, Table } from 'semantic-ui-react';
import { useStore } from '../../app/stores/store';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { FieldArray, Formik } from 'formik';
import EntityContext from '../../app/context/entityContext';
import { FileImportJobStatusDTO } from '../../app/models/sponsorContacts/FileImportJobStatusDTO';
import { FileImportJobUploadDTO } from '../../app/models/sponsorContacts/FileImportJobUploadDTO';
import { downloadFile, maxSize, minSize } from '../../shared/utils';

interface Props {
  onCancel: () => void;
  welcomeEmailEnabled: boolean;
}

function CsvFileImportPanel({ onCancel, welcomeEmailEnabled }: Props) {
  const { entity } = useContext(EntityContext);
  const [loading, setLoading] = useState(false);
  const entityId = entity.id;
  const { csvFileImportStore } = useStore();
  const [showUploadPanel, setShowUploadPanel] = useState(true);
  const [showJobStatusPanel, setShowJobStatusPanel] = useState(false);
  const [lastImportJobStatus, setlastImportStatus] = useState(new FileImportJobStatusDTO());
  const maxFiles = 1;
  const [fileLimitReached, setFileLimitReached] = useState(false);
  const [validFiles, setValidFiles] = useState(new Array<FileImportJobUploadDTO>());
  const [errorMsg, setErrorMsg] = useState("");
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);

  const onDrop = useCallback((uploadedFiles: any) => {
    uploadedFiles.forEach((file: File) => {
      if (!fileLimitReached) {
        if (validFiles.findIndex((vf: FileImportJobUploadDTO) => file.name) === -1) {
          var validFile = new FileImportJobUploadDTO();
          validFile.formFile = file;

          validFiles.push(validFile);
          setValidFiles(validFiles);
          if (validFiles.length === maxFiles) setFileLimitReached(true);
        }
      }
      else {
        setErrorMsg(`You cannot upload more than ${maxFiles} files`);
      }
    });
  }, [maxFiles, fileLimitReached, validFiles]);

  const dropZoneConfig = {
    maxFiles: maxFiles,
    maxSize: maxSize,
    minSize: minSize,
    multiple: false,
    accept: {
      'text/csv': [],
    },
    onDrop: onDrop
  };
  const { getRootProps, getInputProps } = useDropzone(dropZoneConfig);

  useEffect(() => {
    setLoading(true);
    csvFileImportStore.getLastJobStatus(entityId)
      .then(r => {
        setlastImportStatus(r);
        setShowUploadPanel(r.allowNewUpload);
        setShowJobStatusPanel(r.showJobStatusPanel);
      })
      .catch(err => toast.error("There was an error getting import status.", { theme: "colored" }))
      .finally(() => setLoading(false))

  }, [csvFileImportStore, entityId]);

  const UploadContacts = (files: FileImportJobUploadDTO[]) => {
    if (files.length === 0) {
      setErrorMsg("please add at least one csv file to continue");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('entityId', entityId.toString());
    formData.append('sendWelcomeEmail', sendWelcomeEmail === true ? "true" : "false");

    for (let i = 0; i < files.length; i++) {
      formData.append(`formFile`, files[i].formFile);
    }

    const timeoutInSec = 1000 * 60 * 5; //5 min
    csvFileImportStore.submitCsvFile(formData, timeoutInSec)
      .then((r) => {
        setErrorMsg("");
        setLoading(false);
        setShowUploadPanel(false);
        setSendWelcomeEmail(true);
        toast.success("Investment Contacts uploaded successfully.", { theme: "colored" });
      })
      .then(() => {
        csvFileImportStore.getLastJobStatus(entityId)
          .then(r => {
            setlastImportStatus(r);
            setShowUploadPanel(r.allowNewUpload);
            setShowJobStatusPanel(r.showJobStatusPanel);
          })
      })
      .catch(err => {
        setLoading(false);
        toast.error("There was an issue uploading the contacts file.", { theme: "colored" });
      });
  };

  const removeFile = (index: number) => {
    let data = [...validFiles];
    data.splice(index, 1);
    setValidFiles(data);
    setFileLimitReached(false);
  }

  const downloadCsvTemplate = () => {
    csvFileImportStore.downloadCsvTemplate()
      .then(r => {
        downloadFile(r);
      })
  }

  return (
    <>
      {showJobStatusPanel &&
        <Segment clearing>
          <p>Import job for file <b>{lastImportJobStatus.uploadedFileName}</b> has been created.</p>
          <p>Status: <b>{lastImportJobStatus.jobStatus}</b> {lastImportJobStatus.message}</p>
          <p>You will be notified when the import is completed, and you may import additional files at that time.</p>
        </Segment>
      }

      {showUploadPanel &&
        <>
          <Segment>
            <Header>Upload Contacts CSV File</Header>
            <List>
              <List.Item>1. Download CSV Template. <Button style={{ background: 'transparent', color: '#4183c4', margin: 0, padding: 0 }} onClick={() => downloadCsvTemplate()}>Download</Button></List.Item>
              <List.Item>2. Update template with data.</List.Item>
              <List.Item>3. Drag and drop (or select)</List.Item>
              <List.Item>4. Import</List.Item>
            </List>
          </Segment>
          {validFiles?.length === 0 && <Segment>
            <div {...getRootProps({ className: 'dropzone' })}
              style={{
                height: 200, width: "80%", margin: 'auto',
                borderStyle: "dashed", textAlign: 'center', paddingTop: '20px',
                verticalAlign: "middle",
                cursor: (validFiles.length < maxFiles) ? "copy" : "no-drop"
              }}>
              <input type="file" {...getInputProps()} />
              <br /><br />
              <p>Drag and Drop the .csv file with your contacts here
                <br />
                OR
                <br />
                Click in this zone to select a file from your computer.</p>
            </div>
            {errorMsg && <Label color='red'>{errorMsg}</Label>}
          </Segment>}
          {validFiles?.length > 0 && <Segment>
            <h4>Investor Contacts to Upload</h4>
            <Formik
              enableReinitialize
              initialValues={{ files: validFiles }}
              onSubmit={(values) => {
                UploadContacts(values.files);
              }}
            >
              {({ values, handleSubmit }) => (
                <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                  <Segment basic clearing>
                    <Table celled padded>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell singleLine></Table.HeaderCell>
                          <Table.HeaderCell style={{ width: "600px" }}>File Name</Table.HeaderCell>
                          <Table.HeaderCell>Size</Table.HeaderCell>
                          <Table.HeaderCell>Remove</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <FieldArray name="files"
                        render={(arrayHelpers) => (
                          <Table.Body>
                            {values.files.map((doc, i) => (
                              <Table.Row key={i}>
                                <Table.Cell textAlign='center'><i aria-hidden="true" className={"icon large  file text outline"} /></Table.Cell>
                                <Table.Cell textAlign='left'>{doc.formFile.name}</Table.Cell>
                                <Table.Cell textAlign='left'>{doc.formFile.size} bytes</Table.Cell>
                                <Table.Cell textAlign='center'>
                                  <Icon link name='delete' onClick={removeFile} />
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        )}
                      />
                    </Table>
                    {welcomeEmailEnabled && <Checkbox toggle label="Send Welcome Email" style={{ fontWeight: 'bold' }}
                      defaultChecked={sendWelcomeEmail}
                      onChange={(e, { checked }) => setSendWelcomeEmail(checked ?? false)} />
                    }
                    <Button floated='right' primary type='submit' loading={loading} disabled={validFiles.length === 0 || loading} >Upload File</Button>
                    <Button type='button' onClick={onCancel} content='Cancel' floated='right'></Button>
                  </Segment>
                </Form>
              )}
            </Formik>
          </Segment>
          }
        </>
      }
    </>
  )
}
export default observer(CsvFileImportPanel);