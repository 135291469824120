export interface User {
  id: number,
  firstName: string,
  middleName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  require2StepVerification: boolean,
  optInSMS: boolean,
}

export class User implements User {
  constructor(init?: UserFormValues) {
    Object.assign(this, init);
  }
}

export class UserFormValues {
  id: number = 0;
  firstName: string = '';
  middleName: string = '';
  lastName: string = '';
  email: string = '';
  phoneNumber: string = '';
  require2StepVerification: boolean = false;
  optInSMS: boolean = false;

  constructor(user?: UserFormValues) {
    if (user) {
      this.id = user.id;
      this.firstName = user.firstName;
      this.middleName = user.middleName;
      this.lastName = user.lastName;
      this.email = user.email;
      this.phoneNumber = user.phoneNumber;
      this.optInSMS = user.optInSMS;
    }
  }
}