import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Grid, GridColumn, GridRow, Header, Icon, Item, Segment } from 'semantic-ui-react';
import { PhoneNumbersFormValues } from '../../app/models/settings/phoneNumbers';
import { useStore } from '../../app/stores/store';
import { CacheNameEnum, maskPhone } from '../../shared/utils';
import ChangePasswordModal from './changePasswordModal';
import ChangePhoneNumbersModal from './changePhoneNumbersModal';
import { toast } from 'react-toastify';
import { User } from '../../app/models/registration/user';

function SecurityPanel() {
    const [openPasswordChange, setOpenPasswordChange] = useState(false);
    const [openPhoneNumbers, setOpenPhoneNumbers] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userPhones, setUserPhones] = useState(new PhoneNumbersFormValues());
    const { userStore } = useStore();
    const [user, setUser] = useState(new User());

    useEffect(() => {
        setLoading(true);
        userStore.getUserPhones().then(r => {
            setUserPhones(new PhoneNumbersFormValues(r));
            setLoading(false);
        });

        userStore.getCurrentUser().then(result => {
            setUser(result);
        });

    }, [userStore, setUserPhones, setLoading, setUser]);

    const handlePasswordChangeClose = () => setOpenPasswordChange(false);
    const handlePhoneNumbersClose = () => setOpenPhoneNumbers(false);

    const toggle2StepVerification = (checked: boolean) => {
        const update = { ...user, require2StepVerification: checked };
        setUser(update);
        saveUser(update, checked, false);
    }

    const toggleTwoFactor = (checked: boolean) => {
        const update = { ...user, optInSMS: checked };
        setUser(update);
        saveUser(update, checked, true);
    }

    const saveUser = (updatedOptions: User, checked: boolean, twoFactor: boolean) => {
        const action = twoFactor ? 'Two Factor' : '2-Step verification';
        userStore.saveUserVerificationOptions(updatedOptions).then(() => {
            sessionStorage.removeItem(CacheNameEnum.UserInfo);
            toast.success(`${action} ${checked ? 'activated' : 'deactivated'} successfully.`, { theme: "colored" })
        }).catch(() => {
            toast.error(`There was an issue saving the ${action}`, { theme: "colored" });
        });
    }

    const Password = () => {
        return <Segment clearing>
            <Header as="h2">
                Password
                <Button onClick={() => setOpenPasswordChange(true)} floated='right' primary>Change Password</Button>
            </Header>
            <Item>
                <Item.Content>
                    <Item.Description>
                        Remember not to store your password in your email or cloud and to not share it with anyone
                    </Item.Description>
                </Item.Content>
            </Item>
            {openPasswordChange && <ChangePasswordModal onCancel={handlePasswordChangeClose} show={openPasswordChange} user={user} />}
        </Segment>;
    }

    const PhoneNumbers = () => {
        return <Segment loading={loading}>
            <Header as="h2">
                Phone Number
            </Header>
            <Item>
                <Item.Content>
                    <Item.Header>
                        <Button onClick={() => setOpenPhoneNumbers(true)} floated='right' primary>Manage</Button>
                    </Item.Header>
                    <Item.Header>
                        <Icon size='big' name='mobile' />
                        {maskPhone(userPhones.primaryPhone)}
                    </Item.Header>
                    <Item.Description>Keep your primary phone up-to-date</Item.Description>
                </Item.Content>
            </Item>
            {openPhoneNumbers && <ChangePhoneNumbersModal onCancel={handlePhoneNumbersClose} show={openPhoneNumbers} userPhones={userPhones} user={user}/>}
        </Segment>;
    }

    const TwoStepVerification = () => {
        return <Segment clearing>
            <Grid>
                <GridRow>
                    <GridColumn width={8}>
                        <Header as="h2">
                            2-Step Verification
                        </Header>
                        <Item>
                            <Item.Content>
                                <Item.Header>
                                    <Icon size='big' name='lock' />
                                    Activate 2-Step Verification for Secure Transfers
                                </Item.Header>
                            </Item.Content>
                        </Item>
                    </GridColumn>
                    <GridColumn width={8} textAlign='right' verticalAlign='bottom'>
                        <Checkbox toggle checked={user.require2StepVerification} onChange={(e, { checked }) => toggle2StepVerification(checked ?? false)} />
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn width={8}>
                        <Item>
                            <Item.Content>
                                <Item.Header>
                                    <Icon size='big' name='id badge outline' />
                                    Enable SMS-based Two-Factor Authentication (2FA)
                                </Item.Header>
                            </Item.Content>
                        </Item>
                    </GridColumn>
                    <GridColumn width={8} textAlign='right' verticalAlign='middle'>
                        <Checkbox toggle checked={user.optInSMS} onChange={(e, { checked }) => toggleTwoFactor(checked ?? false)} />
                    </GridColumn>
                </GridRow>
            </Grid>
        </Segment>;
    }

    return (
        <>
            <Password />

            <PhoneNumbers />

            <TwoStepVerification />
        </>
    )
}

export default observer(SecurityPanel)