import React, { SyntheticEvent } from 'react'
import { Icon, Pagination } from 'semantic-ui-react'
import { PageParams } from '../app/models/common/PagedResult';

interface Props {
    params: PageParams,
    totalRecords: number,
    pageIndexChange: (change: PageParams) => void;
}
function GridPagination({ params, totalRecords, pageIndexChange }: Props) {

    const handlePageChange = (e: SyntheticEvent, data: any) => {
        //Pagination component starts with index 1
        data.activePage--;
        if (params.pageIndex !== data.activePage) {
            const paging = { ...params };
            paging.pageIndex = data.activePage;
            pageIndexChange(paging);
        }
    }

    return (
        <Pagination onPageChange={handlePageChange}
            boundaryRange={0}
            activePage={params.pageIndex + 1}
            totalPages={Math.ceil(totalRecords / params.pageSize) || 1}
            ellipsisItem={null}
            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
            prevItem={{ content: <Icon name='angle left' />, icon: true }}
            nextItem={{ content: <Icon name='angle right' />, icon: true }}
        />
    )
}

export default GridPagination