import { useState } from "react";
import { Investment } from "../../../app/models/Investment/Investment";
import OfferingGrid from "./OfferingGrid";
import { TabPane } from "semantic-ui-react";
import PendingApprovalOfferingGrid from "./PendingApprovalOfferingGrid";
import OfferingViewModal from "./Components/OfferingView";

interface Props {
    investment: Investment;
}
function Offerings({ investment }: Props) {
    const [selectedOfferingId, setSelectedOfferingId] = useState(0);
    const [offeringModalOpen, setOfferingModalOpen] = useState(false);
    const [reloadPendingApprovalOfferings, setReloadPendingApprovalOfferings] = useState(0);
    const [reloadOfferings, setReloadOfferings] = useState(0);

    const setReloadPendingApprovalOfferingsGrid = () => {setReloadPendingApprovalOfferings(reloadPendingApprovalOfferings + 1);}
    const setReloadOfferingsGrid = () => {setReloadOfferings(reloadOfferings + 1)}

    const handleOfferingModal = (id: number) => {
        setSelectedOfferingId(id);
        setOfferingModalOpen(true);
    }

    const handleReloadOfferings = () => {
        setReloadPendingApprovalOfferingsGrid();
        setReloadOfferingsGrid();
        setSelectedOfferingId(0);
    }
    return (
        <TabPane>
            <PendingApprovalOfferingGrid investment={investment}
                handleOfferingModal={handleOfferingModal}
                reloadPendingApprovalOfferingsGrid={reloadPendingApprovalOfferings}/>

            <OfferingGrid investment={investment}
                handleOfferingModal={handleOfferingModal}
                reloadOfferingsGrid={reloadOfferings}
                setReloadPendingApprovalOfferingsGrid={setReloadPendingApprovalOfferingsGrid} />

            {offeringModalOpen && selectedOfferingId > 0 &&
                <OfferingViewModal investment={investment} offeringId={selectedOfferingId}
                setOfferingModalParentOpen={setOfferingModalOpen}
                handleReloadOfferings={handleReloadOfferings}/>
            }
        </TabPane>
    );
}
export default Offerings;