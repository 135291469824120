import { Grid, GridColumn, GridRow, Segment } from "semantic-ui-react"
import GPProfileInfoCard from "./gpProfileInfoCard"
import GPSponsorshipTeamCard from "./gpSponsorshipTeamCard"
import GPInsightsCard from "./gpInsightsCard"
import GPReviewsCard from "./gpReviewsCard"
import { useAuth0 } from "@auth0/auth0-react"
import { useParams } from "react-router-dom"
import GPInvestmentListCard from "./gpInvestmentListCard"
import EntityContext from '../../.../../app/context/entityContext';
import { useContext, useEffect, useState } from "react"
import { useStore } from "../../app/stores/store"

function GPLanding() {

    const { id } = useParams();
    const { isAuthenticated } = useAuth0();
    const { sponsorContactStore } = useStore();
    const [isConnectedToSponsor, setIsConnectedToSponsor] = useState(false);

    const { entity } = useContext(EntityContext);

    useEffect(() => {
        if (isAuthenticated) {
            sponsorContactStore.isEntityConnectedToSponsor(Number(id), entity.id)
                .then((c) => {
                    setIsConnectedToSponsor(c);
                })
        }
    }, [entity.id, id, isAuthenticated, sponsorContactStore]);


    return (
        <Segment clearing>
            <Grid columns='equal' relaxed className='landingPageBackground'>
                <GridRow>
                    <GridColumn>
                        <GPProfileInfoCard sponsorEntityId={Number(id)} entityId={entity.id} entityType={entity.entityType} isAuthenticated={isAuthenticated}
                            onConnectedChange={function (isConnected: boolean): void {
                                setIsConnectedToSponsor(isConnected);
                            }} />
                    </GridColumn>
                    <GridColumn>
                        <GPSponsorshipTeamCard entityId={Number(id)} enableHeader={true} ></GPSponsorshipTeamCard>
                        <GPInsightsCard entityId={Number(id)}></GPInsightsCard>
                        <GPReviewsCard sponsorEntityId={Number(id)} entityId={entity.id} isConnectedToSponsor={isConnectedToSponsor} isAuthenticated={isAuthenticated} numberOfReviews={5}></GPReviewsCard>
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <GPInvestmentListCard entityId={Number(id)} onlyActiveInvestments={true} headerText={"Active Deals"} isConnected={isConnectedToSponsor}></GPInvestmentListCard>
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <GPInvestmentListCard entityId={Number(id)} onlyActiveInvestments={false} headerText={"Track Record"} isConnected={isConnectedToSponsor}></GPInvestmentListCard>
                    </GridColumn>
                </GridRow>
            </Grid>
        </Segment>
    )
}

export default GPLanding