import React, { useEffect, useState } from 'react'
import { TabPane, Segment, Button, Grid, GridColumn, Header, Form, Divider } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { BankAccountTypeEnum, FundingInfo } from '../../../../app/models/Investment/FundingInfo';
import { useStore } from '../../../../app/stores/store';
import { Formik } from 'formik';
import * as yup from 'yup';
import TextInput from '../../../../app/common/form/TextInput';
import SelectInput from '../../../../app/common/form/SelectInput';
import { getEnumValue } from '../../../../shared/utils';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import TransferModal from '../../../transfer/transferModal';

interface Props {
    investmentId: number;
    continueNextTab: () => void;
}

function Funding({ investmentId, continueNextTab }: Props) {
    const [funding, setFunding] = useState(new FundingInfo());
    const { commonStore, investmentStore } = useStore();
    const [showWireTransferInfo, setShowWireTransferInfo] = useState(false);
    const [showCheckInfo, setShowCheckInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const emptyOption = { key: '' };
    const [bankAccountTypeOptions, setBankAccountTypeOptions] = useState([emptyOption]);
    const [continueButtonCliked, setContinueButtonCliked] = useState(false);
    const navigate = useNavigate();
    const [openTransfer, setOpenTransfer] = useState(false);

    const getAccountTypeID = (value: any) => {
        return getEnumValue(BankAccountTypeEnum, value)
    }

    useEffect(() => {

        commonStore.getInvestmentEnums().then(r => {
            setBankAccountTypeOptions(r.bankAccountType);
        })

        investmentStore.getFundingInfo(investmentId!).then(r => {
            setFunding(new FundingInfo(r));
            r.wireTransferInfo.accountType = getAccountTypeID(r.wireTransferInfo.accountType);
            setShowWireTransferInfo(r.wireTransferInfo.id > 0);
            setShowCheckInfo(r.checkInfo.id > 0);
        })
        .finally(() => setIsLoading(false));
    }, [commonStore, investmentStore, investmentId, setBankAccountTypeOptions]);

    const validationSchema = yup.object({

        wireTransferInfo: yup.object().when('hasWireTransferInfo', {
            is: () => showWireTransferInfo,
            then: yup.object({
                receivingBank: yup
                    .string()
                    .required('Receiving Bank is required'),
                accountType: yup
                    .number()
                    .min(1, 'Account Type is required'),
                routingNumber: yup
                    .string()
                    .required('Routing Number is required'),
                accountNumber: yup
                    .string()
                    .required('Account Number is required'),
            })
        }),
        checkInfo: yup.object().when('hasCheckInfo', {
            is: () => showCheckInfo,
            then: yup.object({
                mailingAddress: yup
                    .string()
                    .required('Receiving Bank is required'),
                makeOutTo: yup
                    .string()
                    .required('Make Out To is required'),
            })
        }),
    });

    const handleFormSubmit = async (values: any, setSubmitting: any) => {
        values.investmentId = investmentId;

        if (!showCheckInfo && values.checkInfo.id === 0) values.checkInfo = null
        if (!showWireTransferInfo && values.wireTransferInfo.id === 0) values.wireTransferInfo = null

        if (values.checkInfo !== null) values.checkInfo.deleted = !showCheckInfo && values.checkInfo.id > 0;
        if (values.wireTransferInfo !== null) values.wireTransferInfo.deleted = !showWireTransferInfo && values.wireTransferInfo.id > 0;

        investmentStore.saveInvestmentFunding(values)
            .then(r => {
                r.wireTransferInfo.accountType = getAccountTypeID(r.wireTransferInfo.accountType);
                setFunding(new FundingInfo(r));
                toast.success("Success!", { theme: "colored" });
                if (continueButtonCliked) continueNextTab();
            })
            .catch(err => {
                toast.error("There was an issue saving the funding info.", { theme: "colored" });
            })
            .finally(() => setSubmitting(false));
    }

    const handleTransferClose = () => {
        setOpenTransfer(false);
    }

    return (
        <TabPane>
            <Segment clearing loading={isLoading}>
                <Grid columns={2}>
                    <GridColumn width={3}>
                        <Header as='h3'>
                            Funding Method
                        </Header>
                    </GridColumn>
                    <GridColumn width={13}>
                        <Grid columns={4}>
                            <GridColumn textAlign='center'>
                                <Button content="Wire Transfer" onClick={() => setShowWireTransferInfo(!showWireTransferInfo)} color={showWireTransferInfo ? 'green' : undefined} />
                            </GridColumn>
                            <GridColumn textAlign='center'>
                                <Button content="Check" onClick={() => setShowCheckInfo(!showCheckInfo)} color={showCheckInfo ? 'green' : undefined} />
                            </GridColumn>
                            <GridColumn textAlign='center'>
                                <Button color={funding.hasStripe ? 'green' : undefined} onClick={() => navigate("/settings/stripe")}>
                                    ACH Payment {funding.hasStripe && <>&nbsp;&nbsp;<FontAwesomeIcon icon={faCheck} /></>}
                                </Button>
                            </GridColumn>
                            <GridColumn textAlign='center'>
                                <Button color='green' onClick={() => setOpenTransfer(true)}>
                                    Cryptocurrency&nbsp;&nbsp;<FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </GridColumn>
                        </Grid>
                    </GridColumn>
                </Grid>

                <Formik
                    validationSchema={validationSchema}
                    enableReinitialize
                    initialValues={funding}
                    onSubmit={(values, { setSubmitting }) => {
                        handleFormSubmit(values, setSubmitting);
                    }}
                >
                    {({ values, handleSubmit, isSubmitting }) => (
                        <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>

                            {showWireTransferInfo &&
                                <>
                                <Divider horizontal section>
                                    <Header as='h4'>
                                        Wire Transfer Details
                                    </Header>
                                </Divider>
                                <Header as='h3' color='blue'>
                                    Account Details
                                </Header>
                                <Form.Group widths='equal'>
                                    <TextInput placeholder='Receiving Bank' name='wireTransferInfo.receivingBank' maxLength={50} showRequired />
                                    <TextInput placeholder='Bank Address' name='wireTransferInfo.bankAddress' maxLength={100} />
                                    <SelectInput options={bankAccountTypeOptions} placeholder='Account Type' name='wireTransferInfo.accountType' showRequired />
                                </Form.Group>

                                <Form.Group widths='equal'>
                                    <TextInput placeholder='Routing Number' name='wireTransferInfo.routingNumber' maxLength={100} showRequired />
                                    <TextInput placeholder='Account Number' name='wireTransferInfo.accountNumber' maxLength={100} showRequired />
                                </Form.Group>

                                <Header as='h3' color='blue'>
                                    Beneficiary Info
                                </Header>
                                <Form.Group widths='equal'>
                                    <TextInput placeholder='Beneficiary Account Name' name='wireTransferInfo.beneficiaryName' maxLength={50} />
                                    <TextInput placeholder='Beneficiary Address' name='wireTransferInfo.beneficiaryAddress' maxLength={100} />
                                </Form.Group>

                                <Header as='h3' color='blue'>
                                    Other Info
                                </Header>
                                <Form.Group widths='equal'>
                                    <TextInput placeholder='Reference' name='wireTransferInfo.reference' maxLength={100} />
                                    <TextInput placeholder='Other Instructions' name='wireTransferInfo.otherInstructions' maxLength={1000} />
                                </Form.Group>
                                </>
                            }

                            {showCheckInfo &&
                                <>
                                <Divider horizontal section>
                                  <Header as='h4'>
                                    Instructions to mail a check
                                  </Header>
                                </Divider>
                                <Form.Group widths='equal'>
                                  <TextInput placeholder='Mailing Address' name='checkInfo.mailingAddress' maxLength={100} showRequired />
                                  <TextInput placeholder='Make out to' name='checkInfo.makeOutTo' maxLength={100} showRequired />
                                  <TextInput placeholder='Memo' name='checkInfo.memo' maxLength={100} />
                                  <TextInput placeholder='Other Instructions' name='checkInfo.otherInstructions' maxLength={1000} />
                                </Form.Group>
                              </>
                            }
                            <br />
                            <Button disabled={isSubmitting} loading={isSubmitting} floated='right' color='green' type='submit' content='Continue' onClick={() => setContinueButtonCliked(true)} />
                            <Button disabled={isSubmitting} loading={isSubmitting} floated='right' primary type='submit' content='Save' />
                        </Form>
                    )}
                </Formik>
            </Segment>

            {openTransfer && <TransferModal closeModal={handleTransferClose} show={openTransfer} hideSend />}

        </TabPane>
    )
}

export default Funding