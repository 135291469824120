import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { ZelleAccountProfile } from "../models/zelle/zelleAccountProfile";

export default class ZelleAccountStore {
    constructor() {
        makeAutoObservable(this)
    }

    getZelleProfile= async (entityId: number) => {
        return agent.Zelle.getZellProfile(entityId);
    }

    saveZelleProfile = async (profile: ZelleAccountProfile) => {
        return agent.Zelle.saveZelleProfile(profile);
    }

    deleteZellProfile = async(entityId:number) => {
        return agent.Zelle.deleteZelleProfile(entityId);
    }
}