export enum LoanDrawStatus
{
    Available = 0,
    Requested = 1,
    Approved = 2,
    Paid = 3
}
export interface LoanDrawSchedule
{
    id: number;
    loanID: number;
    drawNumber: number;
    drawAmount?: number | undefined;
    drawStatus: LoanDrawStatus;
    drawStatusDesc: string;
    receivedDate?: string;
    isAvailableToDraw: boolean;
    isAvailableToAcceptOrDeny: boolean;
    drawAmountRequested: number;
}

export class LoanDrawSchedule implements LoanDrawSchedule {
    constructor() {
        this.id = 0;
        this.loanID = 0;
        this.drawNumber = 0;
        this.drawStatus = LoanDrawStatus.Available;
        this.drawStatusDesc = '';
        this.drawAmount = undefined;
        this.isAvailableToDraw = false;
        this.isAvailableToAcceptOrDeny = false;
        this.receivedDate = undefined;
        this.drawAmountRequested = 0;
    }
}

export enum LoanDrawRequestStatus
{
    Unknown = 0,
    Requested = 1,
    Denied = 3,
    Approved = 4,
    Paid = 5,
    CanceledByBorrower = 6,
    ProcessedManually = 7,
    Canceled = 8
}

export interface LoanDrawRequest
{
    id: number;
    loanDrawScheduleID: number;
    drawRequestDate: Date;
    drawAmount?: number | undefined;
    loanBalanceTimeOfRequest?:  number | undefined;
    drawRequestStatus: LoanDrawRequestStatus;
    drawRequestStatusDesc: string;
    denyReason: string;
    drawNumber: number;
    drawScheduleAmount: number;
    lastModified: Date;
    receivedDate: Date;
}

export class LoanDrawRequest implements LoanDrawRequest {
    constructor() {
        this.id = 0;
        this.loanDrawScheduleID = 0;
        this.drawRequestDate = new Date();
        this.drawAmount = 0;
        this.loanBalanceTimeOfRequest = undefined;
        this.drawRequestStatus = LoanDrawRequestStatus.Unknown;
        this.drawRequestStatusDesc = '';
        this.denyReason = '';
        this.drawNumber = 0;
        this.drawScheduleAmount = 0;
        this.lastModified = new Date();
    }
}

export interface LoanDrawRequestAudit
{
    id: number;
    loanDrawRequestID: number;
    loanDrawScheduleID: number;
    drawAmount?: number | undefined;
    loanBalanceTimeOfRequest?:  number | undefined;
    drawRequestStatus: LoanDrawRequestStatus;
    drawRequestStatusDesc: string;
    denyReason: string;
    drawNumber: number;
    drawScheduleAmount: number;
    created: Date;
    entityName: string;
    comment: string;
    receivedDate: Date;
}
