import { Header, Icon, Label, Segment, Table } from "semantic-ui-react"
import { SponsorshipTeamView } from "../../app/models/GpLanding/sponsorshipTeamView"
import { useStore } from "../../app/stores/store";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

interface Params {
    entityId: number,
    enableHeader: boolean
}
function GPSponsorshipTeamCard({ entityId, enableHeader }: Params) {
    const { entityProfileStore } = useStore();
    const [sponsors, setSponsors] = useState([new SponsorshipTeamView()])
    useEffect(() => {
        entityProfileStore.getGpSponsorshipTeam(entityId)
            .then(r => setSponsors(r))
    }, [entityId, entityProfileStore])
    return (
        <Segment clearing className='classTable'>
            <Table>
                {enableHeader &&
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                <Header as='h3' image style={{ marginBottom: 0 }}>
                                    <Icon name='users'></Icon>
                                    <Header.Content>Sponsorship Team</Header.Content>
                                </Header>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                }
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            {sponsors.map((sponsor, i) => {
                                return <Label key={i} style={{ fontSize: '16px', margin: 5 }}><FontAwesomeIcon icon={faUser} /> {sponsor.firstName} {sponsor.lastName}</Label>
                            })}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </Segment>
    )
}

export default GPSponsorshipTeamCard