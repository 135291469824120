import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Form, Header, Segment } from 'semantic-ui-react'
import CurrencyInput from '../../../app/common/form/CurrencyInput';
import { useStore } from '../../../app/stores/store';
import EntityContext from '../../../app/context/entityContext';
import { Distribution, DistributionFormValues } from '../../../app/models/Investment/Distribution';
import DateInput from '../../../app/common/form/DateInput';
import { InputMasks } from '../../../shared/InputMasks';
import TextInput from '../../../app/common/form/TextInput';
import SelectInput from '../../../app/common/form/SelectInput';
import DistributionDetailShareholdersGrid from './DistributionDetailShareholdersGrid';
import * as yup from 'yup';
import { getFloatValue, sleep } from '../../../shared/utils';
import { useDebounce } from '../../../shared/useDebounce';
import { toast } from 'react-toastify';
import VerificationCodeModal from '../../../shared/VerificationCodeModal';

export interface Props {
    investmentId: number;
    distributionId: number;
    onBack: (refresh: boolean) => void;
}

function DistributionDetail({ investmentId, distributionId, onBack }: Props) {
    const [distribution, setDistribution] = useState(new DistributionFormValues());

    const { entity } = useContext(EntityContext);
    const [loading, setLoading] = useState(false);
    const emptyOption = { key: '' };
    const { commonStore, investmentStore, userStore } = useStore();
    const [distributionTypeOptions, setDistributionTypeOptions] = useState([emptyOption]);
    const [readOnly] = useState(distributionId > 0);
    const [showShareholderGrid, setShowShareholderGrid] = useState(false);
    const formikRef = useRef<any>();

    const [calculateAmounts, setCalculateAmounts] = useState<number>(0);
    const debounceCalculateAmounts = useDebounce<number>(calculateAmounts, 400);
    const [formValues, setFormValues] = useState(new DistributionFormValues());
    const [showVerification, setShowVerification] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [twoStepVerificationEnabled, setTwoStepVerificationEnabled] = useState(false);
    const [optInSMS, setOptInSMS] = useState(false);

    useEffect(() => {

        userStore.getCurrentUser().then(user =>
        {
            setTwoStepVerificationEnabled(user.require2StepVerification)
            setOptInSMS(user.optInSMS);
        });

        commonStore.getInvestmentEnums().then(r => {
            setDistributionTypeOptions(r.distributionType);
        })

        if (distributionId > 0) {
            setLoading(true);
            investmentStore.getDistributionDetail(entity.id, investmentId, distributionId)
                .then(async (r) => {
                    setDistribution(new Distribution(r));
                    await sleep(250); //To avoid calling the component twice
                    setShowShareholderGrid(true);
                })
                .finally(() => setLoading(false));
        }
    }, [entity.id, investmentId, distributionId, investmentStore, commonStore, setDistributionTypeOptions, userStore, setTwoStepVerificationEnabled, setOptInSMS])

    useEffect(() => {
        const formikContext = formikRef.current;
        const values = formikContext.values;
        values.distributionDate = new Date(values.distributionDate);
        if (values.comment && values.distributionDate && values.distributionType && values.distributionAmount) {
            setShowShareholderGrid(true);
        }
    }, [debounceCalculateAmounts])

    const validationSchema = yup.object({
        comment: yup
            .string()
            .required('Description is required'),
        distributionDate: yup
            .date()
            .typeError("Please enter a valid date")
            .required("Distribution Date is required"),
        distributionType: yup
            .number()
            .min(1, 'Type is required'),
        distributionAmount: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Amount is required')
            .positive('Amount must be greater than 0'),
    });

    const runDistribution = (originalValue: number, newValue: number) => {
        if (originalValue !== newValue) {
            setShowShareholderGrid(false);
            setCalculateAmounts(calculateAmounts + 1);
        }
    };

    const createDistribution = (formValues: DistributionFormValues) => {
        investmentStore.saveDistribution(formValues)
            .then(id => {
                toast.success("Success!", { theme: "colored" });
                onBack(true);
            })
            .catch(err => {
                toast.error("There was an issue creating the distribution.", { theme: "colored" });
            })
            .finally(() => setIsSubmitting(false));
    }

    const completeDistribution = (verificationCode: string) => {
        setShowVerification(false);
        const updatedFormValues = {
            ...formValues,
            verificationCode: verificationCode,
        };
        createDistribution(updatedFormValues);
    }


    const cancelVerification = () => {
        setIsSubmitting(false);
        setShowVerification(false);
    }

    const handleFormSubmit = async (values: any) => {
        values.distributionDate = new Date(values.distributionDate);
        values.investmentId = investmentId;
        values.entityId = entity.id;
        setIsSubmitting(true);
        if (twoStepVerificationEnabled) {
            setFormValues(values);
            setShowVerification(true);
        }
        else {
            createDistribution(values);
        }
    }

    return (
        <Segment basic loading={loading}>
            <Header>Distributions Info</Header>
            <Formik
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={distribution}
                onSubmit={(values) => {
                    handleFormSubmit(values);
                }}
                innerRef={formikRef}
            >
                {({ handleSubmit, values }) => (
                    <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                        <Form.Group widths='equal'>
                            <TextInput placeholder='Description' name='comment' maxLength={512} readOnly={readOnly} showRequired />
                            <DateInput placeholder='Distribution Date' name='distributionDate' mask={InputMasks.date} showRequired readOnly className="readOnlyField" />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <SelectInput options={distributionTypeOptions} placeholder='Type' name='distributionType' disabled={readOnly} showRequired onChange={(e) => runDistribution(values.distributionType, e)} />
                            <CurrencyInput placeholder='Amount' name='distributionAmount' maxLength={12} allowNegative={false} thousandSeparator=',' decimalScale={2} showRequired onChange={(e) => runDistribution(values.distributionAmount!, e)} prefix='$' readOnly={readOnly} />
                        </Form.Group>
                        {showShareholderGrid &&
                            <DistributionDetailShareholdersGrid values={values} investmentId={investmentId} distributionId={distributionId} />
                        }
                        {!readOnly && <Button type='submit' content='Submit' primary floated='right' disabled={isSubmitting} loading={isSubmitting} />}
                        <Button onClick={() => onBack(false)} floated='right' type='button' content='Back' disabled={isSubmitting} />
                    </Form>
                )}
            </Formik>
            {showVerification && <VerificationCodeModal show={showVerification} onCancel={cancelVerification} onValidCode={completeDistribution} userOptIn={optInSMS}/>}
        </Segment>
    )
}

export default DistributionDetail