import React, { useState } from 'react'
import { Button, Confirm, Icon } from 'semantic-ui-react';

interface Props {
    id: number;
    color?: any;
    value: string;
    content: string;
    handleConfirm: (id: number) => void;
    compact?: any;
    floated?: any;
    basic?: boolean;
    iconName?: any;
    confirmButtonText?: string;
    stopPropagation?: boolean;
    disabled?: boolean;
    headerText?: string;
    buttonId?: string;
    hideButton?: boolean;
    loading?: boolean;
}

function ConfirmButton({ id, color, value, content, handleConfirm, compact, floated, basic, iconName, confirmButtonText, stopPropagation, disabled, headerText, buttonId, hideButton, loading }: Props) {
    const [state, setState] = useState({ open: false, id: 0 });
    const handleCancel = (e: any) => { if (stopPropagation) { e.stopPropagation(); } setState({ open: false, id: 0 }) }
    const confirmAction = (e: any) => {
        if (stopPropagation) e.stopPropagation();
        handleConfirm(id);
        setState({ open: false, id: 0 });
    }
    return (
        <>
            {typeof iconName != 'undefined' && iconName ?
                <Icon link onClick={() => { setState({ open: true, id: id }) }} name={iconName}
                    disabled={disabled ? disabled : false}
                    loading={loading ? loading : false}
                    floated={floated}
                    id={buttonId}
                    style={hideButton ? { display: 'none' } : {}}
                    size='large'
                    color={color ? color : 'red'}
                />
                :
                <Button type='button' color={color ? color : 'red'}
                    disabled={disabled ? disabled : false}
                    loading={loading ? loading : false}
                    onClick={(e) => { e.stopPropagation(); setState({ open: true, id: id }) }}
                    compact={compact} floated={floated}
                    basic={basic}
                    id={buttonId}
                    style={hideButton ? { display: 'none' } : {}}
                >
                    {value}
                </Button>
            }
            <Confirm header={headerText ?? 'Confirm Action'} content={content} open={state.open} onCancel={handleCancel} onConfirm={confirmAction} size='mini' confirmButton={confirmButtonText ?? value} />
        </>
    )
}

export default ConfirmButton