import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react'
import { Image, Form, Grid, Header, Segment, Button } from 'semantic-ui-react'
import * as yup from 'yup';
import SelectInput from '../../app/common/form/SelectInput';
import CurrencyInput from '../../app/common/form/CurrencyInput';
import { useStore } from '../../app/stores/store';
import EntityContext from '../../app/context/entityContext';
import { AddressBalance } from '../../app/models/crypto/AddressBalance';
import { getFloatValue } from '../../shared/utils';
import { Recipient, TransferFormValues, TransferModel, TransferType } from '../../app/models/settings/transfer';
import CurrentBalance from './currentBalance';
import { EntityFilterParams } from '../../app/models/common/entityFilterParams';
import TextArea from '../../app/common/form/Textarea';
import { TransferFormContext } from './send';

interface Props {
  userBalance: AddressBalance;
  closeModal: (refreshBalance: boolean) => void;
  transferModel?: TransferModel;
  callBack?: (recipients: Recipient[]) => void;
}

function SendInput({ userBalance, closeModal, transferModel, callBack }: Props) {
  const [loading, setLoading] = useState(true);

  const { userCryptoAddressStore } = useStore();
  const { activeStepIndex, setActiveStepIndex, transfer, setTransfer, GetAddressInfo, currentToken, setCurrentToken } = useContext(TransferFormContext);

  const [tokens] = useState(userBalance.balances.map(s => ({ key: s.tokenType, value: s.tokenType, text: s.tokenType, image: { avatar: true, src: s.tokenImage } })));

  const emptyOption = { key: '', text: '' };
  const [userAddressesOptions, setUserAddressesOptions] = useState([emptyOption]);
  const { entity } = useContext(EntityContext);

  const initialState: EntityFilterParams = {
    entityId: entity.id,
    sortIndex: 'Label',
    sortOrder: 'ascending',
    pageIndex: 0,
    pageSize: 100
  };
  const [params] = useState(initialState);
  const [transferCopy] = useState({ ...transfer });

  useEffect(() => {

    setCurrentToken(userBalance.balances[0]);

    if (!transferModel) {
      if (userBalance.balances.length > 1) {
        setCurrentToken(userBalance.balances.filter(token => token.tokenType === "USDC")[0]);
      }
      userCryptoAddressStore.getUserCryptoAddresses(params).then(r => {
        const options = JSON.stringify(r.data.map(s => ({ key: s.id.toString(), value: s.id.toString(), text: s.label })));
        setUserAddressesOptions(JSON.parse(options));
        setLoading(false);
      });
    }
    else {
      const options = [{ key: transferModel.toTableKeyId.toString(), value: transferModel.toTableKeyId.toString(), text: transferModel.toName }];
      setUserAddressesOptions(options);
      const updatedTransfer = { ...transferCopy };
      updatedTransfer.toAddress = transferModel.toTableKeyId.toString();
      updatedTransfer.amount = transferModel.amount;
      setTransfer(updatedTransfer);
      setLoading(false);
    }
  }, [userCryptoAddressStore, setLoading, transferModel, params, transferCopy, userBalance, setTransfer, setCurrentToken]);

  const validationSchema = yup.object({
    amount: yup
      .number()
      .transform((_value, originalValue) => getFloatValue(originalValue))
      .required('Amount is required')
      .positive('Amount must be greater than 0')
      .test(
        'match',
        'Insufficient balance',
        function (value) {
          return value! <= currentToken.availableValue;
        }
      ),
    toAddress: yup
      .string()
      .required('To is required')
  });

  const changeToken = (d: any) => {
    setCurrentToken(userBalance.balances.filter(token => token.tokenType === d.value)[0]);
  }

  const handleFormSubmit = async (values: TransferFormValues, setFieldError: any) => {
    setLoading(true);

    values.toName = userAddressesOptions.filter(f => f.key === values.toAddress).map(r => r.text)[0];
    values.senderTableKeyId = entity.id;

    if (transferModel && transferModel.transferType !== TransferType.coinTransfer) {
      values.toName = transferModel.toName;
      values.amount = transferModel.amount > 0 ? transferModel.amount : getFloatValue(values.amount);
      values.toAddress = userAddressesOptions[0].key;
      values.senderCapblockTable = transferModel.senderCapblockTable;
      values.senderTableKeyId = transferModel.senderTableKeyId;
      values.toCapblockTable = transferModel.toCapblockTable;
      values.toTableKeyId = transferModel.toTableKeyId;

      if (transferModel.transferType === TransferType.escrowFunding) {
        values.toAddress = transferModel.recipients![0].toTableKeyId.toString();
        values.recipients = transferModel.recipients;
      }
    }
    else {
      values.amount = getFloatValue(values.amount);
    }

    values.amountEth = values.amount / currentToken.price;
    values.tokenType = currentToken.tokenType;
    values.transferType = transferModel?.transferType ?? 1;
    values.overrideGasFee = false;

    setTransfer(values);
    setActiveStepIndex(activeStepIndex + 1);

  }

  return (
    <Segment basic loading={loading}>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={transfer}
        onSubmit={(values, { setFieldError }) => {
          handleFormSubmit(values, setFieldError);
        }}
      >
        {({ handleSubmit }) => (
          <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
            <Grid textAlign='center'>
              <Grid.Row>
                <Grid.Column>
                  <Segment basic>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={2}></Grid.Column>
                        <Grid.Column width={8} textAlign='left'>
                          <CurrencyInput placeholder='Amount' name='amount'
                            hidetitle={'true'} thousandSeparator=',' decimalScale={2}
                            allowNegative={false} maxLength={16} className="amountInput"
                            disabled={transferModel !== undefined && transferModel?.amount !== 0} />
                        </Grid.Column>
                        <Grid.Column width={4} textAlign='left' verticalAlign='middle'>
                          <Header as='h1' color='blue'>{currentToken.tokenType}</Header>
                        </Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                      </Grid.Row>

                    </Grid>
                  </Segment>
                  <CurrentBalance balance={currentToken} />
                  <Grid celled columns={2}>
                    <Grid.Row>
                      <Grid.Column width={5} verticalAlign='middle'>
                        Transfer:
                      </Grid.Column>
                      <Grid.Column textAlign='left' width={11}>
                        {transferModel || userBalance.balances.length === 1 ?
                          <Header as='h4'>
                            <Image src={currentToken.tokenImage} rounded size='mini' />
                            <Header.Content>
                              {currentToken.tokenType}
                            </Header.Content>
                          </Header>
                          :
                          <SelectInput options={tokens} placeholder='Token' name='tokenType' hidetitle searchable={false} onChange={(e, d) => changeToken(d)} />
                        }
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5} verticalAlign='middle'>
                        To:
                      </Grid.Column>
                      <Grid.Column width={11} textAlign='left'>
                        {transferModel
                          ? GetAddressInfo()
                          : <SelectInput options={userAddressesOptions} placeholder='To' name='toAddress' hidetitle />
                        }
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5} verticalAlign='middle'>
                        Note:
                      </Grid.Column>
                      <Grid.Column width={11} textAlign='left'>
                        <TextArea placeholder='Add a note about this transfer' name='note' rows={3} hideTitle maxLength={512} />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {transferModel?.recipients && transferModel?.recipients?.length > 0 && (
                    <Button type='button' onClick={() => callBack && callBack(transferModel.recipients!)} content='Back' />
                  )}
                  <Button type='submit' content='Continue' primary />
                  <Button type='button' onClick={() => closeModal(false)} content='Cancel' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </Segment>
  )
}

export default SendInput