import { useContext, useEffect, useState } from 'react'
import { Button, Checkbox, Container, Grid, Header, Icon, Segment, Table } from 'semantic-ui-react'
import { Investment } from '../../../app/models/Investment/Investment';
import { useStore } from '../../../app/stores/store';
import { Offering, OfferingStatus } from '../../../app/models/Investment/InvestmentOffering';
import GridFooter from '../../../shared/GridFooter';
import { useMediaQuery } from 'react-responsive';
import { createGridInitialState, formatCurrency, formatDate, getEnumValue, getFullSizeWidth, handleGridNavigation, handleGridSort } from '../../../shared/utils';
import { toast } from 'react-toastify';
import { PageParams } from '../../../app/models/common/PagedResult';
import ConfirmButton from '../../../shared/ConfirmButton';
import EmptyGridMessage from '../../../shared/EmptyGridMessage';
import { InvestmentQueryFilter } from '../../../app/models/common/InvestmentQueryFilter';
import EntityContext from '../../../app/context/entityContext';
import NewOfferingModal from './Components/NewOffering';

interface Props {
    investment: Investment;
    reloadOfferingsGrid: number;
    handleOfferingModal: (id: number) => void;
    setReloadPendingApprovalOfferingsGrid: (value: React.SetStateAction<boolean>) => void;
}

function OfferingGrid({ investment, reloadOfferingsGrid, handleOfferingModal, setReloadPendingApprovalOfferingsGrid}: Props) {

    const [loading, setLoading] = useState(true);
    const { entity } = useContext(EntityContext);
    const { investmentOfferingStore } = useStore();
    const [offeringNotification, setOfferingNotification] = useState(false);
    const [showActive, setShowActive] = useState(true);
    const [offerings, setOfferings] = useState([new Offering()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const [newOfferingModalOpen, setNewOfferingModalOpen] = useState(false);

    const initialState: InvestmentQueryFilter = createGridInitialState({
        entityId: entity.id,
        investmentId: investment.id,
        showAll: !showActive,
        sortIndex: 'Created',
        sortOrder: 'ascending'
    });

    const [params, setParams] = useState(initialState);

    useEffect(() => {
        params.showAll = !showActive;
        investmentOfferingStore.getOfferings(params).then(r => {
            setTotalRecords(r.totalRecords);
            setOfferings(r.data);
            setLoading(false);
        });

        investmentOfferingStore.getOfferingNotification(entity.id, investment.id).then(r => {
            setOfferingNotification(r);
        });

    }, [investmentOfferingStore, params, setLoading, entity.id, investment.id, showActive, reloadOfferingsGrid]);

    const handleSort = (clickedColumn: string) => {
        if (offerings.length === 0) return;
        params.showAll = !showActive;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        params.showAll = !showActive;
        setParams(handleGridNavigation(params, updatedParams));
    }

    const deleteOffering = (offeringId: number) => {
        investmentOfferingStore.deleteOffering(entity.id, offeringId).then(r => {
            if (setReloadPendingApprovalOfferingsGrid) setReloadPendingApprovalOfferingsGrid(true);
            setParams(handleGridSort(params, initialState.sortIndex));
        }).catch(err => {
            toast.error("There was an issue deleting selected offering", { theme: "colored" });
        });
    }

    const toggleOfferNotification = (checked: boolean) => {
        setOfferingNotification(checked);
        investmentOfferingStore.saveOfferingNotificationSubscription(entity.id, investment.id, checked).then(r => {
            toast.success("New Offerings Notification Setting is saved successfully.")
        }).catch(err => {
            toast.error("There was an issue deleting selected offering", { theme: "colored" });
        });
    }

    const handleReloadOfferings = () => {
        setParams(handleGridSort(params, initialState.sortIndex));
    };

    const createOffering = () => {
        setNewOfferingModalOpen(true);
        viewOffering(0);
    }

    const viewOffering = (id: number) => {
        handleOfferingModal(id);
    }

    return (
        <Segment clearing loading={loading}>
            <Header>Open Offerings</Header>
            <Container textAlign='right'>
            <Checkbox toggle label={showActive ? "Active Offerings" : "All Offerings"}
                    defaultChecked={showActive}
                    onChange={(e, { checked }) => setShowActive(checked ?? false)} />
            </Container>
            <Table sortable>
                {showFullSize &&
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign='left' onClick={() => handleSort("OfferingEntityName")} sorted={params.sortIndex === "Created" && offerings.length > 0 ? params.sortOrder : undefined}>Created On</Table.HeaderCell>
                            <Table.HeaderCell textAlign='left' onClick={() => handleSort("OfferingEntityName")} sorted={params.sortIndex === "OfferingEntityName" && offerings.length > 0 ? params.sortOrder : undefined}>Name</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' onClick={() => handleSort("SharePrice")} sorted={params.sortIndex === "SharePrice" ? params.sortOrder : undefined}>Price</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' onClick={() => handleSort("ShareCount")} sorted={params.sortIndex === "ShareCount" ? params.sortOrder : undefined}>Quantity</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' onClick={() => handleSort("Action")} sorted={params.sortIndex === "Action" ? params.sortOrder : undefined}>Offer</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' onClick={() => handleSort("OfferingExpiryDate")} sorted={params.sortIndex === "OfferingExpiryDate" ? params.sortOrder : undefined}>Expiry</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' onClick={() => handleSort("Status")} sorted={params.sortIndex === "Status" ? params.sortOrder : undefined}>Status</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                }
                <Table.Body>
                    {!loading && offerings.map((offering, i) => {
                        if (showFullSize) {
                            return <Table.Row key={i} style={{ cursor: 'pointer' }} onClick={() => viewOffering(offering.id)}>
                                <Table.Cell>{formatDate(offering.created)}</Table.Cell>
                                <Table.Cell>{offering.offeringEntityName}</Table.Cell>
                                <Table.Cell textAlign='right'>{formatCurrency(offering.sharePrice)}</Table.Cell>
                                <Table.Cell textAlign='right'>{offering.shareCount}</Table.Cell>
                                <Table.Cell textAlign='center'>{offering.action}</Table.Cell>
                                <Table.Cell textAlign='center'>{formatDate(offering.expiryDate)}</Table.Cell>
                                <Table.Cell textAlign='center'>
                                    {offering.offeringStatusDesc}
                                    {offering.actionMsg && <br />}
                                    {offering.actionMsg}
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    {entity.id === offering.entityID && getEnumValue(OfferingStatus, offering.status) < OfferingStatus.AwaitingConfirmation &&
                                        <ConfirmButton id={offering.id} color='red' iconName='delete' basic
                                            content={'Delete Offering?'}
                                            value='' confirmButtonText='Delete'
                                            stopPropagation={true}
                                            handleConfirm={() => deleteOffering(offering.id)}></ConfirmButton>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        }
                        else {
                            return <Table.Row key={i}>
                                <Table.Cell>
                                    {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={8}>
                                                <label onClick={() => handleSort("Created")}>Created On:</label><br />
                                                <label onClick={() => handleSort("OfferingEntityName")}>Name:</label><br />
                                                <label onClick={() => handleSort("SharePrice")}>Price:</label><br />
                                                <label onClick={() => handleSort("ShareCount")}>Quantity:</label><br />
                                                <label onClick={() => handleSort("Action")}>Offer:</label><br />
                                                <label onClick={() => handleSort("OfferingExpiryDate")}>Expiry:</label><br />
                                                <label onClick={() => handleSort("Status")}>Status:</label><br />
                                                <label></label><br />
                                            </Grid.Column>
                                            <Grid.Column style={{ fontWeight: 'normal' }} >
                                                <div style={{ width: '45vw' }} onClick={() => viewOffering(offering.id)}>
                                                    {formatDate(offering.created)} <br/>
                                                    {offering.offeringEntityName}<br />
                                                    {formatCurrency(offering.sharePrice)}<br />
                                                    {offering.shareCount}<br />
                                                    {offering.action}<br />
                                                    {formatDate(offering.expiryDate)}<br />
                                                    {offering.offeringStatusDesc}
                                                    {offering.actionMsg && <br />}
                                                    {offering.actionMsg}
                                                    <br />
                                                    {entity.id === offering.entityID && getEnumValue(OfferingStatus, offering.status) < OfferingStatus.AwaitingConfirmation &&
                                                        <ConfirmButton id={offering.id} color='red' iconName='delete' basic
                                                            content={'Delete Offering?'}
                                                            value='' confirmButtonText='Delete'
                                                            handleConfirm={(e) => deleteOffering(offering.id)}></ConfirmButton>
                                                    } <br />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                        }
                    })}
                    {!loading && offerings.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 8 : 1} message='No Open Offerings' />}
                </Table.Body>
                {!loading && offerings.length > 0 &&
                    <GridFooter colSpan={showFullSize ? 8 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                }
            </Table>
            <Container textAlign='center'>
                {investment.sponsorEntityId !== entity.id &&
                    <Button primary onClick={() => createOffering()}>Create Offering</Button>
                }
                {newOfferingModalOpen &&
                    <NewOfferingModal investment={investment} handleReloadOfferings={handleReloadOfferings} setOfferingModalParentOpen={setNewOfferingModalOpen}/>
                }
            </Container>
            <Container>
                <Checkbox toggle label="Notify Me New Offerings"
                    checked={offeringNotification}
                    onChange={(e, { checked }) => toggleOfferNotification(checked ?? false)} />
            </Container>
        </Segment>
    )
}
export default OfferingGrid