import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react'
import { TabPane, Segment, Form, Button, Icon } from 'semantic-ui-react';
import { InvestmentFormValues } from '../../../../app/models/Investment/Investment';
import CurrencyInput from '../../../../app/common/form/CurrencyInput';
import { useStore } from '../../../../app/stores/store';
import EntityContext from '../../../../app/context/entityContext';
import SelectInput from '../../../../app/common/form/SelectInput';
import * as yup from 'yup';
import { toast } from 'react-toastify';

interface Props {
    investmentId: number;
    continueNextTab: () => void;
}

function TermsAndFees({ investmentId, continueNextTab }: Props) {
    const emptyOption = { key: '' };
    const [profitSharingOptions, setProfitSharingOptions] = useState([emptyOption]);
    const { commonStore, investmentStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [investment, setInvestment] = useState(new InvestmentFormValues());
    const [loading, setLoading] = useState(true);
    const [continueButtonCliked, setContinueButtonCliked] = useState(false);

    useEffect(() => {
        commonStore.getInvestmentEnums().then(r => {
            setProfitSharingOptions(r.profitSharing);
        })

        investmentStore.getInvestmentById(entity.id, investmentId).then((r) => {
            const result = new InvestmentFormValues(r);
            setInvestment(result);
        })
        .finally(() => setLoading(false));
    }, [investmentId, setInvestment, entity, investmentStore, setLoading, commonStore, setProfitSharingOptions]);

    const validationSchema = yup.object({
        acquisitionFee: yup
            .number()
            .max(999.99, "Acquisition Fee must be less than or equal to 999.99")
            .nullable(),
        assetManagementFee: yup
            .number()
            .max(999.99, "Asset Management Fee must be less than or equal to 999.99")
            .nullable(),
        dispositionFee: yup
            .number()
            .max(999.99, "Disposition Fee must be less than or equal to 999.99")
            .nullable(),
        refinanceFee: yup
            .number()
            .max(999.99, "Refinance Fee must be less than or equal to 999.99")
            .nullable(),
        profitSharingId: yup
            .number()
            .min(1, 'Profit Sharing is required'),
    });

    const handleFormSubmit = async (values: any, setSubmitting: any) => {
        values.acquisitionDate = new Date(values.acquisitionDate);
        investmentStore.saveInvestment(new InvestmentFormValues(values))
            .then(r => {
                toast.success("Success!", { theme: "colored" });
                if (continueButtonCliked) continueNextTab();
            })
            .catch(err => {
                toast.error("There was an issue saving the terms and fees.", { theme: "colored" });
            })
            .finally(() => setSubmitting(false));
    }
    return (
        <TabPane loading={loading}>
            <Segment clearing>
                <Formik
                    validationSchema={validationSchema}
                    enableReinitialize
                    initialValues={investment}
                    onSubmit={(values, { setSubmitting }) => {
                        handleFormSubmit(values, setSubmitting);
                    }}
                >
                    {({ handleSubmit, isSubmitting }) => (
                        <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                            <Form.Group widths='equal'>
                                <CurrencyInput placeholder='Acquisition Fee' name='acquisitionFee' allowNegative={false} maxLength={6} icon={<Icon name='percent' />} />
                                <CurrencyInput placeholder='Asset Management Fee' name='assetManagementFee' allowNegative={false} maxLength={6} icon={<Icon name='percent' />} />
                                <CurrencyInput placeholder='Disposition Fee' name='dispositionFee' allowNegative={false} maxLength={6} icon={<Icon name='percent' />} />
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <CurrencyInput placeholder='Refinance Fee' name='refinanceFee' allowNegative={false} maxLength={6} icon={<Icon name='percent' />} />
                                <SelectInput options={profitSharingOptions} placeholder='Profit Sharing' name='profitSharingId' showRequired />
                            </Form.Group>

                            <br />
                            <Button disabled={isSubmitting} loading={isSubmitting} floated='right' color='green' type='submit' content='Continue' onClick={() => setContinueButtonCliked(true)} />
                            <Button disabled={isSubmitting} loading={isSubmitting} floated='right' primary type='submit' content='Save' />
                        </Form>
                    )}
                </Formik>
            </Segment>
        </TabPane>
    )
}

export default TermsAndFees