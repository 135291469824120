import React, { useState } from 'react'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import useIdleTimeout from './Inactivity/useIdleTimeout';
import InactivityDialog from './Inactivity/InactivityDialog';
import { clearCache } from '../../shared/utils';

function ProtectedRoute({ component, ...args }: any) {
    const Component = withAuthenticationRequired(component, args);
    const [openModal, setOpenModal] = useState(false);
    const { logout } = useAuth0();

    const handleIdle = () => {
        setOpenModal(true);
    }
    const {idleTimer} = useIdleTimeout({ onIdle: handleIdle });
    const stay = () => {
        setOpenModal(false)
        idleTimer.reset()
    }
    const handleLogout = () => {
        clearCache();
        logout({ returnTo: window.location.origin });
    }

    if (openModal) return <InactivityDialog show={openModal} handleLogout={handleLogout} stay={stay} />

    return <Component />;
}

export default ProtectedRoute