export interface PayoffInput {
    loanId: number;
    entityId: number;
    payoffDate: Date;
    includeEscrows: boolean;
    includeLoanInfo: boolean;
    payoffAmount: number;
}

export class PayoffInput implements PayoffInput {
     constructor(init?: PayoffInput) {
        Object.assign(this, init);
    }
}