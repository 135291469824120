import React, { createContext, useState } from 'react'
import { Card, Container } from 'semantic-ui-react'
import Stepper from './stepper';
import UserForm from './userForm';
import { User } from '../../app/models/registration/user';
export const FormContext = createContext();

function RegistrationForm() {

    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [userData, setUserData] = useState(new User());

    return (
        <FormContext.Provider value={{ activeStepIndex, setActiveStepIndex, userData, setUserData }}>
            <Container>
                <Card.Group>
                    <Card fluid raised>
                        <Card.Content>
                            <Card.Header>
                                <Stepper />
                            </Card.Header>
                        </Card.Content>
                        {activeStepIndex === 0 && <UserForm />}
                    </Card>
                </Card.Group>
            </Container>
        </FormContext.Provider>
    )
}

export default RegistrationForm