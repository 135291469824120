
export interface SponsorshipTeamView
{
    userId:number,
    firstName:string,
    lastName:string,
}

export class SponsorshipTeamView implements SponsorshipTeamView {
    constructor(init?: SponsorshipTeamView) {
      Object.assign(this, init);
    }
}