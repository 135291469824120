import { Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { Button, DropdownItemProps, Form, Grid, GridColumn, GridRow, Header, Segment } from 'semantic-ui-react'
import TextInput from '../../../app/common/form/TextInput'
import SelectInput from '../../../app/common/form/SelectInput'
import { MonthlyStatement } from '../../../app/models/MonthlyStatement/MonthlyStatement'
import DateInput from '../../../app/common/form/DateInput'
import { InputMasks } from '../../../shared/InputMasks'
import CurrencyInput from '../../../app/common/form/CurrencyInput'
import { formatDateFormal, getFloatValue } from '../../../shared/utils'
import * as yup from 'yup';
import { useStore } from '../../../app/stores/store'
import { toast } from 'react-toastify'
import EntityContext from '../../../app/context/entityContext'
import { useParams } from 'react-router-dom'
import VerificationCodeModal from '../../../shared/VerificationCodeModal'
import { LoanPaymentProcessing } from '../../../app/models/Loan/LoanPaymentProcessing'
import CheckboxInput from '../../../app/common/form/CheckboxInput'

interface Props {
    statement: MonthlyStatement;
    onCancel: (removePaid: boolean, newBalance?: number) => void;
}
function ProcessPaymentView({ statement, onCancel }: Props) {
    const paymentProcessingTypeOptions: DropdownItemProps[] = [
        { key: "1", value: 1, text: "Payment" },
        { key: "2", value: 2, text: "Issue Credit" },
        { key: "3", value: 3, text: "Fees" },
    ];
    const { loanStore, userStore, commonStore } = useStore();
    const { entity } = useContext(EntityContext);
    const { id } = useParams();
    const [showVerification, setShowVerification] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formValues, setFormValues] = useState(new LoanPaymentProcessing());
    const [twoStepVerificationEnabled, setTwoStepVerificationEnabled] = useState(false);
    const [optInSMS, setOptInSMS] = useState(false);
    const [paymentProcessingType, setPaymentProcessingType] = useState(1);
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date();

    var lastStatement = statement?.details?.loan.lastStatement ?? false;
    const emptyOption = { key: '', text: '' };
    const [feeTypeOptions, setFeeTypeOptions] = useState([emptyOption]);

    const initPayment = new LoanPaymentProcessing();
    initPayment.monthlyStatementId = statement?.id ?? 0;
    initPayment.loanId = Number(id);
    initPayment.statementAmount = lastStatement ? statement?.amount - statement.details.outstandingBalance : statement?.amount ?? 0;
    initPayment.amount = statement?.amount ?? 0;
    initPayment.principal = 0;
    initPayment.paymentProcessingType = paymentProcessingType;
    initPayment.interest = 0;
    initPayment.statementDueDate = formatDateFormal(statement?.dueDate);
    initPayment.paymentDate = new Date();
    initPayment.creditDate = new Date();
    initPayment.creditAmount = 0;
    initPayment.feeDate = new Date();
    initPayment.feeAmount = 0;
    initPayment.feeType = 0;
    initPayment.feePrePaid = false;
    initPayment.outstandingBalance = lastStatement ? statement.details.outstandingBalance : 0;

    const [payment, setPayment] = useState(initPayment);

    useEffect(() => {
        userStore.getCurrentUser().then(user => {
            setTwoStepVerificationEnabled(user.require2StepVerification)
            setOptInSMS(user.optInSMS);
        });
        commonStore.getFeeEnums().then(r => {
            setFeeTypeOptions(r.feeTypes);
        })
    }, [userStore, setTwoStepVerificationEnabled, setOptInSMS, commonStore]);

    const onChangePrincipal = (value: number, setFieldValue: any, values: any) => {
        if (value !== undefined) setFieldValue("amount", getFloatValue(payment.statementAmount) + getFloatValue(value) + getFloatValue(values.interest));
    };

    const onChangeInterest = (value: number, setFieldValue: any, values: any) => {
        if (value !== undefined) setFieldValue("amount", getFloatValue(payment.statementAmount) + getFloatValue(value) + getFloatValue(values.principal));
    };

    const paymentValidationSchema = yup.object({
        paymentProcessingType: yup
            .number()
            .min(1, 'Payment type is required'),
        paymentDate: yup
            .date()
            .typeError("Please enter a valid date")
            .required("Effective Date is required"),
        principal: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Principal  is required')
            .min(0),
        interest: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Interest is required')
            .min(0),
        amount: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Total Amount is required')
            .positive('Total Amount must be greater than 0'),
    });

    const creditValidationSchema = yup.object({
        paymentProcessingType: yup
            .number()
            .min(1, 'Payment type is required'),
        creditAmount: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Credit Amount is required')
            .positive('Credit Amount must be greater than 0'),
        creditDate: yup
            .date()
            .typeError("Please enter a valid date")
            .required("Credit Date is required"),
    });

    const feeValidationSchema = yup.object({
        paymentProcessingType: yup
            .number()
            .min(1, 'Payment type is required'),
        feeAmount: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Fee Amount is required')
            .positive('Fee Amount must be greater than 0'),
        feeDate: yup
            .date()
            .typeError("Please enter a valid date")
            .required("Fee Date is required"),
        feeType: yup
            .number()
            .min(1, 'Fee type is required'),
    });

    const handleFormSubmit = async (values: any) => {
        setIsSubmitting(true);
        if (twoStepVerificationEnabled) {
            setFormValues(values);
            setShowVerification(true);
        }
        else {
            processPayment(values);
        }
    }

    const completeDistribution = (verificationCode: string) => {
        setShowVerification(false);
        const updatedFormValues = {
            ...formValues,
            verificationCode: verificationCode,
        };
        processPayment(updatedFormValues);
    }


    const cancelVerification = () => {
        setIsSubmitting(false);
        setShowVerification(false);
    }

    const processPayment = (values: LoanPaymentProcessing) => {
        loanStore.processPayment(entity.id, values)
            .then(response => {
                if (response !== null) {
                    toast.success("Success!", { theme: "colored" });
                    onCancel(true, response);
                }
            })
            .catch(err => {
                toast.error(err.response.data, { theme: "colored" });
            })
            .finally(() => setIsSubmitting(false));
    }

    return (
        <Segment clearing>
            <Header as='h3' color='blue'>
                Process Payment
            </Header>
            <Formik
                enableReinitialize
                validationSchema={paymentProcessingType === 1 ? paymentValidationSchema : paymentProcessingType === 2 ? creditValidationSchema : feeValidationSchema}
                initialValues={payment}
                onSubmit={(values) => {
                    handleFormSubmit(values);
                }}
            >
                {({ handleSubmit, setFieldValue, values }) => (
                    <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                        <Grid>
                            <GridRow>
                                <GridColumn width={5}>
                                    <SelectInput options={paymentProcessingTypeOptions} placeholder='Payment Type' name='paymentProcessingType' showRequired onChange={(e, d) => setPaymentProcessingType(d.value)} />
                                </GridColumn>
                                <GridColumn width={5}>
                                    {paymentProcessingType === 1 && <DateInput placeholder='Effective Date' name='paymentDate' mask={InputMasks.date} showRequired />}
                                </GridColumn>
                                {statement !== undefined &&
                                    <GridColumn width={5}>
                                        {paymentProcessingType === 1 && <TextInput placeholder='For the payment due on' name='statementDueDate' maxLength={512} readOnly className="readOnlyField" />}
                                    </GridColumn>
                                }
                            </GridRow>
                            {paymentProcessingType === 1 &&
                                <>
                                    <GridRow>
                                        <GridColumn width={5} verticalAlign='middle' textAlign='right'><b>Payment Amount *</b></GridColumn>
                                        <GridColumn width={5}>
                                            <CurrencyInput placeholder='Payment Amount' name='statementAmount' allowNegative={false} maxLength={15} showRequired readOnly className="readOnlyField paymentInput" prefix='$' thousandSeparator=',' decimalScale={2} hidetitle='true' fixedDecimalScale />
                                        </GridColumn>
                                    </GridRow>
                                {lastStatement ?
                                    <GridRow>
                                        <GridColumn width={5} verticalAlign='middle' textAlign='right'><b>Outstanding Balance *</b></GridColumn>
                                        <GridColumn width={5}>
                                            <CurrencyInput placeholder='' name='outstandingBalance' allowNegative={false} maxLength={15} showRequired readOnly className="readOnlyField paymentInput" prefix='$' thousandSeparator=',' decimalScale={2} hidetitle='true' fixedDecimalScale />
                                        </GridColumn>
                                    </GridRow>
                                    :
                                    <>
                                        <GridRow>
                                            <GridColumn width={5} verticalAlign='middle' textAlign='right'><b>Additional Principal *</b></GridColumn>
                                            <GridColumn width={5}>
                                                <CurrencyInput placeholder='Additional Principal' name='principal' maxLength={14} allowNegative={false} showRequired thousandSeparator=',' decimalScale={2} onChange={(e) => onChangePrincipal(e, setFieldValue, values)} hidetitle='true' className="paymentInput" prefix='$' />
                                            </GridColumn>
                                        </GridRow>
                                        <GridRow>
                                            <GridColumn width={5} verticalAlign='middle' textAlign='right'><b>Additional Interest *</b></GridColumn>
                                            <GridColumn width={5}>
                                                <CurrencyInput placeholder='Additional Interest' name='interest' maxLength={11} allowNegative={false} showRequired thousandSeparator=',' decimalScale={2} onChange={(e) => onChangeInterest(e, setFieldValue, values)} hidetitle='true' className="paymentInput" prefix='$' />
                                            </GridColumn>
                                        </GridRow>
                                    </>
                                }
                                    <GridRow>
                                        <GridColumn width={5} verticalAlign='middle' textAlign='right'><b>Total Payment *</b></GridColumn>
                                        <GridColumn width={5}>
                                            <CurrencyInput placeholder='Total Payment' name='amount' allowNegative={false} showRequired readOnly className="readOnlyField paymentInput" thousandSeparator=',' decimalScale={2} prefix='$' hidetitle='true' fixedDecimalScale />
                                        </GridColumn>
                                    </GridRow>
                                </>
                            }
                            {paymentProcessingType === 2 &&
                                <>
                                    <GridRow>
                                        <GridColumn width={5} verticalAlign='middle' textAlign='right'><b>Amount *</b></GridColumn>
                                        <GridColumn width={5}>
                                            <CurrencyInput placeholder='Credit Amount' name='creditAmount' maxLength={14} allowNegative={false} showRequired thousandSeparator=',' decimalScale={2} onChange={(e) => onChangePrincipal(e, setFieldValue, values)} hidetitle='true' className="paymentInput" prefix='$' />
                                        </GridColumn>
                                    </GridRow>
                                    <GridRow>
                                        <GridColumn width={5} verticalAlign='middle' textAlign='right'><b>Date *</b></GridColumn>
                                        <GridColumn width={5}>
                                            <DateInput placeholder='Date' startDate={firstDay} maxDate={lastDay} name='creditDate' mask={InputMasks.date} dateFormat='MM/dd/yyyy' hideTitle showMonthYearDropdown={false} />
                                        </GridColumn>
                                    </GridRow>
                                </>
                            }
                            {paymentProcessingType === 3 &&
                                <>
                                    <GridRow>
                                        <GridColumn width={5} verticalAlign='middle' textAlign='right'><b>Date *</b></GridColumn>
                                        <GridColumn width={5}>
                                            <DateInput placeholder='Date' startDate={values.feePrePaid ? null : firstDay} maxDate={lastDay} name='feeDate' mask={InputMasks.date} dateFormat='MM/dd/yyyy' hideTitle showMonthYearDropdown={values.feePrePaid} />
                                        </GridColumn>
                                    </GridRow>
                                    <GridRow>
                                        <GridColumn width={5} verticalAlign='middle' textAlign='right'><b>Fee Type *</b></GridColumn>
                                        <GridColumn width={5}>
                                            <SelectInput options={feeTypeOptions} placeholder='Fee Type' name='feeType' hidetitle showRequired />
                                        </GridColumn>
                                    </GridRow>
                                    <GridRow>
                                        <GridColumn width={5} verticalAlign='middle' textAlign='right'><b>Amount *</b></GridColumn>
                                        <GridColumn width={5}>
                                            <CurrencyInput placeholder='Fee Amount' name='feeAmount' maxLength={14} allowNegative={false} showRequired thousandSeparator=',' decimalScale={2} onChange={(e) => onChangePrincipal(e, setFieldValue, values)} hidetitle='true' className="paymentInput" prefix='$' />
                                        </GridColumn>
                                    </GridRow>
                                    <GridRow>
                                        <GridColumn width={5} verticalAlign='middle' textAlign='right'><b>Pre-Paid *</b></GridColumn>
                                        <GridColumn width={5}>
                                            <CheckboxInput placeholder='Pre-Paid' name='feePrePaid' toggle hideTitle defaultChecked={false} 
                                            onChange={(e, { checked }) => {
                                                const updateFields = { ...values };
                                                updateFields.feePrePaid = checked ?? false;
                                                if (!checked)
                                                {
                                                    const currentDate = new Date();
                                                    const feeDate = updateFields.feeDate;

                                                    if (feeDate && !(feeDate.getFullYear() === currentDate.getFullYear() && feeDate.getMonth() === currentDate.getMonth()))
                                                    {
                                                        updateFields.feeDate = null;
                                                    }
                                                }
                                                setPayment(updateFields);
                                            }}
                                            />
                                        </GridColumn>
                                    </GridRow>
                                </>
                            }
                        </Grid>
                        <Button disabled={isSubmitting} loading={isSubmitting} floated='right' primary type='submit' content='Process' />
                        <Button disabled={isSubmitting} floated='right' type='button' content='Back' onClick={() => onCancel(false)} />
                    </Form>
                )}
            </Formik>
            {showVerification && <VerificationCodeModal show={showVerification} onCancel={cancelVerification} onValidCode={completeDistribution} userOptIn={optInSMS} />}
        </Segment>
    )
}

export default ProcessPaymentView