import { BalanceResult } from "./BalanceResult";

export interface AddressBalance {
    address: string;
    date: string;
    balances: BalanceResult[];
}

export class AddressBalance implements AddressBalance {
    constructor() {
        this.address = "";
        this.date = "";
        this.balances = [];
    }
}