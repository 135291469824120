import { useContext, useEffect, useState } from "react";
import { Button, Label, Modal, Table } from "semantic-ui-react";
import { useStore } from "../../../../app/stores/store";
import { OfferingAction, OfferingStatus, Offering, OfferingWorkflow } from "../../../../app/models/Investment/InvestmentOffering";
import { toast } from "react-toastify";
import EntityContext from '../../../../app/context/entityContext';
import { formatCurrency, formatDate, getEnumText, getEnumValue } from "../../../../shared/utils";
import { Investment } from "../../../../app/models/Investment/Investment";
import ConfirmButton from "../../../../shared/ConfirmButton";
import { SellEstimates } from "./SellEstimates";
import { AddressBalance } from "../../../../app/models/crypto/AddressBalance";
import TransferModal from "../../../transfer/transferModal";
import { CapBlockTable } from "../../../../app/models/common/CapblockTable";
import { TransferModel, TransferType } from "../../../../app/models/settings/transfer";

interface OfferingViewModalData {
    investment: Investment;
    offeringId: number;
    handleReloadOfferings?: () => void;
    setOfferingModalParentOpen: (isOpen: boolean) => void;
}
const OfferingViewModal = ({ investment, offeringId, handleReloadOfferings, setOfferingModalParentOpen }: OfferingViewModalData) => {
    const { entity } = useContext(EntityContext);
    const [loading, setLoading] = useState(true);
    const { commonStore, investmentOfferingStore, cryptoStore } = useStore();
    const [userBalance, setUserBalance] = useState(new AddressBalance());
    const [entityShareInfo, setEntityShareInfo] = useState({availableEntityShareCount : 0, entityShareValue : 0});
    const [offering, setOffering] = useState(new Offering());
    const [offeringModalOpen, setOfferingModalOpen] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [showTransfer, setShowTransfer] = useState(false);

    const showUserBalance = (offering: Offering) => {
        if (offering.action === OfferingAction.Sell){
            if (offering.status === OfferingStatus.Active && offering.entityID !== entity.id)
                return true;

            if (offering.status === OfferingStatus.PendingSettlement  && offering.activeResponderEntityId === entity.id)
                return true;
        }

        if (offering.action === OfferingAction.Buy){
            if (offering.status === OfferingStatus.PendingSettlement && offering.entityID === entity.id)
                return true;
        }
        return false;
    }
    const showShareCountAndBalance = (offering: Offering) => {
        if (offering.action === OfferingAction.Buy){
            if (offering.status === OfferingStatus.Active && offering.entityID !== entity.id)
                return true;
        }

        return false;
    }
    useEffect(() => {
        setLoading(true);
        setOfferingModalOpen(true);
        if (offeringId > 0) {
            investmentOfferingStore.getOffering(entity.id, offeringId).then((r) => {
                if (r) {
                    r.action = getEnumValue(OfferingAction, r.action);
                    r.status = getEnumValue(OfferingStatus, r.status);
                    setOffering(r);
                    if (showUserBalance(r)) {
                        cryptoStore.getUserBalanceCached(entity.id).then((u) => {setUserBalance(u); setLoading(false);});
                    }
                    else if (showShareCountAndBalance(r)) {
                        investmentOfferingStore.getEntityShareCountAndBalance(entity.id, investment.id).then((s) => {
                            setEntityShareInfo({availableEntityShareCount : s.availableEntityShareCount, entityShareValue : s.entityShareValue});
                            setLoading(false);
                        });
                    }
                    else{
                        setLoading(false);
                    }
                }
            }).catch(err => {
                toast.error("There was an issue loading offering.", { theme: "colored" });
            })
        }
    }, [investment, offeringId, investmentOfferingStore, commonStore, cryptoStore, entity])

    const getOfferingWorkflowTitle = (offering: Offering) => {
        const offeringType = getEnumText(OfferingAction, offering.action);
        switch (offering.status) {
            case OfferingStatus.PendingApproval:
                if (offering.entityID === entity.id)
                    return `Approve / Reject ${offeringType} Offering`;
                else
                    return `View ${offeringType} Offering`;
            case OfferingStatus.PendingSponsorApproval:
                if (entity.id === investment.sponsorEntityId)
                    return `Sponsor Approve / Reject ${offeringType} Offering`;
                else
                    return `View ${offeringType} Offering`;
            case OfferingStatus.AwaitingConfirmation:
                return `Confirm  ${offeringType} Offering`;
            case OfferingStatus.PendingSettlement:
                    return `Settle  ${offeringType} Offering`;
            case OfferingStatus.Active:
                if (offering.entityID === entity.id)
                    return `View ${offeringType} Offering`;
                else
                    if (offering.action === OfferingAction.Sell)
                        return "Initiate Purchase of Shares";
                    else
                        return "Initiate Sale of Shares";
            default:
                return `View ${offeringType} Offering`;
        }
    }

    const hasNoBalance = () => {
        const balance = userBalance.balances.reduce((a, b) => a + b.value, 0);
        if (balance < Math.abs(offering.totalDueSeller)) {
            return true;
        }
        return false;
    }

    const hasNoShareQuantity = () => {
        return entityShareInfo.availableEntityShareCount < offering.shareCount
    }

    const processOfferingWorkflow = (offeringId: number, workflow: OfferingWorkflow) => {
        if (submitted === true) return;
        if (workflow === OfferingWorkflow.InitiatedBuyOffering && hasNoBalance()) return;
        if (workflow === OfferingWorkflow.InitiatedSellOffering && hasNoShareQuantity()) return;
        setSubmitted(true);
        const input = {
            entityId: entity.id,
            offeringId: offeringId,
            shareCount: offering.shareCount,
            sharePrice: offering.sharePrice,
            workflow: workflow
        };
        investmentOfferingStore.processOfferingWorkflow(input).then((r) => {
            let msg = '';
            switch (workflow) {
                case OfferingWorkflow.InitiatedBuyOffering:
                case OfferingWorkflow.InitiatedSellOffering:
                    msg = 'Sell offering initiated successfully';
                    break;
                case OfferingWorkflow.ApprovedByCreator:
                case OfferingWorkflow.ApprovedBySponsor:
                    msg = 'Offering Approved';
                    break;
                case OfferingWorkflow.RejectedByCreator:
                case OfferingWorkflow.RejectedBySponsor:
                    msg = 'Offering Rejected';
                    break;
                case OfferingWorkflow.Settled:
                    msg = 'Offering Settled';
                    break;
                default:
                    msg = 'Unknown Offering Workflow';
                    break;
            }
            toast.success(msg, { theme: "colored" });
            if (handleReloadOfferings) handleReloadOfferings();
            setOfferingModalOpen(false);
            setOfferingModalParentOpen(false);
        }).catch(err => {
            toast.error("There was an issue processing offer workflow.", { theme: "colored" });
            setSubmitted(false);
        });
    }

    const handleOfferingTransferClose = (refreshBalance: boolean) => {
        setShowTransfer(false);
        if (refreshBalance) {
            if (handleReloadOfferings) handleReloadOfferings();
            setOfferingModalOpen(false);
            setOfferingModalParentOpen(false);
        }
    }

    const getOfferingTransferModel = (): TransferModel => {
        var model = {
            senderCapblockTable: CapBlockTable.entity,
            senderTableKeyId: entity.id,
            transferType: TransferType.offerSellerPayment,
            sourceId: offering.id,
            amount: offering.amount,
            toCapblockTable: CapBlockTable.entity,
            toTableKeyId: offering.action === OfferingAction.Buy ? offering.activeResponderEntityId : offering.entityID,
            toName:  offering.action === OfferingAction.Buy ? offering.activeResponderEntityName : offering.sellerEntityName
        };
        return model;
    }

    const handleConfirmOfferingTransfer = () => {
        setShowTransfer(true);
    }

    return (
        <Modal
            open={offeringModalOpen} closeOnEscape={false} closeOnDimmerClick={false}
            onClose={() => { setOfferingModalOpen(false); setOfferingModalParentOpen(false); }} size="small">
            <Modal.Header>
                {getOfferingWorkflowTitle(offering)}
            </Modal.Header>
            <Modal.Content>
                <Table inverted>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell><b>Creator Name</b>: {offering.offeringEntityName}</Table.Cell>
                            <Table.Cell textAlign="right"><b>Status</b>: {offering.offeringStatusDesc}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                {offering.status === OfferingStatus.Active &&
                    <Table>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Offer Type: {getEnumText(OfferingAction, offering.action)}</Table.Cell>
                                <Table.Cell>Quantity: {offering.shareCount.toLocaleString()}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Expiry Date: {formatDate(offering.expiryDate)}</Table.Cell>
                                <Table.Cell>Share Price: {formatCurrency(offering.sharePrice)}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                }
                {offering.status !== OfferingStatus.Active &&
                    <Table>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell><b>Buyer</b>: {offering.buyerEntityName}</Table.Cell>
                                <Table.Cell><b>Seller</b>: {offering.sellerEntityName}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><b>Unit Price</b>: {formatCurrency(offering.sharePrice)}</Table.Cell>
                                <Table.Cell><b>Quantity</b>: {offering.shareCount}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                }
                {!loading && <SellEstimates offering={offering} investment={investment} isValid={() => true}/>}
                {/* buy validations - balance check */}
                {!loading && showUserBalance(offering) &&
                    <Label>Balance:  {formatCurrency(userBalance.balances.reduce((a, b) => a + b.value, 0))}</Label>
                }
                {!loading && showUserBalance(offering) && hasNoBalance() &&
                    <Label color="red">Insufficient Balance</Label>
                }
                {/* sell validations - share count check */}
                 {!loading && showShareCountAndBalance(offering) &&
                    <>
                    <Label>Available Share Quantity: {entityShareInfo.availableEntityShareCount}</Label>
                    <Label>Share Value: {formatCurrency(entityShareInfo.entityShareValue)}</Label>
                    </>
                }
                {!loading && showShareCountAndBalance(offering) && hasNoShareQuantity() &&
                    <Label color="red">Insufficient Share Quantity</Label>
                }
            </Modal.Content>
            <Modal.Actions style={{ display: 'flex', justifyContent: 'center' }}>
                {/* non-creator active SELL offering actions - buy */}
                {!loading && offering.status === OfferingStatus.Active && offering.entityID !== entity.id && offering.action === OfferingAction.Sell &&
                    <ConfirmButton id={offering.id} color='blue' iconName=''
                        content={'Initiate buy process of the selected offering?'}
                        value='Continue' confirmButtonText='Continue to Buy'
                        disabled={hasNoBalance() || submitted}
                        stopPropagation={true}
                        handleConfirm={() => processOfferingWorkflow(offering.id, OfferingWorkflow.InitiatedBuyOffering)}></ConfirmButton>
                }
                {/* non-creator active offering actions - continue sell */}
                {!loading && offering.status === OfferingStatus.Active && offering.entityID !== entity.id && offering.action === OfferingAction.Buy &&
                    <ConfirmButton id={offering.id} color='blue' iconName=''
                        content={'Initiate sell process of the selected offering?'}
                        value='Continue' confirmButtonText='Continue to Sell'
                        stopPropagation={true}
                        disabled={hasNoShareQuantity() || submitted}
                        handleConfirm={() => processOfferingWorkflow(offering.id, OfferingWorkflow.InitiatedSellOffering)}></ConfirmButton>
                }
                {/* creator pending approval offering actions - approve / reject */}
                {!loading && offering.status === OfferingStatus.PendingApproval && offering.entityID === entity.id &&
                    <>
                        <ConfirmButton id={offering.id} color='blue' iconName=''
                            content={'Approve initiated offering?'}
                            value='Approve' confirmButtonText='Approve'
                            stopPropagation={true}
                            disabled={submitted}
                            handleConfirm={() => processOfferingWorkflow(offering.id, OfferingWorkflow.ApprovedByCreator)}></ConfirmButton>

                        <ConfirmButton id={offering.id} color='red' iconName=''
                            content={'Reject initiated offering?'}
                            value='Reject' confirmButtonText='Reject'
                            disabled={submitted}
                            stopPropagation={true}
                            handleConfirm={() => processOfferingWorkflow(offering.id, OfferingWorkflow.RejectedByCreator)}></ConfirmButton>
                    </>
                }
                {/* sponsor pending approval offering actions - approve/reject */}
                {!loading && offering.status === OfferingStatus.PendingSponsorApproval && entity.id === investment.sponsorEntityId &&
                    <>
                        <ConfirmButton id={offering.id} color='blue' iconName=''
                            content={'Approve initiated offering?'}
                            value='Approve' confirmButtonText='Approve'
                            stopPropagation={true}
                            disabled={submitted}
                            handleConfirm={() => processOfferingWorkflow(offering.id, OfferingWorkflow.ApprovedBySponsor)}></ConfirmButton>

                        <ConfirmButton id={offering.id} color='red' iconName=''
                            content={'Reject initiated offering?'}
                            value='Reject' confirmButtonText='Reject'
                            disabled={submitted}
                            stopPropagation={true}
                            handleConfirm={() => processOfferingWorkflow(offering.id, OfferingWorkflow.RejectedBySponsor)}></ConfirmButton>
                    </>
                }
                {/* approved by creator and sponsor, ready to settle (as we not doing the blockchain for now) */}
                {!loading && offering.action === OfferingAction.Buy && offering.status === OfferingStatus.PendingSettlement && offering.entityID === entity.id &&
                    <>
                        <ConfirmButton id={offering.id} color='blue' iconName=''
                            content={'Settle the offering?'}
                            value='Settle Offering' confirmButtonText='Settle Offering'
                            disabled={hasNoBalance() || submitted }
                            stopPropagation={true}
                            handleConfirm={() => handleConfirmOfferingTransfer()}></ConfirmButton>
                    </>
                }
                {!loading && offering.action === OfferingAction.Sell && offering.status === OfferingStatus.PendingSettlement && offering.activeResponderEntityId === entity.id &&
                    <>
                        <ConfirmButton id={offering.id} color='blue' iconName=''
                            content={'Settle the offering?'}
                            disabled={hasNoBalance() || submitted}
                            value='Settle Offering' confirmButtonText='Settle Offering'
                            stopPropagation={true}
                            handleConfirm={() => handleConfirmOfferingTransfer()}></ConfirmButton>
                    </>
                }
                <Button content={offeringId > 0 ? "Close" : "Cancel"} onClick={(e) => { setOfferingModalOpen(false); setOfferingModalParentOpen(false); }} />

                {/* render transfer modal dialog */}
                {showTransfer && <TransferModal closeModal={handleOfferingTransferClose}
                    show={showTransfer}
                    hideReceive={true}
                    transferModel={getOfferingTransferModel()} />
                }
            </Modal.Actions>
        </Modal>
    )
};
export default OfferingViewModal;