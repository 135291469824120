import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Divider, Header, Icon } from 'semantic-ui-react'
import { useStore } from '../../app/stores/store';
import EntityContext from '../../app/context/entityContext';
import { Notification } from '../../app/models/Notifications/notifications';
import { useNavigate } from 'react-router-dom';
import { EntityFilterParams } from '../../app/models/common/entityFilterParams';
import Loading from '../../app/layout/Loading';
import { formatFullDate } from '../../shared/utils';

function NotificationSideBar() {
    const [loading, setLoading] = useState(false);
    const { notificationStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [notifications, setNotifications] = useState([new Notification()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const navigate = useNavigate();

    const initialState: EntityFilterParams = {
        entityId: entity.id,
        sortIndex: 'Name',
        sortOrder: 'descending',
        pageIndex: 0,
        pageSize: 10
    };

    const [params, setParams] = useState(initialState);

    useEffect(() => {
        notificationStore.getEntityNotifications(params).then(r => {
            setTotalRecords(r.totalRecords);
            setNotifications(prevNotifications =>
                params.pageIndex === 0 ? r.data : [...prevNotifications, ...r.data]
            );
            setLoading(false);
        });
    }, [notificationStore, params, setLoading]);

    const cardClick = (notification: Notification) => {
        navigate(notification.actionURL);
    }

    const showMore = () => {
        const paging = { ...params };
        paging.pageIndex++;
        setParams(paging);
    }

    const setAsRead = (id: number) => {
        notificationStore.updateNotificationStatus(entity.id, id).then(r => {
            setNotifications(previous => previous.filter(notification => notification.id !== id));
            setTotalRecords(totalRecords-1);
        });
    }

    if (loading) return <Loading content='Loading...' />

    return (
        <div className='task-card'>
            <Divider horizontal section>
                <Header as='h4'>
                    {totalRecords === 0 && "No"} Notifications
                </Header>
            </Divider>

            {!loading && totalRecords > 0 && notifications.map((notification: Notification, index) => (
                <Card key={index} color='blue' link onClick={() => cardClick(notification)}>
                    <Card.Content>
                        <Card.Description>
                            {notification.message}
                            <Icon name="close" style={{ float: "right" }} onClick={(e: any) => { e.stopPropagation(); setAsRead(notification.id); }} />
                        </Card.Description>
                        <Card.Meta>{formatFullDate(notification.created)}</Card.Meta>
                    </Card.Content>
                </Card>
            ))}

            {notifications.length < totalRecords && <Button content='Show More' basic color='blue' onClick={showMore} />}
        </div>
    )
}

export default NotificationSideBar