import { useAuth0 } from '@auth0/auth0-react'
import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Button, Card, Header, Icon, Segment } from 'semantic-ui-react'
import EntityContext from '../../app/context/entityContext'
import { Entity } from '../../app/models/registration/entity'
import { useStore } from '../../app/stores/store'
import { clearRedirectUrl, formatPhoneNumber, getRedirectUrl } from '../../shared/utils'
import Loading from '../../app/layout/Loading'

function EntitySelection() {
  const { entityStore } = useStore();
  const [entities, setEntities] = useState([new Entity()]);
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const { user } = useAuth0();
  const { setEntity } = useContext(EntityContext);
  const navigate = useNavigate();

  useEffect(() => {
    entityStore.getUserEntities().then(r => {
      setEntities(r);
      setIsLoading(false);
    });
  }, [entityStore, user]);

  const cardClick = (value: Entity) => {
    setEntity(value);
    var registerRedirectUrl = getRedirectUrl();
    if (registerRedirectUrl != null) {
      clearRedirectUrl();
      window.location.href = String(registerRedirectUrl);
      return;
    }
    navigate('/dashboard');
  }

  if (isLoading) return <Loading content='Loading entities...' />;
  else return (
    <Segment textAlign='center' padded>
      <Header as="h2">
        Select your entity
      </Header>
      <Card.Group style={{ textAlign: 'left', justifyContent: "center" }}>
        {!isLoading && (
          entities.map((entity) => (
            <Card key={entity.id} color='blue' link onClick={() => cardClick(entity)}>
              <Card.Content>
                <Card.Header>{entity.name}</Card.Header>
                <Card.Meta>{entity.addressLine1}{(entity.addressLine2 ? ', ' : '') + entity.addressLine2 === null ? '' : entity.addressLine2}</Card.Meta>
                <Card.Meta>{entity.city === null ? '' : entity.city + ','} {entity.stateCode} {entity.zip}</Card.Meta>
                {entity.cellPhone !== null &&
                  <>
                    <Card.Description>Primary Contact</Card.Description>
                    <Card.Description>{formatPhoneNumber(entity.cellPhone)}</Card.Description>
                  </>
                }
              </Card.Content>
            </Card>
          ))
        )}

        <Card color='blue' as={NavLink} to='/entity' state={{ previousPath: pathname }} className='addEntityCard'>
          <Card.Content textAlign='center' className='addEntity'>
            <Icon name='plus circle' size='huge' />
          </Card.Content>
        </Card>
      </Card.Group>

      <Header>
        <Button as={NavLink} to='/entities' primary>Manage entities</Button>
      </Header>
    </Segment>
  )
}

export default EntitySelection
