import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Divider, Dropdown, Form, Grid, Header, Icon, Message, Segment, TabPane } from 'semantic-ui-react'
import TextInput from '../../../app/common/form/TextInput';
import SelectInput from '../../../app/common/form/SelectInput';
import { Investment, ParticipationType } from '../../../app/models/Investment/Investment';
import { booleanOptions, getFloatValue, getFullSizeWidth } from '../../../shared/utils';
import CurrencyInput from '../../../app/common/form/CurrencyInput';
import { useStore } from '../../../app/stores/store';
import { useMediaQuery } from 'react-responsive';
import EntityContext from '../../../app/context/entityContext';
import FinancialGraph from './Graph/FinancialGraph';
import DateInput from '../../../app/common/form/DateInput';
import { InputMasks } from '../../../shared/InputMasks';
import * as yup from 'yup';
import { MonthlyInvestmentFinancialFormValues } from '../../../app/models/Investment/MonthlyInvestmentFinancial';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { HistoricFinancialInfo } from '../../../app/models/Investment/HistoricFinancialInfo';
import { CalculateMonthlyFinancial } from '../../../app/models/Investment/CalculateMonthlyFinancial';
import { useDebounce } from '../../../shared/useDebounce';
import { addMonths } from "date-fns"

interface Props {
    investment: Investment;
}

function Financial({ investment }: Props) {
    const [historicDates, setHistoricDates] = useState(investment.historicFinancialInfo.map(s => ({ key: s.id, value: s.id, text: s.asofDate })));
    const [graphData, setGraphData] = useState([...investment.historicFinancialInfo]);

    const emptyOption = { key: '' };
    const { commonStore, investmentStore } = useStore();
    const [interestTypeOptions, setInterestTypeOptions] = useState([emptyOption]);

    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const { entity } = useContext(EntityContext);
    const [loading, setLoading] = useState(false);
    const [loadingCalculation, setLoadingCalculation] = useState(false);

    const [newFinancial, setNewFinancial] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [monthlyFinancial, setMonthlyFinancial] = useState(investment.filteredFinancialInfo);
    const [calculateValuation, setCalculateValuation] = useState<number>(0);
    const debounceCalculateValuation = useDebounce<number>(calculateValuation, 500);
    const formikRef = useRef<any>();
    const [valuesChanged, setValuesChanged] = useState(false);

    useEffect(() => {
        commonStore.getInvestmentEnums().then(r => {
            setInterestTypeOptions(r.interestType);
        })
    }, [commonStore, setInterestTypeOptions]);

    useEffect(() => {
        const formikContext = formikRef.current;
        const values = formikContext.values;

        if (getFloatValue(values.valuation) === 0 || getFloatValue(values.noi) === 0) {
            formikContext.setFieldValue("capRate", 0);
            formikContext.setFieldValue("shareValue", 0);
        }

        if (debounceCalculateValuation > 1 && valuesChanged && values.valuation !== '' && values.totalLoan !== '' && values.noi !== '') {

            setLoadingCalculation(true);

            const calcInput: CalculateMonthlyFinancial = {
                investmentId: values.investmentId,
                valuation: values.valuation,
                totalLoan: values.totalLoan,
                noi: values.noi,
            };
            investmentStore.calculateMonthlyFinancial(calcInput).then(r => {
                formikContext.setFieldValue("capRate", r.capRate);
                formikContext.setFieldValue("shareValue", r.shareValue);
            })
                .finally(() => setLoadingCalculation(false));
        }
    }, [debounceCalculateValuation, investmentStore, valuesChanged]);

    const runValuation = (originalValue: number, newValue: number) => {
        if (originalValue !== newValue) {
            setValuesChanged(true);
        }
        setCalculateValuation(calculateValuation + 1);
    };

    const getHistoricFinancialInfo = (id: number) => {
        setLoading(true);
        investmentStore.getMonthlyInvestmentFinancial(entity.id, investment.id, id).then((r) => {
            restrictGraphData(id);
            setMonthlyFinancial(new Investment(r).filteredFinancialInfo);
        })
            .finally(() => setLoading(false));
    }

    function restrictGraphData(id: number) {
        const value = [...investment.historicFinancialInfo];
        setGraphData(value.splice(value.findIndex((item) => item.id === id)));
    }

    const validationSchema = yup.object({
        asofDate: yup
            .date()
            .typeError("Please enter a valid date")
            .required("As of Date is required"),
        valuation: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Valuation is required')
            .positive('Valuation must be greater than 0'),
        occupancy: yup
            .number()
            .required('Occupancy is required')
            .positive('Percentage must be greater than 0')
            .max(1000, 'Number must be lower than 1000'),
        netIncome: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Net Income is required'),
        noi: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('NOI is required')
            .positive('NOI must be greater than 0'),
        lender: yup
            .string()
            .required('Lender is required'),
        term: yup
            .number()
            .required('Term is required')
            .positive('Term must be greater than 0'),
        ownedSince: yup
            .date()
            .typeError("Please enter a valid date")
            .required("Owned Since Date is required"),
        interestRate: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Interest Rate is required')
            .positive('Interest Rate must be greater than 0')
            .max(1000, 'Number must be lower than 1000'),
        interestType: yup
            .number()
            .min(1, 'Interest Type is required'),
        loanToValue: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Loan to Value is required')
            .positive('Loan to Value must be greater than 0')
            .max(1000, 'Number must be lower than 1000'),
        interestOnly: yup
            .number()
            .min(0, 'Interest Only is required'),
        interestYears: yup
            .number()
            .required('Years is required')
            .positive('Years must be greater than 0'),
        debtCoverage: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Debt Coverage is required')
            .positive('Debt Coverage must be greater than 0'),
        year: yup
            .number()
            .required('Year is required')
            .min(1900, 'Year must be higher than 1900')
            .max(3000, 'Year must be lower than 1900'),
        annualDebtService: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Annual Debt Service is required')
            .positive('Annual Debt Service must be greater than 0'),
        totalLoan: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Total Loan is required'),
    });

    const cancel = () => {
        setValuesChanged(false);
        setCalculateValuation(0);
        setNewFinancial(false);
        setEditMode(false);
    }

    const reset = (resetForm: any) => {
        cancel();
        resetForm();
    }

    const newMonthlyFinancial = () => {
        setCalculateValuation(0);
        const test = { ...investment.currentFinancialInfo };
        Object.entries(test).map(([key, value]) => (
            formikRef.current.setFieldValue(key, value)
        ))
        formikRef.current.setFieldValue("asofDate", "");
        setNewFinancial(true);
        setEditMode(true);
    }

    const updateMonthlyFinancial = () => {
        setEditMode(true);
    }

    const handleFormSubmit = async (values: any, setFieldError: any, setSubmitting: any) => {
        values.asofDate = new Date(values.asofDate);
        values.ownedSince = new Date(values.ownedSince);
        values.month = values.asofDate.getMonth() + 1;
        values.year = values.asofDate.getFullYear();
        const financial = new MonthlyInvestmentFinancialFormValues(values);

        if (newFinancial) {
            financial.id = 0;
        }

        investmentStore.saveMonthlyFinancial(investment.id, financial)
            .then(response => {
                if (response !== null) {
                    toast.success("Success!", { theme: "colored" });

                    const previousFinancialId = financial.id;
                    financial.id = response;
                    const info: HistoricFinancialInfo = {
                        asofDate: financial.formattedAsofDate,
                        id: response,
                        valuation: financial.valuation,
                        totalLoan: financial.totalLoan,
                        shareValue: financial.shareValue,
                        equity: financial.valuation - financial.totalLoan,
                        year: financial.year,
                        month: financial.month
                    }

                    if (newFinancial) {
                        //Add new entry to the as of dropdown
                        const updatedHistoricDates = [{ key: response, value: response, text: financial.formattedAsofDate }, ...historicDates];
                        setHistoricDates(updatedHistoricDates);

                        //Set added financial as the current and filtered record
                        investment.filteredFinancialInfo = financial;
                        investment.currentFinancialInfo = financial;

                        //Add new financial to the top of the array for the graph
                        investment.historicFinancialInfo.unshift(info);
                        setGraphData([...investment.historicFinancialInfo]);
                    }
                    else {
                        //Find entry in array and update with the new financial entry
                        const historicDatesCopy = historicDates;
                        const targetIndex = historicDates.findIndex(item => item.key === previousFinancialId);
                        if (targetIndex !== -1) {
                            historicDatesCopy[targetIndex] = { key: response, value: response, text: financial.formattedAsofDate };
                        }

                        //Find entry in graph array and update with the new financial entry
                        const index = investment.historicFinancialInfo.findIndex(item => item.id === previousFinancialId);
                        if (index !== -1) {
                            investment.historicFinancialInfo[index] = info;
                            restrictGraphData(financial.id);
                        }
                    }

                    setMonthlyFinancial(financial);

                    cancel();
                }
            })
            .catch(err => {
                toast.error("There was an issue saving the monthly financial info.", { theme: "colored" });
            })
            .finally(() => setSubmitting(false));
    }

    const onAnnualizedChange = (value: number, setFieldValue: any, fieldName: string) => {
        let amount = 0;
        if (value !== undefined) {
            amount = value * 12;
        }
        setFieldValue(fieldName, amount);
    };

    return (
        <TabPane loading={loading}>
            <Formik
                enableReinitialize
                initialValues={monthlyFinancial}
                validationSchema={validationSchema}
                onSubmit={(values, { setFieldError, setSubmitting }) => {
                    handleFormSubmit(values, setFieldError, setSubmitting);
                }}
                innerRef={formikRef}
            >
                {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => (
                    <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>

                        {!editMode ?
                            <FinancialGraph data={graphData} />
                            :
                            <Message info header={(newFinancial ? "Add New" : "Update") + " Financial"} />
                        }

                        <Divider horizontal section>
                            <Header as='h4'>
                                Monthly Financials
                            </Header>
                        </Divider>

                        {!editMode &&
                            <Grid columns={3} >
                                <Grid.Row>
                                    <Grid.Column>
                                        {investment.entityParticipation === ParticipationType.sponsor &&
                                            <Button primary onClick={updateMonthlyFinancial} type='button' style={{ marginBottom: "15px" }}>Update current</Button>
                                        }
                                    </Grid.Column>
                                    <Grid.Column textAlign='center'>
                                        As Of: {' '}
                                        <Dropdown compact selection
                                            options={historicDates} defaultValue={monthlyFinancial.id}
                                            onChange={(e, d) => {
                                                getHistoricFinancialInfo(Number(d.value));
                                            }}
                                        />
                                    </Grid.Column>
                                    <Grid.Column textAlign='right'>
                                        {investment.entityParticipation === ParticipationType.sponsor &&
                                            <Button primary onClick={newMonthlyFinancial} type='button'>Add new</Button>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        }

                        <Form.Group widths='equal'>
                            <TextInput placeholder='Occ. %' name='occupancy' maxLength={6} icon={<Icon name='percent' />} readOnly={!editMode} showRequired={editMode} />

                            <CurrencyInput placeholder='Net Income' name='netIncome' maxLength={13} thousandSeparator=',' decimalScale={2} readOnly={!editMode} prefix={editMode ? '' : '$'} allowNegative={false} showRequired={editMode} onChange={(e) => onAnnualizedChange(e, setFieldValue, "annualizedNetIncome")} />
                            <CurrencyInput placeholder='NOI' name='noi' maxLength={13} thousandSeparator=',' decimalScale={2} readOnly={!editMode} allowNegative={false} prefix={editMode ? '' : '$'} showRequired={editMode} onChange={(e) => { onAnnualizedChange(e, setFieldValue, "annualizedNOI"); runValuation(values.noi, e); }} />
                        </Form.Group>

                        <Form.Group widths='equal'>
                            {showFullSize && <Form.Field />}
                            <CurrencyInput placeholder='Annualized Net Income' name='annualizedNetIncome' maxLength={16} thousandSeparator=',' decimalScale={2} readOnly prefix={'$'} className={editMode ? "readOnlyField" : ""} />
                            <CurrencyInput placeholder='Annualized NOI' name='annualizedNOI' maxLength={16} thousandSeparator=',' decimalScale={2} readOnly prefix={'$'} className={editMode ? "readOnlyField" : ""} />
                        </Form.Group>

                        <Form.Group widths='equal'>
                            {editMode &&
                                <DateInput placeholder='As of Date' name='asofDate' mask={InputMasks.monthYear} dateFormat='MM/yyyy' showRequired showMonthYearPicker startDate={newFinancial ? addMonths(new Date(investment.currentFinancialInfo.asofDate), 1) : values.asofDate} />
                            }

                            <CurrencyInput placeholder='Latest Valuation' name='valuation' maxLength={16} thousandSeparator=',' decimalScale={2} readOnly={!editMode} allowNegative={false} prefix={editMode ? '' : '$'} showRequired={editMode} onChange={(e) => runValuation(values.valuation, e)} />
                            <TextInput placeholder='Cap Rate' name='capRate' maxLength={6} icon={<Icon name='percent' />} readOnly showRequired={editMode} className={editMode ? "readOnlyField" : ""} />
                            <CurrencyInput placeholder='Share Value' name='shareValue' maxLength={16} thousandSeparator=',' decimalScale={2} readOnly allowNegative prefix='$' showRequired={editMode} className={editMode ? "readOnlyField" : ""} />
                            {loadingCalculation && <Segment basic loading />}
                        </Form.Group>

                        <Divider horizontal section>
                            <Header as='h4'>
                                Loan Information
                            </Header>
                        </Divider>
                        <Form.Group widths='equal'>
                            <TextInput placeholder='Lender' name='lender' maxLength={255} readOnly={!editMode} showRequired={editMode} />
                            <TextInput placeholder='Term' name='term' maxLength={9} readOnly={!editMode} showRequired={editMode} />
                            <DateInput placeholder='Owned Since' name='ownedSince' mask={InputMasks.date} readOnly={!editMode} showRequired={editMode} />
                            <TextInput placeholder='Interest Rate' name='interestRate' maxLength={3} icon={<Icon name='percent' />} readOnly={!editMode} showRequired={editMode} />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <SelectInput options={interestTypeOptions} placeholder='Fixed/Variable' name='interestType' disabled={!editMode} showRequired={editMode} />
                            <TextInput placeholder='Loan to Value' name='loanToValue' maxLength={6} icon={<Icon name='percent' />} readOnly={!editMode} showRequired={editMode} />
                            <SelectInput options={booleanOptions} placeholder='Interest Only' name='interestOnly' disabled={!editMode} showRequired={editMode} />
                            <TextInput placeholder='Debt Coverage Ratio' name='debtCoverage' maxLength={6} readOnly={!editMode} showRequired={editMode} />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            {!!values.interestOnly && <TextInput placeholder='Years' name='interestYears' maxLength={4} readOnly={!editMode} showRequired={editMode} />}
                            <CurrencyInput placeholder='Annual Debt Service' name='annualDebtService' maxLength={16} thousandSeparator=',' decimalScale={2} readOnly={!editMode} prefix={editMode ? '' : '$'} showRequired={editMode} />
                            <CurrencyInput placeholder='Total Loan Balance' name='totalLoan' maxLength={16} thousandSeparator=',' decimalScale={2} readOnly={!editMode} prefix={editMode ? '' : '$'} showRequired={editMode} onChange={(e) => runValuation(values.totalLoan, e)} />
                        </Form.Group>

                        {editMode &&
                            <Segment clearing basic>
                                <Button primary floated='right' type='submit' disabled={isSubmitting} loading={isSubmitting}>Save</Button>
                                <Button onClick={() => reset(resetForm)} type="button" floated='right'>Cancel</Button>
                            </Segment>
                        }
                    </Form>
                )}
            </Formik>
        </TabPane>
    )
}

export default observer(Financial)