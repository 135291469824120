import { useField } from "formik";
import React from "react";
import { Checkbox, Form, Label } from "semantic-ui-react";
import FieldTitle from "./FieldTitle";

interface Props {
    placeholder: string;
    name: string;
    toggle?: boolean;
    readOnly?: boolean;
    hideTitle?: boolean;
    showRequired?: boolean;
    defaultChecked?: boolean;
    onChange?: (e:any, d:any) => void;
}

function CheckboxInput(props: Props) {
    const [field, meta, helpers] = useField(props.name!);
    const {hideTitle, showRequired, onChange, ...rest} = props;
    return (
        <Form.Field error={meta.touched && !!meta.error}>
            {!hideTitle && <FieldTitle name={props.name} placeholder={props.placeholder} showRequired={props.showRequired} />}
            <Checkbox id={props.name} {...field} {...rest}
            value={1}
                onChange={(e, d) => {
                    helpers.setValue(d.value);
                    if (props.onChange)
                    {
                        props.onChange(e, d);
                    }
                }}
            />
            {meta.touched && meta.error ? (
                <Label basic color='red' pointing='above'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    )
}

export default CheckboxInput;