import { Header, Placeholder, PlaceholderLine, PlaceholderParagraph, Table, TableBody, TableCell, TableRow } from "semantic-ui-react";
import { getVisualizationSignIcon } from "../../shared/utils";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { KeyMetric } from "../../app/models/Investment/KeyMetric";

interface Params {
    investmentId: number
}

function ProjectedReturnsPanelComponent({ investmentId }: Params) {

    const [loading, setLoading] = useState(true);
    const { investmentInsightsStore } = useStore();
    const [keyMetrics, setKeyMetrics] = useState([new KeyMetric()])

    useEffect(() => {
        setLoading(true);
        investmentInsightsStore.getProjectedReturns(investmentId)
            .then(r => setKeyMetrics(r))
            .finally(() => setLoading(false))
    }, [investmentId, investmentInsightsStore])

    return (
        <>
            {loading &&
                <Placeholder>
                    <PlaceholderParagraph>
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </PlaceholderParagraph>
                </Placeholder>
            }
            {!loading &&
                <>
                    {keyMetrics.length > 0 ?
                        <Table className='investmentMarketingPanel' striped color='green'>
                            <TableBody>
                                {keyMetrics.map((k, i) =>
                                (
                                    <TableRow className='insightsTableText'>
                                        <TableCell><Header as='h4'>{k.returnTypeDescription}</Header></TableCell>
                                        <TableCell>
                                            <div>
                                                {k.low}-{k.high} {getVisualizationSignIcon(k.visualizationSign)}&nbsp;
                                                ({k.target} {getVisualizationSignIcon(k.visualizationSign)} target)
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                        :
                        <div>No metrics are available for this investment</div>
                    }
                </>
            }
        </>
    )
}
export default ProjectedReturnsPanelComponent