export interface Notification {
    id: number;
    entityId: number;
    notificationType: number;
    notificationDescription: number;
    status: number;
    statusDescription: string;
    message: string;
    actionURL: string;
    isDeleted: boolean;
    created: Date;
}

export class Notification implements Notification {
  constructor(init?: Notification) {
    Object.assign(this, init);
  }
}