import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { CommonRegularExpressions } from '../../shared/CommonRegularExpressions';
import { observer } from 'mobx-react-lite';
import { Button, Card, Divider, Form, Header, Message, Segment } from 'semantic-ui-react';
import TextInput from '../../app/common/form/TextInput';
import { Entity, EntityFormValues } from '../../app/models/registration/entity';
import SelectInput from '../../app/common/form/SelectInput';
import PhotoUploadWidget from '../../app/common/imageUpload/photoUploadWidget';
import { useStore } from '../../app/stores/store';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CacheNameEnum, sleep } from '../../shared/utils';
import { InputMasks } from '../../shared/InputMasks';

interface Props {
    entity: EntityFormValues;
    navigateTo?: string;
    backEvent?: (entity: EntityFormValues) => void;
    submitEvent?: (entity: EntityFormValues) => void;
    disableButtons?: boolean;
}

function EntityForm({ entity, navigateTo, backEvent, submitEvent, disableButtons }: Props) {

    const commonRegex = CommonRegularExpressions;
    const { entityStore, commonStore } = useStore();
    const navigate = useNavigate();
    const { state }: { state: any } = useLocation();

    const [individualFields, setIndividualFields] = useState(true);
    const [currentEntity, setCurrentEntity] = useState<EntityFormValues>(new EntityFormValues());
    const [values, setValues] = useState({});
    const [loading, setLoading] = useState(true);

    const emptyOption = { key: '' };
    const [stateOptions, setStateOptions] = useState([emptyOption]);
    const [countryOptions, setCountryOptions] = useState([emptyOption]);
    const [residencyStatus, setResidencyStatus] = useState([emptyOption]);
    const { id } = useParams();
    const defaultNavigateTo = state ? state.previousPath : '/entities';

    useEffect(() => {

        commonStore.getStates().then(r => setStateOptions(r));
        commonStore.getCountries().then(r => setCountryOptions(r));
        commonStore.getResidencyStatus().then(r => setResidencyStatus(r));

        if (id) {
            entityStore.getEntity(Number(id)).then((e) => {
                let entity = new EntityFormValues();
                if (e.entityType === 0) {
                    entity.id = e.id;
                    entity.name = e.name;
                }
                else {
                    entity = e;
                }
                setIndividualFields(entity.entityType === 1);
                setCurrentEntity(entity);
                setLoading(false);
            });
        }
        else {
            if (entity?.individual) {
                setIndividualFields(entity.entityType === undefined || entity.entityType === 1);
                setCurrentEntity(new EntityFormValues(entity));
            }
            setLoading(false);
        }
    }, [entity, setCurrentEntity, commonStore, entityStore, id])

    const validationSchema = yup.object({
        name: yup
            .string()
            .required('Name is required')
            .matches(commonRegex.entityName, 'Special characters not allowed'),
        addressLine1: yup
            .string()
            .required('Address Line 1 is required'),
        city: yup
            .string()
            .required('City is required'),
        stateCode: yup
            .string()
            .required('State is required'),
        zip: yup
            .string()
            .required('Zip code is required')
            .matches(commonRegex.zip, 'Invalid zip code'),
        cellPhone: yup
            .string()
            .matches(commonRegex.phoneNumber, 'Invalid Phone Number'),
        workPhone: yup
            .string()
            .matches(commonRegex.phoneNumber, 'Invalid Phone Number'),
        individual: yup.object().when('isIndividual', {
            is: () => individualFields,
            then: yup.object({
                employerZip: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === '' ? null : v))
                    .matches(commonRegex.zip, 'Invalid zip code'),
                residencyStatus: yup
                    .number()
                    .min(1, "Residency Status is required"),
                countryOfCitizenship: yup
                    .number()
                    .min(1, "Country Of Citizenship is required"),
                ssn: yup
                    .string()
                    .required("SSN is required")
                    .matches(commonRegex.ssn, 'Invalid ssn'),
                dob: yup
                    .date()
                    .typeError("Please enter a valid date")
                    .required(),
                dlNumber: yup
                    .string()
                    .required("DL Number is required"),
                dlStateCode: yup
                    .string()
                    .required("DL State Code is required"),
            })
        }),
        company: yup.object().when('iscompany', {
            is: () => !individualFields,
            then: yup.object({
                ein: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === '' ? null : v))
                    .matches(commonRegex.ein, 'Invalid EIN')
            })
        }),
    });

    const handleFormSubmit = async (entityValues: EntityFormValues, setFieldError: any, setSubmitting: any) => {
        if (individualFields) {
            document.getElementById("confirmImage")?.click();
            await sleep(250);
            entityValues.entityType = 1;
            entityValues.company = undefined;
        }
        else {
            entityValues.entityType = 2;
            entityValues.individual = undefined;
        }

        if (submitEvent) {
            submitEvent(entityValues);
        }
        else {
            entityStore.createEntity(new Entity(entityValues))
                .then(response => {
                    sessionStorage.removeItem(CacheNameEnum.UserEntities);
                    if (response !== null) {
                        toast.success("Success!", { theme: "colored" });
                        navigate(navigateTo ?? defaultNavigateTo);
                    }
                    else {
                        setSubmitting(false);
                    }
                })
                .catch(err => {
                    toast.error("There was an issue saving the entity.", { theme: "colored" });
                    setSubmitting(false);
                });
        }
    }

    const handlePhotoUpload = (file: Blob) => {
        const data = { ...entity, ...values };
        data.individual!.dlFile = file;
        setCurrentEntity(data);
    }

    const showIndividualFields = (individualFields: boolean) => {
        setIndividualFields(individualFields);
    }

    const handleBack = () => {
        if (individualFields) {
            document.getElementById("confirmImage")?.click();
        }
        const data = { ...entity, ...values };
        data.entityType = individualFields ? 1 : 2;

        if (backEvent) {
            backEvent(data);
        }
        else {
            navigate(navigateTo ?? defaultNavigateTo);
        }
    }

    return (
        <Card.Content>
            <Segment clearing>
                <Formik
                    validationSchema={validationSchema}
                    enableReinitialize
                    initialValues={currentEntity}
                    onSubmit={(values, { setFieldError, setSubmitting }) => {
                        return handleFormSubmit(values, setFieldError, setSubmitting);
                    }}
                    innerRef={(formikActions) => (formikActions ? setValues(formikActions.values) : setValues({}))} >
                    {({ handleSubmit, isSubmitting, isValid }) => (
                        <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                            {!loading &&
                                <>
                                    <Form.Group style={{ 'display': 'flex', 'justifyContent': 'center' }}>
                                        {currentEntity.individual!.id > 0 || currentEntity.company!.id > 0 ?
                                            <Button type='button' positive size='big'>{individualFields ? "Individual" : "Company"}</Button>
                                            :
                                            <Button.Group>
                                                <Button type='button' onClick={() => showIndividualFields(true)} primary={individualFields} size='big'>Individual</Button>
                                                <Button.Or />
                                                <Button type='button' onClick={() => showIndividualFields(false)} primary={!individualFields} size='big'>Company</Button>
                                            </Button.Group>
                                        }
                                    </Form.Group>

                                    <Form.Group widths='equal'>
                                        <TextInput placeholder='Name' name='name' maxLength={512} showRequired />
                                        <TextInput placeholder='Address Line 1' name='addressLine1' maxLength={128} showRequired />
                                        <TextInput placeholder='Address Line 2' name='addressLine2' maxLength={128} />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <TextInput placeholder='City' name='city' maxLength={50} showRequired />
                                        <SelectInput options={stateOptions} placeholder='State' name='stateCode' showRequired />
                                        <TextInput placeholder='Zip' name='zip' mask={InputMasks.zip} showRequired />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <TextInput placeholder='Cell Phone' name='cellPhone' mask={InputMasks.phoneNumber} />
                                        <TextInput placeholder='Work Phone' name='workPhone' mask={InputMasks.phoneNumber} />
                                    </Form.Group>
                                    {individualFields && (
                                        <>
                                            <Divider horizontal section>
                                                <Header as='h4'>
                                                    Employer
                                                </Header>
                                            </Divider>
                                            <Form.Group widths='equal'>
                                                <TextInput placeholder='Name' name='individual.employer' maxLength={100} />
                                                <TextInput placeholder='Address Line 1' name='individual.employerAddressLine1' maxLength={128} />
                                                <TextInput placeholder='Address Line 2' name='individual.employerAddressLine2' maxLength={128} />
                                            </Form.Group>
                                            <Form.Group widths='equal'>
                                                <TextInput placeholder='City' name='individual.employerCity' maxLength={50} />
                                                <SelectInput options={stateOptions} placeholder='State' name='individual.employerStateCode' />
                                                <TextInput placeholder='Zip' name='individual.employerZip' mask={InputMasks.zip} />
                                                <TextInput placeholder='Occupation' name='individual.occupation' maxLength={100} />
                                            </Form.Group>
                                            <Divider horizontal section>
                                                <Header as='h4'>
                                                    Identity Confirmation
                                                </Header>
                                            </Divider>
                                            <Message info icon='info circle' header='We need to confirm your identity' content='Please provide the following information' />
                                            <Form.Group widths='equal'>
                                                <SelectInput options={residencyStatus} placeholder='Residency Status' name='individual.residencyStatus' showRequired />
                                                <SelectInput options={countryOptions} placeholder='Country Of Citizenship' name='individual.countryOfCitizenship' showRequired />
                                                <TextInput placeholder='SSN' name='individual.ssn' mask={InputMasks.ssn} showRequired />
                                                <TextInput placeholder='DOB' name='individual.dob' mask={InputMasks.date} showRequired />
                                            </Form.Group>
                                            <Form.Group widths='equal'>
                                                <TextInput placeholder='DL Number' name='individual.dlNumber' maxLength={50} showRequired />
                                                <SelectInput options={stateOptions} placeholder='DL State' name='individual.dlStateCode' showRequired />
                                            </Form.Group>
                                            {currentEntity.individual!.id === 0 && (
                                                <PhotoUploadWidget uploadPhoto={handlePhotoUpload} loading={false} currentImage={currentEntity.individual!.dlFile} />
                                            )}

                                        </>
                                    )}
                                    {!individualFields && (
                                        <Form.Group widths='equal'>
                                            <TextInput placeholder='EIN' name='company.ein' mask={InputMasks.ein} />
                                            <TextInput placeholder='Description' name='company.description' maxLength={512} />
                                        </Form.Group>
                                    )}
                                    <Button
                                        disabled={!isValid || isSubmitting || disableButtons}
                                        loading={isSubmitting || disableButtons}
                                        floated='right'
                                        primary
                                        type='submit'
                                        content='Save'
                                    />
                                    <Button onClick={handleBack} floated='right' type='button' content='Back' />
                                </>
                            }
                        </Form>
                    )}
                </Formik>
            </Segment>
        </Card.Content>
    );
}

export default observer(EntityForm)