import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Container, Grid, GridColumn, Header, Icon, Segment } from 'semantic-ui-react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useStore } from '../../../app/stores/store';
import { Document } from '../../../app/models/common/Document';
import { Link } from 'react-router-dom';

interface Params {
    headerText: string;
    entityId: number;
    investmentId: number;
    enableInvestmentLink: boolean;
}
function InvestmentImages({ headerText, entityId, investmentId, enableInvestmentLink }: Params) {

    const { investmentStore } = useStore();
    const [documents, setDocuments] = useState([new Document()])
    useEffect(() => {
        // We might want to generalize  this component better.
        // Not using entityId for this call, but keeping the interface for the investmentdetails.tsx .
        // Prevously, the component just displayed static images so it didn't matter.
        investmentStore.getInvestmentAllImages(investmentId)
            .then((r) => {
                setDocuments(r.data);
            })
    }, [entityId, investmentId, investmentStore])

    return (
            <Container className='investmentMarketingPanel'>
                {headerText.length > 0 &&
                    <Header as='h3'>
                        {headerText}
                    </Header>
                }
                <Grid>
                    <GridColumn width={2}></GridColumn>
                    <GridColumn width={12}>
                        {documents.length > 0 ?
                            <Carousel showArrows autoPlay width='100%' infiniteLoop useKeyboardArrows swipeable >
                                {documents.map((document, i) => (
                                    <div key={i}>
                                        <img src={document.shortLivedBlobUrl} alt='' />
                                        {enableInvestmentLink
                                            ?

                                            <Link className="legend" to={`/investor/investment-insights/${investmentId}`} >
                                                {document.info}
                                            </Link>
                                            :
                                            <p className="legend">{document.info}</p>

                                        }

                                    </div>
                                ))}
                            </Carousel>
                            :
                            <Segment placeholder>
                                <Header icon>
                                    <Icon name='images outline' size='massive' />
                                    No images are available for this investment.
                                </Header>
                            </Segment>
                        }
                    </GridColumn>
                    <GridColumn width={2}></GridColumn>
                </Grid>

            </Container>
    )
}

export default InvestmentImages