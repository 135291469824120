import React, { useEffect, useState } from 'react'
import { Grid, Header, Icon, Segment, Table } from 'semantic-ui-react'
import EmptyGridMessage from '../../../shared/EmptyGridMessage'
import GridFooter from '../../../shared/GridFooter'
import { useStore } from '../../../app/stores/store';
import { useMediaQuery } from 'react-responsive';
import { createGridInitialState, getFullSizeWidth, handleGridNavigation, handleGridSort } from '../../../shared/utils';
import { PageParams } from '../../../app/models/common/PagedResult';
import { ShareClassFilter } from '../../../app/models/common/ShareClassFilter';
import { ShareClassification } from '../../../app/models/Investment/ShareClassification';

interface Props {
    entityId: number;
    investmentId: number;
    shareClass: string;
}

function ShareClassCapTableGrid({ entityId, investmentId, shareClass }: Props) {
    const [loading, setLoading] = useState(true);
    const { investmentStore } = useStore();
    const [capTable, setCapTable] = useState([new ShareClassification()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` });

    const initialState: ShareClassFilter = createGridInitialState({
        entityId: entityId,
        investmentId: Number(investmentId),
        shareClass: shareClass,
        sortIndex: 'ShareHolderName',
        sortOrder: 'ascending'
    });
    const [params, setParams] = useState(initialState);

    useEffect(() => {
        setLoading(true);
        params.shareClass = shareClass;
        investmentStore.getShareClassCapTable(params).then(r => {
            setTotalRecords(r.totalRecords);
            setCapTable(r.data);
            setLoading(false);
        });
    }, [investmentStore, params, shareClass]);


    const handleSort = (clickedColumn: string) => {
        if (capTable.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    return (
        <Segment clearing loading={loading}>
            <Header>Share Class: {shareClass} - Capitalization Table</Header>
            <Table singleLine sortable selectable>
                {showFullSize &&
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={() => handleSort("ShareHolderName")} sorted={params.sortIndex === "ShareHolderName" && capTable.length > 0 ? params.sortOrder : undefined}>Share Holder</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("ShareCount")} sorted={params.sortIndex === "ShareCount" && capTable.length > 0 ? params.sortOrder : undefined}>Shares</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("PercentageOwnership")} sorted={params.sortIndex === "PercentageOwnership" ? params.sortOrder : undefined}>% of ownership</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                }

                <Table.Body>
                    {!loading && capTable.map((cap, i) => {
                        if (showFullSize) {
                            return <Table.Row key={i}>
                                <Table.Cell textAlign='left'>{cap.shareHolderName}</Table.Cell>
                                <Table.Cell textAlign='right'>{cap.shareCount}</Table.Cell>
                                <Table.Cell textAlign='right'>{cap.percentageOwnership}</Table.Cell>
                            </Table.Row>
                        }
                        else {
                            return <Table.Row key={i}>
                                <Table.Cell>
                                    {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={3}>
                                                <label onClick={() => handleSort("ShareHolderName")}>Class:</label><br />
                                                <label onClick={() => handleSort("ShareCount")}>Initial Value / Share:</label><br />
                                                <label onClick={() => handleSort("PercentageOwnership")}>Current Value / Share:</label><br />
                                            </Grid.Column>
                                            <Grid.Column style={{ fontWeight: 'normal' }} >
                                                <div style={{ width: '45vw' }}>
                                                    {cap.shareHolderName}<br />
                                                    {cap.shareCount}<br />
                                                    {cap.percentageOwnership}<br />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                        }
                    })}
                    {!loading && capTable.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 5 : 1} message='You do not hold any share class on CapBlock' />}
                </Table.Body>
                {!loading && capTable.length > 0 &&
                    <GridFooter colSpan={showFullSize ? 5 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                }
            </Table>
        </Segment>
    )
}

export default ShareClassCapTableGrid