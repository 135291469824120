import { KeyValue } from "../common/keyValue";

export interface LoanFeeEnums {
    feeTypes: KeyValue[];
    calculationMethod: KeyValue[];
}

export enum FeeTypeEnum {
    Unknown = 0,
    OriginationFee = 1,
    ApplicationFee = 2,
    ProcessingFee = 3,
    UnderwritingFee = 4,
    LateFee = 5,
    Other = 6,
}

export enum FeeCalculationMethodEnum {
    Unknown = 0,
    FlatAmount = 1,
    Percentage = 2
}