import { Button, Form, Modal } from "semantic-ui-react";
import * as Yup from 'yup';
import { Formik } from "formik";
import CurrencyInput from "../../../app/common/form/CurrencyInput";
import { LoanDrawSchedule } from "../../../app/models/Loan/LoanDrawSchedule";
import { Loan } from "../../../app/models/Loan/Loan";
import { useStore } from "../../../app/stores/store";
import { toast } from "react-toastify";
import { formatCurrency, getRandomNumber } from "../../../shared/utils";
import { useContext, useState } from "react";
import EntityContext from "../../../app/context/entityContext";

interface RequestModelData {
    loan: Loan,
    drawSchedule: LoanDrawSchedule,
    requestModalOpen: boolean,
    setRequestModalOpen: (showOrHide: boolean) => void;
    setReloading : (reload:number) => void;
    setParentReloading : (reload:number) => void;
}

export const RequestDrawModal = (input : RequestModelData) => {
    const { entity } = useContext(EntityContext);
    const { loanDrawStore } = useStore();
    const [submitting, setSubmitting] = useState(false);
    const totalDrawAmountAllowed = input.loan.totalDrawAmountAvailable;

    interface DrawRequestFormData {requestedAmount:number}

    const handleDrawRequest = async (formValues:DrawRequestFormData) => {
        if (formValues.requestedAmount <= totalDrawAmountAllowed){
            setSubmitting(true);
            loanDrawStore.requestLoanDraw(entity.id, input.drawSchedule.id, formValues.requestedAmount)
                .then(r => {
                    toast.success("The draw has been successfully requested!", { theme: "colored" });
                    input.setReloading(getRandomNumber());
                    input.setParentReloading(getRandomNumber());
                    input.setRequestModalOpen(false);
                    setSubmitting(false);
                })
                .catch(err => {
                    toast.error("There was an issue requesting the loan draw.", { theme: "colored" });
                    input.setRequestModalOpen(false);
                    setSubmitting(false);
                });
            }
    }
    return (
        <Modal
            open={input.requestModalOpen} closeOnEscape={false} closeOnDimmerClick={false} onClose={() => input.setRequestModalOpen(false)}  size="mini">
            <Modal.Header>Request Draw #{input.drawSchedule.drawNumber}</Modal.Header>
            <Modal.Content>
                <Formik
                    validationSchema={Yup.object({
                        requestedAmount: Yup.number().typeError('Invalid Draw Request Amount')
                                .transform((_value, originalValue) => parseFloat(originalValue))
                                .required('Request Amount is required')
                                .positive('Request Amount must be greater than 0')
                                .test(
                                    'match',
                                    `Draw Request Amount cannot be more than ${formatCurrency(totalDrawAmountAllowed)}`,
                                    function (value) {return value! <= totalDrawAmountAllowed;}
                                )
                    })}
                    enableReinitialize
                    initialValues={{'requestedAmount' : input.drawSchedule.drawAmount ?? 0}}
                    onSubmit={(values, setSubmitting) =>  handleDrawRequest(values)}>
                    {({ handleSubmit }) => (
                        <Form id="requestForm" className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                            <label>Max. Allowed Draw Request Amount: {formatCurrency(totalDrawAmountAllowed)}</label><br/><br/>
                             <CurrencyInput size="large" placeholder='Draw Amount To Request ($)' name='requestedAmount' showRequired thousandSeparator=',' decimalScale={2} allowNegative={false} maxLength={16} />
                        </Form>
                    )}
                </Formik>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => input.setRequestModalOpen(false)}>Cancel</Button>
                <Button floated='right' form="requestForm" primary type='submit' content='Request Draw' disabled={submitting}/>
            </Modal.Actions>
        </Modal>
    )
};