import { makeAutoObservable } from "mobx";
import agent from "../api/agent";


export default class StripeAccountStore {
    constructor() {
        makeAutoObservable(this)
    }

    getStripeAccountInfo = async (entityId: number) => {
        return agent.Stripe.getStripeAcountInfo(entityId);
    }

    onboardEntity = async (entityId: number) => {
        return agent.Stripe.onBoardEntity(entityId);
    }

    getStripeBalance = async(entityId:number) => {
        return agent.Stripe.getStripeAccountBalance(entityId);
    }

    getDashBoardUrl = async(entityId:number) =>  {
        return agent.Stripe.getDashboardUrl(entityId);
    }

    getPaymentUrl = async(statementId:number) => {
        return agent.Stripe.getPaymentUrl(statementId);
    }
}