import { useAuth0 } from "@auth0/auth0-react";
import { Outlet, useNavigate } from "react-router-dom";
import { Container, Dropdown, Menu, Segment, Image, Button } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { clearCache, setRedirectUrl } from "../../../shared/utils";

export default function NavBarAuthenticationNotRequired() {

    const { logout } = useAuth0();
    const logoutHandler = () => {
        clearCache();
        logout({ returnTo: window.location.origin });
    }
    const navigate = useNavigate();

    const { isAuthenticated, isLoading, user } = useAuth0();
    const showFullSize = useMediaQuery({ query: "(min-width:576px)" })

    const navigateToRegister = (() => {
        setRedirectUrl(window.location.href);
        navigate(`/register`);
    })
    return (
        <>
            <Menu fixed='top' inverted borderless size={showFullSize ? 'large' : 'tiny'} className="nav-menu">
                <Menu.Item header>
                    <Image src='/assets/logo white.png' size={showFullSize ? 'small' : 'tiny'} />
                </Menu.Item>
                {showFullSize && !isLoading && isAuthenticated && (
                    <Menu.Menu position='right'>
                        <Menu.Item position="right">
                            <Image src={user?.picture || './assets/user.png'} avatar spaced='right' />
                            <Dropdown pointing='top left' text={user?.name} className="nav-sel">
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={logoutHandler} text='Logout' icon='power' />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                    </Menu.Menu>
                )}
                {showFullSize && !isLoading && !isAuthenticated && (
                    <Menu.Menu position='right'>
                        <Menu.Item position="right">
                            <Button primary content='Register' onClick={() => navigateToRegister()} />
                        </Menu.Item>
                    </Menu.Menu>
                )}
            </Menu>

            <Segment className="main-content-segment">
                <Container>
                    <Outlet />
                </Container>
            </Segment>
        </>
    )
}