import { List, ListContent, ListDescription, ListHeader, ListIcon, ListItem, Placeholder, PlaceholderLine, PlaceholderParagraph, Segment } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { InvestmentTermsFeesView } from "../../app/models/Investment/InvestmentTermsFeesView";

interface Params {
    investmentId: number
}

function TermsFeesPanelComponent({ investmentId }: Params) {

    const [loading, setLoading] = useState(true);
    const [termsFees, setTermsFees] = useState(new InvestmentTermsFeesView());
    const { investmentInsightsStore } = useStore();
    const iconSize = 'large'

    useEffect(() => {
        setLoading(true);
        investmentInsightsStore.getTermsFees(Number(investmentId))
            .then(r => setTermsFees(r))
            .finally(() => setLoading(false));
    }, [investmentId, investmentInsightsStore])

    return (
        <Segment clearing loading={loading} raised color='yellow'>
            {loading &&
                <Placeholder>
                    <PlaceholderParagraph>
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </PlaceholderParagraph>
                    <PlaceholderParagraph>
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </PlaceholderParagraph>
                </Placeholder>
            }
            {!loading &&
                <>
                    <List divided relaxed verticalAlign="top" style={{ padding: 10 }}>
                        <ListItem>
                            <ListIcon name='area graph' size={iconSize} verticalAlign='middle' />
                            <ListContent>
                                <ListHeader><span style={{ fontSize: 'larger' }}>Acquisition Fee</span></ListHeader>
                                <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{termsFees.acquisitionFee}%</span></ListDescription>
                            </ListContent>
                        </ListItem>
                        <ListItem>
                            <ListIcon name='area graph' size={iconSize} verticalAlign='middle' />
                            <ListContent>
                                <ListHeader><span style={{ fontSize: 'larger' }}>Asset Management Fee</span></ListHeader>
                                <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{termsFees.assetManagementFee}%</span></ListDescription>
                            </ListContent>
                        </ListItem>
                        <ListItem>
                            <ListIcon name='area graph' size={iconSize} verticalAlign='middle' />
                            <ListContent>
                                <ListHeader><span style={{ fontSize: 'larger' }}>Disposition Fee</span></ListHeader>
                                <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{termsFees.dispositionFee}%</span></ListDescription>
                            </ListContent>
                        </ListItem>
                        <ListItem>
                            <ListIcon name='area graph' size={iconSize} verticalAlign='middle' />
                            <ListContent>
                                <ListHeader><span style={{ fontSize: 'larger' }}>Refinance Fee</span></ListHeader>
                                <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{termsFees.refinanceFee}%</span></ListDescription>
                            </ListContent>
                        </ListItem>
                        <ListItem>
                            <ListIcon name='pie graph' size={iconSize} verticalAlign='middle' />
                            <ListContent>
                                <ListHeader><span style={{ fontSize: 'larger' }}>Profit Sharing</span></ListHeader>
                                <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{termsFees.profitSharing}</span></ListDescription>
                            </ListContent>
                        </ListItem>
                    </List>
                </>
            }
        </Segment>
    )
}

export default TermsFeesPanelComponent