import React, { useEffect, useState } from 'react'
import { Button, Header, Segment, Tab } from 'semantic-ui-react';
import { useStore } from '../../app/stores/store';
import { Loan } from '../../app/models/Loan/Loan';
import { Link, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { formatCurrency, getLastSegmentOfRoute } from '../../shared/utils';
import { toast } from 'react-toastify';
import { LoanViewDetails } from './components/LoanViewDetails';
import { LoanViewDrawDetails } from './components/LoanViewDrawDetails';
import LoanViewPaymentDetails from './components/LoanViewPaymentDetails';
import Escrow from './escrows/Escrow';
import LoanDocuments from './Documents/LoanDocuments';

function LoanViewForm() {
    const { loanStore } = useStore();
    const [selectedLoan, setSelectedLoan] = useState(new Loan());
    const [loading, setLoading] = useState(false);
    const [reloading, setReloading] = useState(0);
    const { id } = useParams();
    const lastOfRoute = getLastSegmentOfRoute();
    const hasPaymentsInRoute = lastOfRoute === "payments";
    const hasDrawsInRoute = lastOfRoute === "draws";
    const hasDocumentsInRoute = lastOfRoute === "documents";
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        if (id) {
            setLoading(true);

            loanStore.getLoan(Number(id)).then((r) => {
                setSelectedLoan(r);
                setLoading(false);
                if (hasDocumentsInRoute) setActiveIndex(r.draws?.length === 0 ? 3 : 4);
                if (hasPaymentsInRoute) setActiveIndex(r.draws?.length === 0 ? 2 : 3);
                if (hasDrawsInRoute) setActiveIndex(1);
            })
                .catch(err => {
                    toast.error("There was an issue loading loan details.", { theme: "colored" });
                });

        }
    }, [id, loanStore, hasPaymentsInRoute, hasDrawsInRoute, hasDocumentsInRoute])

    useEffect(() => {
        if (reloading) {
            loanStore.getLoan(Number(id)).then((r) => {
                setSelectedLoan(r);
                setLoading(false);
            })
                .catch(err => {
                    toast.error("There was an issue loading loan details.", { theme: "colored" });
                });
        }
    }, [reloading, id, loanStore])

    var panes = [
        {
            menuItem: { key: 'LoanDetails', content: 'Loan Details' },
            render: () => <LoanViewDetails loan={selectedLoan} />
        },
        {
            menuItem: { key: 'Draws', content: 'Draws' },
            render: () => <LoanViewDrawDetails loan={selectedLoan} setParentReloading={setReloading} />
        },
        {
            menuItem: { key: 'Escrows', content: 'Escrows' },
            render: () => <Escrow loan={selectedLoan} />
        },
        {
            menuItem: { key: 'Payments', content: 'Payments' },
            render: () => <LoanViewPaymentDetails loan={selectedLoan} setParentReloading={setReloading} />
        },
        {
            menuItem: { key: 'Documents', content: 'Documents' },
            render: () => <LoanDocuments loan={selectedLoan}  />
        }
    ];

    if (selectedLoan?.draws?.length === 0) {
        panes = panes.filter(x => x.menuItem.key !== "Draws");
    }

    return (
        <>
            <Segment basic loading={loading} style={{ marginTop: '4em' }}>
                <Header as='h2'>
                    {!loading && selectedLoan?.name + ' (' + formatCurrency(selectedLoan?.currentLoanBalance ?? 0) + ')'}
                    <Button floated='right' content='Back to Loans' as={Link} to='/loans' />
                </Header>
                <Tab className="scroll" panes={panes} defaultActiveIndex={activeIndex} key={activeIndex} />
            </Segment>
        </>
    )
}

export default observer(LoanViewForm)