import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { EntityFilterParams } from "../models/common/entityFilterParams";

export default class NotificationStore {

    constructor() {
        makeAutoObservable(this)
    }

    getEntityTasks = async (params: EntityFilterParams) => {
        return agent.Notifications.getEntityTasks(params)
    }

    getEntityNotifications = async (params: EntityFilterParams) => {
        return agent.Notifications.getEntityNotifications(params)
    }

    updateNotificationStatus = async (entityId: number, notificationId: number) => {
        return agent.Notifications.updateNotificationStatus(entityId, notificationId)
    }
}
