import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Loan } from '../../../app/models/Loan/Loan'
import { Button, Dropdown, Grid, Header, Icon, Segment, Tab, Table } from 'semantic-ui-react'
import EmptyGridMessage from '../../../shared/EmptyGridMessage';
import GridFooter from '../../../shared/GridFooter';
import { useMediaQuery } from 'react-responsive';
import { createGridInitialState, formatCurrency, getFullSizeWidth, handleGridNavigation, handleGridSort } from '../../../shared/utils';
import { PageParams } from '../../../app/models/common/PagedResult';
import EntityContext from '../../../app/context/entityContext';
import { LoanEscrow } from '../../../app/models/Loan/LoanEscrow';
import EscrowModal from './EscrowModal';
import { useStore } from '../../../app/stores/store';
import TransferModal from '../../transfer/transferModal';
import { CapBlockTable } from '../../../app/models/common/CapblockTable';
import EscrowHistoryModal from './EscrowHistoryModal';
import EscrowAddFundsModal from './EscrowAddFunds';
import { TransferModel, TransferType } from '../../../app/models/settings/transfer';
import { LoanEscrowFilter } from '../../../app/models/Loan/LoanEscrowFilter';

interface Props {
    loan: Loan
}

function Escrow({ loan }: Props) {
    const [loading, setLoading] = useState(false);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const { entity } = useContext(EntityContext);
    const [escrows, setEscrows] = useState([new LoanEscrow()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [openHistoryModal, setOpenHistoryModal] = useState(false);
    const [openAddFundsModal, setOpenAddFundsModal] = useState(false);
    const [loanEscrowId, setLoanEscrowId] = useState(0);
    const { loanStore } = useStore();
    const [showTransfer, setShowTransfer] = useState(false);
    const [selectedEscrow, setSelectedEscrow] = useState(new LoanEscrow());

    const initialState: LoanEscrowFilter = createGridInitialState({
        loanId: loan.id,
        entityId: entity.id,
        loanEscrowId: 0,
        includeBalance: true,
        sortIndex: 'EscrowTypeName',
        sortOrder: 'ascending'
    });

    const [params, setParams] = useState(initialState);

    const loadLoanEscrows = useCallback(() => {
        setLoading(true);
        loanStore.getLoanEscrows(params).then(r => {
            setTotalRecords(r.totalRecords);
            setEscrows(r.data);
            setLoading(false);
        });
    }, [loanStore, params, setLoading, setEscrows])


    useEffect(() => {
        loadLoanEscrows();
    }, [loadLoanEscrows]);

    const handleSort = (clickedColumn: string) => {
        if (escrows.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    const viewEscrow = (id: number) => {
        setLoanEscrowId(id);
        setOpenModal(true);
    }

    const closeModal = (reload: boolean) => {
        if (reload) loadLoanEscrows();
        setLoanEscrowId(0);
        setOpenModal(false);
        setOpenHistoryModal(false);
        setOpenAddFundsModal(false);
    }

    const viewHistory = (escrow: LoanEscrow) => {
        setSelectedEscrow(escrow);
        setOpenHistoryModal(true);
    }

    const handleTransferClose = (refreshBalance: boolean) => {
        setShowTransfer(false);
    }

    const getTransferModel = (): TransferModel => {
        var model = {
            senderCapblockTable: CapBlockTable.loanEscrow,
            senderTableKeyId: loanEscrowId,
            transferType: TransferType.escrowTransfer,
            sourceId: 0,
            amount: 0,
            toCapblockTable: CapBlockTable.entity,
            toTableKeyId: loan.lenderEntityId,
            toName: loan.lenderName
        };
        return model;
    }

    const openTransfer = (id: number) => {
        setLoanEscrowId(id);
        setShowTransfer(true);
    }

    return (
        <Tab.Pane attached={true} >
            <Segment clearing basic textAlign='center' loading={loading}>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column verticalAlign='middle' textAlign='left' width={showFullSize ? 10 : 6}>
                            <Header>Escrow Items</Header>
                        </Grid.Column>
                        <Grid.Column textAlign='right' verticalAlign='middle' width={showFullSize ? 6 : 10}>
                            {loan.lenderEntityId === entity.id && escrows.length > 0 && <Button floated='right' content="Add Funds" primary onClick={() => setOpenAddFundsModal(true)} />}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Table singleLine sortable selectable>
                    {showFullSize &&
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell onClick={() => handleSort("EscrowTypeName")} sorted={params.sortIndex === "EscrowTypeName" && escrows.length > 0 ? params.sortOrder : undefined}>Type</Table.HeaderCell>
                                <Table.HeaderCell onClick={() => handleSort("Amount")} sorted={params.sortIndex === "Amount" && escrows.length > 0 ? params.sortOrder : undefined}>Amount</Table.HeaderCell>
                                <Table.HeaderCell>Balance</Table.HeaderCell>
                                <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                    }

                    <Table.Body>
                        {!loading && escrows.map((escrow, i) => {
                            if (showFullSize) {
                                return <Table.Row key={i}>
                                    <Table.Cell>{escrow.escrowTypeName}</Table.Cell>
                                    <Table.Cell textAlign='right'>{formatCurrency(escrow.amount)}</Table.Cell>
                                    <Table.Cell textAlign='right'>{formatCurrency(escrow.currentEscrowBalance)}</Table.Cell>
                                    <Table.Cell>
                                        <Dropdown text='Actions'>
                                            <Dropdown.Menu>
                                                {loan.lenderEntityId === entity.id &&
                                                    <>
                                                        <Dropdown.Item text='Edit' value='1' icon='edit' onClick={(e, d) => viewEscrow(escrow.id)} />
                                                        <Dropdown.Item text='Transfer' value='2' icon='dollar sign' onClick={(e, d) => openTransfer(escrow.id)} />
                                                    </>
                                                }
                                                <Dropdown.Item text='History' value='3' icon='history' onClick={() => viewHistory(escrow)} />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Table.Cell>
                                </Table.Row>
                            }
                            else {
                                return <Table.Row key={i}>
                                    <Table.Cell>
                                        {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                        <Grid>
                                            <Grid.Row style={{ cursor: 'pointer' }}>
                                                <Grid.Column width={6}>
                                                    <label onClick={() => handleSort("EscrowTypeName")}>Type:</label><br />
                                                    <label onClick={() => handleSort("Amount")}>Amount:</label><br />
                                                    <label>Balance:</label><br />
                                                    <label>Actions:</label><br />
                                                </Grid.Column>
                                                <Grid.Column style={{ fontWeight: 'normal' }} >
                                                    <div style={{ width: '45vw' }} onClick={() => viewEscrow(escrow.id)}>
                                                        {escrow.escrowTypeName}<br />
                                                        {formatCurrency(escrow.amount)}<br />
                                                        {formatCurrency(escrow.currentEscrowBalance)}<br />
                                                        <Dropdown text='Actions'>
                                                            <Dropdown.Menu>
                                                                {loan.lenderEntityId === entity.id &&
                                                                    <>
                                                                        <Dropdown.Item text='Edit' value='1' icon='edit' onClick={(e, d) => viewEscrow(escrow.id)} />
                                                                        <Dropdown.Item text='Transfer' value='2' icon='dollar sign' onClick={(e, d) => openTransfer(escrow.id)} />
                                                                    </>
                                                                }
                                                                <Dropdown.Item text='History' value='3' icon='history' onClick={() => viewHistory(escrow)} />
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Table.Cell>
                                </Table.Row>
                            }
                        })}
                        {!loading && escrows.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 4 : 1} message='No escrow available' />}
                    </Table.Body>

                    {!loading && escrows.length > 0 &&
                        <GridFooter colSpan={showFullSize ? 4 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                    }
                </Table>

                {loan.lenderEntityId === entity.id && <Button primary content="Add Escrow Item" onClick={() => setOpenModal(true)} />}
            </Segment>

            {openModal && <EscrowModal loan={loan} show={openModal} onCancel={closeModal} id={loanEscrowId} />}

            {openAddFundsModal && <EscrowAddFundsModal loanId={loan.id} show={openAddFundsModal} onCancel={closeModal} />}

            {openHistoryModal && <EscrowHistoryModal loan={loan} escrow={selectedEscrow} show={openHistoryModal} onCancel={closeModal} />}

            {showTransfer && <TransferModal closeModal={handleTransferClose}
                show={showTransfer}
                hideReceive={false}
                transferModel={getTransferModel()} />
            }

        </Tab.Pane>
    )
}

export default Escrow