import { Placeholder, PlaceholderLine, PlaceholderParagraph, Segment, Statistic, StatisticGroup, StatisticLabel, StatisticValue } from "semantic-ui-react";
import { formatCurrency, formatDate } from "../../shared/utils";
import { useEffect, useState } from "react";
import { InvestmentSummaryView } from "../../app/models/GpLanding/InvestmentSummaryView";
import { useStore } from "../../app/stores/store";

interface Params {
    investmentId: number
}

function OverviewPanelComponent({ investmentId }: Params) {

    const [loading, setLoading] = useState(true);
    const [investment, setInvestment] = useState(new InvestmentSummaryView());
    const { investmentInsightsStore } = useStore();

    useEffect(() => {
        setLoading(true);
        investmentInsightsStore.getOverview(investmentId)
            .then(r => setInvestment(r))
            .finally(() => setLoading(false));
    }, [investmentId, investmentInsightsStore])

    return (
        <Segment clearing loading={loading} className='investmentMarketingPanel'>
            {loading &&
                <Placeholder>
                    <PlaceholderParagraph>
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </PlaceholderParagraph>
                    <PlaceholderParagraph>
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </PlaceholderParagraph>
                </Placeholder>
            }
            {!loading &&
                <StatisticGroup size="mini" widths='3' color='blue'>
                    <Statistic className='investmentSummaryStatistics' >
                        <StatisticValue >{formatCurrency(investment.minimumInvestment)}</StatisticValue>
                        <StatisticLabel>Minimum Investment</StatisticLabel>
                    </Statistic>
                    <Statistic className='investmentSummaryStatistics'>
                        <StatisticValue>{investment.secType}</StatisticValue>
                        <StatisticLabel>SEC Type</StatisticLabel>
                    </Statistic>
                    <Statistic className='investmentSummaryStatistics'>
                        <StatisticValue>{formatCurrency(investment.totalRaiseAmount)}</StatisticValue>
                        <StatisticLabel>Total Raise Amount</StatisticLabel>
                    </Statistic>
                    <Statistic className='investmentSummaryStatistics'>
                        <StatisticValue>{investment.dealType}</StatisticValue>
                        <StatisticLabel>Deal Type</StatisticLabel>
                    </Statistic>
                    <Statistic className='investmentSummaryStatistics'>
                        <StatisticValue>{formatDate(investment.closeDate)}</StatisticValue>
                        <StatisticLabel>Close Date</StatisticLabel>
                    </Statistic>
                    <Statistic className='investmentSummaryStatistics'>
                        <StatisticValue>{formatCurrency(investment.acquisitionPrice)}</StatisticValue>
                        <StatisticLabel>Acquisition Price</StatisticLabel>
                    </Statistic>
                </StatisticGroup>
            }
        </Segment>
    )
}

export default OverviewPanelComponent