import { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Form, Header, Image, Label, Segment, Table, TableBody, TableCell, TableRow } from 'semantic-ui-react';
import { useStore } from '../../app/stores/store';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { Formik } from 'formik';
import EntityContext from '../../app/context/entityContext';
import { EntityProfileDTO } from '../../app/models/registration/entityProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import TextInput from '../../app/common/form/TextInput';
import * as yup from 'yup';
import { CommonRegularExpressions } from '../../shared/CommonRegularExpressions';

import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { maxSize, minSize } from '../../shared/utils';
import HeaderSubHeader from 'semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader';
import TextArea from '../../app/common/form/Textarea';

function EntityProfilePanel() {
    const { entity } = useContext(EntityContext);
    const [loading, setLoading] = useState(false);
    const [entityId] = useState(entity.id);
    const [showUploadPanel, setShowUploadPanel] = useState(false);
    const maxFiles = 1;
    const [errorMsg] = useState("");
    const { entityProfileStore } = useStore();
    const [entityProfile, setEntityProfile] = useState(new EntityProfileDTO());
    const [profileImagePath, setProfileImagePath] = useState('/assets/sponsor-profile.jpg');
    const commonRegex = CommonRegularExpressions;

    const validationSchema = yup.object({
        httpUrl: yup
            .string()
            .matches(commonRegex.httpurl, 'Enter a valid url'),
    });

    useEffect(() => {
        setLoading(true);
        entityProfileStore.getProfile(entityId)
            .then(r => {
                setEntityProfile(r);
                if (r.profileImageUrl !== null) setProfileImagePath(r.profileImageUrl);
            })
            .catch(() => {
                toast.error("There was an error retrieving your public profile.", { theme: "colored" })
            })
            .finally(() => { setLoading(false) })
    }, [entityId, entityProfileStore, setEntityProfile, setLoading]);

    const handleFormSubmit = (profile: EntityProfileDTO, setSubmitting: any) => {
        const formData = new FormData();

        formData.append('id', profile.id.toString());
        formData.append('entityId', entityId.toString());
        formData.append('description', profile.description == null ? "" : profile.description);
        formData.append('profileUrlLinkedIn', profile.profileUrlLinkedIn == null ? "" : profile.profileUrlLinkedIn);
        formData.append('profileUrlFacebook', profile.profileUrlFacebook == null ? "" : profile.profileUrlFacebook);
        formData.append('profileUrlTwitterX', profile.profileUrlTwitterX == null ? "" : profile.profileUrlTwitterX);
        formData.append('profileUrlOther', profile.profileUrlOther == null ? "" : profile.profileUrlOther);
        formData.append('profileImageFile', profile.profileImageFile);
        formData.append('profileImageUpdated', profile.profileImageUpdated.toString());

        setLoading(true);

        entityProfileStore.saveProfile(formData)
            .then(() => {
                toast.success("Public profile updated.", { theme: "colored" });
                profile.profileImageUpdated = false;
                setEntityProfile(profile);
            })
            .catch(e => {
                toast.error(e.response.data, { theme: "colored" })
            })
            .finally(() => {
                setLoading(false);
                setSubmitting(false);
            })
    }

    const onDrop = useCallback((uploadedFiles: any) => {
        entityProfile.profileImageFile = uploadedFiles[0];
        entityProfile.profileImageUpdated = true;
        setProfileImagePath(URL.createObjectURL(entityProfile.profileImageFile));
        setShowUploadPanel(false);
    }, [entityProfile, setProfileImagePath, setShowUploadPanel]);

    const dropZoneConfig = {
        maxFiles: maxFiles,
        maxSize: maxSize,
        minSize: minSize,
        multiple: false,
        accept: {
            'image/*': [],
        },
        onDrop: onDrop
    };
    const { getRootProps, getInputProps } = useDropzone(dropZoneConfig);

    return (
        <Segment clearing>
            <Header as='h2'>Public Profile
                <HeaderSubHeader>Your profile allows you to provide additional information to investors interested in your offerings. Your profile will be visible publicly.</HeaderSubHeader>
            </Header>

            <Formik
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={entityProfile}
                onSubmit={(values, { setSubmitting }) => {
                    return handleFormSubmit(values, setSubmitting);
                }} >
                {({ handleSubmit, isSubmitting }) => (
                    <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        {showUploadPanel ?
                                            <>
                                                <Segment>
                                                    <div {...getRootProps({ className: 'dropzone' })}
                                                        style={{
                                                            height: 200, width: "80%", margin: 'auto',
                                                            borderStyle: "dashed", textAlign: 'center', paddingTop: '20px',
                                                            verticalAlign: "middle",
                                                            cursor: (0 < maxFiles) ? "copy" : "no-drop"
                                                        }}>
                                                        <input type="file" {...getInputProps()} />
                                                        <br /><br />
                                                        <p>Drag and Drop the your profile image file here
                                                            <br />
                                                            OR
                                                            <br />
                                                            Click in this zone to select a file from your computer.</p>
                                                    </div>
                                                    {errorMsg && <Label color='red'>{errorMsg}</Label>}
                                                </Segment>
                                                <Button onClick={() => setShowUploadPanel(false)} type='button' content='Cancel' floated='right'></Button>
                                            </>
                                            :
                                            <>
                                                <Header as='h2'>Profile Banner</Header>
                                                {!loading && <Image src={profileImagePath} size={'large'} rounded />}
                                                <br />
                                                <Button onClick={() => setShowUploadPanel(true)} type='button' content='Upload' primary></Button>
                                            </>
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <TextArea placeholder='Description (public)' name='description' rows={5} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Header as='h3'>
                            Social Media
                            <HeaderSubHeader>Provide the URLs of your social platform profiles, or leave blank.</HeaderSubHeader>
                        </Header>
                        <Table padded>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={'1'} textAlign='right'><FontAwesomeIcon icon={faLinkedin} size='2x' /></Table.Cell>
                                    <Table.Cell textAlign='left'><TextInput placeholder='Linked In Profile URL' name='profileUrlLinkedIn' maxLength={50} /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <TableCell width={'1'} textAlign='right'><FontAwesomeIcon icon={faFacebook} size='2x' /></TableCell>
                                    <Table.Cell textAlign='left'><TextInput placeholder='Facebook Profile URL' name='profileUrlFacebook' maxLength={50} /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <TableCell width={'1'} textAlign='right'><FontAwesomeIcon icon={faXTwitter} size='2x' /></TableCell>
                                    <Table.Cell textAlign='left'><TextInput placeholder='X (Twitter) In Profile URL' name='profileUrlTwitterX' maxLength={50} /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <TableCell width={'1'} textAlign='right'><FontAwesomeIcon icon={faGlobe} size='2x' /></TableCell>
                                    <Table.Cell textAlign='left'><TextInput placeholder='Other Profile URL' name='profileUrlOther' maxLength={50} /></Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <Button floated='right' primary type='submit' loading={loading} disabled={isSubmitting} content='Save'></Button>
                    </Form>
                )}
            </Formik>
        </Segment>
    )
}
export default observer(EntityProfilePanel);