import { useCallback, useContext, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { Button, Form, Grid, GridColumn, GridRow, Header, Icon, Input, Label, Segment, Table } from 'semantic-ui-react'
import EntityContext from '../../app/context/entityContext';
import { PageParams } from '../../app/models/common/PagedResult';
import { SponsorContact, SponsorContactStatus } from '../../app/models/sponsorContacts/SponsorContact'
import { formatPhoneNumber, getEnumText, getEnumValue, handleGridSearch } from '../../shared/utils';
import { SponsorContactParams } from '../../app/models/sponsorContacts/SponsorContactParams';
import { useStore } from '../../app/stores/store';
import EmptyGridMessage from '../../shared/EmptyGridMessage';
import GridFooter from '../../shared/GridFooter';
import { createGridInitialState, getFullSizeWidth, handleGridNavigation, handleGridSort } from '../../shared/utils';
import { Formik } from 'formik';
import TextInput from '../../app/common/form/TextInput';
import * as yup from 'yup';
import { CommonRegularExpressions } from '../../shared/CommonRegularExpressions';
import { toast } from 'react-toastify';
import { InputMasks } from '../../shared/InputMasks';
import SelectInput from '../../app/common/form/SelectInput';
import ConfirmButton from '../../shared/ConfirmButton';
import CsvFileImportPanel from './CsvFileImportPanel'

function SponsorContactGrid() {

    const [loading, setLoading] = useState(true);
    const { sponsorContactStore, commonStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [sponsorContactsList, setSponsorContactsList] = useState([new SponsorContact()]);
    const [sponsorContact, setSponsorContact] = useState(new SponsorContact());
    const [totalRecords, setTotalRecords] = useState(0);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const initialState: SponsorContactParams = createGridInitialState({
        sponsorEntityId: entity.id,
        sortIndex: 'LastName',
        sortOrder: 'ascending',
    });

    const [viewContactDetail, setViewContactDetail] = useState(false);
    const [params, setParams] = useState(initialState);
    const commonRegex = CommonRegularExpressions;
    const emptyOption = { key: '' };
    const [stateOptions, setStateOptions] = useState([emptyOption]);
    const [viewUploadPanel, setViewUploadPanel] = useState(false);
    const [enableEmailButtons, setEnableEmailButtons] = useState(true);


    const loadInvestorList = useCallback(() => {
        sponsorContactStore.getSponsorContacts(params)
            .then(r => {
                setTotalRecords(r.totalRecords);
                r.data.map(d => d.statusId = getEnumValue(SponsorContactStatus, d.statusId));
                setSponsorContactsList(r.data);
                setLoading(false);
            }
            );
    }, [sponsorContactStore, setTotalRecords, setSponsorContactsList, setLoading, params]);

    useEffect(() => {
        commonStore.getStates()
            .then(r => setStateOptions(r))
            .then(() => loadInvestorList());
    }, [sponsorContactStore, params, commonStore, setLoading, loadInvestorList]);


    const handleSort = (clickedColumn: string) => {
        if (sponsorContactsList.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    const viewContact = (sponsorContactId: number) => {
        if (sponsorContactId > 0) {
            sponsorContactStore.getSponsorContact(entity.id, sponsorContactId)
                .then(r => {
                    setSponsorContact(r);
                    setViewContactDetail(true);
                })

        }
        else {
            setSponsorContact(new SponsorContact());
            setViewContactDetail(true);
        }
    }

    const closeContact = () => {
        setViewContactDetail(false);
    }

    const searchChange = (event: any) => {
        setParams(handleGridSearch(params, event.target.value));
    }

    const handleDelete = (sponsorContactId: number) => {
        setLoading(true);
        sponsorContactStore.deleteSponsorContact(entity.id, sponsorContactId)
            .then(() => loadInvestorList())
            .then(() => {
                setSponsorContact(new SponsorContact());
                setViewContactDetail(false);
                toast.success("Contact deleted", { theme: "colored" });
            })
            .catch(err => toast.error("There was an error deleting the contact.", { theme: "colored" }))
            .finally(() => {
                setLoading(false);
            });
    }

    const handleFormSubmit = (values: SponsorContact, setFieldError: any) => {
        setLoading(true);
        values.sponsorEntityId = entity.id;
        sponsorContactStore.saveSponsorContact(values)
            .then(() => loadInvestorList())
            .then(() => {
                setSponsorContact(new SponsorContact());
                setViewContactDetail(false);
                toast.success("Contact saved.", { theme: "colored" });
            })
            .catch(err => toast.error(err.response.data, { theme: "colored" }))
            .finally(() => {
                setLoading(false);
            });
    }
    const closeCsvUpload = () => {
        setViewContactDetail(false);
        setViewUploadPanel(false);
    }

    const sendSingleEmail = (contactId: number) => {
        setEnableEmailButtons(false);
        sponsorContactStore.sendSingleWelcomeEmail(entity.id, contactId)
            .then((r) => {
                var sc = sponsorContactsList.find((c) => c.id === contactId);
                sc!.statusId = SponsorContactStatus.Invited;
                toast.success("Invitation email sent", { theme: "colored" });

            })
            .catch(err => {
                toast.error("There was an error sending the invitation", { theme: "colored" });
            })
            .finally(() => {
                setEnableEmailButtons(true);
            });

    }

    const validationSchema = yup.object({
        firstName: yup
            .string()
            .required('First Name is required')
            .matches(commonRegex.firstNameOrLastName, 'Special characters not allowed'),
        lastName: yup
            .string()
            .required('Last Name is required')
            .matches(commonRegex.firstNameOrLastName, 'Special characters not allowed'),
        email: yup
            .string()
            .required('Email is required')
            .email('Enter a valid email'),
        zip: yup
            .string()
            .matches(commonRegex.zip, 'Invalid zip code'),
        cellPhone: yup
            .string()
            .matches(commonRegex.phoneNumber, 'Invalid Phone Number'),
        workPhone: yup
            .string()
            .matches(commonRegex.phoneNumber, 'Invalid Phone Number'),
    });

    return (
<>
            <Grid columns={3} padded={false}>
                <GridRow>
                {viewContactDetail ?
                    <>
                        <GridColumn verticalAlign='middle'><Header>Investor Details</Header></GridColumn>
                        <GridColumn></GridColumn>
                        {sponsorContact.id > 0 ?
                            <GridColumn textAlign='right'><Label>{sponsorContact.statusId}</Label></GridColumn>
                            :
                            <GridColumn textAlign='right'><Label>New</Label></GridColumn>
                        }
                    </>
                    :
                    <>
                        <GridColumn verticalAlign='middle'><Header>Investors</Header></GridColumn>
                        <GridColumn>
                            {!viewUploadPanel && !viewContactDetail &&
                                <Input onChange={(e) => searchChange(e)} placeholder='Search...' icon='search' />}
                        </GridColumn>
                        <GridColumn>
                            {!viewUploadPanel ?
                                <>
                                    <Button floated='right' primary content='Upload Investors' onClick={() => { setViewUploadPanel(!viewUploadPanel); }}></Button>
                                    <Button floated='right' primary content="Add Investor" onClick={() => viewContact(0)}></Button>
                                </>
                                :
                                <Button floated='right' primary content='Back' onClick={() => { setViewUploadPanel(!viewUploadPanel); }}></Button>
                            }
                        </GridColumn>
                    </>
                }
                </GridRow>
            </Grid>
            {viewUploadPanel &&
                <Segment clearing>
                    <CsvFileImportPanel onCancel={closeCsvUpload} welcomeEmailEnabled={false} />
                </Segment>
            }
            {!viewContactDetail && !viewUploadPanel &&
                <Table singleLine sortable selectable striped fixed>
                    {showFullSize &&
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell onClick={() => handleSort("FirstName")} sorted={params.sortIndex === "FirstName" && sponsorContactsList.length > 0 ? params.sortOrder : undefined}>First Name</Table.HeaderCell>
                                <Table.HeaderCell onClick={() => handleSort("LastName")} sorted={params.sortIndex === "LastName" && sponsorContactsList.length > 0 ? params.sortOrder : undefined}>Last Name</Table.HeaderCell>
                                <Table.HeaderCell onClick={() => handleSort("WorkPhone")} sorted={params.sortIndex === "WorkPhone" && sponsorContactsList.length > 0 ? params.sortOrder : undefined}>Work Phone</Table.HeaderCell>
                                <Table.HeaderCell onClick={() => handleSort("CellPhone")} sorted={params.sortIndex === "CellPhone" && sponsorContactsList.length > 0 ? params.sortOrder : undefined}>Cell Phone</Table.HeaderCell>
                                <Table.HeaderCell onClick={() => handleSort("Email")} sorted={params.sortIndex === "Email" ? params.sortOrder : undefined}>Email</Table.HeaderCell>
                                <Table.HeaderCell onClick={() => handleSort("AccreditationStatusId")} sorted={params.sortIndex === "AccreditationStatusId" ? params.sortOrder : undefined}>Accreditation</Table.HeaderCell>
                                <Table.HeaderCell onClick={() => handleSort("StatusId")} sorted={params.sortIndex === "StatusId" ? params.sortOrder : undefined}>Status</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                    }

                    <Table.Body>
                        {!loading && sponsorContactsList.map((sponsorContact, i) => {
                            if (showFullSize) {
                                return <Table.Row key={i} style={{ cursor: 'pointer' }} onClick={() => viewContact(sponsorContact.id)}>
                                    <Table.Cell>{sponsorContact.firstName} </Table.Cell>
                                    <Table.Cell>{sponsorContact.lastName}</Table.Cell>
                                    <Table.Cell>{formatPhoneNumber(sponsorContact.workPhone)}</Table.Cell>
                                    <Table.Cell>{formatPhoneNumber(sponsorContact.cellPhone)}</Table.Cell>
                                    <Table.Cell>{sponsorContact.email}</Table.Cell>
                                    <Table.Cell>{sponsorContact.accreditationStatusId}</Table.Cell>
                                    <Table.Cell>{getEnumText(SponsorContactStatus, sponsorContact.statusId)}</Table.Cell>
                                    <Table.Cell width={3}>
                                        <Button size='small' basic color='blue' icon labelPosition='left' onClick={(e) => { sendSingleEmail(sponsorContact.id); e.stopPropagation(); }}
                                            disabled={!enableEmailButtons}
                                            loading={!enableEmailButtons}>
                                            <Icon name='mail'></Icon>
                                            {enableEmailButtons ? <> Send Invitation</> : <> Please wait</>}
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            }
                            else {
                                return <Table.Row key={i}>
                                    <Table.Cell>
                                        {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ' '}
                                        <Grid>
                                            <Grid.Row style={{ cursor: 'pointer' }}>
                                                <Grid.Column width={7}>
                                                    <label onClick={() => handleSort("LastName")}>Last Name:</label><br />
                                                    <label onClick={() => handleSort("FirstName")}>First Name:</label><br />
                                                    <label onClick={() => handleSort("WorkPhone")}>Work Phone:</label><br />
                                                    <label onClick={() => handleSort("CellPhone")}>Cell Phone:</label><br />
                                                    <label onClick={() => handleSort("Email")}>Email:</label><br />
                                                    <label onClick={() => handleSort("AccreditationStatusId")}>Accreditation:</label><br />
                                                    <label onClick={() => handleSort("StatusId")}>Status:</label><br />
                                                    <label>Actions</label>
                                                </Grid.Column>
                                                <Grid.Column style={{ fontWeight: 'normal' }} >
                                                    <div style={{ width: '45vw' }} onClick={() => viewContact(sponsorContact.id)}>
                                                        {sponsorContact.firstName} {sponsorContact.lastName}<br />
                                                        {sponsorContact.workPhone}<br />
                                                        {sponsorContact.cellPhone}<br />
                                                        {sponsorContact.email}<br />
                                                        {sponsorContact.accreditationStatusId}<br />
                                                        {sponsorContact.statusId}<br />
                                                        <Button basic color='blue' icon labelPosition='left' onClick={(e) => { sendSingleEmail(sponsorContact.id); e.stopPropagation(); }}
                                                            disabled={!enableEmailButtons}
                                                            loading={!enableEmailButtons}>
                                                            <Icon name='mail'></Icon>
                                                            {enableEmailButtons ? <> Send Invitation</> : <> Please wait</>}
                                                        </Button>
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Table.Cell>
                                </Table.Row>
                            }
                        })}
                        {!loading && sponsorContactsList.length === 0 &&
                            <EmptyGridMessage colSpan={showFullSize ? 8 : 1} message={`You do not have any investor contacts on CapBlock`} />}
                    </Table.Body>
                    {!loading && sponsorContactsList.length > 0 &&
                        <GridFooter colSpan={showFullSize ? 8 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                    }
                </Table>
            }
            {viewContactDetail &&
                <Segment clearing>
                    <Formik
                        validationSchema={validationSchema}
                        enableReinitialize
                        initialValues={sponsorContact}
                        onSubmit={(values, { setFieldError }) => {
                            return handleFormSubmit(values, setFieldError);
                        }}>
                        {({ handleSubmit, isSubmitting, handleReset }) => (
                            <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                                <Form.Group widths='equal'>
                                    <TextInput placeholder='First name' name='firstName' showRequired maxLength={50} />
                                    <TextInput placeholder='Last name' name='lastName' showRequired maxLength={50} />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <TextInput placeholder='Work Phone' name='workPhone' mask={InputMasks.phoneNumber} />
                                    <TextInput placeholder='Cell Phone' name='cellPhone' mask={InputMasks.phoneNumber} />
                                    <TextInput placeholder='Email' name='email' maxLength={128} showRequired />
                                </Form.Group>
                                <TextInput placeholder='Address line 1' name='addressLine1' maxLength={128} />
                                <TextInput placeholder='Address line 2' name='addressLine2' maxLength={128} />
                                <Form.Group widths='equal'>
                                    <TextInput placeholder='City' name='city' maxLength={50} />
                                    <SelectInput options={stateOptions} placeholder='State' name='stateCode' />
                                    <TextInput placeholder='Zip' name='zip' mask={InputMasks.zip} />
                                </Form.Group>
                                {sponsorContact.id > 0 &&
                                    <Form.Group>
                                        <Label color='green'>Accreditation: {sponsorContact.accreditationStatusId}</Label>
                                    </Form.Group>
                                }
                                <Button
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                    floated='right'
                                    primary
                                    type='submit'
                                    content='Save'
                                />
                                {sponsorContact.id > 0 && <ConfirmButton id={sponsorContact.id} floated='right' value='Delete' content='Delete contact?' handleConfirm={handleDelete} />}
                                <Button type="button" content="Cancel" floated='right' onClick={() => closeContact()}></Button>

                            </Form>
                        )}
                    </Formik>
                </Segment>
            }
</>
    )
}

export default SponsorContactGrid