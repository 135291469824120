export interface WireXferProfile {
    entityId: number,
    accountNumber: string,
    routingNumber: string,
  }

  export class WireXferProfile implements WireXferProfile {
    constructor() {     
      this.entityId = 0;
      this.accountNumber = "";
      this.routingNumber = "";
    }
  }