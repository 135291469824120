import React, { useContext, useEffect, useState } from 'react'
import MyInvestmentsGrid from './MyInvestmentsGrid'
import SponsorInvestmentsGrid from './SponsorInvestmentsGrid'
import EntityContext from '../../app/context/entityContext';
import { useStore } from '../../app/stores/store';
import MarketPlaceInvestmentsGrid from './MarketPlaceInvestmentsGrid';
import { Button, Header, Segment } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import InvestmentsGrid from './Manage/InvestmentsGrid';

function InvestmentDashboard() {
  const { entity } = useContext(EntityContext);
  const [totalRecords, setTotalRecords] = useState(0);

  const { investmentStore } = useStore();
  useEffect(() => {
    investmentStore.checkSponsorEntity(entity.id).then(r => {
      setTotalRecords(r.totalRecords);
    });
  }, [investmentStore, entity.id]);

  return (
    <>
      {totalRecords > 0 ?
        <>
          <SponsorInvestmentsGrid />
          <InvestmentsGrid />
        </>
        :
        <>
          <MyInvestmentsGrid />
          <MarketPlaceInvestmentsGrid />
        </>
      }
      <br />
      <Segment basic textAlign='center' >
          <Header>
              Interest in bringing on an investment to CapBlock?
          </Header>
          <Button as={NavLink} to='/investment/create' type='button' content='Create Investment' primary />
      </Segment >
    </>
  )
}

export default InvestmentDashboard