export interface GasInfo {
    low: number;
    lowEth: number;
    safe: number;
    safeEth: number;
    high: number;
    highEth: number;
}

export class GasInfo implements GasInfo {
    constructor() {
        this.low = 0;
        this.safe = 0;
        this.high = 0;
    }
}