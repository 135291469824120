import { useField } from "formik";
import { Form, Input, Label } from "semantic-ui-react";
import InputMask from 'react-input-mask';
import FieldTitle from "./FieldTitle";

interface Props {
    placeholder: string;
    name: string;
    type?: string;
    maxLength?: number;
    readOnly?: boolean;
    width?: number;
    icon?: any;
    action?: any;
    autoFocus?: boolean;
    showRequired?: boolean,
    mask?: any;
    value?: any;
    maskplaceholder?: string;
    alwaysshowMask?: boolean;
    hideTitle?: boolean;
    className?: any;
    onChange?: (values: any) => void;
}

function TextInput(props: Props) {
    const [field, meta, helpers] = useField(props.name);
    const {onChange, showRequired, hideTitle, ...rest} = props;

    const handleChange = (event: any) => {
        helpers.setValue(event.target.value);
        if (onChange) {
            onChange(event.target.value);
        }
        // Trigger validation check manually as onValueChange may happen before the validation check takes place.
        setTimeout(() => { helpers.setValue(event.target.value); }, 0);
    };

    return (
        <Form.Field error={meta.touched && !!meta.error}>
            {!hideTitle && <FieldTitle name={props.name} placeholder={props.placeholder} showRequired={props.showRequired} />}
            <InputMask mask={props.mask} {...field} {...rest} maskPlaceholder={props.maskplaceholder} onChange={handleChange}>
                <Input id={props.name} {...rest} />
                </InputMask>
            {meta.touched && meta.error ? (
                <Label basic color='red' pointing='above'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    )
}
export default TextInput;