export interface EntityProfileDTO {
  id: number,
  entityId: number,
  description: string,
  profileImageUrl: string,
  profileUrlLinkedIn: string,
  profileUrlFacebook: string,
  profileUrlTwitterX: string,
  profileUrlOther: string,
  profileImageUpdated: boolean,
  profileImageFile: File
}

export class EntityProfileDTO implements EntityProfileDTO {
  profileImageUpdated = false;
  description = "";
  profileImageUrl = "";
  profileUrlLinkedIn = "";
  profileUrlFacebook = "";
  profileUrlTwitterX = "";
  profileUrlOther = "";

  constructor(init?: EntityProfileDTO) {
    Object.assign(this, init);
  }
}