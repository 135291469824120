import { Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'semantic-ui-react'
import * as yup from 'yup';
import SelectInput from '../../../app/common/form/SelectInput';
import CurrencyInput from '../../../app/common/form/CurrencyInput';
import { getFloatValue } from '../../../shared/utils';
import { useStore } from '../../../app/stores/store';
import { LoanFee, LoanFeeValues } from '../../../app/models/Loan/LoanFee';
import { FeeCalculationMethodEnum } from '../../../app/models/Loan/LoanFeeEnums';
import DateInput from '../../../app/common/form/DateInput';
import { InputMasks } from '../../../shared/InputMasks';

interface Props {
    loanFee?: LoanFee | null;
    show: boolean;
    onCancel: (fee: LoanFee | null) => void;
}

function NewLoanFeeModal({ loanFee, show, onCancel }: Props) {
    const [loading, setLoading] = useState(false);
    const [editLoanFee, setEditLoanFee] = useState(new LoanFeeValues());
    const emptyOption = { key: '', text: '' };
    const [typeOptions, setTypeOptions] = useState([emptyOption]);
    const [calculationMethodOptions, setCalculationMethodOptions] = useState([emptyOption]);
    const { commonStore, loanStore } = useStore();

    const validationSchema = yup.object({
        feeDate: yup
            .date()
            .typeError("Please enter a valid date")
            .required("Fee Date is required"),
        feeType: yup
            .number()
            .min(1, 'Fee Type is required'),
        calculationMethod: yup
            .number()
            .min(1, 'Calculation Method is required'),
        amount: yup
            .number()
            .when('calculationMethod', {
                is: (v:FeeCalculationMethodEnum) => v !== FeeCalculationMethodEnum.Percentage,
                then: yup.number()
                    .transform((_value, originalValue) => getFloatValue(originalValue))
                    .required('Amount is required')
                    .positive('Amount must be greater than 0'),
            })
            .nullable(),
        percentage: yup
            .number()
            .when('calculationMethod', {
                is: FeeCalculationMethodEnum.Percentage,
                then: yup.number()
                    .transform((_value, originalValue) => getFloatValue(originalValue))
                    .required('Percentage is required')
                    .positive('Percentage must be greater than 0'),
            })
            .nullable(),
    });

    useEffect(() => {
        commonStore.getFeeEnums().then(r => {
            setTypeOptions(r.feeTypes);
            setCalculationMethodOptions(r.calculationMethod);
        })

        if (loanFee)
        {
            setEditLoanFee(loanFee);
        }

    }, [commonStore, loanStore, setTypeOptions, setEditLoanFee, loanFee])

    const handleFormSubmit = (values: any, setFieldError: any) => {
        setLoading(true);
        values.feeTypeDescription = typeOptions.filter(f => Number(f.key) === values.feeType)[0].text;
        values.calculationMethodDescription = calculationMethodOptions.filter(f => Number(f.key) === values.calculationMethod)[0].text;
        if (values.calculationMethod === Number(FeeCalculationMethodEnum.Percentage))
        {
            values.amount = 0;
        }
        if (values.calculationMethod === Number(FeeCalculationMethodEnum.FlatAmount))
        {
            values.percentage = 0;
        }
        onCancel(values);
    }
    return (
        <Modal centered={false} size="mini" open={show} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>{loanFee === undefined ? "Add" : "Edit"} Fee</Modal.Header>
            <Modal.Content>
                <Formik
                    validationSchema={validationSchema}
                    enableReinitialize
                    initialValues={editLoanFee}
                    onSubmit={(values, { setFieldError }) => {
                        handleFormSubmit(values, setFieldError);
                    }} >
                    {({ handleSubmit, values }) => (
                        <Form id='feeForm' className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                            <DateInput placeholder='Date' maxDate={new Date()} name='feeDate' mask={InputMasks.date} dateFormat='MM/dd/yyyy' />
                            <SelectInput options={typeOptions} placeholder='Fee Type' name='feeType' showRequired />
                            <SelectInput options={calculationMethodOptions} placeholder='Calculation Method' name='calculationMethod' showRequired />
                            {values.calculationMethod === FeeCalculationMethodEnum.Percentage &&
                                <CurrencyInput placeholder='Percentage' name='percentage' maxLength={12} thousandSeparator=',' decimalScale={2} allowNegative={false} showRequired  />
                            }

                            {values.calculationMethod !== FeeCalculationMethodEnum.Percentage &&
                                <CurrencyInput placeholder='Amount' name='amount' maxLength={12} thousandSeparator=',' decimalScale={2} allowNegative={false} showRequired />
                            }
                        </Form>
                    )}
                </Formik>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => onCancel(null)} content='Cancel' />
                <Button form="feeForm" primary type='submit' content='Confirm' loading={loading} disabled={loading} />
            </Modal.Actions>
        </Modal>
    )
}

export default NewLoanFeeModal