import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react'
import Loading from '../app/layout/Loading';
import { clearCache } from './utils';

function Logout() {
    const { logout } = useAuth0();

    useEffect(() => {
        clearCache();
        logout({ returnTo: window.location.origin });
    }, [logout])

    return (
        <Loading content='' />
    )
}

export default Logout