export interface EntityInvestmentInsightsView {
    activeUnits:number,
    assetsUnderManagement:number,
    closedDeals:number,
    activeDeals:number
  }
  
  export class EntityInvestmentInsightsView implements EntityInvestmentInsightsView {
    constructor(init?: EntityInvestmentInsightsView) {
      Object.assign(this, init);
    }
  }