import { formatDate } from "../../../shared/utils";
import { VerificationCode } from "../verificationCode/verificationCode";

export interface Distribution {
    id: number;
    entityId: number;
    investmentId: number;
    distributionDate: string;
    distributionType: number;
    distributionTypeDescription: string;
    distributionAmount: number;
    totalDistributionAmount: number;
    totalShareholderAmount: number;
    comment: string;
    shareholderAmount: number;
}

export interface ShareholderDistribution {
    id: number;
    shareholderId: number;
    shareholderEntityId: number;
    shareholderName: string;
    shareClass: string;
    amount: number;
    shareCount: number;
    totalAmount: number;
}

export class Distribution implements Distribution, VerificationCode {
    verificationCode: string = '';
    constructor(init?: Distribution) {
        Object.assign(this, init);
    }
}

export class ShareholderDistribution implements ShareholderDistribution {
    constructor(init?: ShareholderDistribution) {
        Object.assign(this, init);
    }
}


export class DistributionFormValues {
    id: number = 0;
    entityId: number = 0;
    investmentId: number = 0;
    distributionDate: string = formatDate(new Date());
    distributionType: number = 0;
    distributionTypeDescription: string = '';
    distributionAmount?: number;
    totalDistributionAmount: number = 0;
    comment: string = '';

    constructor(distribution?: DistributionFormValues) {
        if (distribution) {
            this.id = distribution.id;
            this.investmentId = distribution.investmentId;
            this.distributionDate = distribution.distributionDate;
            this.distributionType = distribution.distributionType;
            this.distributionTypeDescription = distribution.distributionTypeDescription;
            this.distributionAmount = distribution.distributionAmount;
            this.totalDistributionAmount = distribution.totalDistributionAmount;
            this.comment = distribution.comment;
        }
    }
}