import React from 'react'
import { BalanceResult } from '../../app/models/crypto/BalanceResult'
import { formatCurrency } from '../../shared/utils'

interface Props 
{
    balance: BalanceResult;
}

function CurrentBalance({balance}: Props) {
  return (
    <>
        {balance.tokenType} Balance: <label className='balance'>{formatCurrency(balance.availableValue)}</label>
    </>
  )
}

export default CurrentBalance