import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Header, Segment, Table, Image, Grid, Input, Icon } from 'semantic-ui-react'
import { useStore } from '../../app/stores/store';
import { useMediaQuery } from 'react-responsive';
import { createGridInitialState, formatCurrency, formatDate, getFullSizeWidth, handleGridNavigation, handleGridSearch, handleGridSort } from '../../shared/utils';
import { useNavigate } from 'react-router-dom';
import { PageParams } from '../../app/models/common/PagedResult';
import { MarketPlaceDashboard } from '../../app/models/Investment/MarketPlaceDashboard';
import EmptyGridMessage from '../../shared/EmptyGridMessage';
import GridFooter from '../../shared/GridFooter';
import { EntityFilterParams } from '../../app/models/common/entityFilterParams';
import EntityContext from '../../app/context/entityContext';

function MarketPlaceInvestmentsGrid() {

  const [loading, setLoading] = useState(true);
  const { marketPlaceStore } = useStore();
  const [investments, setInvestments] = useState([new MarketPlaceDashboard()]);
  const [totalRecords, setTotalRecords] = useState(0);
  const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
  const { entity } = useContext(EntityContext);
  const navigate = useNavigate();
  
  const initialState: EntityFilterParams = createGridInitialState({
    entityId: entity.id,
    sortIndex: 'InvestmentName',
    sortOrder: 'ascending'
  });

  const [params, setParams] = useState(initialState);

  const getInvestments = useCallback(() => {
    marketPlaceStore.getMarketPlaceDashboard(params).then(r => {
      setTotalRecords(r.totalRecords);
      setInvestments(r.data);
      setLoading(false);
    });
  }, [marketPlaceStore, params, setLoading]);

  useEffect(() => {
    getInvestments();
  }, [getInvestments]);

  const handleSort = (clickedColumn: string) => {
    if (investments.length === 0) return;
    setParams(handleGridSort(params, clickedColumn));
  }

  const gridChange = (updatedParams: PageParams) => {
    setParams(handleGridNavigation(params, updatedParams));
  }

  const viewInvestment = (id: number) => {
    navigate(`/investment/${id}`);
  }

  const searchChange = (event: any) => {
    setParams(handleGridSearch(params, event.target.value));
  }

  return (
    <Segment clearing loading={loading}>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column width={showFullSize ? 10 : 6} verticalAlign='middle'>
            <Header>Discover Investments</Header>
          </Grid.Column>
          <Grid.Column textAlign='right' verticalAlign='middle' width={showFullSize ? 6 : 10}>
            <Input onChange={(e) => searchChange(e)} placeholder='Search...' icon='search' />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Table singleLine sortable selectable>
        {showFullSize &&
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell onClick={() => handleSort("InvestmentName")} sorted={params.sortIndex === "InvestmentName" && investments.length > 0 ? params.sortOrder : undefined}>Investment</Table.HeaderCell>
              <Table.HeaderCell onClick={() => handleSort("Valuation")} sorted={params.sortIndex === "Valuation" ? params.sortOrder : undefined}>Valuation</Table.HeaderCell>
              <Table.HeaderCell onClick={() => handleSort("TotalChange")} sorted={params.sortIndex === "TotalChange" ? params.sortOrder : undefined}>Total Change (%)</Table.HeaderCell>
              <Table.HeaderCell onClick={() => handleSort("Equity")} sorted={params.sortIndex === "Equity" ? params.sortOrder : undefined}>Equity</Table.HeaderCell>
              <Table.HeaderCell onClick={() => handleSort("SellOffer")} sorted={params.sortIndex === "SellOffer" ? params.sortOrder : undefined}>Sell Offers</Table.HeaderCell>
              <Table.HeaderCell onClick={() => handleSort("BuyOffer")} sorted={params.sortIndex === "BuyOffer" ? params.sortOrder : undefined}>Buy Offers</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        }
        <Table.Body>
          {!loading && investments.map((investment, i) => {
            if (showFullSize) {
              return <Table.Row key={i} style={{ cursor: 'pointer' }} onClick={() => viewInvestment(investment.investmentId)}>
                <Table.Cell>
                  <Header as='h4' image>
                    <Image src='https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png' rounded size='mini' />
                    <Header.Content>
                      {investment.investmentName}
                      <Header.Subheader>
                        {investment.sponsorName}<br />
                        Owned since: {formatDate(investment.ownedSince)}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell textAlign='right'>{formatCurrency(investment.valuation)}</Table.Cell>
                <Table.Cell textAlign='center'><label style={{ color: '#18bd09' }}>{investment.totalChange}</label></Table.Cell>
                <Table.Cell textAlign='center'>{formatCurrency(investment.equity)}</Table.Cell>
                <Table.Cell textAlign='center'>{investment.sellOffer}</Table.Cell>
                <Table.Cell textAlign='center'>{investment.buyOffer}</Table.Cell>
              </Table.Row>
            }
            else {
              return <Table.Row key={i}>
                <Table.Cell>
                  {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                  <Grid>
                    <Grid.Row style={{ cursor: 'pointer' }}>
                      <Grid.Column width={6}>
                        <label onClick={() => handleSort("InvestmentName")}>Investment:</label><br />
                        <label onClick={() => handleSort("Valuation")}>Valuation:</label><br />
                        <label onClick={() => handleSort("TotalChange")}>Total Change:</label><br />
                        <label onClick={() => handleSort("Equity")}>Equity:</label><br />
                        <label onClick={() => handleSort("SellOffer")}>SellOffer:</label><br />
                        <label onClick={() => handleSort("BuyOffer")}>BuyOffer:</label><br />
                      </Grid.Column>
                      <Grid.Column style={{ fontWeight: 'normal' }} >
                        <div style={{ width: '45vw' }} onClick={() => viewInvestment(investment.investmentId)}>
                          {investment.investmentName}<br />
                          {formatCurrency(investment.valuation)}<br />
                          <label style={{ color: '#18bd09' }}>{investment.totalChange}</label><br />
                          {formatCurrency(investment.equity)}<br />
                          {investment.sellOffer}<br />
                          {investment.buyOffer}<br />
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Table.Cell>
              </Table.Row>
            }
          })}
          {!loading && investments.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 8 : 1} message='No investments available' />}
        </Table.Body>
        {!loading && investments.length > 0 &&
          <GridFooter colSpan={showFullSize ? 8 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
        }
      </Table>

    </Segment >
  )
}

export default MarketPlaceInvestmentsGrid