import { Header, List, ListContent, ListDescription, ListHeader, ListIcon, ListItem, Placeholder, PlaceholderLine, PlaceholderParagraph, Segment, Statistic, StatisticGroup, StatisticLabel, StatisticValue } from "semantic-ui-react";
import { formatCurrency, formatDate } from "../../shared/utils";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { InvestmentAssetDetailsView } from "../../app/models/Investment/InvestmentAssetDetailsView";

interface Params {
    investmentId: number
}

function AssetDetailsPanelComponent({ investmentId }: Params) {

    const [loading, setLoading] = useState(true);
    const [assetDetails, setAssetDetails] = useState<InvestmentAssetDetailsView>();
    const { investmentInsightsStore } = useStore();
    const iconSize = 'large'

    useEffect(() => {
        setLoading(true);
        investmentInsightsStore.getAssetDetails(investmentId)
            .then(r => setAssetDetails(r))
            .finally(() => setLoading(false));
    }, [investmentId, investmentInsightsStore])

    return (
        <Segment clearing loading={loading} raised color='green' >
            {loading &&
                <Placeholder>
                    <PlaceholderParagraph>
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </PlaceholderParagraph>
                    <PlaceholderParagraph>
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </PlaceholderParagraph>
                </Placeholder>
            }
            {!loading && assetDetails &&
                <List divided relaxed verticalAlign="top" style={{ padding: 10 }} >
                    <ListItem>
                        <ListIcon name='building outline' size={iconSize} verticalAlign='middle' />
                        <ListContent>
                            <ListHeader><span style={{ fontSize: 'larger' }}>Property Type</span></ListHeader>
                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{assetDetails.propertyType}</span></ListDescription>
                        </ListContent>
                    </ListItem>
                    <ListItem>
                        <ListIcon name='pie graph' size={iconSize} verticalAlign='middle' />
                        <ListContent>
                            <ListHeader><span style={{ fontSize: 'larger' }}>Property Class</span></ListHeader>
                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{assetDetails.propertyClass}</span></ListDescription>
                        </ListContent>
                    </ListItem>
                    <ListItem>
                        <ListIcon name='building outline' size={iconSize} verticalAlign='middle' />
                        <ListContent>
                            <ListHeader><span style={{ fontSize: 'larger' }}>Number of Units</span></ListHeader>
                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{assetDetails.numberOfUnits}</span></ListDescription>
                        </ListContent>
                    </ListItem>
                    <ListItem>
                        <ListIcon name='calendar alternate outline' size={iconSize} verticalAlign='middle' />
                        <ListContent>
                            <ListHeader><span style={{ fontSize: 'larger' }}>Year Built</span></ListHeader>
                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{assetDetails.yearBuilt}</span></ListDescription>
                        </ListContent>
                    </ListItem>
                    <ListItem>
                        <ListIcon name='calendar alternate outline' size={iconSize} verticalAlign='middle' />
                        <ListContent>
                            <ListHeader><span style={{ fontSize: 'larger' }}>Year Renovated</span></ListHeader>
                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{assetDetails.yearRenovated}</span></ListDescription>
                        </ListContent>
                    </ListItem>
                    <ListItem>
                        <ListIcon name='calendar alternate outline' size={iconSize} verticalAlign='middle' />
                        <ListContent>
                            <ListHeader><span style={{ fontSize: 'larger' }}>Acquisition Date</span></ListHeader>
                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{formatDate(assetDetails.acquisitionDate)}</span></ListDescription>
                        </ListContent>
                    </ListItem>
                    <ListItem>
                        <ListIcon name='money bill alternate outline' size={iconSize} verticalAlign='middle' />
                        <ListContent>
                            <ListHeader><span style={{ fontSize: 'larger' }}>Acquisition Price</span></ListHeader>
                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{formatCurrency(assetDetails.acquisitionPrice)}</span></ListDescription>
                        </ListContent>
                    </ListItem>
                    <ListItem>
                        <ListIcon name='line graph' size={iconSize} verticalAlign='middle' />
                        <ListContent>
                            <ListHeader><span style={{ fontSize: 'larger' }}>Current Asset Value</span></ListHeader>
                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{formatCurrency(assetDetails.currentAssetValue)}</span></ListDescription>
                        </ListContent>
                    </ListItem>
                </List>
            }
            {!assetDetails &&
                <Segment placeholder>
                    <Header>
                        No details are available for this investment
                    </Header>
                </Segment>
            }
        </Segment>
    )
}

export default AssetDetailsPanelComponent