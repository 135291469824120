import { Table } from "semantic-ui-react";
import { formatCurrency, getFloatValue } from "../../../../shared/utils";

interface BuyEstimatesInput {
    shareCount: number;
    sharePrice: number;
}
export const BuyEstimates = ({shareCount, sharePrice}: BuyEstimatesInput) => {
    return (
        <Table definition selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Estimated Settlement Details</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Amount</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>Purchase Price</Table.Cell>
                    <Table.Cell textAlign="right">{formatCurrency(getFloatValue(shareCount) * getFloatValue(sharePrice))}</Table.Cell>
                </Table.Row>
            </Table.Body>
            <Table.Footer fullWidth>
                <Table.Row>
                    <Table.Cell>Estimated Cost</Table.Cell>
                    <Table.HeaderCell textAlign="right"><b>{formatCurrency(getFloatValue(shareCount) * getFloatValue(sharePrice))}</b></Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
}