import { useField } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { Dropdown, Form, Label } from "semantic-ui-react";
import FieldTitle from "./FieldTitle";

interface Props {
    placeholder: string;
    name: string;
    options: any;
    showRequired?: boolean;
    hidetitle?: boolean;
    className?: any;
    onChange?: (e:any, d:any) => void;
    disabled?: any;
    value?: string;
    searchable?: boolean;
}

function SelectInput(props: Props) {
    const [field, meta, helpers] = useField(props.name);
    return (
        <Form.Field error={meta.touched && !!meta.error}>
            {!props.hidetitle && <FieldTitle name={props.name} placeholder={props.placeholder} showRequired={props.showRequired} />}
            <Dropdown
                id={props.name}
                fluid
                search={props.searchable ?? true}
                selection
                options={props.options}
                placeholder={props.placeholder}
                disabled={props.disabled}
                onChange={(e, d) => {
                    helpers.setValue(d.value);
                    if (props.onChange)
                    {
                        props.onChange(e, d);
                    }
                }}
                value={field.value}
            />
            {meta.touched && meta.error ? (
                <Label basic color='red' pointing='above'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    )
}

export default observer(SelectInput);