import { RecordState } from "./LoanEscrow";
import { FeeCalculationMethodEnum, FeeTypeEnum } from "./LoanFeeEnums";

export interface LoanFee {
  id: number;
  loanId: number;
  amount: number;
  percentage: number;
  feeDate: Date;
  feeType: FeeTypeEnum;
  feeTypeDescription: string;
  calculationMethod: FeeCalculationMethodEnum;
  calculationMethodDescription: string;
  recordState: RecordState;
  deleted: boolean;
  isPrePaid: boolean;
}

export class LoanFee implements LoanFee {
  constructor(init?: LoanFee) {
    Object.assign(this, init);
  }
}

export class LoanFeeValues {
  id: number = 0;
  loanId: number = 0;
  deleted: boolean = false;
  amount?: number;
  percentage?: number;
  feeDate: Date = new Date();
  feeType?: FeeTypeEnum = 0;
  feeTypeDescription: string = '';
  calculationMethod?: FeeCalculationMethodEnum = 0;
  calculationMethodDescription: string = '';
  isPrePaid: boolean = false;

  constructor(fee?: LoanFeeValues) {
    if (fee) {
      this.id = fee.id;
      this.loanId = fee.loanId;
      this.deleted = fee.deleted;
      this.amount = fee.amount;
      this.percentage = fee.percentage;
      this.feeDate = fee.feeDate;
      this.feeType = fee.feeType;
      this.calculationMethod = fee.calculationMethod;
      this.isPrePaid = fee.isPrePaid;
    }
  }
}