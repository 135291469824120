import React from 'react'
import './Navbar.css';
import TopMenu from './TopMenu';

interface Props {
  sidebarOn: boolean,
  showSidebar: () => void,
  logoutHandler: () => void,
  showNotificationBar: () => void,
}

function NavBar({ showSidebar, logoutHandler, showNotificationBar }: Props) {

  return (
    <TopMenu logoutHandler={logoutHandler} showSidebar={showSidebar} showNotificationBar={showNotificationBar} />
  )
}

export default NavBar