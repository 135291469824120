import { Card, Container, Divider, Grid, Header, Segment, Tab } from "semantic-ui-react";
import { Loan } from "../../../app/models/Loan/Loan";
import { formatCurrency, formatDate, getFloatValue } from "../../../shared/utils";

interface Props {
    loan: Loan
}

export const LoanViewDetails = ({ loan }: Props) => {
    if (loan === undefined) {
        return (<></>);
    }

    const calculateTotalLoanAmount = (loan: Loan) => {
        return getFloatValue(loan?.initialLoanAmount) + getTotalDrawAmount(loan);
    }

    const calculateTotalDrawAmount = (loan: Loan) => {
        return getTotalDrawAmount(loan);
    }

    const getTotalDrawAmount = (loan: Loan) => {
        return loan?.draws?.reduce((total, draw) => total + getFloatValue(draw?.drawAmount ?? '0'), 0);
    }

    return (
        <Tab.Pane attached={true}>
            <Container text>
                <Card.Group centered stackable>
                    <Card raised style={{ paddingTop: "5px", paddingLeft: "5px" }}>
                        <Card.Header as="h3" textAlign='center' style={{ marginBottom: "0px" }}>Loan Balance</Card.Header>
                        <Card.Meta textAlign='center' style={{ paddingTop: "0px" }}>Maturity Date: {formatDate(loan?.maturityDate)}</Card.Meta>
                        <Card.Content as="h3" textAlign='center'>{formatCurrency(loan?.currentLoanBalance)}</Card.Content>
                    </Card>
                </Card.Group>
                <Divider horizontal section>
                    <Header as='h4'>
                        General Details
                    </Header>
                </Divider>
                <Grid columns='equal' stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <label>Loan Name</label>
                            <Segment>{loan?.name}</Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <label>Loan Status</label>
                            <Segment>{loan?.loanStatusName}</Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <label>Borrower</label>
                            <Segment>{loan?.borrowerName}</Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <label>Description</label>
                            <Segment style={{ minimumHeight: 100 }}>{loan?.description}
                                <label>&nbsp;</label>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <label>Property Address</label>
                            <Segment>{loan?.addressLine1}</Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <label>Address Line 2</label>
                            <Segment>{loan?.addressLine2}
                                <label>&nbsp;</label>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <label>City</label>
                            <Segment>{loan?.city}</Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <label>State</label>
                            <Segment>{loan?.stateCode}</Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <label>Zip Code</label>
                            <Segment>{loan?.zip}</Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <label>Purchase Price</label>
                            <Segment>{formatCurrency(loan?.purchasePrice)}</Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <label>Initial Loan Amount</label>
                            <Segment>{formatCurrency(loan?.initialLoanAmount)}</Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <label>After Repair Value</label>
                            <Segment>{formatCurrency(loan?.afterRepairValue)}</Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <label>Loan Start Date</label>
                            <Segment>{formatDate(loan?.loanStartDate)}</Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Divider horizontal section>
                    <Header as='h4'>
                        Terms
                    </Header>
                </Divider>

                <Grid columns='equal' stackable>

                    <Grid.Row>
                        <Grid.Column>
                            <label>Loan Terms (Months)</label>
                            <Segment>{loan?.terms}</Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <label>Loan Type</label>
                            <Segment>{loan?.loanTypeDescription}</Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <label>Annual Interest Rate</label>
                            <Segment>{loan?.annualInterestRate}%</Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <label>Calculation Method</label>
                            <Segment>{loan?.calculationMethodDescription}</Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <label>Pay Period</label>
                            <Segment>{loan?.paymentPeriodDescription}</Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <label>First Payment Date</label>
                            <Segment>{formatDate(loan?.firstPaymentDate)}</Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Divider horizontal section>
                    <Header as='h4'>
                        Draws
                    </Header>
                </Divider>

                <Grid columns='equal' stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <label>Total Draw Amount</label>
                            <Segment>{formatCurrency(calculateTotalDrawAmount(loan))}</Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <label>Total Loan Amount</label>
                            <Segment>{formatCurrency(calculateTotalLoanAmount(loan))}</Segment>
                        </Grid.Column>
                    </Grid.Row>
                    {loan?.draws?.length > 0 &&
                        <>
                            <Grid.Row columns={4}>
                                <Grid.Column textAlign='center' style={{ maxWidth: 50 }}>
                                    <Header as='h4'>#</Header>
                                </Grid.Column>
                                <Grid.Column textAlign='center' style={{ maxWidth: 200 }}>
                                    <Header as='h4'>Status</Header>
                                </Grid.Column>
                                <Grid.Column textAlign='center'>
                                    <Header as='h4'>Amount</Header>
                                </Grid.Column>
                                <Grid.Column textAlign='center'>
                                    <Header as='h4'>Date Received</Header>
                                </Grid.Column>
                            </Grid.Row>
                            {loan?.draws?.map((item, i) => (
                                <Grid.Row key={i} columns={4}>
                                    <Grid.Column textAlign='center' style={{ maxWidth: 50 }}>
                                        <label>{item.drawNumber}</label>
                                    </Grid.Column>
                                    <Grid.Column textAlign='center' style={{ maxWidth: 200 }}>
                                        <label>{item.drawStatus}</label>
                                    </Grid.Column>
                                    <Grid.Column textAlign='center'>
                                        <label>{formatCurrency(item.drawAmount ?? 0)}</label>
                                    </Grid.Column>
                                    <Grid.Column textAlign='center'>
                                        <label>{formatDate(item?.receivedDate)}</label>
                                    </Grid.Column>
                                </Grid.Row>
                            ))}
                </>
                }
                </Grid>
            </Container>
        </Tab.Pane>
    );
};