import { LoanDrawSchedule } from "./LoanDrawSchedule";
import { LoanEscrow } from "./LoanEscrow";
import { LoanFee } from "./LoanFee";

export interface Loan {
  id: number;
  loanType: number;
  loanTypeDescription: string;
  fileNumber: string;
  lenderEntityId: number;
  lenderUserId: number;
  lenderName: string;
  borrowerEntityId: number;
  borowerUserId: number;
  borrowerName: string;
  loanStartDate: string;
  maturityDate: string;
  name: string;
  description: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateCode: string;
  zip: string;
  purchasePrice: number;
  initialLoanAmount: number;
  principalLoanAmount: number;
  afterRepairValue: number;
  annualInterestRate: number;
  terms: number;
  calculationMethod: number;
  calculationMethodDescription: string;
  dailyInterestRate: number;
  initialFundingAmount: number;
  currentLoanBalance: number;
  paymentPeriod: number;
  paymentPeriodDescription: string;
  firstPaymentDate: string;
  loanStatus: number;
  loanStatusName: string;
  totalDrawAmount: number;
  numberDraws: number;
  draws: LoanDrawSchedule[];
  nextDue?: string;
  nextDueAmount?: number;
  numberDrawsAvailable: number;
  totalDrawAmountAvailable: number;
  amortizationTerms: number;

  statementAmount: number;
  statementDueDate: Date | null;
  statementStatus: string;
  currentCreditBalance: number;

  escrows: LoanEscrow[];
  fees: LoanFee[];
}

export class Loan implements Loan {
  constructor(init?: LoanFormValues) {
    Object.assign(this, init);
  }
}

export class LoanFormValues {
  id: number = 0;
  loanType: number = 0;
  fileNumber: string = '';
  lenderEntityId: number = 0;
  borrowerEntityId: number = 0;
  loanStartDate: string = '';
  name: string = '';
  description: string = '';
  addressLine1: string = '';
  addressLine2: string = '';
  city: string = '';
  stateCode: string = '';
  zip: string = '';
  purchasePrice?: number;
  initialLoanAmount?: number;
  principalLoanAmount?: number;
  afterRepairValue?: number;
  annualInterestRate?: number;
  terms?: number;
  calculationMethod: number = 0;
  dailyInterestRate: number = 0;
  initialFundingAmount: number = 0;
  currentLoanBalance: number = 0;
  paymentPeriod: number = 0;
  firstPaymentDate: string = '';
  loanStatus: number = 0;
  totalDrawAmount?: number = 0;
  numberDraws: number = 0;
  draws: LoanDrawSchedule[] = [];
  amortizationTerms?: number;
  statementAmount: number = 0;
  statementDueDate: Date | null = null;
  statementStatus: string = '';
  escrows: LoanEscrow[] = [];
  fees: LoanFee[] = [];

  constructor(loan?: LoanFormValues) {
    if (loan) {
      this.id = loan.id;
      this.loanType = loan.loanType;
      this.fileNumber = loan.fileNumber;
      this.lenderEntityId = loan.lenderEntityId;
      this.borrowerEntityId = loan.borrowerEntityId;
      this.loanStartDate = loan.loanStartDate;
      this.name = loan.name;
      this.description = loan.description;
      this.addressLine1 = loan.addressLine1;
      this.addressLine2 = loan.addressLine2;
      this.city = loan.city;
      this.stateCode = loan.stateCode;
      this.zip = loan.zip;
      this.purchasePrice = loan.purchasePrice;
      this.initialLoanAmount = loan.initialLoanAmount;
      this.principalLoanAmount = loan.principalLoanAmount;
      this.afterRepairValue = loan.afterRepairValue;
      this.annualInterestRate = loan.annualInterestRate;
      this.terms = loan.terms;
      this.calculationMethod = loan.calculationMethod;
      this.dailyInterestRate = loan.dailyInterestRate;
      this.initialFundingAmount = loan.initialFundingAmount;
      this.currentLoanBalance = loan.currentLoanBalance;
      this.paymentPeriod = loan.paymentPeriod;
      this.firstPaymentDate = loan.firstPaymentDate;
      this.loanStatus = loan.loanStatus;
      this.totalDrawAmount = loan.totalDrawAmount;
      this.numberDraws = loan.numberDraws;
      this.draws = loan.draws;
      this.amortizationTerms = loan.amortizationTerms;
      this.escrows = loan.escrows;
      this.fees = loan.fees;
    }
  }
}


export interface LoanPrePayment {
  startDate: Date;
  endDate: Date;
  amount: number;
  description: string;
}

export class LoanPrePayment implements LoanPrePayment {
  constructor(init?: LoanPrePayment) {
    Object.assign(this, init);
  }
}

export interface AmortizationTable {
  paymentDate: Date;
  formattedDate: string;
  beginningBalance: number;
  endingBalance: number;
  payment: number;
  principal: number;
  interest: number;
}

export class AmortizationTable implements AmortizationTable {
  constructor(init?: AmortizationTable) {
    Object.assign(this, init);
  }
}

export enum LoanStatus
{
    Draft = 1,
    Active = 2,
    Repaid = 5,
}