import { VerificationCode } from "../verificationCode/verificationCode";

export interface ChangePassword {
  currentPassword: string,
  newPassword: string,
  confirmPassword: string,
}

export class ChangePassword implements ChangePassword {
  constructor(init?: ChangePasswordFormValues) {
    Object.assign(this, init);
  }
}

export class ChangePasswordFormValues implements VerificationCode {
  currentPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  verificationCode: string = '';

  constructor(password?: ChangePasswordFormValues) {
    if (password) {
      this.currentPassword = password.currentPassword;
      this.newPassword = password.newPassword;
      this.confirmPassword = password.confirmPassword;
    }
  }
}