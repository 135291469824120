import React, { useContext, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Grid, GridColumn, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react'
import EntityContext from '../../app/context/entityContext';
import { PageParams } from '../../app/models/common/PagedResult';
import { Loan, LoanStatus } from '../../app/models/Loan/Loan';
import { BorrowerLoanParams } from '../../app/models/Loan/LoanParams';
import { useStore } from '../../app/stores/store';
import EmptyGridMessage from '../../shared/EmptyGridMessage';
import GridFooter from '../../shared/GridFooter';
import { CacheNameEnum, createGridInitialState, enumToKeyValueList, formatCurrency, formatDate, getEnumText, getFullSizeWidth, getLoanFilter, handleGridNavigation, handleGridSort, setLoanFilter } from '../../shared/utils';

function BorrowerGrid() {

    const [loading, setLoading] = useState(true);
    const { loanStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [loans, setLoans] = useState([new Loan()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const navigate = useNavigate();
    const [statusOptions] = useState(enumToKeyValueList(LoanStatus));
    const [cachedStatus, setCachedStatus] = useState(getLoanFilter(CacheNameEnum.BorrowerLoanFilterCache));
    const initialState: BorrowerLoanParams = createGridInitialState({
        borrowerEntityId: entity.id,
        sortIndex: 'Name',
        sortOrder: 'ascending',
        status: cachedStatus
    });

    const [params, setParams] = useState(initialState);

    useEffect(() => {
        loanStore.getBorrowerLoans(params).then(r => {
            setTotalRecords(r.totalRecords);
            setLoans(r.data);
            setLoading(false);
        });
    }, [loanStore, params, setLoading]);


    const handleSort = (clickedColumn: string) => {
        if (loans.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    const viewLoan = (id: number, status: number) => {
        if (status > 1) navigate('/loanview/' + id);
        navigate(`/loan/${id}`);
    }

    return (
        <Segment clearing loading={loading}>
            <Grid columns={2} padded={false}>
                <GridColumn><Header>My Loans</Header></GridColumn>
                <GridColumn textAlign='right'>
                    <b>Status:</b> <Dropdown placeholder='Status' selection options={statusOptions} value={cachedStatus}
                        onChange={(e, data) => {
                            const value = Number(data.value);
                            setCachedStatus(value);
                            setLoanFilter(value, CacheNameEnum.BorrowerLoanFilterCache)
                            const status = { ...params };
                            status.status = value;
                            setParams(status);
                        }}
                    />
                </GridColumn>
            </Grid>

            <Table singleLine sortable>
                {showFullSize &&
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={() => handleSort("Name")} sorted={params.sortIndex === "Name" && loans.length > 0 ? params.sortOrder : undefined}>Loan Name</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("LenderName")} sorted={params.sortIndex === "LenderName" && loans.length > 0 ? params.sortOrder : undefined}>Lender</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("CurrentLoanBalance")} sorted={params.sortIndex === "CurrentLoanBalance" ? params.sortOrder : undefined}>Amount</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("NextDueDate")} sorted={params.sortIndex === "NextDueDate" ? params.sortOrder : undefined}>Next Due</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("NextDueAmount")} sorted={params.sortIndex === "NextDueAmount" ? params.sortOrder : undefined}>Amount Due</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("NumberDrawsAvailable")} sorted={params.sortIndex === "NumberDrawsAvailable" ? params.sortOrder : undefined}>Draws</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("TotalDrawAmountAvailable")} sorted={params.sortIndex === "TotalDrawAmountAvailable" ? params.sortOrder : undefined}>Remaining Draws</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("LoanStatus")} sorted={params.sortIndex === "LoanStatus" ? params.sortOrder : undefined}>Status</Table.HeaderCell>
                            <Table.HeaderCell>Statement Status</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                }

                <Table.Body>
                    {!loading && loans.map((loan, i) => {
                        if (showFullSize) {
                            return <Table.Row key={i} style={{ cursor: 'pointer' }} onClick={() => viewLoan(loan.id, loan.loanStatus)}>
                                <Table.Cell>{loan.name}</Table.Cell>
                                <Table.Cell>{loan.lenderName}</Table.Cell>
                                <Table.Cell textAlign='right'>{formatCurrency(loan.currentLoanBalance)}</Table.Cell>
                                <Table.Cell textAlign='center'>{loan.nextDue}</Table.Cell>
                                <Table.Cell textAlign='right'>{formatCurrency(loan.nextDueAmount!)}</Table.Cell>
                                <Table.Cell textAlign='center'>{loan.numberDrawsAvailable}</Table.Cell>
                                <Table.Cell textAlign='right'>{formatCurrency(loan.totalDrawAmountAvailable!)}</Table.Cell>
                                <Table.Cell>{loan.loanStatusName}</Table.Cell>
                                <Table.Cell>
                                    {loan.statementStatus && (
                                        <>
                                            {loan.statementStatus !== 'Paid' ? (
                                                <>
                                                    <Popup
                                                        trigger={<Icon
                                                            name="warning circle"
                                                            color={loan.statementStatus === 'Late' ? 'red' : 'yellow'}
                                                            size="large"
                                                        />}
                                                        content={<p>
                                                                <b>Due Date: </b> {formatDate(loan.statementDueDate)}<br />
                                                                <b>Amount: </b> {formatCurrency(loan.statementAmount)}
                                                            </p>}
                                                        position='bottom center'
                                                    />
                                                    <span style={{ color: loan.statementStatus === 'Late' ? 'red' : 'orange', fontWeight: 'bold' }}>
                                                        {loan.statementStatus}
                                                    </span>
                                                </>
                                            ) : (
                                                <>{loan.statementStatus}</>
                                            )}
                                        </>
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        }
                        else {
                            return <Table.Row key={i}>
                                <Table.Cell>
                                    {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                    <Grid>
                                        <Grid.Row style={{ cursor: 'pointer' }}>
                                            <Grid.Column width={6}>
                                                <label onClick={() => handleSort("Name")}>Loan Name:</label><br />
                                                <label onClick={() => handleSort("LenderName")}>Borrower:</label><br />
                                                <label onClick={() => handleSort("CurrentLoanBalance")}>Current Balance:</label><br />
                                                <label onClick={() => handleSort("NextDueDate")}>Next Due:</label><br />
                                                <label onClick={() => handleSort("NextDueAmount")}>Amount Due:</label><br />
                                                <label onClick={() => handleSort("NumberDrawsAvailable")}>Start Date:</label><br />
                                                <label onClick={() => handleSort("TotalDrawAmountAvailable")}>Status:</label><br />
                                                <label onClick={() => handleSort("StatementStatus")}>Statement Status:</label><br />
                                            </Grid.Column>
                                            <Grid.Column style={{ fontWeight: 'normal' }}>
                                                <div style={{ width: '45vw' }} onClick={() => viewLoan(loan.id, loan.loanStatus)}>
                                                    {loan.name}<br />
                                                    {loan.lenderName}<br />
                                                    {formatCurrency(loan.currentLoanBalance)}<br />
                                                    {loan.nextDue}<br />
                                                    {formatCurrency(loan.nextDueAmount!)}<br />
                                                    {loan.numberDrawsAvailable}<br />
                                                    {formatCurrency(loan.totalDrawAmountAvailable!)}<br />
                                                    {loan.loanStatusName}<br />
                                                    {loan.statementStatus !== 'Paid' ? (
                                                        <>
                                                            <span style={{ color: loan.statementStatus === 'Late' ? 'red' : 'orange', fontWeight: 'bold' }}>
                                                                {loan.statementStatus}
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>{loan.statementStatus}</>
                                                    )}
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                        }
                    })}
                    {!loading && loans.length === 0 &&
                        <EmptyGridMessage colSpan={showFullSize ? 9 : 1} message={`You do not hold any ${getEnumText(LoanStatus, cachedStatus).toLowerCase()} loans on CapBlock`} />}
                </Table.Body>
                {!loading && loans.length > 0 &&
                    <GridFooter colSpan={showFullSize ? 9 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                }
            </Table>
        </Segment>
    )
}

export default BorrowerGrid