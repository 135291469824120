import { Formik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Form, Grid, Header, Icon, Item, Popup, Segment, Table } from 'semantic-ui-react';
import TextInput from '../../app/common/form/TextInput';
import { UserCryptoAddress, UserCryptoAddressFormValues } from '../../app/models/settings/userCryptoAddress';
import * as yup from 'yup';
import { ethers } from 'ethers';
import { useStore } from '../../app/stores/store';
import { toast } from 'react-toastify';
import EntityContext from '../../app/context/entityContext';
import { useNavigate } from 'react-router-dom';
import ConfirmButton from '../../shared/ConfirmButton';
import { useMediaQuery } from 'react-responsive';
import { createGridInitialState, formatCryptoAddress, getFullSizeWidth, handleGridNavigation, handleGridSort } from '../../shared/utils';
import EmptyGridMessage from '../../shared/EmptyGridMessage';
import GridFooter from '../../shared/GridFooter';
import { EntityFilterParams } from '../../app/models/common/entityFilterParams';
import { PageParams } from '../../app/models/common/PagedResult';

function CryptoAddressesPanel() {
    const [cryptoAddress, setCryptoAddress] = useState<UserCryptoAddressFormValues>(new UserCryptoAddressFormValues());
    const [newAddress, setNewAddress] = useState(false);
    const [loading, setLoading] = useState(false);
    const { userCryptoAddressStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [addresses, setAddresses] = useState([new UserCryptoAddress()]);
    const navigate = useNavigate();
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const [totalRecords, setTotalRecords] = useState(0);

    const initialState: EntityFilterParams = createGridInitialState({
        entityId: entity.id,
        sortIndex: 'Label',
        sortOrder: 'ascending'
    });

    const [params, setParams] = useState(initialState);

    const getAddresses = useCallback(() => {
        userCryptoAddressStore.getUserCryptoAddresses(params).then(r => {
            setTotalRecords(r.totalRecords);
            setAddresses(r.data);
            setLoading(false);
        });
    }, [userCryptoAddressStore, params]);

    useEffect(() => {
        if (entity.id === undefined) navigate('/');
        setLoading(true);
        getAddresses();
    }, [getAddresses, entity, navigate]);

    const validationSchema = yup.object({
        cryptoAddress: yup
            .string()
            .required('Address is required')
            .test(
                'validAddress',
                'Address is invalid',
                function (value) {
                    return ethers.utils.isAddress(value!);
                }
            ),
        label: yup
            .string()
            .required('Label is required'),
    });

    const handleFormSubmit = (values: UserCryptoAddressFormValues, setFieldError: any) => {
        setLoading(true);
        values.entityID = entity.id;
        userCryptoAddressStore.saveUserCryptoAddress(values)
            .then(response => {
                if (response !== null) {
                    getAddresses();
                    setNewAddress(false);
                    toast.success("Success!", { theme: "colored" });
                }
            })
            .catch(err => {
                toast.error(err.response.data, { theme: "colored" });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSort = (clickedColumn: string) => {
        if (addresses.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    const viewAddress = (address: UserCryptoAddress) => {
        setCryptoAddress(address);
        setNewAddress(true);
    }

    const format = (address: UserCryptoAddress) => {
        if (address.cryptoAddress) return formatCryptoAddress(address.cryptoAddress);
    }

    const handleConfirmDelete = (userCryptoAddressId: number) => {
        userCryptoAddressStore.deleteUserCryptoAddress(userCryptoAddressId)
            .then(r => {
                getAddresses();
                setNewAddress(false);
                toast.success("Address Deleted!", { theme: "colored" });
            })
            .catch(err => {
                toast.error("There was an issue deleting the address.", { theme: "colored" });
            });
    }

    return (
        <>
            <Segment clearing>
                <Header as="h2">
                    USD Coin
                    {!newAddress && <Button floated='right' primary onClick={() => { setCryptoAddress(new UserCryptoAddressFormValues()); setNewAddress(true) }}>New Address</Button>}
                </Header>
                <Item>
                    <Item.Content>
                        <Item.Description>
                            Enter your USDC address below. Be sure to only enter a USD Coin address, as sending any other cryptocurrency will result in permanent loss.
                        </Item.Description>
                    </Item.Content>
                </Item>

                {newAddress ?
                    <Segment clearing>
                        <Formik
                            validationSchema={validationSchema}
                            enableReinitialize
                            initialValues={cryptoAddress}
                            onSubmit={(values, { setFieldError }) => {
                                handleFormSubmit(values, setFieldError);
                            }} >
                            {({ handleSubmit, values }) => (
                                <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                                    <TextInput type='text' placeholder='Address' name='cryptoAddress' maxLength={512} showRequired readOnly={values.statusId === 4} className={values.statusId === 4 ? "readOnlyField" : ""} />
                                    <TextInput type='text' placeholder='Label' name='label' maxLength={512} showRequired />
                                    {values.id > 0 && values.statusId === 1 &&
                                        <ConfirmButton id={values.id} value='Delete' content='Delete Address?' handleConfirm={handleConfirmDelete}  />
                                    }
                                    <Button floated='right' primary type='submit' content='Save' loading={loading} disabled={loading} />
                                    <Button floated='right' type='button' content='Cancel' onClick={() => setNewAddress(false)} />
                                </Form>
                            )}
                        </Formik>
                    </Segment>
                    :
                    <Table singleLine sortable selectable>
                        {showFullSize &&
                            <Table.Header>
                                <Table.Row>
                                <Table.HeaderCell onClick={() => handleSort("Label")} sorted={params.sortIndex === "Label" && addresses.length > 0 ? params.sortOrder : undefined}>Label</Table.HeaderCell>
                                <Table.HeaderCell onClick={() => handleSort("CryptoAddress")} sorted={params.sortIndex === "CryptoAddress" && addresses.length > 0 ? params.sortOrder : undefined}>Address</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                        }

                        <Table.Body>
                            {addresses.map((address, i) => {
                                if (showFullSize) {
                                    return <Table.Row key={i} style={{ cursor: 'pointer' }} onClick={() => viewAddress(address)}>
                                        <Table.Cell>{address.label}</Table.Cell>
                                        <Table.Cell>
                                            <Popup
                                                trigger={<label>{format(address)}</label>}
                                                content={address.cryptoAddress}
                                                position='bottom center'
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                }
                                else {
                                    return <Table.Row key={i}>
                                        <Table.Cell>
                                            {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                            <Grid>
                                                <Grid.Row style={{ cursor: 'pointer' }}>
                                                    <Grid.Column width={6}>
                                                        <label onClick={() => handleSort("Label")}>Label:</label><br />
                                                        <label onClick={() => handleSort("Address")}>Address:</label><br />
                                                    </Grid.Column>
                                                    <Grid.Column style={{ fontWeight: 'normal' }} >
                                                        <div style={{ width: '45vw' }} onClick={() => viewAddress(address)}>
                                                            {address.label}<br />
                                                            {format(address)}<br />
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Table.Cell>
                                    </Table.Row>
                                }
                            })}
                            {!loading && addresses.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 2 : 1} message='You do not have any address' />}
                        </Table.Body>
                        {!loading && addresses.length > 0 &&
                            <GridFooter colSpan={showFullSize ? 2 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                        }
                    </Table>
                }


            </Segment>
        </>
    )
}

export default CryptoAddressesPanel