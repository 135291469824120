import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import CryptoStore from "./cryptoStore";
import EntityStore from "./entityStore";
import LoanDrawStore from "./loanDrawStore";
import LoanStore from "./loanStore";
import TransferStore from "./transferStore";
import UserCryptoAddressStore from "./userCryptoAddressStore";
import UserStore from "./userStore";
import VerificationCodeStore from "./verificationCodeStore";
import MonthlyStatementStore from "./monthlyStatementStore";
import InvestmentStore from "./investmentStore";
import MarketPlaceStore from "./marketPlaceStore";
import InvestmentOfferingStore from "./investmentOfferingStore";
import NotificationStore from "./notificationStore";
import StripeAccountStore from "./stripeAccountStore";
import ZelleAccountStore from "./zelleAccountStore";
import PaymentMethodStore from "./paymentMethodStore";
import WireXferProfileStore from "./wireXferProfileStore";
import SponsorContactStore from "./sponsorContactStore";
import CsvFileImportStore from "./csvFileImportStore";
import EntityProfileStore from "./entityProfileStore";
import InvestmentInsightsStore from "./investmentInsightsStore";

interface Store {
    commonStore: CommonStore;
    userStore: UserStore;
    entityStore: EntityStore;
    verificationCodeStore: VerificationCodeStore;
    userCryptoAddressStore : UserCryptoAddressStore;
    cryptoStore: CryptoStore;
    transferStore: TransferStore;
    loanStore: LoanStore;
    loanDrawStore: LoanDrawStore;
    monthlyStatementStore: MonthlyStatementStore;
    investmentStore: InvestmentStore;
    marketPlaceStore: MarketPlaceStore;
    investmentOfferingStore: InvestmentOfferingStore;
    notificationStore: NotificationStore;
    stripeAccountStore: StripeAccountStore;
    zelleAccountStore: ZelleAccountStore;
    paymentMethodStore: PaymentMethodStore;
    wireXferProfileStore: WireXferProfileStore;
    sponsorContactStore: SponsorContactStore;
    csvFileImportStore: CsvFileImportStore;
    entityProfileStore: EntityProfileStore;
    investmentInsightsStore: InvestmentInsightsStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    entityStore: new EntityStore(),
    verificationCodeStore: new VerificationCodeStore(),
    userCryptoAddressStore: new UserCryptoAddressStore(),
    cryptoStore: new CryptoStore(),
    transferStore: new TransferStore(),
    loanStore: new LoanStore(),
    loanDrawStore: new LoanDrawStore(),
    monthlyStatementStore: new MonthlyStatementStore(),
    investmentStore: new InvestmentStore(),
    marketPlaceStore: new MarketPlaceStore(),
    investmentOfferingStore: new InvestmentOfferingStore(),
    notificationStore: new NotificationStore(),
    stripeAccountStore: new StripeAccountStore(),
    zelleAccountStore: new ZelleAccountStore(),
    paymentMethodStore: new PaymentMethodStore(),
    wireXferProfileStore: new WireXferProfileStore(),
    sponsorContactStore: new SponsorContactStore(),
    csvFileImportStore: new CsvFileImportStore(),
    entityProfileStore: new EntityProfileStore(),
    investmentInsightsStore: new InvestmentInsightsStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}