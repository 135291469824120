import { useContext, useEffect, useState } from 'react'
import { Button, Dropdown, Grid, GridColumn, Header, Segment, Table } from 'semantic-ui-react'
import EmptyGridMessage from '../../../../shared/EmptyGridMessage'
import { useStore } from '../../../../app/stores/store';
import { useMediaQuery } from 'react-responsive';
import { createGridInitialState, formatFullDate, getFullSizeWidth } from '../../../../shared/utils';
import { InvestmentQueryFilter } from '../../../../app/models/common/InvestmentQueryFilter';
import { Investment } from '../../../../app/models/Investment/Investment';
import ConfirmButton from '../../../../shared/ConfirmButton';
import { toast } from 'react-toastify';
import EntityContext from '../../../../app/context/entityContext';
import { Document } from '../../../../app/models/common/Document';
import { DndContext, closestCenter, useSensor, useSensors, PointerSensor, DragEndEvent, TouchSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy, } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import InvestmentImageForm from './InvestmentImageForm';

interface Props {
    investment: Investment;
    reloadImages: number;
    showUploadPanel: boolean;
    setShowUploadPanel: (showPanel: boolean) => void;
}

interface SortableItemProps {
    item: Document;
    showFullSize: boolean;
    setImage: (image: Document) => void;
    setShowForm: (show: boolean) => void;
    showDeleteConfirmation: (image: Document) => void;
}

const SortableItem: React.FC<SortableItemProps> = ({ item, showFullSize, setImage, setShowForm, showDeleteConfirmation }) => {
    const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef, } = useSortable({ id: item.id });

    const style: React.CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const ActionDropdown = ({ item }: any) => {
        return <Dropdown text='Actions'>
            <Dropdown.Menu>
                <Dropdown.Item text='Edit' value='1' icon='pencil' onClick={() => { setImage(item); setShowForm(true); }} />
                <Dropdown.Item text='Delete' value='2' icon='trash' onClick={() => showDeleteConfirmation(item)} />
            </Dropdown.Menu>
        </Dropdown>;
    }

    return (
        <>
            {showFullSize ?
                <tr ref={setNodeRef} style={style} {...attributes}>
                    <td>
                        <span ref={setActivatorNodeRef} style={{ cursor: 'grab', padding: '0 30px' }} {...listeners}>
                            <FontAwesomeIcon icon={faBars} size='lg' />
                        </span>
                    </td>
                    <td><a href={item.shortLivedBlobUrl}>{item.documentName}</a></td>
                    <td>{item.info}</td>
                    <td>{formatFullDate(item.created)}</td>
                    <td style={{ textAlign: 'center', width: '250px' }}>
                        <img
                            width='80%'
                            alt=''
                            src={item.shortLivedBlobUrl}
                        />
                    </td>
                    <td>
                        <ActionDropdown item={item} />
                    </td>
                </tr>
                :
                <tr ref={setNodeRef} style={style} {...attributes}>
                    <td>
                        <Grid>
                            <GridColumn width={8}>
                                <label>Sort Order:</label><br />
                                <label>Name:</label><br /><br />
                                <label>Created:</label><br /><br /><br />
                                Actions
                            </GridColumn>
                            <GridColumn style={{ fontWeight: 'normal' }} >
                                <span ref={setActivatorNodeRef} {...listeners} style={{ cursor: 'grab' }}>
                                    <FontAwesomeIcon icon={faBars} />
                                </span><br />
                                <a href={item.shortLivedBlobUrl}>{item.documentName}</a> <br />
                                {formatFullDate(item.created)} <br />
                                <ActionDropdown item={item} />
                            </GridColumn>
                        </Grid>
                    </td>
                </tr>
            }
        </>
    );
};


function ImagesGrid({ investment, reloadImages, showUploadPanel, setShowUploadPanel }: Props) {

    const [loading, setLoading] = useState(true);
    const { entity } = useContext(EntityContext);
    const { investmentStore } = useStore();
    const [images, setImages] = useState([new Document()]);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const [image, setImage] = useState(new Document());
    const [showForm, setShowForm] = useState(false);

    const initialState: InvestmentQueryFilter = createGridInitialState({
        entityId: entity.id,
        investmentId: investment.id,
        showAll: false,
        sortIndex: 'DocumentName',
        sortOrder: 'ascending'
    });

    const [params, setParams] = useState(initialState);

    useEffect(() => {
        investmentStore.getInvestmentImages(params).then(r => {
            setImages(r.data);
            setLoading(false);
        });
    }, [investmentStore, params, setLoading, reloadImages]);


    const deleteImage = () => {
        investmentStore.deleteInvestmentImage(entity.id, investment.id, image.id).then(r => {
            toast.success("Image deleted successfully!", { theme: "colored" });
            setParams(prevState => ({
                ...prevState,
                showAll: !prevState.showAll
            }));
        }).catch(err => {
            toast.error("There was an issue deleting selected image", { theme: "colored" });
        });
    }

    const uploadFields = () => {
        setShowUploadPanel(!showUploadPanel)
    }

    const sensors = useSensors(
        useSensor(PointerSensor)
    );

    const mobileSensors = useSensors(
        useSensor(TouchSensor)
    );

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active && over && active.id !== over.id) {
            setImages((items) => {
                const oldIndex = items.findIndex((item) => item.id === active.id);
                const newIndex = items.findIndex((item) => item.id === over.id);

                const newItems = arrayMove(items, oldIndex, newIndex).map((item, index) => ({
                    ...item,
                    sortOrder: index + 1,
                }));

                investmentStore.updateInvestmentImageOrder(investment.id, newItems)
                    .then(r => {
                        toast.success("Photo order updated successfully!", { theme: "colored" });
                    })
                    .catch(err => {
                        toast.error(err.response.data, { theme: "colored" });
                    })

                return newItems;
            });
        }
    };

    useEffect(() => {
        setImages((items) =>
            items.map((item, index) => ({
                ...item,
                sortOrder: index + 1,
            }))
        );
    }, []);

    const showDeleteConfirmation = (imageDelete: Document) => {
        setImage(imageDelete);
        document.getElementById("btnDelete")?.click();
    }

    return (
        <Segment clearing loading={loading}>
            {showForm ?
                <InvestmentImageForm onCancel={(refresh) => {if (refresh) setParams(prevState => ({...prevState, showAll: !prevState.showAll}));  setShowForm(false);}} investmentId={investment.id} image={image} />
                :
                <>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column verticalAlign='middle' textAlign='left' width={showFullSize ? 10 : 6}>
                                <Header>Investment Photos</Header>
                            </Grid.Column>
                            <Grid.Column textAlign='right' verticalAlign='middle' width={showFullSize ? 6 : 10}>
                                {investment.sponsorEntityId === entity.id &&
                                    <Button onClick={uploadFields} floated='right' primary>{!showUploadPanel ? "Upload Images" : "Hide Upload Panel"}</Button>}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <DndContext sensors={showFullSize ? sensors : mobileSensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                        <SortableContext items={images} strategy={verticalListSortingStrategy}>
                            <Table sortable>
                                {showFullSize &&
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell></Table.HeaderCell>
                                            <Table.HeaderCell>Name</Table.HeaderCell>
                                            <Table.HeaderCell>Info</Table.HeaderCell>
                                            <Table.HeaderCell>Created On</Table.HeaderCell>
                                            <Table.HeaderCell>Preview</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                }
                                <Table.Body>
                                    {!loading && images.map((image) => (
                                        <SortableItem key={image.id} item={image} showFullSize={showFullSize} setImage={setImage} setShowForm={setShowForm} showDeleteConfirmation={showDeleteConfirmation} />
                                    ))}
                                    {!loading && images.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 6 : 1} message="This investment doesn't have images" />}
                                </Table.Body>
                            </Table>
                        </SortableContext>
                    </DndContext>
                </>
            }
            <ConfirmButton id={1} buttonId='btnDelete' value='Delete Investment Photo' content='Delete Investment Photo?' handleConfirm={deleteImage} hideButton />
        </Segment>
    )
}

export default ImagesGrid