import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { SponsorContactParams } from "../models/sponsorContacts/SponsorContactParams";
import { SponsorContact } from "../models/sponsorContacts/SponsorContact";

export default class SponsorContactStore {

    constructor() {
        makeAutoObservable(this)
    }

    getSponsorContacts = async (params: SponsorContactParams) => {
        return agent.SponsorContacts.getSponsorContacts(params)
    }

    saveSponsorContact = async (contact: SponsorContact) => {
        return agent.SponsorContacts.saveSponsorContact(contact)
    }

    getSponsorContact = async (entityId:number, sponsorContactId: number) => {
        return agent.SponsorContacts.getSponsorContactById(entityId, sponsorContactId);
    }

    deleteSponsorContact = async (entityId:number, sponsorContactId: number) => {
        return await agent.SponsorContacts.deleteSponsorContact(entityId, sponsorContactId);
    }

    isEntityConnectedToSponsor = async (sponsorEntityId:number, entityId: number) => {
        return await agent.SponsorContacts.getIsConnected(sponsorEntityId, entityId);
    }

    createSponsorConnection = async (sponsorEntityId:number, entityId: number, entityType:number) => {
        return await agent.SponsorContacts.createSponsorConnection(sponsorEntityId, entityId, entityType);
    }
    
    sendSingleWelcomeEmail = async (entityId:number, contactId:number) => {
        return await agent.SponsorContacts.sendSingleWelcomeEmail(entityId, contactId);
    }
}
