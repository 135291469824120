import { Icon, Placeholder, PlaceholderLine, PlaceholderParagraph, Statistic, StatisticGroup, StatisticLabel, StatisticValue, Table, TableBody, TableCell, TableRow } from "semantic-ui-react";
import { getEnumValue } from "../../shared/utils";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { KeyMetric, KeyMetricVisualizationSignEnum } from "../../app/models/Investment/KeyMetric";

interface Params {
    investmentId: number
}

function KeyMetricsHeaderPanelComponent({ investmentId }: Params) {

    const [loading, setLoading] = useState(true);
    const { investmentInsightsStore } = useStore();
    const [keyMetrics, setKeyMetrics] = useState([new KeyMetric()])

    useEffect(() => {
        setLoading(true);
        investmentInsightsStore.getKeyMetricsHeader(investmentId)
            .then(r => setKeyMetrics(r))
            .finally(() => setLoading(false))
    }, [investmentId, investmentInsightsStore])

    const getVisualizationSignIcon = (visualizationSign: KeyMetricVisualizationSignEnum) => {
        return getEnumValue(KeyMetricVisualizationSignEnum, visualizationSign) === KeyMetricVisualizationSignEnum.Factor ? 'x' : '%';
    }

    return (
        <>
            {loading &&
                <Placeholder>
                    <PlaceholderParagraph>
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </PlaceholderParagraph>
                </Placeholder>
            }
            {!loading &&
                <>
                    {keyMetrics.length > 0 ?
                        <Table style={{ borderStyle: 'none' }}>
                            <TableBody>
                                <TableRow textAlign="center">
                                    <TableCell textAlign='center'>
                                        <StatisticGroup size="mini" color='blue'>
                                            {keyMetrics.map((k, i) =>
                                            (
                                                <Statistic className='investmentSummaryStatistics' >
                                                    <StatisticLabel>{k.returnTypeDescription}</StatisticLabel>
                                                    <StatisticValue >{k.target} {getVisualizationSignIcon(k.visualizationSign)}</StatisticValue>
                                                </Statistic>
                                            ))}
                                        </StatisticGroup>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        :
                        <>
                            <Icon name='line graph' />
                            <div>No metrics are available for this investment</div>
                        </>
                    }
                </>
            }
        </>
    )
}
export default KeyMetricsHeaderPanelComponent