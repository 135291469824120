import React, { useEffect, useState } from 'react'
import { Container, Grid, Header, Segment, Image } from 'semantic-ui-react'
import EntitySelection from './entitySelection'
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { check2Step, getFullSizeWidth } from '../../shared/utils';

function EntitySwitch() {
    const { user } = useAuth0();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` });
    useEffect(() => {
        if (user && user!["capblock_userid"] === 0) navigate(`/register`);

        if (!check2Step()) return;

        setIsLoading(false);
    }, [navigate, user]);

    return (
        <Segment placeholder vertical className="masthead" loading={isLoading} style={showFullSize ? {height: '100vh'} : {}}>
            <Grid relaxed textAlign='center'>
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column>
                        <Container text textAlign="center">
                            <Header as="h1">
                                <Image src='/assets/logo.png' alt="logo" style={{ marginBottom: 12 }} />
                            </Header>
                        </Container>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column>
                        {!isLoading && <EntitySelection />}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

export default EntitySwitch