import { observer } from 'mobx-react-lite';
import React, { useState } from 'react'
import { Header, Tab } from 'semantic-ui-react'
import CryptoAddressesPanel from './cryptoAddressesPanel';
import SecurityPanel from './securityPanel';
import UserInfo from './UserInfo';
import StripeAccountPannel from './stripeAccountPannel';
import { getLastSegmentOfRoute } from '../../shared/utils';
import ZelleAccountPanel from './zelleAccountPanel';
import WireXferProfilePanel from './wireXferProfilePanel';
import SponsorContactGrid from './sponsorContactGrid';

function Settings() {
    const panes = [
        {
            menuItem: { key: 'userInfo', content: 'User Info' },
            render: () =>
                <Tab.Pane>
                    <UserInfo />
                </Tab.Pane>,
        },
        {
            menuItem: { key: 'security', content: 'Security' },
            render: () =>
                <Tab.Pane>
                    <SecurityPanel />
                </Tab.Pane>,
        },
        {
            menuItem: { key: 'CryptoAddresses', content: 'Crypto Addresses' },
            render: () =>
                <Tab.Pane>
                    <CryptoAddressesPanel />
                </Tab.Pane>,
        },
        {
            menuItem: { key: 'SponsorContacts', content: 'Investor List' },
            render: () =>
                <Tab.Pane>
                    <SponsorContactGrid />
                </Tab.Pane>,          
        },
        {
            menuItem: { key: 'StripeAccount', content: 'Stripe Connect' },
            render: () =>
                <Tab.Pane>
                    <StripeAccountPannel />
                </Tab.Pane>,
        },
        {
            menuItem: { key: 'ZelleAccount', content: 'Zelle Payments' },
            render: () =>
                <Tab.Pane>
                    <ZelleAccountPanel />
                </Tab.Pane>,
        },
        {
            menuItem: { key: 'WireXferProfile', content: 'Wire Transfers' },
            render: () =>
                <Tab.Pane>
                    <WireXferProfilePanel />
                </Tab.Pane>,
        }
    ]

    const lastOfRoute = getLastSegmentOfRoute();
    const stripeTabSelected = lastOfRoute === "stripe";
    const [activeIndex] = useState(stripeTabSelected ? 4 : 0);

    return (
        <>
            <Header as="h2">
                Settings
            </Header>
            <Tab panes={panes} defaultActiveIndex={activeIndex} key={activeIndex} />
        </>
    )
}

export default observer(Settings)