export interface Individual {
    id: number;
    entityId: number;
    occupation: string;
    employer: string;
    employerAddressLine1: string;
    employerAddressLine2: string;
    employerCity: string;
    employerStateCode: string;
    employerZip: string;
    residencyStatus: number;
    countryOfCitizenship: number;
    ssn: string;
    dob: string;
    dlNumber: string;
    dlStateCode: string;
    dlFile: Blob | null;
}

export class Individual implements Individual {
    constructor()
    {
        this.id = 0;
        this.entityId = 0;
        this.occupation = "";
        this.employer = "";
        this.employerAddressLine1 = "";
        this.employerAddressLine2 = "";
        this.employerCity = "";
        this.employerStateCode = "";
        this.employerZip = "";
        this.residencyStatus = 0;
        this.countryOfCitizenship = 0;
        this.ssn = "";
        this.dob = "";
        this.dlNumber = "";
        this.dlStateCode = "";
        this.dlFile = null;
    }
}