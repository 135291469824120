import React, { useEffect, useState } from 'react'
import { Button, Icon, Modal } from 'semantic-ui-react'

interface Props {
    show: boolean,
    handleLogout: () => void,
    stay: () => void
}

function InactivityDialog({ show, handleLogout, stay }: Props) {
    const promptTime = parseInt(process.env.REACT_APP_PROMPT_BEFORE_TIMEOUT_IN_MINUTES ?? "2") * 60;
    const [, setCountdown] = useState(promptTime);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown <= 1) {
                    clearInterval(timer);
                    handleLogout();
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000); // Update the countdown every second

        return () => {
            clearInterval(timer);
        };
    }, [handleLogout]);

    return (
        <Modal size="tiny" centered={false} open={show} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>
                <Icon name='exclamation triangle' size='big' color='red' />
                Your session is about to expire
            </Modal.Header>
            <Modal.Content>
                <p>
                    Your session will end in 2 minutes due to inactivity.
                </p>
                <p>
                    As a security precaution, if there is no additional activity, the session will end and you will be redirected to the login page.
                </p>
                <p>
                    Do you want to stay signed in?
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={stay}>
                    Stay signed in
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default InactivityDialog