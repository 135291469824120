import React from 'react'
import { Button, Grid, GridColumn, GridRow, Header, Modal, Popup, Image } from 'semantic-ui-react';
import { formatCryptoAddress, formatCurrency, getFullSizeWidth } from '../../../shared/utils';
import { toast } from 'react-toastify';
import { BalanceResult } from '../../../app/models/crypto/BalanceResult';
import TokenHistory from './TokenHistory';
import { useMediaQuery } from 'react-responsive';

interface Props {
    token: BalanceResult;
    show: boolean;
    onCancel: () => void;
}

function TokenHistoryModal({ token, show, onCancel }: Props) {

    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })

    const copy = async () => {
        await navigator.clipboard.writeText(token.accountAddress);
        toast.info('Copied!', {
            position: "bottom-right",
            autoClose: 3000,
            closeOnClick: true,
            theme: "light",
        });
    }

    return (
        <Modal centered={false} size="large" open={show} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>Transaction History</Modal.Header>
            <Modal.Content>
                <Grid>
                    {showFullSize ?
                        <GridRow>
                            <GridColumn width={5} verticalAlign='middle'>
                                <Header as='h4' image>
                                    <Image src={token.tokenImage} rounded size='small' />
                                    <Header.Content>
                                        {token.tokenName}
                                        <Header.Subheader>{token.tokenType}</Header.Subheader>
                                    </Header.Content>
                                </Header>
                            </GridColumn>
                            <GridColumn width={6}>
                                <div className='accountAddress' style={{ fontSize: "18pt" }}>
                                    <Popup
                                        trigger={<label>{formatCryptoAddress(token.accountAddress)}</label>}
                                        content={token.accountAddress}
                                        position='bottom center'
                                    />
                                    &nbsp;<Button icon='copy outline' basic size='small' circular onClick={copy} />
                                </div>
                            </GridColumn>
                            <GridColumn width={5} textAlign='right'>Current Balance: <Header as={'h2'}>{formatCurrency(token.availableValue)}</Header></GridColumn>
                        </GridRow>
                        :
                        <>
                            <GridRow>
                                <GridColumn width={8} verticalAlign='middle'>
                                    <Header as='h4' image>
                                        <Image src={token.tokenImage} rounded size='small' />
                                        <Header.Content>
                                            {token.tokenName}
                                            <Header.Subheader>{token.tokenType}</Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </GridColumn>
                                <GridColumn width={8} textAlign='right'>Current Balance: <Header as={'h2'}>{formatCurrency(token.availableValue)}</Header></GridColumn>
                            </GridRow>
                            <GridRow>
                                <GridColumn width={16} verticalAlign='middle'>
                                <div className='accountAddress' style={{ fontSize: "18pt" }}>
                                    <Popup
                                        trigger={<label>{formatCryptoAddress(token.accountAddress)}</label>}
                                        content={token.accountAddress}
                                        position='bottom center'
                                    />
                                    &nbsp;<Button icon='copy outline' basic size='small' circular onClick={copy} />
                                </div>
                                </GridColumn>
                            </GridRow>
                        </>
                    }
                </Grid>
                <TokenHistory token={token} />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onCancel} content='Close' />
            </Modal.Actions>
        </Modal>
    )
}

export default TokenHistoryModal