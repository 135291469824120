export interface WireTransferInfo {
    id: number;
    investmentId: number;
    receivingBank: string;
    bankAddress: string;
    accountType: number;
    routingNumber: string;
    accountNumber: string;
    beneficiaryName: string;
    beneficiaryAddress: string;
    reference: string;
    otherInstructions: string;
    deleted: boolean;
}

export class WireTransferInfo implements WireTransferInfo {
    id: number = 0;
    investmentId: number = 0;
    receivingBank: string = '';
    bankAddress: string = '';
    accountType: number = 0;
    routingNumber: string = '';
    accountNumber: string = '';
    beneficiaryName: string = '';
    beneficiaryAddress: string = '';
    reference: string = '';
    otherInstructions: string = '';
    deleted: boolean = false;

    constructor(init?: WireTransferInfo) {
        Object.assign(this, init);
    }
}