import React, { SyntheticEvent } from 'react'
import { Dropdown, DropdownItemProps } from 'semantic-ui-react'
import { PageParams } from '../app/models/common/PagedResult';
import { setGridsRecordsPerPage } from './utils';

interface Props {
    params: PageParams;
    pageSizeChange: (change: PageParams) => void;
    customOptions?: DropdownItemProps[];
}
function RecordsPerPage({ params, pageSizeChange, customOptions }: Props) {
    const handleRecordsPerPage = (event: SyntheticEvent, data: any) => {
        const size = { ...params };
        size.pageIndex = 0;
        size.pageSize = data.value;

        setGridsRecordsPerPage(data.value);

        pageSizeChange(size);
    }

    const limitOptions: DropdownItemProps[] = [
        { key: "0", value: "5", text: "5" },
        { key: "1", value: "10", text: "10" },
        { key: "2", value: "25", text: "25" },
        { key: "3", value: "50", text: "50" },
        { key: "4", value: "100", text: "100" },
    ];

    const defaultValue = (customOptions ?? limitOptions)[0].text?.toString();
    return (
        <>
            Records per page:&nbsp;&nbsp;
            <Dropdown
                style={{width:"50px", textAlign: "center"}}
                inline={true}
                options={customOptions ?? limitOptions}
                onChange={handleRecordsPerPage}
                value={params.pageSize > 0 ? params.pageSize.toString() : defaultValue}
            />
        </>
    )
}

export default RecordsPerPage