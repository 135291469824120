export interface EntityReview {
    id: number,
    entityId: number,
    userId: number,
    reviewerFirstName: string,
    reviewerLastName: string,
    rating:number,
    reviewText:string
  }
  
  export class EntityReview implements EntityReview {
    constructor(init?: EntityReview) {
      Object.assign(this, init);
    }
  }