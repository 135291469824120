    import React, { useState, useEffect } from 'react';
import { DndContext, closestCenter, useSensor, useSensors, PointerSensor, DragEndEvent, TouchSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy, } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { KeyMetric } from '../../../../app/models/Investment/KeyMetric';
import { useStore } from '../../../../app/stores/store';
import { Button, Checkbox, Dropdown, Grid, GridColumn, Header, Segment, TabPane, Table } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import ConfirmButton from '../../../../shared/ConfirmButton';
import KeyMetricsForm from './KeyMetricsForm';
import { useMediaQuery } from 'react-responsive';
import { getFullSizeWidth, getRandomNumber, getVisualizationSignIcon } from '../../../../shared/utils';
import { toast } from 'react-toastify';

interface SortableItemProps {
    item: KeyMetric;
    showFullSize: boolean;
    setKeyMetricId: (id: number) => void;
    setShowForm: (show: boolean) => void;
    showDeleteConfirmation: (id: number) => void;
    saveKeyMetricShowInHeader: (id: number, checked: boolean) => void;
}

const SortableItem: React.FC<SortableItemProps> = ({ item, showFullSize, setKeyMetricId, setShowForm, showDeleteConfirmation, saveKeyMetricShowInHeader }) => {
    const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef, } = useSortable({ id: item.id });

    const style: React.CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const ActionDropdown = ({ item }: any) => {
        return <Dropdown text='Actions'>
            <Dropdown.Menu>
                <Dropdown.Item text='Edit' value='1' icon='pencil' onClick={() => { setKeyMetricId(item.id); setShowForm(true); }} />
                <Dropdown.Item text='Delete' value='2' icon='trash' onClick={() => showDeleteConfirmation(item.id)} />
            </Dropdown.Menu>
        </Dropdown>;
    }


    return (
        <>
            {showFullSize ?
                <tr ref={setNodeRef} style={style} {...attributes}>
                    <td>
                        <span ref={setActivatorNodeRef} style={{ cursor: 'grab', padding: '0 30px' }} {...listeners}>
                            <FontAwesomeIcon icon={faBars} size='lg' />
                        </span>
                    </td>
                    <td>{item.returnTypeDescription}</td>
                    <td style={{ textAlign: 'center' }}>{item.low}{getVisualizationSignIcon(item.visualizationSign)}</td>
                    <td style={{ textAlign: 'center' }}>{item.high}{getVisualizationSignIcon(item.visualizationSign)}</td>
                    <td style={{ textAlign: 'center' }}>{item.target}{getVisualizationSignIcon(item.visualizationSign)}</td>
                    <td style={{ textAlign: 'center' }}>
                        <Checkbox toggle name='visualizationSign' defaultChecked={item.showInHeader === true}
                            onChange={(e, { checked }) => saveKeyMetricShowInHeader(item.id, checked ?? false)} />
                    </td>
                    <td>
                        <ActionDropdown item={item} />
                    </td>
                </tr>
                :
                <tr ref={setNodeRef} style={style} {...attributes}>
                    <td>
                        <Grid>
                            <GridColumn width={8}>
                                <label>Sort Order:</label><br />
                                <label>Return Type:</label><br /><br />
                                <label>Low:</label><br />
                                <label>High:</label><br />
                                <label>Target:</label><br />
                                <label>Show in Header:</label><br />
                                <ActionDropdown item={item} />
                            </GridColumn>
                            <GridColumn style={{ fontWeight: 'normal' }} >
                                <span ref={setActivatorNodeRef} {...listeners} style={{ cursor: 'grab' }}>
                                    <FontAwesomeIcon icon={faBars} />
                                </span><br />
                                {item.returnTypeDescription} <br />
                                {item.low}{getVisualizationSignIcon(item.visualizationSign)} <br />
                                {item.high}{getVisualizationSignIcon(item.visualizationSign)} <br />
                                {item.target}{getVisualizationSignIcon(item.visualizationSign)} <br />
                                <Checkbox toggle name='visualizationSign' defaultChecked={item.showInHeader === true}
                                    onChange={(e, { checked }) => saveKeyMetricShowInHeader(item.id, checked ?? false)} />
                            </GridColumn>
                        </Grid>
                    </td>
                </tr>
            }
        </>
    );
};


interface Props {
    investmentId: number;
}
const SortableTable: React.FC<Props> = ({ investmentId }) => {
    const [keyMetrics, setKeyMetrics] = useState<KeyMetric[]>([]);
    const [loading, setLoading] = useState(false);
    const { investmentStore } = useStore();
    const [showForm, setShowForm] = useState(false);
    const [keyMetricId, setKeyMetricId] = useState(0);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const [refreshGrid, setRefreshGrid] = useState(0);

    const cancel = (refresh: boolean) => {
        setKeyMetricId(0);
        setShowForm(false);
        if (refresh) setRefreshGrid(getRandomNumber);
    }

    useEffect(() => {
        investmentStore.getKeyMetrics(investmentId).then((r) => {
            setKeyMetrics(r);
        })
            .finally(() => setLoading(false));
    }, [investmentId, setKeyMetrics, investmentStore, setLoading, refreshGrid]);

    const sensors = useSensors(
        useSensor(PointerSensor)
    );

    const mobileSensors = useSensors(
        useSensor(TouchSensor)
    );

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active && over && active.id !== over.id) {
            setKeyMetrics((items) => {
                const oldIndex = items.findIndex((item) => item.id === active.id);
                const newIndex = items.findIndex((item) => item.id === over.id);

                const newItems = arrayMove(items, oldIndex, newIndex).map((item, index) => ({
                    ...item,
                    sortOrder: index + 1,
                }));

                investmentStore.updateInvestmentKeyMetricOrder(investmentId, newItems)
                    .then(r => {
                        toast.success("Key Metric order updated successfully!", { theme: "colored" });
                    })
                    .catch(err => {
                        toast.error(err.response.data, { theme: "colored" });
                    })

                return newItems;
            });
        }
    };

    useEffect(() => {
        setKeyMetrics((items) =>
            items.map((item, index) => ({
                ...item,
                sortOrder: index + 1,
            }))
        );
    }, []);

    const handleConfirmDelete = () => {
        investmentStore.deleteInvestmentKeyMetric(keyMetricId)
            .then(r => {
                toast.success("Key Metric deleted successfully!", { theme: "colored" });
                setRefreshGrid(getRandomNumber);
            })
            .catch(err => {
                toast.error(err.response.data, { theme: "colored" });
            })
    }

    const showDeleteConfirmation = (keyMetricId: number) => {
        setKeyMetricId(keyMetricId);
        document.getElementById("btnDelete")?.click();
    }

    const saveKeyMetricShowInHeader = (id: number, checked: boolean) => {
        investmentStore.updateInvestmentKeyMetricHeader(id, checked).then(r => {
            toast.success("Show in header updated successfully.", { theme: "colored" })
        }).catch(err => {
            toast.error("There was an issue saving the key metric", { theme: "colored" });
        });
    }

    return (
        <TabPane loading={loading}>
            <Segment clearing>
                {!showForm ?
                    <>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column verticalAlign='middle' textAlign='left' width={showFullSize ? 10 : 6}>
                                    <Header>Key Metrics</Header>
                                </Grid.Column>
                                <Grid.Column textAlign='right' verticalAlign='middle' width={showFullSize ? 6 : 10}>
                                    <Button floated='right' primary onClick={() => setShowForm(true)}>Add New</Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <DndContext sensors={showFullSize ? sensors : mobileSensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                            <SortableContext items={keyMetrics} strategy={verticalListSortingStrategy}>
                                <Table>
                                    {showFullSize &&
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Sort Order</Table.HeaderCell>
                                                <Table.HeaderCell>Return Type</Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center'>Low</Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center'>High</Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center'>Target</Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center'>Show in Header</Table.HeaderCell>
                                                <Table.HeaderCell>Actions</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                    }
                                    <Table.Body>
                                        {keyMetrics.map((item) => (
                                            <SortableItem key={item.id} item={item}
                                                setKeyMetricId={setKeyMetricId}
                                                setShowForm={setShowForm}
                                                showDeleteConfirmation={showDeleteConfirmation}
                                                showFullSize={showFullSize}
                                                saveKeyMetricShowInHeader={saveKeyMetricShowInHeader}
                                            />
                                        ))}
                                    </Table.Body>
                                </Table>
                            </SortableContext>
                        </DndContext>
                    </>
                    :
                    <KeyMetricsForm investmentId={investmentId} keyMetricId={keyMetricId} onCancel={cancel} />
                }
            </Segment>
            <ConfirmButton id={1} buttonId='btnDelete' value='Delete Key Metric' content='Delete Key Metric?' handleConfirm={handleConfirmDelete} hideButton />
        </TabPane>
    );
};

export default SortableTable;
