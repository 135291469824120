import { Header, Icon, Segment, Statistic, StatisticGroup, StatisticLabel, StatisticValue, Table, TableBody } from "semantic-ui-react"
import { useStore } from '../../app/stores/store';
import { EntityInvestmentInsightsView } from "../../app/models/GpLanding/EntityInvestmentInsightsView";
import { useEffect, useState } from "react";
import { formatCurrency } from "../../shared/utils";

interface Params {
    entityId: number;
}
function GPInsightsCard({ entityId }: Params) {
    const { entityProfileStore } = useStore();
    const [landingPageInsightsView, setLandingPageInsightsView] = useState(new EntityInvestmentInsightsView());

    useEffect(() => {
        entityProfileStore.getProfileInsights(entityId)
            .then(r => {
                setLandingPageInsightsView(r);
            })
    }, [entityId, entityProfileStore])

    return (
        <Segment clearing className='classTable'>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <Header as='h2' image>
                                <Icon name='chart bar'></Icon>
                                <Header.Content>Insights</Header.Content>
                            </Header>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <TableBody>
                    <Table.Row>
                        <Table.Cell>
                            <Table>
                                <TableBody>
                                    <Table.Row>
                                        <Table.Cell>
                                            <StatisticGroup size="tiny" color='blue' widths='four'>
                                                <Statistic className='investmentSummaryStatistics'>
                                                    <StatisticValue>{landingPageInsightsView.activeUnits}
                                                    </StatisticValue>
                                                    <StatisticLabel>Active Units</StatisticLabel>
                                                </Statistic>
                                                <Statistic className='investmentSummaryStatistics'>
                                                    <StatisticValue>{formatCurrency(landingPageInsightsView.assetsUnderManagement)}
                                                    </StatisticValue>
                                                    <StatisticLabel >Assets Under Management</StatisticLabel>
                                                </Statistic>
                                                <Statistic className='investmentSummaryStatistics'>
                                                    <StatisticValue>{landingPageInsightsView.activeDeals}
                                                    </StatisticValue>
                                                    <StatisticLabel>Active Deals</StatisticLabel>
                                                </Statistic>
                                                <Statistic className='investmentSummaryStatistics'>
                                                    <StatisticValue>{landingPageInsightsView.closedDeals}
                                                    </StatisticValue>
                                                    <StatisticLabel>Closed Deals</StatisticLabel>
                                                </Statistic>
                                            </StatisticGroup>
                                        </Table.Cell>
                                    </Table.Row>
                                </TableBody>
                            </Table>
                        </Table.Cell>
                    </Table.Row>
                </TableBody>
            </Table>
        </Segment>
    )
}

export default GPInsightsCard