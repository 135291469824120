import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { Transfer } from "../models/settings/transfer";
import { AddressBalance } from "../models/crypto/AddressBalance";
import { CapBlockTable } from "../models/common/CapblockTable";
import { CacheNameEnum } from "../../shared/utils";
import { TokenFilter } from "../models/crypto/TokenFilter";


type EntityListAddressBalance = {
    [entityID: string]: { timestamp: number; balance: AddressBalance; }
};

export default class CryptoStore {

    constructor() {
        makeAutoObservable(this)
    }

    getUserBalance = async (entityId: number) => {
        return agent.Crypto.getUserBalance(entityId);
    }

    getUserTokenBalance = async (capblockTable: CapBlockTable, senderTableKeyId: number, tokenType: string) => {
        return agent.Crypto.getUserTokenBalance(capblockTable, senderTableKeyId, tokenType);
    }

    getGasEstimate = async (capblockTable: CapBlockTable, senderTableKeyId: number, transferInfo: Transfer) => {
        return agent.Crypto.getGasEstimate(capblockTable, senderTableKeyId, transferInfo);
    }

    getUserBalanceCached = async (entityId: number) => {
        try {
            const cachedData = sessionStorage.getItem(CacheNameEnum.EntityListAddressBalances);
            let cachedDict: EntityListAddressBalance = cachedData ? JSON.parse(cachedData) : {};
            if (entityId in cachedDict){
                const currentTime = Date.now();
                const cachedTimestamp = cachedDict[entityId].timestamp;
                const timeElapsed = currentTime - cachedTimestamp;
                const fifteen_min = 15 * 60 * 1000;
                if (timeElapsed <= fifteen_min) {
                    return cachedDict[entityId].balance;
                }
            }
            const addressBalance = await this.getUserBalance(entityId);
            cachedDict[entityId] = {
                timestamp: Date.now(),
                balance: addressBalance
            };
            sessionStorage.setItem(CacheNameEnum.EntityListAddressBalances, JSON.stringify(cachedDict));
            return addressBalance;
        }
        catch {
            return new AddressBalance();
        }
    }

    getTokenHistory = async (params: TokenFilter) => {
        return agent.Crypto.getTokenHistory(params);
    }
}
