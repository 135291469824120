import { EntityFilterParams } from "./entityFilterParams";

export interface InvestmentQueryFilter extends EntityFilterParams {
    investmentId: number;
    distributionId?: number;
    showAll?: boolean;
}

export class InvestmentQueryFilter implements InvestmentQueryFilter {
    constructor() {
        this.entityId = 0;
        this.distributionId = 0;
        this.investmentId = 0;
        this.showAll = false;
        this.pageIndex = 0;
        this.pageSize = 0;
        this.sortIndex = '';
        this.sortOrder = "ascending";
    }
}
