export enum KeyMetricVisualizationSignEnum {
    Unknown = 0,
    Percent = 1,
    Factor = 2,
}

export interface KeyMetric {
    id: number;
    investmentId: number;
    returnTypeId: number;
    returnTypeDescription: string;
    low: number | string;
    high: number | string;
    target: number | string;
    sortOrder: number;
    deleted: boolean;
    showInHeader: number | boolean;
    visualizationSign: KeyMetricVisualizationSignEnum;
}

export class KeyMetric implements KeyMetric {
    id: number = 0;
    investmentId: number = 0;
    returnTypeId: number = 0;
    returnTypeDescription: string = '';
    low: number | string = '';
    high: number | string = '';
    target: number | string = '';
    sortOrder: number = 0;
    deleted: boolean = false;
    showInHeader: number | boolean = -1;
    visualizationSign: KeyMetricVisualizationSignEnum = KeyMetricVisualizationSignEnum.Percent;

    constructor(init?: KeyMetric) {
        Object.assign(this, init);
    }
}
