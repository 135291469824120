import React, { useContext, useEffect, useRef, useState } from 'react'
import { Accordion, AccordionContent, AccordionTitle, Button, Form, Grid, GridColumn, GridRow, Header, HeaderContent, Icon, Label, Message, Segment, Table, TableBody, TableCell } from 'semantic-ui-react'
import { FieldArray, Formik } from 'formik';
import SelectInput from '../../../../app/common/form/SelectInput';
import TextInput from '../../../../app/common/form/TextInput';
import { ShareClassification } from '../../../../app/models/Investment/ShareClassification';
import CurrencyInput from '../../../../app/common/form/CurrencyInput';
import { useStore } from '../../../../app/stores/store';
import EditableDropdown from '../../../../app/common/form/EditableDropdown';
import * as yup from 'yup';
import { booleanOptions, formatCurrency, getFloatValue, getPreferredReturnTypeAcronym, getRandomNumber } from '../../../../shared/utils';
import { useDebounce } from '../../../../shared/useDebounce';
import ConfirmButton from '../../../../shared/ConfirmButton';
import EntityContext from '../../../../app/context/entityContext';
import { ShareHolder } from '../../../../app/models/Investment/Shareholder';
import { Hurdle } from '../../../../app/models/Investment/Hurdle';
import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface Props {
  share: ShareClassification;
  updateShare: (newShare: ShareClassification) => void;
  gpClassName: string;
}

function SharesForm({ share, updateShare, gpClassName }: Props) {

  const emptyOption = { key: '', text: '' };
  const { commonStore, investmentStore } = useStore();
  const [shareClassTypeOptions, setShareClassTypeOptions] = useState([emptyOption]);
  const [shareClassTypeNpGPOptions, setShareClassTypeGPOptions] = useState([emptyOption]);
  const [shareClassNameOptions, setShareClassNameOptions] = useState([emptyOption]);
  const [preferredReturnsOptions, setpreferredReturnsOptions] = useState([emptyOption]);
  const [dayCountConventionOptions, setDayCountConventionOptions] = useState([emptyOption]);
  const [partnerOptions, setPartnerOptions] = useState([emptyOption]);
  const formikRef = useRef<any>();
  let percentageError = new Array<string>();
  const [activeIndex, setActiveState] = useState(share.shareHolders && share.shareHolders.length > 0 ? 0 : -1);
  const { entity } = useContext(EntityContext);

  if (share.legalOwnershipPercentageError) percentageError.push("Entity Legal Ownership Percentage");

  const [formChange, setFormChange] = useState<number>(0);
  const debounceFormChange = useDebounce<number>(formChange, 500);

  const [sharePriceChange, setSharePriceChange] = useState<number>(0);
  const debouncesharePriceChange = useDebounce<number>(sharePriceChange, 500);
  const [openIndexes, setOpenIndexes] = useState<number[]>([0]);

  const handleExpandClick = (index: number) => {
    setOpenIndexes((prevOpenIndexes) =>
      prevOpenIndexes.includes(index)
        ? prevOpenIndexes.filter((i) => i !== index)
        : [...prevOpenIndexes, index]
    );
  }

  useEffect(() => {
    if (debounceFormChange > 0) {
      var form = formikRef.current;
      form.submitForm();
    }
  }, [debounceFormChange]);

  useEffect(() => {
    if (debouncesharePriceChange > 0) {
      var form = formikRef.current;
      if (form.values.shareCount !== "" && form.values.capitalRaised !== "") {
        form.setFieldValue("shareValue", calculateShareValue(form.values.shareCount, form.values.capitalRaised));
      }
    }
  }, [debouncesharePriceChange]);

  useEffect(() => {
    commonStore.getInvestmentEnums().then(r => {
      setShareClassTypeOptions(r.shareClassType);
      setShareClassTypeGPOptions(r.shareClassType.filter((item: any) => item.value !== 2))
      setShareClassNameOptions(r.shareEquityClassName);
      setpreferredReturnsOptions(r.preferredReturns);
    });

    commonStore.getLoanEnums().then(r => {
      setDayCountConventionOptions(r.calculationMethod);
    });

    investmentStore.getEntityPartners(entity.id).then(r => {
      setPartnerOptions(r.map(s => ({ key: s.id.toString(), value: s.id, text: s.name })))
    });

    if (share.shareType === 1) {
      setOpenIndexes(Array.from({ length: share.hurdles!.length }, (_, index) => index));
    }
  }, [commonStore, setShareClassTypeOptions, setShareClassNameOptions, investmentStore, setPartnerOptions, entity, setDayCountConventionOptions, share, setOpenIndexes])

  const validationSchema = yup.object({
    shareType: yup
      .number()
      .min(1, 'Class Type is required'),
    shareClass: yup
      .string()
      .trim()
      .required("Equity Class Name is required"),
    capitalRaised: yup
      .number()
      .when('shareType', {
        is: (v: number) => v !== 2,
        then: yup.number()
          .transform((_value, originalValue) => getFloatValue(originalValue))
          .required('Raise Amount is required')
          .positive('Raise Amount must be greater than 0')
      })
      .nullable(),
    shareCount: yup
      .number()
      .required('Share Count is required')
      .positive('Raise Amount must be greater than 0'),
    legalOwnershipPercentage: yup.number()
      .required('Legal Ownership is required')
      .positive('Legal Ownership must be greater than 0')
      .max(100, "Legal Ownership cannot be higher than 100%"),
    preferredReturnType: yup.number()
      .when('shareType', {
        is: 3,
        then: yup.number()
          .min(1, "Preferred Return Type is required")
      })
      .nullable(),
    returnPercentage: yup.number()
      .when('shareType', {
        is: 3,
        then: yup.number()
          .transform((_value, originalValue) => getFloatValue(originalValue))
          .required('Return Percentage is required')
          .positive('Return Percentage must be greater than 0')
          .max(100, "Return Percentage cannot be higher than 100%")
      })
      .nullable(),
    catchUpOnPreferredReturns: yup.string()
      .when('shareType', {
        is: 3,
        then: yup.string()
          .transform((value) => { return value === null ? "" : value })
          .trim()
          .required('Catch Up On Preferred Returns is required'),
      })
      .nullable(),
    shareHolders: yup.array().of(
      yup.object().shape({
        shareholderEntityID: yup.number()
          .min(1, 'ShareHolder is required'),
        shareCount: yup.number()
          .required('Number of Shares is required')
          .positive('Number of Shares must be greater than 0'),
      })
    ),

    hurdles: yup.array()
      .when('shareType', {
        is: 1,
        then: yup.array().of(
          yup.object().shape({
            name: yup.string()
              .trim()
              .required('Hurdle Name is required'),
            finalPercentage: yup.number()
              .transform((value) => Number.isNaN(value) ? null : value)
              .nullable()
              .required('Percentage required')
              .max(100, "Final Percent cannot be higher than 100%"),
            finalHurdle: yup.string()
              .trim()
              .required('Catch Up On Preferred Returns is required'),
            preferredReturnType: yup.number()
              .nullable()
              .when('finalHurdle', {
                is: (val: string) => val === '0',
                then: yup.number()
                  .min(1, "Preferred Return Type is required"),
              }),
            catchUpOnPreferredReturns: yup.string()
              .when('finalHurdle', {
                is: (val: string) => val === '0',
                then: yup.string()
                  .transform((value) => { return value === null ? "" : value })
                  .trim()
                  .required('Catch Up On Preferred Returns is required'),
              })
              .nullable(),
            honorOnlyCapitalEvents: yup.string()
              .when('finalHurdle', {
                is: (val: string) => val === '0',
                then: yup.string()
                  .transform((value) => { return value === null ? "" : value })
                  .trim()
                  .required('Honor Only Capital Events is required'),
              })
              .nullable(),
            dayCountConvention: yup.number()
              .when('finalHurdle', {
                is: (val: string) => val === '0',
                then: yup.number()
                  .transform((value) => { return value === null ? 0 : value })
                  .nullable()
                  .min(1, 'Day Count Convention is required'),
              })
              .nullable(),
            limitPercentage: yup.number()
              .when('finalHurdle', {
                is: (val: string) => val === '0',
                then: yup.number()
                  .transform((value) => Number.isNaN(value) ? null : value)
                  .nullable()
                  .required('Percentage required')
                  .max(100, "limit Percentage cannot be higher than 100%"),
              })
              .nullable()
          })
        ),
      })
      .nullable()
  });

  const handleClick = (index: number) => {
    setActiveState(activeIndex === index ? -1 : index)
  }

  const handleAddShareholder = () => {
    const newShareholder: ShareHolder = {
      investmentShareClassificationID: share.id,
      shareholderEntityID: 0,
      shareClass: "",
      shareCount: "",
      amount: 0,
      value: 0,
      status: 2
    };

    if (share.shareHolders === undefined) {
      share.shareHolders = [];
    }

    const updatedShareClassification = updateShareHolders([...share.shareHolders!, newShareholder]);
    updateShare(updatedShareClassification);
  }

  const handleDeleteShareholder = (index: number) => {
    const updatedShareClassification = updateShareHolders(share.shareHolders?.filter((_, i) => i !== index));
    updateShare(updatedShareClassification);
  };

  const updateShareHolders = (newShareHolders: ShareHolder[] | undefined) => {
    return {
      ...share,
      shareHolders: newShareHolders || []
    };
  };

  const getPartnerName = (id: number) => {
    const name = partnerOptions.find(f => f.key === id.toString());
    return name ? name.text : '';
  }

  const calculatePercentage = (shareCount: any, totalShareCount: any) => {
    return `${formatCurrency(Number(shareCount) / Number(totalShareCount) * 100, true, 3)}%`;
  }

  const showGPShareCountError = () => {
    const totalShareHoldersShareCount = share.shareHolders!.reduce((sum, holder) => sum + Number(holder.shareCount), 0);
    const totalShares = Number(share.shareCount);
    const showError = totalShareHoldersShareCount > 0 && totalShares !== totalShareHoldersShareCount;
    return showError;
  }

  const calculateShareValue = (shareCount: any, capitalRaised: any) => {
    return Number(shareCount) > 0 ? Number(capitalRaised) / Number(shareCount) : 0;
  }

  const updateHurdles = (newHurdles: Hurdle[] | undefined) => {
    return {
      ...share,
      hurdles: newHurdles || []
    };
  };

  const addHurdle = () => {
    const hurdle: Hurdle = {
      id: 0,
      investmentShareClassificationId: 0,
      name: "",
      initialPercentage: 80,
      finalPercentage: 20,
      limitPercentage: 0,
      preferredReturnType: 0,
      catchUpOnPreferredReturns: null,
      honorOnlyCapitalEvents: null,
      dayCountConvention: 0,
      finalHurdle: 1
    };

    if (share.hurdles === undefined) {
      share.hurdles = [];
    }

    const updatedHurdles = [...share.hurdles];

    if (updatedHurdles.length > 0) {
      // Modify the previous hurdle
      const previousHurdle = { ...updatedHurdles[updatedHurdles.length - 1] };
      previousHurdle.finalHurdle = 0;
      updatedHurdles[updatedHurdles.length - 1] = previousHurdle;
    }

    updatedHurdles.push(hurdle);
    const updatedShareClassification = updateHurdles(updatedHurdles);
    updateShare(updatedShareClassification);

    setOpenIndexes([...openIndexes, share.hurdles.length]);
  }

  const handleDeleteHurdle = (index: number) => {
    const updatedHurdles = share.hurdles?.filter((_, i) => i !== index);

    if (updatedHurdles && index > 0) {
      updatedHurdles[index - 1] = {
        ...updatedHurdles[index - 1],
        limitPercentage: 0,
        preferredReturnType: 0,
        catchUpOnPreferredReturns: null,
        honorOnlyCapitalEvents: null,
        dayCountConvention: 0,
        finalHurdle: 1
      };
    }

    const updatedShareClassification = updateHurdles(updatedHurdles);
    updateShare(updatedShareClassification);
  };

  const lastHurdle = (index: number, hurdleLength: number) => {
    return (index + 1) === hurdleLength;
  }

  const changeInitialPercentage = (index: number, value: number) => {
    const form = formikRef.current;
    if (form) {
      const result = Math.max(0, 100 - value);
      form.setFieldValue(`hurdles[${index}].initialPercentage`, result);
    }
  }

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={share}
        onSubmit={(values) => {
          values.capitalRaised = getFloatValue(values.capitalRaised);
          values.minimumInvestmentAmount = getFloatValue(values.minimumInvestmentAmount);
          values.maximumInvestmentAmount = getFloatValue(values.maximumInvestmentAmount);
          values.shareValue = getFloatValue(values.shareValue);
          updateShare(values);
        }}
        innerRef={formikRef}
      >
        {({ handleSubmit, values, errors }) => {

          return <Form onSubmit={handleSubmit} autoComplete='Off'>

            {values.shareType !== 2 && <>

              <Form.Group widths='equal'>
                <SelectInput options={shareClassTypeNpGPOptions} placeholder='Class Type' name='shareType' showRequired onChange={() => setFormChange(getRandomNumber)} />
                <EditableDropdown options={shareClassNameOptions} placeholder='Equity Class Name' name='shareClass' showRequired maxLength={128} onChange={() => setFormChange(getRandomNumber)} />
                <TextInput placeholder='Type of Equity' name='typeOfEquity' maxLength={128} onChange={() => setFormChange(getRandomNumber)} />
                <CurrencyInput placeholder='Raise Amount' name='capitalRaised' maxLength={12} icon={<Icon name='dollar' />} showRequired allowNegative={false} decimalScale={2} thousandSeparator=',' onChange={(e) => { setFormChange(getRandomNumber); setSharePriceChange(getRandomNumber); }} />
              </Form.Group>

              <Form.Group widths='equal'>
                <CurrencyInput placeholder='Minimum Investment' name='minimumInvestmentAmount' maxLength={12} icon={<Icon name='dollar' />} allowNegative={false} decimalScale={2} thousandSeparator=',' onChange={() => setFormChange(getRandomNumber)} />
                <CurrencyInput placeholder='Maximum Investment' name='maximumInvestmentAmount' maxLength={12} icon={<Icon name='dollar' />} allowNegative={false} decimalScale={2} thousandSeparator=',' onChange={() => setFormChange(getRandomNumber)} />
                <CurrencyInput placeholder='Share Count' name='shareCount' maxLength={12} icon={<Icon name='pie chart' />} showRequired allowNegative={false} decimalScale={0} thousandSeparator=',' onChange={(e) => { setFormChange(getRandomNumber); setSharePriceChange(getRandomNumber); }} />
                <CurrencyInput placeholder='Share Price' name='shareValue' maxLength={12} icon={<Icon name='dollar' />} allowNegative={false} decimalScale={2} thousandSeparator=',' onChange={() => setFormChange(getRandomNumber)} />
              </Form.Group>

              {values.shareType === 3 ? (
                <Form.Group widths='equal'>
                  <SelectInput options={preferredReturnsOptions} placeholder='Preferred Return Type' name='preferredReturnType' showRequired onChange={() => setFormChange(getRandomNumber)} />
                  <CurrencyInput placeholder='Return Percentage' name='returnPercentage' maxLength={12} icon={<Icon name='percent' />} allowNegative={false} decimalScale={2} onChange={() => setFormChange(getRandomNumber)} showRequired />
                  <SelectInput options={booleanOptions} placeholder='Catch Up On Preferred Returns' name='catchUpOnPreferredReturns' showRequired onChange={() => setFormChange(getRandomNumber)} />
                  <CurrencyInput placeholder='Entity Legal Ownership' name='legalOwnershipPercentage' maxLength={5} icon={<Icon name='percent' />} allowNegative={false} onChange={() => setFormChange(getRandomNumber)} showRequired />
                </Form.Group>
              )
                :
                (
                  <Form.Group widths={2}>
                    <CurrencyInput placeholder='Entity Legal Ownership' name='legalOwnershipPercentage' maxLength={5} icon={<Icon name='percent' />} allowNegative={false} onChange={() => setFormChange(getRandomNumber)} showRequired />
                  </Form.Group>
                )
              }

              {values.shareType === 1 &&
                <>
                  <Header as='h3'>
                    <HeaderContent>
                      Hurdles
                    </HeaderContent>
                  </Header>

                  <FieldArray
                    name="hurdles"
                    render={arrayHelpers => (
                      <>
                        {values.hurdles && values.hurdles!.map((hurdle, i) => {
                          return <Segment basic clearing className='classTable' key={i}>
                            <Table>
                              <TableBody>
                                <Table.Row>
                                  <TableCell width={8}>
                                    <Icon link onClick={() => handleExpandClick(i)}>
                                      <FontAwesomeIcon icon={openIndexes.includes(i) ? faChevronRight : faChevronDown} size='lg' />
                                    </Icon>
                                    <span style={{ fontSize: '20px' }}>{hurdle.name}</span>
                                  </TableCell>
                                  <TableCell width={3}>Upside Split <b>{hurdle.initialPercentage}/{hurdle.finalPercentage}</b></TableCell>
                                  <TableCell width={2}>Limit <b>{lastHurdle(i, values.hurdles!.length) ? 'Final' : hurdle.limitPercentage + '%'}</b></TableCell>
                                  <TableCell width={2}>{!lastHurdle(i, values.hurdles!.length) && <>Type <b>{getPreferredReturnTypeAcronym(hurdle.preferredReturnType)}</b></>}</TableCell>
                                  <TableCell width={1} textAlign='center'>
                                    {lastHurdle(i, values.hurdles!.length) && values.hurdles!.length > 1 &&
                                      <ConfirmButton
                                        id={0}
                                        iconName='trash'
                                        color='black'
                                        value=''
                                        confirmButtonText='Delete'
                                        content='Delete Hurdle?'
                                        handleConfirm={() => handleDeleteHurdle(i)}
                                      />
                                    }
                                  </TableCell>
                                </Table.Row>
                                {openIndexes.includes(i) && <Table.Row>
                                  <TableCell colSpan={5}>
                                    <Segment clearing basic>

                                      <table className='splitTable'>
                                        <tbody>
                                          <tr>
                                            <td style={{ padding: "0" }}>
                                              <CurrencyInput className='hurdlePercentage' placeholder='' name={`hurdles[${i}].initialPercentage`} disabled maxLength={3} decimalScale={0} />
                                            </td>
                                            <td className='cellMargin'>
                                              % to <b>{share.shareClass}</b>,
                                            </td>
                                            <td style={{ padding: "0" }}>
                                              <CurrencyInput fieldClassName='hurdlePercentage' placeholder='' name={`hurdles[${i}].finalPercentage`} maxLength={3} allowNegative={false} decimalScale={0}
                                                onChange={(e) => {
                                                  changeInitialPercentage(i, e);
                                                  setFormChange(getRandomNumber);
                                                }}
                                              />
                                            </td>
                                            <td className='cellMargin'>
                                              % to <b>{gpClassName}</b>
                                            </td>
                                            {!lastHurdle(i, values.hurdles!.length) &&
                                              <>
                                                <td className='cellMargin'>
                                                  until <b>{share.shareClass}</b> achieves
                                                </td>
                                                <td style={{ padding: "0" }}>
                                                  <CurrencyInput placeholder='' fieldClassName='hurdlePercentage' name={`hurdles[${i}].limitPercentage`} maxLength={3} onChange={(e) => setFormChange(getRandomNumber)} decimalScale={0} />
                                                </td>
                                                <td className='cellMargin'>
                                                  % {hurdle.preferredReturnType === 1 && "cash on cash return"}{hurdle.preferredReturnType === 2 && "return on initial investment"}.
                                                </td>
                                              </>
                                            }
                                          </tr>
                                        </tbody>
                                      </table>

                                      <br />

                                      {lastHurdle(i, values.hurdles!.length) ?
                                        (
                                          <Grid>
                                            <GridColumn width={8}>
                                              <TextInput placeholder='Hurdle Name' name={`hurdles[${i}].name`} maxLength={128} showRequired onChange={() => setFormChange(getRandomNumber)} />
                                            </GridColumn>
                                            <GridColumn width={8}>
                                              <SelectInput options={booleanOptions} placeholder='Final Hurdle' name={`hurdles[${i}].finalHurdle`} showRequired onChange={() => { addHurdle(); setFormChange(getRandomNumber) }} disabled={Object.keys(errors).length > 0} />
                                              {Object.keys(errors).length > 0 &&
                                                <Label color='red' basic>
                                                  <Icon name='info circle' />
                                                  Fix the issues above to enable the dropdown
                                                </Label>
                                              }
                                            </GridColumn>
                                          </Grid>
                                        )
                                        :
                                        (
                                          <>
                                            <Form.Group widths='equal'>
                                              <TextInput placeholder='Hurdle Name' name={`hurdles[${i}].name`} maxLength={128} showRequired onChange={() => setFormChange(getRandomNumber)} />
                                              <SelectInput options={preferredReturnsOptions} placeholder='Preferred Return Type' name={`hurdles[${i}].preferredReturnType`} showRequired onChange={() => setFormChange(getRandomNumber)} />
                                              <SelectInput options={booleanOptions} placeholder='Catch Up On Preferred Returns' name={`hurdles[${i}].catchUpOnPreferredReturns`} showRequired onChange={() => setFormChange(getRandomNumber)} />
                                            </Form.Group>

                                            <Form.Group widths='equal'>
                                              <SelectInput options={booleanOptions} placeholder='Honor Only Capital Events' name={`hurdles[${i}].honorOnlyCapitalEvents`} onChange={() => setFormChange(getRandomNumber)} showRequired />
                                              <SelectInput options={dayCountConventionOptions} placeholder='Day Count Convention' name={`hurdles[${i}].dayCountConvention`} showRequired onChange={() => setFormChange(getRandomNumber)} />
                                              <SelectInput options={booleanOptions} placeholder='Final Hurdle' name={`hurdles[${i}].finalHurdle`} showRequired onChange={() => setFormChange(getRandomNumber)} disabled />
                                            </Form.Group>
                                          </>
                                        )
                                      }
                                    </Segment>
                                  </TableCell>
                                </Table.Row>
                                }
                              </TableBody>
                            </Table>
                          </Segment>
                        })}
                      </>
                    )}
                  />
                </>
              }
            </>
            }

            {values.shareType === 2 &&
              <>
                <Form.Group widths='equal'>
                  <SelectInput options={shareClassTypeOptions} placeholder='Class Type' name='shareType' showRequired onChange={() => setFormChange(getRandomNumber)} disabled />
                  <EditableDropdown options={shareClassNameOptions} placeholder='Equity Class Name' name='shareClass' showRequired maxLength={128} onChange={() => setFormChange(getRandomNumber)} />
                  <CurrencyInput placeholder='Share Count' name='shareCount' maxLength={12} icon={<Icon name='pie chart' />} showRequired allowNegative={false} decimalScale={0} thousandSeparator=',' onChange={() => setFormChange(getRandomNumber)} />
                </Form.Group>

                <Form.Group widths='equal'>
                  <TextInput placeholder='Type of Equity' name='typeOfEquity' maxLength={128} onChange={() => setFormChange(getRandomNumber)} />
                  <CurrencyInput placeholder='Entity Legal Ownership' name='legalOwnershipPercentage' maxLength={5} icon={<Icon name='percent' />} allowNegative={false} onChange={() => setFormChange(getRandomNumber)} />
                </Form.Group>
                <Accordion>
                  <AccordionTitle active={activeIndex === 0} index={0} onClick={() => handleClick(0)}>
                    <Header as='h5' color='blue'>
                      <Icon name='dropdown' />
                      Share Assignment
                    </Header>
                  </AccordionTitle>
                  <AccordionContent active={activeIndex === 0}>
                    <Button type='button' floated='right' compact primary onClick={() => handleAddShareholder()} style={{ marginTop: '-30px' }}>Add Shareholder</Button>

                    {values.shareHolders != null && values.shareHolders.length > 0 &&
                      <Segment>
                        <Grid columns={4} divided='vertically'>
                          <FieldArray
                            name="shareHolders"
                            render={arrayHelpers => (
                              <>
                                {values.shareHolders!.map((item, i) => {
                                  return <GridRow key={i}>
                                    <GridColumn width={5}>
                                      <SelectInput options={partnerOptions} placeholder='Shareholder' name={`shareHolders[${i}].shareholderEntityID`} showRequired onChange={() => setFormChange(getRandomNumber)} />
                                    </GridColumn>
                                    <GridColumn width={5}>
                                      <CurrencyInput placeholder='Number of Shares' name={`shareHolders[${i}].shareCount`} maxLength={12} icon={<Icon name='pie chart' />} showRequired allowNegative={false} decimalScale={0} thousandSeparator=',' onChange={() => setFormChange(getRandomNumber)} />
                                    </GridColumn>
                                    <GridColumn verticalAlign='middle' width={5} textAlign='center'>
                                      <Header as='h4' color={showGPShareCountError() ? 'red' : undefined}>
                                        {Number(item.shareCount) > 0 && <>{item.shareCount}/{values.shareCount} Class B shares / {calculatePercentage(item.shareCount, values.shareCount)}</>}
                                      </Header>
                                    </GridColumn>
                                    <GridColumn width={1} verticalAlign='middle' textAlign='right'>
                                      <ConfirmButton
                                        id={0}
                                        iconName='trash'
                                        color='black'
                                        value=''
                                        confirmButtonText='Delete'
                                        content={`Remove ${getPartnerName(item.shareholderEntityID)} from shareholder list?`}
                                        handleConfirm={() => handleDeleteShareholder(i)}
                                      />
                                    </GridColumn>
                                  </GridRow>
                                })}
                                {showGPShareCountError() && <Message compact negative icon='attention' header='Sum of shares must match the total share count' content='Please review the shares assignments' />}
                              </>
                            )}
                          />
                        </Grid>
                      </Segment>
                    }
                  </AccordionContent>
                </Accordion>

              </>
            }

            {(share.legalOwnershipPercentageError) &&
              <Message compact negative icon='attention' header='The total percentage must sum up to 100%. Please review your allocations for the following fields.'
                list={percentageError} />
            }
          </Form>
        }}
      </Formik>
    </Segment>
  )
}

export default observer(SharesForm)