import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Card, Container, Divider, Grid, Header, List, Popup, Segment, Tab } from "semantic-ui-react";
import EntityContext from "../../../app/context/entityContext";
import { Loan } from "../../../app/models/Loan/Loan";
import { LoanDrawRequestAudit, LoanDrawRequestStatus, LoanDrawSchedule, LoanDrawStatus } from "../../../app/models/Loan/LoanDrawSchedule";
import { useStore } from "../../../app/stores/store";
import ConfirmButton from "../../../shared/ConfirmButton";
import { formatCurrency, formatDate, formatFullDate, getEnumValue, getRandomNumber } from "../../../shared/utils";
import TransferModal from "../../transfer/transferModal";
import { RequestDrawModal } from "./DrawRequestModal";
import { DenyDrawModal } from "./DrawRequestDenyModal";
import { CapBlockTable } from "../../../app/models/common/CapblockTable";
import { TransferModel, TransferType } from "../../../app/models/settings/transfer";
import ProcessDrawModal from "./ProcessDrawModal";

interface Props {
    loan: Loan,
    setParentReloading: (reload: number) => void;
}
export const LoanViewDrawDetails = ({ loan, setParentReloading }: Props) => {

    const { entity } = useContext(EntityContext);
    const { loanDrawStore } = useStore();
    const [drawHistory, setDrawHistory] = useState(new Array<LoanDrawRequestAudit>());
    const [showTransfer, setShowTransfer] = useState(false);
    const initialNumber = getRandomNumber();
    const [reloading, setReloading] = useState(initialNumber);
    const [drawSchedule, setDrawSchedule] = useState(new LoanDrawSchedule());
    const [requestModalOpen, setRequestModalOpen] = useState(false);
    const [denyModalOpen, setDenyModalOpen] = useState(false);
    const [availableDraws, setAvailableDraws] = useState(new Array<LoanDrawSchedule>());
    const [processDrawModalOpen, setProcessDrawModalOpen] = useState(false);

    useEffect(() => {
        if (loan) {
            const availableDraws = loan.draws.map(x => { return { ...x, drawStatus: getEnumValue(LoanDrawStatus, x.drawStatus) } });
            setAvailableDraws(availableDraws);
            loanDrawStore.getLoanDrawRequestHistory(Number(loan.id)).then((r) => {
                setDrawHistory(r);
            }).catch(err => {
                toast.error("There was an issue loading loan draw request history.", { theme: "colored" });
            });
        }
    }, [loan, loanDrawStore, reloading])

    if (loan === undefined) {
        return (<></>);
    }

    const showDrawRequestModal = (drawSChedule: LoanDrawSchedule) => {
        setDrawSchedule(drawSChedule);
        setRequestModalOpen(true);
    }

    const showDenyModal = (drawSChedule: LoanDrawSchedule) => {
        setDrawSchedule(drawSChedule);
        setDenyModalOpen(true);
    }

    const handleConfirmApprove = (drawSChedule: LoanDrawSchedule) => {
        setDrawSchedule(drawSChedule);
        setShowTransfer(true);
    }

    const handleDrawTransferClose = (refreshBalance: boolean) => {
        setShowTransfer(false);
        if (refreshBalance) {
            setParentReloading(getRandomNumber());
            setReloading(getRandomNumber());
        }
    }

    const showProcessDrawRequestModal = () => {
        setDrawSchedule(availableDraws.filter(x => x.isAvailableToDraw)[0]);
        setProcessDrawModalOpen(true);
    }

    const getDrawModel = (): TransferModel => {
        var model = {
            senderCapblockTable: CapBlockTable.entity,
            senderTableKeyId: entity.id,
            transferType: TransferType.loanDraw,
            sourceId: drawSchedule.id,
            amount: drawSchedule.drawAmountRequested ?? 0,
            toCapblockTable: CapBlockTable.entity,
            toTableKeyId: loan.borrowerEntityId,
            toName: loan.borrowerName
        };
        return model;
    }

    const handleConfirmCancel = (loanRequestId: number) => {
        loanDrawStore.cancelLoanDraw(entity.id, loanRequestId)
            .then(r => {
                toast.success("Loan Draw canceled successfully!", { theme: "colored" });
                setReloading(getRandomNumber());
                setParentReloading(getRandomNumber());
            })
            .catch(err => {
                toast.error("There was an issue canceling the loan draw", { theme: "colored" });
            });
    }

    return (
        <Tab.Pane attached={true}>
            {loan.lenderEntityId === entity.id && loan.numberDrawsAvailable >= 1 &&
                <Segment basic clearing>
                    {availableDraws.some(x => x.isAvailableToAcceptOrDeny) ?
                        (
                            <Popup
                                trigger={
                                    <div style={{ display: "inline-block", float: "right" }}>
                                        <Button floated="right" primary type='button' content='Process Draw' disabled />
                                    </div>
                                }
                                header='Draw request is active'
                                content='Approve or deny the request to process draws manually.'
                                on={['hover']}
                                position='bottom left'
                            />
                        )
                        :
                        (
                            <Button floated="right" primary type='button' content='Process Draw' onClick={showProcessDrawRequestModal} />
                        )}
                </Segment>
            }
            <Container text>
                <Card.Group centered stackable>
                    <Card raised style={{ paddingTop: "5px", paddingLeft: "5px" }}>
                        <Card.Header as="h3" textAlign='center' style={{ marginBottom: "0px" }}>Draws Used</Card.Header>
                        <Card.Meta textAlign='center' style={{ paddingTop: "0px" }}>Used: {(loan?.draws.length - loan.numberDrawsAvailable) ?? 0}</Card.Meta>
                        <Card.Content as="h3" textAlign='center'>{formatCurrency(((loan?.totalDrawAmount - loan?.totalDrawAmountAvailable) ?? 0))}</Card.Content>
                    </Card>
                    <Card raised style={{ paddingTop: "5px", paddingLeft: "5px" }}>
                        <Card.Header as="h3" textAlign='center' style={{ marginBottom: "0px" }}>Draws Remaining</Card.Header>
                        <Card.Meta textAlign='center' style={{ paddingTop: "0px" }}>Remaining: {loan?.numberDrawsAvailable ?? 0}</Card.Meta>
                        <Card.Content as="h3" textAlign='center'>{formatCurrency((loan?.totalDrawAmountAvailable ?? 0))}</Card.Content>
                    </Card>
                </Card.Group>

                {processDrawModalOpen && <ProcessDrawModal loan={loan} drawSchedule={drawSchedule} openModal={processDrawModalOpen} ModalOpen={setProcessDrawModalOpen} setReloading={setReloading} setParentReloading={setParentReloading} />}

                {/* Render Draw Request Modal Dialog */}
                {requestModalOpen && <RequestDrawModal loan={loan} drawSchedule={drawSchedule} requestModalOpen={requestModalOpen} setRequestModalOpen={setRequestModalOpen} setReloading={setReloading} setParentReloading={setParentReloading} />}

                {/* Render Deny Draw Request Modal Dialog */}
                {denyModalOpen && <DenyDrawModal drawSchedule={drawSchedule} denyModalOpen={denyModalOpen} setDenyModalOpen={setDenyModalOpen} setReloading={setReloading} setParentReloading={setParentReloading} />}

                {/* render transfer modal dialog */}
                {showTransfer && <TransferModal closeModal={handleDrawTransferClose}
                    show={showTransfer}
                    hideReceive={true}
                    transferModel={getDrawModel()} />
                }

                {availableDraws?.length > 0 &&
                    <>
                        <Grid stackable celled >
                            <Grid.Row columns={1}>
                                <Grid.Column textAlign='center'>
                                    <Header as='h3' style={{ marginTop: '0px' }}>
                                        Draw Schedule
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={5}>
                                <Grid.Column textAlign='center' width={1}>
                                    <Header as='h4'>#</Header>
                                </Grid.Column>
                                <Grid.Column textAlign='center' width={3}>
                                    <Header as='h4'>Status</Header>
                                </Grid.Column>
                                <Grid.Column textAlign='center' width={3}>
                                    <Header as='h4'>Scheduled Amount</Header>
                                </Grid.Column>
                                <Grid.Column textAlign='center' width={3}>
                                    <Header as='h4'>Requested Amount</Header>
                                </Grid.Column>
                                <Grid.Column textAlign='center' width={6}>
                                    <Header as='h4'>Date Received</Header>
                                </Grid.Column>
                            </Grid.Row>
                            {
                                availableDraws?.map((item, i) => (
                                    <Grid.Row key={i} columns={5}>
                                        <Grid.Column textAlign='center' width={1}>
                                            <label>{item.drawNumber}</label>
                                        </Grid.Column>
                                        <Grid.Column textAlign='center' width={3}>
                                            <label>{item.drawStatusDesc}</label>
                                        </Grid.Column>
                                        <Grid.Column textAlign='center' width={3}>
                                            <label>{formatCurrency(item.drawAmount ?? 0)}</label>
                                        </Grid.Column>
                                        <Grid.Column textAlign='center' width={3}>
                                            {item.drawStatus !== LoanDrawStatus.Available &&
                                                <label>{formatCurrency(item.drawAmountRequested ?? 0)}</label>
                                            }
                                        </Grid.Column>
                                        <Grid.Column textAlign='center' width={6}>
                                            {loan.borrowerEntityId === entity.id && item.isAvailableToDraw === true &&
                                                <Button id={item.id} color='teal'
                                                    value='Request Draw' content='Request Draw' style={{ width: 140 }}
                                                    onClick={() => showDrawRequestModal(item)}></Button>
                                            }
                                            {loan.borrowerEntityId === entity.id && item.drawStatus === LoanDrawStatus.Requested &&
                                                <ConfirmButton id={item.id} color='teal' value='Cancel Draw' content='Cancel Draw?' handleConfirm={handleConfirmCancel} />
                                            }
                                            {loan.lenderEntityId === entity.id && item.isAvailableToAcceptOrDeny === true &&
                                                <Button.Group>
                                                    <ConfirmButton id={item.id} color='teal'
                                                        value='Approve' content={`Approve Draw #${item.drawNumber} for requested draw amount: ${formatCurrency(item.drawAmountRequested)}`}
                                                        handleConfirm={() => handleConfirmApprove(item)}></ConfirmButton>
                                                    <Button.Or />
                                                    <Button id={item.id} color='red'
                                                        value='Deny' content='Deny'
                                                        onClick={() => showDenyModal(item)}></Button>
                                                </Button.Group>
                                            }
                                            {item.receivedDate && formatDate(item.receivedDate)}
                                        </Grid.Column>
                                    </Grid.Row>
                                ))}
                        </Grid>

                        <Divider horizontal section>
                            <Header as='h4'>
                                Draw History
                            </Header>
                        </Divider>
                        <List divided relaxed>
                            {
                                drawHistory?.map((item, i) => (
                                    <List.Item key={i}>
                                        <List.Content>
                                            <List.Header>Draw #{item?.drawNumber} was &nbsp;
                                                {item?.comment} on &nbsp;
                                                {formatFullDate(item?.created)}
                                            </List.Header>
                                            <List.Description>
                                                {getEnumValue(LoanDrawRequestStatus, item?.drawRequestStatus) === LoanDrawRequestStatus.ProcessedManually ? "Processed" : "Requested"} Amount: {formatCurrency(item?.drawAmount)} &nbsp;
                                                Draw Schedule Amount: {formatCurrency(item?.drawScheduleAmount)}<br />
                                                {getEnumValue(LoanDrawRequestStatus, item?.drawRequestStatus) === LoanDrawRequestStatus.ProcessedManually && <>Received Date: {formatDate(item?.receivedDate)}&nbsp;</>}
                                                Loan Balance (time of request): {formatCurrency(item?.loanBalanceTimeOfRequest)} &nbsp;
                                                {item.denyReason && item.denyReason.trim().length > 0 ?
                                                    <><br /><label className="txt-red-color">Deny Reason: {item.denyReason}</label> </>
                                                    : ""}
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                ))}
                        </List>
                    </>
                }
            </Container>
        </Tab.Pane>
    );
};