export class CommonRegularExpressions {
    static readonly firstNameOrLastName = /^[a-zA-Z '-]*$/i;
    static readonly entityName = /^[A-Za-z0-9- @.#&!']+$/;
    static readonly phoneNumber = /^([(]*)[1-9]{1}[0-9]{2}([/.\-\s)]*)[1-9]{1}[0-9]{2}([/.\-\s]*)[0-9]{4}$/;
    static readonly password = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
    static readonly zip = /^\d{5}(?:[-\s]\d{4})?$/;
    static readonly ssn = /^(?!000|666)[0-9]{3}([-]?)(?!00)[0-9]{2}\1(?!0000)[0-9]{4}$/;
    static readonly ein = /^\d{2}-?\d{7}$/;
    static readonly verificationCode = /^(\d(\s+)?){6}$/;
    static readonly routingNumber = /^(0|[1-9][0-9]*)$/;
    static readonly accountNumber = /^(0|[1-9][0-9]*)$/;
    static readonly httpurl = /^(http|https):\/\/[^ "]+$/;
  }
  