import React, { createContext, useState, useEffect } from 'react'
import SendInput from './SendInput';
import SendGasEstimate from './SendGasEstimate';
import { GasEstimate } from '../../app/models/crypto/GasEstimate';
import { TransferFormValues, TransferType } from '../../app/models/settings/transfer';
import { BalanceResult } from '../../app/models/crypto/BalanceResult';
import { useStore } from '../../app/stores/store';
import { getEnumValue } from '../../shared/utils';
import { CapBlockTable } from '../../app/models/common/CapblockTable';
import Loading from '../../app/layout/Loading';
import { toast } from 'react-toastify';
export const TransferFormContext = createContext();

function Send(props) {
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [transfer, setTransfer] = useState(new TransferFormValues());
    const [gasEstimate, setGasEstimate] = useState(new GasEstimate());
    const [currentToken, setCurrentToken] = useState(new BalanceResult());
    const { transferStore } = useStore();

    useEffect(() => {
        const action = props.actionParams;
        if (action && action.trxHash !== "") {
            transferStore.getTransferByHash(action.trxHash).then(r => {

                if (r.statusDescription === "Pending") {
                    const values = new TransferFormValues();
                    values.senderCapblockTable = getEnumValue(CapBlockTable, r.senderCapblockTable);
                    values.senderTableKeyId = r.senderTableKeyId;
                    values.amount = r.amount;
                    values.amountEth = r.amount;
                    values.tokenType = r.tokenType;
                    values.transferType = r.transferType;
                    values.toCapblockTable = getEnumValue(CapBlockTable, r.toCapblockTable);
                    values.toTableKeyId = r.toTableKeyId;
                    values.toAddress = r.toTableKeyId;
                    values.transferAction = action;
                    values.currentTransactionFee = r.transactionFee;
                    setTransfer(values);
                }
                else {
                    toast.error("This transaction has been mined already", { theme: "colored" });
                    props.closeModal(true);
                }
            })
            .finally(() => setActiveStepIndex(2));
        }
        else {
            setActiveStepIndex(1);
        }
    }, [props, setActiveStepIndex, transferStore, setTransfer])

    const GetAddressInfo = () => {
        return (
            <>
                {props.transferModel && props.transferModel.transferType === TransferType.escrowFunding
                    ? <>
                        {props.transferModel.recipients.map((s, index) => (
                            <label key={index}>{s.toName}<br /></label>
                        ))}
                    </>
                    : <label>{props.transferModel.toName}</label>
                }
            </>
        );
    };

    if (activeStepIndex === 0) return <Loading content='Loading...' />;

    return (
        <TransferFormContext.Provider value={{ activeStepIndex, setActiveStepIndex, transfer, setTransfer, gasEstimate, setGasEstimate, GetAddressInfo, currentToken, setCurrentToken }}>
            {activeStepIndex === 1 && <SendInput userBalance={props.userBalance} closeModal={props.closeModal}
                    transferModel={props.transferModel} callBack={props.callBack} />
            }
            {activeStepIndex === 2 && <SendGasEstimate userBalance={props.userBalance} closeModal={props.closeModal}
                    transferModel={props.transferModel} callBack={props.callBack} />
            }
        </TransferFormContext.Provider>
    )
}

export default Send