import { makeAutoObservable } from "mobx";
import agent from "../api/agent";


export default class PaymentMethodStore {
    constructor() {
        makeAutoObservable(this)
    }

    getAvaileblePaymentMethods = async (loanId: number) => {
        return agent.PaymentMethods.getAvailablePaymentMethods(loanId);
    }
}