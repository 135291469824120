import React from 'react'
import { TabPane } from 'semantic-ui-react'
import { Investment, ParticipationType } from '../../../app/models/Investment/Investment';
import SponsorDistributionGrid from './SponsorDistributionGrid';
import ShareholderDistributionGrid from './ShareholderDistributionGrid';

interface Props {
    investment: Investment;
}

function Distributions({ investment }: Props) {
    return (
        <TabPane>
            {investment.entityParticipation === ParticipationType.shareholder ?
                <ShareholderDistributionGrid investment={investment} />
                :
                <SponsorDistributionGrid investment={investment} />
            }
        </TabPane>
    )
}

export default Distributions