export interface BalanceResult {
    accountAddress: string;
    tokenImage: string;
    tokenName: string;
    tokenType: string;
    balance: number;
    price: number;
    value: number;
    availableValue: number;
    tokenAddress: string;
    geckoId: string;
}

export class BalanceResult implements BalanceResult {
    constructor() {
        this.accountAddress = "";
        this.tokenImage = "";
        this.tokenName = "";
        this.tokenType = "";
        this.balance = 0;
        this.price = 0;
        this.value = 0;
        this.availableValue = 0;
        this.tokenAddress = "";
        this.geckoId = "";
    }
}