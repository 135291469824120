import { Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'semantic-ui-react'
import * as yup from 'yup';
import { LoanEscrow, LoanEscrowValues } from '../../../app/models/Loan/LoanEscrow';
import SelectInput from '../../../app/common/form/SelectInput';
import CurrencyInput from '../../../app/common/form/CurrencyInput';
import { getFloatValue } from '../../../shared/utils';
import { useStore } from '../../../app/stores/store';

interface Props {
    loanEscrow?: LoanEscrow | null;
    show: boolean;
    onCancel: (escrow: LoanEscrow | null) => void;
}

function NewLoanEscrowModal({ loanEscrow, show, onCancel }: Props) {
    const [loading, setLoading] = useState(false);
    const [editLoanEscrow, setEditLoanEscrow] = useState(new LoanEscrowValues());
    const emptyOption = { key: '', text: '' };
    const [typeOptions, setTypeOptions] = useState([emptyOption]);
    const { commonStore, loanStore } = useStore();

    const validationSchema = yup.object({
        escrowTypeId: yup
            .number()
            .min(1, 'Escrow Type is required'),
        amount: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Amount is required')
            .min(0, 'Amount cannot be negative'),
        startingBalance: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Starting Balance is required')
            .min(0, 'Starting Balance cannot be negative'),
    });

    useEffect(() => {
        commonStore.getEscrowTypes().then(r => setTypeOptions(r));

        if (loanEscrow)
        {
            setEditLoanEscrow(loanEscrow);
        }

    }, [commonStore, loanStore, setTypeOptions, setEditLoanEscrow, loanEscrow])

    const handleFormSubmit = (values: any, setFieldError: any) => {
        setLoading(true);
        values.escrowTypeName = typeOptions.filter(f => f.key === values.escrowTypeId)[0].text;
        onCancel(values);
    }
    return (
        <Modal centered={false} size="mini" open={show} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>{loanEscrow === undefined ? "Add" : "Edit"} Escrow Item</Modal.Header>
            <Modal.Content>
                <Formik
                    validationSchema={validationSchema}
                    enableReinitialize
                    initialValues={editLoanEscrow}
                    onSubmit={(values, { setFieldError }) => {
                        handleFormSubmit(values, setFieldError);
                    }} >
                    {({ handleSubmit }) => (
                        <Form id='escrowForm' className="ui form" onSubmit={handleSubmit} autoComplete='Off'>

                            <SelectInput options={typeOptions} placeholder='Escrow Type' name='escrowTypeId' showRequired={true} />
                            <CurrencyInput placeholder='Amount' name='amount' maxLength={12} thousandSeparator=',' decimalScale={2} allowNegative={false} showRequired={true} />
                            <CurrencyInput placeholder='Starting Balance' name='startingBalance' maxLength={12} thousandSeparator=',' decimalScale={2} allowNegative={false} showRequired={true} />
                        </Form>
                    )}
                </Formik>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => onCancel(null)} content='Cancel' />
                <Button form="escrowForm" primary type='submit' content='Confirm' loading={loading} disabled={loading} />
            </Modal.Actions>
        </Modal>
    )
}

export default NewLoanEscrowModal