import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { Registration } from "../models/registration/registration";
import { ChangePasswordFormValues } from "../models/settings/changePassword";
import { PhoneNumbers } from "../models/settings/phoneNumbers";
import { User, UserFormValues } from "../models/registration/user";
import { CacheNameEnum } from "../../shared/utils";

export default class UserStore {
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    createUser = async (user: Registration) => {
        return await agent.Users.create(user);
    }

    changePassword = async (passwords: ChangePasswordFormValues) => {
        return await agent.Users.changePassword(passwords);
    }

    getUserFromAuth = async () => {
        return agent.Users.getUserFromAuth();
    }

    getCurrentUser = async () => {
        const cachedData = sessionStorage.getItem(CacheNameEnum.UserInfo);
        if (cachedData) {
            const parsedData = JSON.parse(cachedData) as { timestamp: number; data: UserFormValues };
            const currentTime = new Date().getTime();
            const fifteen_min = 15 * 60 * 1000;
            if (parsedData.timestamp && currentTime - parsedData.timestamp < fifteen_min) {
                return parsedData.data;
            }
        }

        const userInfo = await agent.Users.getCurrentUser();
        if (userInfo !== null) {
            const newData = {
                timestamp: new Date().getTime(),
                data: userInfo
            };

            sessionStorage.setItem(CacheNameEnum.UserInfo, JSON.stringify(newData));
        }
        return userInfo;
    }

    getUserPhones = async () => {
        return agent.Users.getUserPhones();
    }

    updateUserPhones = async (userPhones: PhoneNumbers) => {
        return await agent.Users.updateUserPhones(userPhones);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    updateUser = async (user: UserFormValues) => {
        return await agent.Users.updateUser(user);
    }

    saveUserVerificationOptions = async (user: User) => {
        return await agent.Users.saveUserVerificationOptions(user);
    }
}
