import React from 'react'

interface Props{
    name: string;
    placeholder: string;
    showRequired?: boolean;
}
function FieldTitle({name, placeholder, showRequired} : Props) {
  return (
    <label htmlFor={name}>{placeholder} {showRequired ? <label style={{color: 'red'}}>*</label> : <></> }</label>
  )
}

export default FieldTitle