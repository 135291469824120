import { Formik } from 'formik';
import React, { useState } from 'react'
import { Button, Form, Header, Modal } from 'semantic-ui-react';
import TextInput from '../../app/common/form/TextInput';
import { CommonRegularExpressions } from '../../shared/CommonRegularExpressions';
import VerificationCodeModal from '../../shared/VerificationCodeModal';
import * as Yup from 'yup';
import { InputMasks } from '../../shared/InputMasks';
import { PhoneNumbers, PhoneNumbersFormValues } from '../../app/models/settings/phoneNumbers';
import { useStore } from '../../app/stores/store';
import { toast } from 'react-toastify';
import { sleep } from '../../shared/utils';
import { observer } from 'mobx-react-lite';
import { User } from '../../app/models/registration/user';

interface Props {
    show: boolean;
    onCancel: () => void;
    userPhones: PhoneNumbers;
    user: User;
}

function AddPhoneNumberModal({ show, onCancel, userPhones, user }: Props) {
    const [showVerification, setShowVerification] = useState(false);
    const [newPhone, setNewPhone] = useState(new PhoneNumbersFormValues());
    const [loading, setLoading] = useState(false);
    const [submit, setSubmit] = useState(false);
    const { userStore } = useStore();

    const handleFormSubmit = (values: PhoneNumbersFormValues, setFieldError: any) => {
        if (submit) {
            setLoading(true);
            userStore.updateUserPhones(values)
                .then(r => {
                    if (userPhones.alternatePhone1 === '' || userPhones.alternatePhone1 === null)
                    {
                        userPhones.alternatePhone1 = values.alternatePhone1;
                    }
                    else {
                        userPhones.alternatePhone2 = values.alternatePhone1;
                    }
                    toast.success("New phone added", { theme: "colored" });
                })
                .finally(() => {
                    setSubmit(false);
                    setLoading(false);
                    onCancel();
                });
        }
        else {
            newPhone.alternatePhone1 = values.alternatePhone1;
            setNewPhone(newPhone);
            setShowVerification(true);
        }
    }

    const submitForm = async () => {
        setShowVerification(false);
        setSubmit(true);
        await sleep(100);
        document.getElementById("submitButton")?.click();
    }

    return (
        <Modal size="mini" centered={false} open={show} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>Add phone number</Modal.Header>
            <Modal.Content>
                <Header as='h5'>Verification is required to add new devices.<br />To help keep your account safe, we'll text you a verification code.</Header>
                <Formik
                    validationSchema={Yup.object({
                        alternatePhone1: Yup
                            .string()
                            .required('Phone Number is required')
                            .matches(CommonRegularExpressions.phoneNumber, 'Invalid Phone Number'),
                    })}
                    enableReinitialize
                    initialValues={newPhone}
                    onSubmit={(values, { setFieldError }) => {
                        handleFormSubmit(values, setFieldError);
                    }} >
                    {({ handleSubmit }) => (
                        <Form id='phoneNumberForm' className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                            <TextInput placeholder='Phone Number' name='alternatePhone1' mask={InputMasks.phoneNumber} showRequired autoFocus />
                        </Form>
                    )}
                </Formik>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onCancel} content='Cancel' />
                <Button id="submitButton" form="phoneNumberForm" primary type='submit' content='Send code' loading={loading} disabled={loading} />
            </Modal.Actions>

            {showVerification && <VerificationCodeModal show={showVerification} onCancel={() => { setShowVerification(false); }} onValidCode={submitForm} alternatePhone={newPhone.alternatePhone1} userOptIn={user.optInSMS} />}

        </Modal>
    )
}

export default observer(AddPhoneNumberModal)