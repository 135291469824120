import { useContext, useEffect, useState } from "react";
import { Button, Container, Form, Label, Modal } from "semantic-ui-react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useStore } from "../../../../app/stores/store";
import { OfferingFormValues, OfferingAction, Offering } from "../../../../app/models/Investment/InvestmentOffering";
import { toast } from "react-toastify";
import EntityContext from '../../../../app/context/entityContext';
import SelectInput from "../../../../app/common/form/SelectInput";
import { enumToKeyValueList, formatCurrency, formatDate, getFloatValue } from "../../../../shared/utils";
import { Investment, ParticipationType } from "../../../../app/models/Investment/Investment";
import CurrencyInput from "../../../../app/common/form/CurrencyInput";
import { useDebounce } from "../../../../shared/useDebounce";
import { SellEstimates } from "./SellEstimates";
import { BuyEstimates } from "./BuyEstimates";
import { AddressBalance } from "../../../../app/models/crypto/AddressBalance";

interface NewOfferingModalData {
    investment: Investment;
    handleReloadOfferings?: () => void;
    setOfferingModalParentOpen: (isOpen: boolean) => void;
}
const NewOfferingModal = ({ investment, handleReloadOfferings, setOfferingModalParentOpen }: NewOfferingModalData) => {
    const emptyOption = { key: '', value: 0 };
    const { entity } = useContext(EntityContext);
    const [loading, setLoading] = useState(true);
    const { commonStore, investmentOfferingStore, cryptoStore } = useStore();
    const [userBalance, setUserBalance] = useState(new AddressBalance());
    const [entityShareInfo, setEntityShareInfo] = useState({availableEntityShareCount : 0, entityShareValue : 0});
    const [offering, setOffering] = useState(new OfferingFormValues());
    const [estimated, setEstimated] = useState(new Offering());
    const [expiryOptions, setExpiryOptions] = useState([emptyOption]);
    const [actionOptions, setActionOptions] = useState(enumToKeyValueList(OfferingAction));
    const [offeringAction, setOfferingAction] = useState(OfferingAction.Sell);
    const [offeringModalOpen, setOfferingModalOpen] = useState(false);
    const [expiryDate, setExpiryDate] = useState<string>("");
    const [shareCount, setShareCount] = useState<number>(0);
    const debounceShareCount = useDebounce<number>(shareCount, 500);
    const [sharePrice, setSharePrice] = useState<number>(0);
    const debounceSharePrice = useDebounce<number>(sharePrice, 500);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        setLoading(true);
        setOfferingModalOpen(true);
        commonStore.getOfferingExpiryDurations().then(r => {
            setExpiryOptions(r);
        })
        calculateExpiryDate(offering.expiryDuration);
        var estimated = new Offering();
        estimated.offeringEntityName = entity.name;
        estimated.entityID = entity.id;
        estimated.action = OfferingAction.Sell;
        if (investment.entityParticipation === ParticipationType.nonShareholder) {
            setActionOptions(actionOptions.filter((item) => item.key !== '2'));
            setOfferingAction(OfferingAction.Buy);
            estimated.action = OfferingAction.Buy;
            offering.action = OfferingAction.Buy;
            setOffering(offering);
        }
        setEstimated(estimated);
        cryptoStore.getUserBalanceCached(entity.id).then((r) => {
            setUserBalance(r);
            investmentOfferingStore.getEntityShareCountAndBalance(entity.id, investment.id).then((s) => {
                setEntityShareInfo({availableEntityShareCount : s.availableEntityShareCount, entityShareValue : s.entityShareValue});
                setLoading(false);
            });
        });

    }, [investment, investmentOfferingStore, commonStore, cryptoStore, entity])

    const getEstimatedCalculations = () => {
        if (offeringAction === OfferingAction.Sell &&
            debounceShareCount > 0 &&
            debounceSharePrice > 0) {
            var input = {
                investmentId: investment.id,
                entityId: entity.id,
                shareCount: debounceShareCount,
                sharePrice: debounceSharePrice,
                availableEntityShareCount: estimated.availableEntityShareCount,
                entityShareValue: estimated.entityShareValue
            };
            investmentOfferingStore.getOfferingSellEstimation(input).then((r) => {
                if (r) {
                    const estimatedOffering = {
                        ...estimated,
                        costBasis: r.costBasis,
                        capitalGains: r.capitalGains,
                        saleProceeds: r.saleProceeds,
                        gpOverride: r.gpOverride,
                        totalDueSeller: r.totalDueSeller,
                        availableEntityShareCount: r.availableEntityShareCount,
                        entityShareValue: r.entityShareValue
                    };
                    setEstimated(estimatedOffering);
                }
            }).catch(err => {
                toast.error("There was an issue getting sell estimations.", { theme: "colored" });
            });
        }
    }

    useEffect(() => {
        if (isValid()){
            getEstimatedCalculations();
        }
    }, [investment, investmentOfferingStore, commonStore, entity, debounceShareCount, debounceSharePrice])

    const validationSchema = Yup.object({
        action: Yup.number().oneOf([1, 2]).typeError('Action is required').required('Action is required'),
        expiryDuration: Yup.number().typeError('Expiry is required').required('Expiry is required'),
        shareCount: Yup.number().typeError('Invalid Quantity')
            .transform((_value, originalValue) => parseInt(originalValue))
            .required('Quantity is required')
            .positive('Quantity must be greater than 0')
            .test(
                'match',
                'Insufficient Share Quantity',
                function (value) {
                    if (offeringAction === OfferingAction.Sell)
                        return value! <= entityShareInfo.availableEntityShareCount;
                    return true;
                }
            ),
        sharePrice: Yup.number().typeError('Invalid Unit Price')
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Unit Price is required')
            .positive('Unit Price must be greater than 0'),
    });

    const calculateExpiryDate = (expiryDuration: number) => {
        if (!expiryDuration) return;
        const today = new Date();
        const expiryDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + expiryDuration * 7);
        setExpiryDate(formatDate(expiryDate));
    }

    const isValid = () => {
        if (offeringAction === OfferingAction.Sell &&
            (shareCount <= 0 ||
            sharePrice <= 0 ||
            entityShareInfo.availableEntityShareCount <= 0 ||
            shareCount > entityShareInfo.availableEntityShareCount)) {
            return false;
        }

        return true;
    }
    const saveOffering = async (input: OfferingFormValues) => {
        if (submitted === true) return;
        if (input.action === OfferingAction.Sell && input.shareCount > estimated.availableEntityShareCount) {
            return;
        }

        setSubmitted(true);
        input.entityID = entity.id;
        input.investmentId = investment.id;
        investmentOfferingStore.saveOffering(new OfferingFormValues(input)).then((r) => {
            toast.success("Offering created successfully.", { theme: "colored" });
            setOfferingModalOpen(false);
            setOfferingModalParentOpen(false);
            if (handleReloadOfferings) handleReloadOfferings();
        }).catch(err => {
            toast.error("There was an issue saving offer.", { theme: "colored" });
            setSubmitted(false);
        });
    };

    return (
        <Modal
            open={offeringModalOpen} closeOnEscape={false} closeOnDimmerClick={false}
            onClose={() => { setOfferingModalOpen(false); setOfferingModalParentOpen(false); }} size="small">
            <Modal.Header>New Offering</Modal.Header>
            <Modal.Content>
                <Formik enableReinitialize={true}
                    validationSchema={validationSchema}
                    initialValues={offering}
                    onSubmit={(values) => { saveOffering(values); }}>
                    {({ values, setValues, handleSubmit, isValid, isSubmitting, dirty }) => (
                        <Form id="offeringForm" className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                            {!loading && offeringAction === OfferingAction.Sell &&
                                <Container textAlign="right" style={{ paddingBottom: "5px" }}>
                                    <Label>Available Share Quantity: {entityShareInfo.availableEntityShareCount ?? 0}</Label>
                                    <Label>Share Value: {formatCurrency(entityShareInfo.entityShareValue ?? 0)}</Label>
                                </Container>
                            }
                            {!loading && offeringAction === OfferingAction.Buy &&
                                <Container textAlign="right" style={{ paddingBottom: "5px" }}>
                                    <Label>Available Balance: {formatCurrency(userBalance.balances.reduce((a, b) => a + b.value, 0) ?? 0)}</Label>
                                </Container>
                            }
                            <Form.Group widths='equal'>
                                <SelectInput placeholder='Offer Type' name='action' options={actionOptions} showRequired
                                    onChange={(e, d) => setOfferingAction(d.value)} />
                                <CurrencyInput placeholder='Quantity' name='shareCount' showRequired thousandSeparator=',' decimalScale={0} allowNegative={false} maxLength={16}
                                    onChange={(e) => { setShareCount(parseInt(e)) }} />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <SelectInput placeholder='Expiry' name='expiryDuration' options={expiryOptions} showRequired
                                    onChange={(e, d) => calculateExpiryDate(d.value)} />
                                <CurrencyInput placeholder='Unit Price ($)' name='sharePrice' showRequired thousandSeparator=',' prefix="$" decimalScale={2} allowNegative={false} maxLength={16}
                                    onChange={(e) => {  setSharePrice(e); }} />
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
                <label>Expiry Date: {expiryDate}</label>
                {!loading && offeringAction === OfferingAction.Sell && <SellEstimates offering={estimated}  investment={investment} isValid={isValid}/>}
                {!loading && offeringAction === OfferingAction.Buy && <BuyEstimates shareCount={debounceShareCount} sharePrice={debounceSharePrice} />}
            </Modal.Content>
            <Modal.Actions style={{ display: 'flex', justifyContent: 'center' }}>
                {/* new offering actions - create offering*/}
                {!loading &&
                    <Button form="offeringForm" primary type='submit'
                    disabled={!isValid() || submitted}
                    content='Create Offering' />
                }
                <Button content="Cancel" onClick={(e) => { setOfferingModalOpen(false); setOfferingModalParentOpen(false); }} />
            </Modal.Actions>
        </Modal>
    )
};
export default NewOfferingModal;