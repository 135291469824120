import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { InvestmentQueryFilter } from "../models/common/InvestmentQueryFilter";
import { OfferingFormValues, OfferingInitiate, OfferingSellEstimation } from "../models/Investment/InvestmentOffering";

export default class InvestmentOfferingStore {
    constructor() {
        makeAutoObservable(this)
    }

    getOffering = (entityId: number, investmentOfferingId: number) => {
        return agent.InvestmentOfferingAgent.getOffering(entityId, investmentOfferingId);
    }

    getOfferingSellEstimation = (params: OfferingSellEstimation) => {
        return agent.InvestmentOfferingAgent.getOfferingSellEstimation(params);
    }

    getEntityShareCountAndBalance = (entityId: number, investmentId: number) => {
        return agent.InvestmentOfferingAgent.getEntityShareCountAndBalance(entityId, investmentId);
    }

    getOfferings  = async (filter: InvestmentQueryFilter) => {
        return agent.InvestmentOfferingAgent.getOfferings(filter);
    }

    getPendingApprovalOfferings  = async (filter: InvestmentQueryFilter) => {
        return agent.InvestmentOfferingAgent.getPendingApprovalOfferings(filter);
    }

    saveOffering = async (input: OfferingFormValues) => {
        return agent.InvestmentOfferingAgent.saveOffering(input);
    }

    processOfferingWorkflow = async (input: OfferingInitiate) => {
        return agent.InvestmentOfferingAgent.processOfferingWorkflow(input);
    }

    deleteOffering  = async (entityId: number, investmentOfferingId: number) => {
        return agent.InvestmentOfferingAgent.deleteOffering(entityId, investmentOfferingId);
    }

    getOfferingNotification = async (entityId: number, investmentId: number) => {
        return agent.InvestmentOfferingAgent.getOfferingNotification(entityId, investmentId);
    }

    saveOfferingNotificationSubscription = async (entityId: number, investmentId: number, subscribe: boolean) => {
        return agent.InvestmentOfferingAgent.saveOfferingNotificationSubscription(entityId, investmentId, subscribe);
    }
}
