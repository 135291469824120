import { FieldArray, Formik } from 'formik';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { DropdownItemProps, Button, Divider, Form, Grid, GridColumn, GridRow, Header, Segment, Message, Table, Dropdown, Label } from 'semantic-ui-react';
import SelectInput from '../../app/common/form/SelectInput';
import TextInput from '../../app/common/form/TextInput';
import { InputMasks } from '../../shared/InputMasks';
import * as yup from 'yup';
import { CommonRegularExpressions } from '../../shared/CommonRegularExpressions';
import { useStore } from '../../app/stores/store';
import { LoanFormValues, LoanPrePayment } from '../../app/models/Loan/Loan';
import CurrencyInput from '../../app/common/form/CurrencyInput';
import TextArea from '../../app/common/form/Textarea';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import DateInput from '../../app/common/form/DateInput';
import { observer } from 'mobx-react-lite';
import { LoanDrawSchedule } from '../../app/models/Loan/LoanDrawSchedule';
import { downloadFile, formatCurrency, formatDate, getFloatValue, getFullSizeWidth, getRandomNumber } from '../../shared/utils';
import { toast } from 'react-toastify';
import EntityContext from '../../app/context/entityContext';
import ConfirmButton from '../../shared/ConfirmButton';
import { Role } from '../../app/models/registration/entity';
import { useDebounce } from '../../shared/useDebounce';
import AmortizationView from './components/AmortizationView';
import EmptyGridMessage from '../../shared/EmptyGridMessage';
import { useMediaQuery } from 'react-responsive';
import NewLoanEscrowModal from './escrows/NewLoanEscrowModal';
import { RecordState, LoanEscrow } from '../../app/models/Loan/LoanEscrow';
import { LoanFee } from '../../app/models/Loan/LoanFee';
import NewLoanFeeModal from './Fees/NewLoanFeeModal';
import { FeeCalculationMethodEnum } from '../../app/models/Loan/LoanFeeEnums';



function LoanForm() {
    const commonRegex = CommonRegularExpressions;
    const emptyOption = { key: '' };
    const [stateOptions, setStateOptions] = useState([emptyOption]);
    const [entitiesOptions, setEntitiesOptions] = useState([emptyOption]);
    const { commonStore, entityStore, loanStore } = useStore();
    const [currentLoan, setCurrentLoan] = useState(new LoanFormValues());
    const [calculationMethodOptions, setCalculationMethodOptions] = useState([emptyOption]);
    const [loanTypeOptions, setLoanTypeOptions] = useState([emptyOption]);
    const [payPeriodOptions, setpayPeriodOptions] = useState([emptyOption]);
    const { entity } = useContext(EntityContext);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const [readOnly, setReadOnly] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [prePayment, setPrePayment] = useState<any>([]);
    const [loadingPrePayment, setLoadingPrePayment] = useState(false);

    const [initialLoanAmount, setInitialLoanAmount] = useState<number>(0);
    const debounceInitialLoanAmount = useDebounce<number>(initialLoanAmount, 250);

    const [totalDrawAmount, setTotalDrawAmount] = useState<number>(0);
    const debounceTotalDrawAmount = useDebounce<number>(totalDrawAmount, 250);

    const [startDate, setStartDate] = useState<number>(0);
    const debounceStartDate = useDebounce<number>(startDate, 250);

    const [paymentDate, setPaymentDate] = useState<number>(0);
    const debouncePaymentDate = useDebounce<number>(paymentDate, 250);

    const [loanTerm, setLoanTerm] = useState<number>(0);
    const debounceLoanTerm = useDebounce<number>(loanTerm, 250);

    const [amortizationTerm, setAmortizationTerm] = useState<number>(0);
    const debounceAmortizationTerm = useDebounce<number>(amortizationTerm, 250);

    const [loanType, setLoanType] = useState<number>(0);
    const debounceLoanType = useDebounce<number>(loanType, 100);

    const [interestRate, setInterestRate] = useState<number>(0);
    const debounceInterestRate = useDebounce<number>(interestRate, 250);

    const [calculationMethod, setCalculationMethod] = useState<number>(0);
    const debouncecalculationMethod = useDebounce<number>(calculationMethod, 100);

    const [payPeriod, setPayPeriod] = useState<number>(0);
    const debouncePayPeriod = useDebounce<number>(payPeriod, 100);

    const [formCopy, setFormCopy] = useState<LoanFormValues>(new LoanFormValues());
    const debounceFormCopy = useDebounce<LoanFormValues>(formCopy, 100);

    const formikRef = useRef<any>();

    const [showAmortizationTable, setShowAmortizationTable] = useState(false);

    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const [openEscrowModal, setOpenEscrowModal] = useState(false);
    const [loanEscrow, setLoanEscrow] = useState<LoanEscrow | null>();
    const [escrowIndex, setEscrowIndex] = useState(0);

    const [openFeeModal, setOpenFeeModal] = useState(false);
    const [loanFee, setLoanFee] = useState<LoanFee | null>();
    const [feeIndex, setFeeIndex] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadLWFS, setDownloadLWFS] = useState(false);

    const calculateTotalLoanAmount = useCallback((values: LoanFormValues) => {
        return getFloatValue(values.initialLoanAmount) + getTotalDrawAmount(values);
    }, [])

    const calculateTotalDrawAmount = useCallback((values: LoanFormValues) => {
        return getTotalDrawAmount(values);
    }, [])

    const cleanFormValues = useCallback((values: any) => {
        values.totalDrawAmount = getTotalDrawAmount(values);
        values.initialFundingAmount = calculateTotalLoanAmount(values);
        values.purchasePrice = getFloatValue(values.purchasePrice);
        values.initialLoanAmount = getFloatValue(values.initialLoanAmount);
        values.afterRepairValue = getFloatValue(values.afterRepairValue);
        values.annualInterestRate = getFloatValue(values.annualInterestRate);
        values.firstPaymentDate = new Date(values.firstPaymentDate);
        values.loanStartDate = new Date(values.loanStartDate);
        if (values.id === 0)
        {
            values.escrows = values.escrows.filter((f: LoanEscrow) => f.recordState !== RecordState.Deleted);
            values.fees = values.fees.filter((f: LoanFee) => f.recordState !== RecordState.Deleted);
        }
        values.draws = values.draws.map((item: LoanDrawSchedule, i: number) => ({
            ...item,
            drawAmount: getFloatValue(item.drawAmount),
            drawNumber: i + 1,
        }));

        values.fees = values.fees.map((item: LoanFee) => ({
            ...item,
            isPrePaid: true,
            amount: getFloatValue(item.calculationMethod === FeeCalculationMethodEnum.FlatAmount ? item.amount : values.initialLoanAmount * (item.percentage / 100))
        }));
    }, [calculateTotalLoanAmount])

    useEffect(() => {
        commonStore.getStates().then(r => setStateOptions(r));
        commonStore.getLoanEnums().then(r => {
            setCalculationMethodOptions(r.calculationMethod);
            setLoanTypeOptions(r.loanType);
            setpayPeriodOptions(r.payPeriod);
        });
        entityStore.getBorrowerEntities().then(r => {
            //Don't include deleted entities for new loans
            if (!id) r = r.filter((item) => !item.deleted);
            const options = JSON.stringify(r.map(s => ({ key: s.id.toString(), value: s.id, text: s.name, icon: s.entityType === 1 ? 'user' : 'building' })));
            setEntitiesOptions(JSON.parse(options));
        });

        if (id) {
            setLoading(true);
            loanStore.getLoan(Number(id)).then((r) => {
                if (r.loanStatus > 1) {
                    navigate('/loanview/' + id);
                }
                else {
                    setReadOnly(r.loanStatus > 1 || r.lenderEntityId !== entity.id);
                    setCanEdit(r.lenderEntityId === entity.id && r.loanStatus === 1)
                    setCurrentLoan(new LoanFormValues(r));
                    setLoading(false);
                    //Fire pre payment api call
                    setInitialLoanAmount(getRandomNumber);
                }
            });
        }
        else { //Show save button for new loans. Setting true here as the buttons were showing then hiding.
            var userRole = entity?.userRoles.filter(x => x === Role[Role.LoanCreation]);
            if (!userRole || userRole.length === 0) {
                //user has no access, so redirect to loan dashboard
                navigate('/loans');
            }
            setCanEdit(true);
        }

    }, [commonStore, entityStore, id, loanStore, entity, navigate])

    const validationSchema = yup.object({
        borrowerEntityId: yup
            .number()
            .min(1, "Borrower is required"),
        name: yup
            .string()
            .trim()
            .required('Name is required'),
        addressLine1: yup
            .string()
            .trim()
            .required('Address Line 1 is required'),
        city: yup
            .string()
            .trim()
            .required('City is required'),
        stateCode: yup
            .string()
            .trim()
            .required('State is required'),
        zip: yup
            .string()
            .trim()
            .required('Zip code is required')
            .matches(commonRegex.zip, 'Invalid zip code'),
        initialLoanAmount: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Initial Loan Amount is required')
            .positive('Amount must be greater than 0'),
        loanStartDate: yup
            .date()
            .typeError("Please enter a valid date")
            .required("Loan Start Date is required"),
        terms: yup
            .number()
            .required('Loan Term is required')
            .positive('Term must be greater than 0')
            .max(480, 'Term cannot be grater than 480'),
        loanType: yup
            .number()
            .min(1, 'Loan Type is required'),
        amortizationTerms: yup
            .number()
            .when('loanType', {
                is: 2,
                then: yup.number()
                    .transform((_value, originalValue) => { if (originalValue !== null) return parseInt(originalValue) })
                    .required('Amortization Term is required')
                    .positive('Amortization Term must be greater than 0')
                    .max(480, 'Amortization Term cannot be grater than 480')
                    .test(
                        'is-greater-or-equal',
                        'Amortization Term cannot be lower than Loan Term',
                        function (value) {
                            const loanTerm = parseInt(this.resolve(yup.ref('terms')));
                            const amortizationTerm = value!;
                            return amortizationTerm >= loanTerm;
                        }
                    ),
            })
            .nullable(),
        annualInterestRate: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Annual Interest Rate is required')
            .positive('Amount must be greater than 0'),
        calculationMethod: yup
            .number()
            .min(1, 'Calculation Method is required'),
        paymentPeriod: yup
            .number()
            .min(1, 'Payment Period is required'),
        firstPaymentDate: yup
            .date()
            .typeError("Please enter a valid date")
            .required("First Payment Date is required")
            .test(
                'is-greater',
                'First payment cannot be over 91 days of start date',
                function (value) {
                    const startDate = new Date(this.resolve(yup.ref('loanStartDate')));
                    const endDate = new Date(value!);
                    const diffInTime = endDate.getTime() - startDate.getTime();
                    const diffInDays = diffInTime / (1000 * 3600 * 24);
                    return diffInDays <= 91;
                }
            ),
        numberDraws: yup
            .number()
            .min(0)
            .max(5),
        draws: yup.array().of(
            yup.object().shape({
                drawAmount: yup.number()
                    .transform((_value, originalValue) => getFloatValue(originalValue))
                    .required('Draw Amount is required')
                    .positive('Amount must be greater than 0'),
            })
        ),
    });

    const drawOptions: DropdownItemProps[] = [
        { key: "0", value: 0, text: "0" },
        { key: "1", value: 1, text: "1" },
        { key: "2", value: 2, text: "2" },
        { key: "3", value: 3, text: "3" },
        { key: "4", value: 4, text: "4" },
        { key: "5", value: 5, text: "5" },
    ];

    function handleDrawFields(values: LoanFormValues, field: any) {
        const numberOfDraws = parseInt(field.value || '0');
        const previousDraws = values.draws.length;

        let draws = values.draws;

        if (previousDraws < numberOfDraws) {
            draws = [
                ...draws,
                ...Array.from({ length: numberOfDraws - previousDraws }, () => new LoanDrawSchedule())
            ];
        } else if (previousDraws > numberOfDraws) {
            draws = draws.slice(0, numberOfDraws);
        }

        values.numberDraws = numberOfDraws;
        values.draws = draws;
        setCurrentLoan(values);
        changeTotalDrawAmount();
    }

    const getTotalDrawAmount = (values: LoanFormValues) => {
        return values.draws.reduce((total, draw) => total + getFloatValue(draw.drawAmount ?? '0'), 0);
    }

    const handleFormSubmit = async (values: any, setFieldError: any, setSubmitting: any) => {
        values.lenderEntityId = entity.id;
        cleanFormValues(values);
        loanStore.save(new LoanFormValues(values))
            .then(loan => {
                if (loan.id && loan.id > 0) {
                    setCurrentLoan(() => ({...values, escrows: loan.escrows, fees: loan.fees }));
                    if (downloadLWFS)
                    {
                        setDownloadLWFS(false);
                        downloadLenderBreakdown();
                    }
                    else {
                        toast.success("Success!", { theme: "colored" });
                        navigate(`/loan/${loan.id}`);
                    }
                }
            })
            .catch(err => {
                toast.error("There was an issue creating the loan.", { theme: "colored" });
            })
            .finally(() => setSubmitting(false));
    }

    const handleConfirm = (loanId: number) => {
        loanStore.deleteLoan(loanId)
            .then(r => {
                toast.success("Loan Deleted!", { theme: "colored" });
                navigate('/loans');
            })
            .catch(err => {
                toast.error("There was an issue deleting the loan.", { theme: "colored" });
            });
    }

    const handleConfirmFinalize = (loanId: number) => {
        loanStore.finalizeLoan(loanId)
            .then(r => {
                toast.success("Loan Finalized!", { theme: "colored" });
                navigate('/loans');
            })
            .catch(err => {
                toast.error("There was an issue finalizing the loan.", { theme: "colored" });
            });
    }

    useEffect(() => {
        const formikContext = formikRef.current;
        formikContext.setFieldValue("initialFundingAmount", calculateTotalLoanAmount(formikContext.values));
    }, [debounceInitialLoanAmount, calculateTotalLoanAmount])

    useEffect(() => {
        const formikContext = formikRef.current;
        formikContext.setFieldValue("initialFundingAmount", formatCurrency(calculateTotalLoanAmount(formikContext.values)));
        formikContext.setFieldValue("totalDrawAmount", formatCurrency(calculateTotalDrawAmount(formikContext.values)));
    }, [debounceTotalDrawAmount, calculateTotalLoanAmount, calculateTotalDrawAmount])

    useEffect(() => {
        const values = formikRef.current.values;
        if (values.initialLoanAmount && values.loanStartDate && values.firstPaymentDate && values.annualInterestRate && values.calculationMethod && values.paymentPeriod && values.terms) {
            setPrePayment([]);

            cleanFormValues(values);

            const diffInTime = new Date(values.firstPaymentDate).getTime() - new Date(values.loanStartDate).getTime()
            const diffInDays = diffInTime / (1000 * 3600 * 24);
            if (diffInDays <= 91) {
                setLoadingPrePayment(true);
                let button = document.getElementById("scrollDiv");
                loanStore.calculatePrePayment(new LoanFormValues(values))
                    .then(r => {
                        setPrePayment(r);
                        //Only scroll for the first time showing the table.
                        if (button === null && !id) {
                            setTimeout(() => {
                                button = document.getElementById("scrollDiv");
                                if (button) window.scrollTo({ top: button!.offsetTop, behavior: 'smooth' })
                            }, 100);
                        }
                    })
                    .finally(() => {
                        setLoadingPrePayment(false);
                    });
            }

            setShowAmortizationTable(false);
            if (values.loanType === 2 && Number(values.amortizationTerms) >= Number(values.terms)) {
                setTimeout(() => {
                    setShowAmortizationTable(true);
                }, 250);
                setFormCopy(formikRef.current.values);
            }
        }
    }, [debounceInitialLoanAmount, debounceStartDate, debouncePaymentDate, debounceLoanTerm, debounceLoanType, debounceInterestRate, debouncePayPeriod, debouncecalculationMethod,
        cleanFormValues, id, loanStore, debounceFormCopy, setShowAmortizationTable, setFormCopy, debounceAmortizationTerm])

    const changeTotalDrawAmount = () => {
        setTotalDrawAmount(getRandomNumber);
    }

    const disableCalculationMethod = (values: LoanFormValues, d: any) => {
        if (d.value === 2) values.calculationMethod = 1;
    }

    const closeEscrowModal = (escrow: LoanEscrow | null) => {
        if (escrow !== null) {
            const values = formikRef.current.values;

            if (loanEscrow === null) {
                escrow.recordState = RecordState.Added;
                escrow.statusId = 2;
                setCurrentLoan(prevState => ({
                    ...values,
                    escrows: [...prevState.escrows, escrow]
                }));
            }
            else
            {
                setCurrentLoan(prevState => ({
                    ...prevState,
                    escrows: prevState.escrows.map((item, index) =>
                        index === escrowIndex ? {
                            ...item,
                            escrowTypeId: escrow.escrowTypeId,
                            escrowTypeName: escrow.escrowTypeName,
                            amount: escrow.amount,
                            startingBalance: escrow.startingBalance,
                            statusId: 2,
                            recordState: RecordState.Updated
                        } : item
                    )
                }));
            }
        }
        setLoanEscrow(null);
        setOpenEscrowModal(false);
    }

    const viewEscrow = (escrow: LoanEscrow, index: number) => {
        setLoanEscrow(escrow);
        setEscrowIndex(index);
        setOpenEscrowModal(true);
    }

    const deleteEscrow = (escrowIndex: number) => {
        setEscrowIndex(escrowIndex);
        document.getElementById("btnDeleteEscrow")?.click();
    }

    const confirmDeleteEscrow = () => {
        setCurrentLoan(prevState => ({
            ...prevState,
            escrows: prevState.escrows.map((item, index) =>
                index === escrowIndex ? {
                    ...item,
                    deleted: true,
                    recordState: RecordState.Deleted
                } : item
            )
        }));
    }

    const EscrowActions = ({escrow, index}: any) =>{
        return <>
            {escrow.recordState !== RecordState.Deleted &&
                <Dropdown text='Actions'>
                    <Dropdown.Menu>
                        <Dropdown.Item text='Edit' value='1' icon='edit' onClick={(e, d) => viewEscrow(escrow, index)} />
                        <Dropdown.Item text='Delete' value='2' icon='delete' onClick={(e, d) => deleteEscrow(index)} />
                    </Dropdown.Menu>
                </Dropdown>
            }
        </>;
    }


    const closeFeeModal = (fee: LoanFee | null) => {
        if (fee !== null) {
            const values = formikRef.current.values;

            if (loanFee === null) {
                fee.recordState = RecordState.Added;
                setCurrentLoan(prevState => ({
                    ...values,
                    fees: [...prevState.fees, fee]
                }));
            }
            else
            {
                setCurrentLoan(prevState => ({
                    ...prevState,
                    fees: prevState.fees.map((item, index) =>
                        index === feeIndex ? {
                            ...item,
                            feeType: fee.feeType,
                            feeDate: fee.feeDate,
                            feeTypeDescription: fee.feeTypeDescription,
                            calculationMethod: fee.calculationMethod,
                            calculationMethodDescription: fee.calculationMethodDescription,
                            amount: fee.amount,
                            percentage: fee.percentage,
                            statusId: 2,
                            recordState: RecordState.Updated
                        } : item
                    )
                }));
            }
        }
        setLoanFee(null);
        setOpenFeeModal(false);
    }

    const viewFee = (fee: LoanFee, index: number) => {
        setLoanFee(fee);
        setFeeIndex(index);
        setOpenFeeModal(true);
    }

    const deleteFee = (feeIndex: number) => {
        setFeeIndex(feeIndex);
        document.getElementById("btnDeleteFee")?.click();
    }

    const confirmDeleteFee = () => {
        setCurrentLoan(prevState => ({
            ...prevState,
            fees: prevState.fees.map((item, index) =>
                index === feeIndex ? {
                    ...item,
                    deleted: true,
                    recordState: RecordState.Deleted
                } : item
            )
        }));
    }

    const FeeActions = ({fee, index}: any) =>{
        return <>
            {fee.recordState !== RecordState.Deleted &&
                <Dropdown text='Actions'>
                    <Dropdown.Menu>
                        <Dropdown.Item text='Edit' value='1' icon='edit' onClick={(e, d) => viewFee(fee, index)} />
                        <Dropdown.Item text='Delete' value='2' icon='delete' onClick={(e, d) => deleteFee(index)} />
                    </Dropdown.Menu>
                </Dropdown>
            }
        </>;
    }

    const markAsDeleted = (text: any, state: RecordState) => {
        return state === RecordState.Deleted ? <s>{text}</s> : text;
    }

    const showFeeValue = (fee: LoanFee, values: any) => {
        let value = formatCurrency(fee.amount);
        if (fee.calculationMethod === FeeCalculationMethodEnum.Percentage) {
            value = showPercentage(fee, values);
        }
        return markAsDeleted(value, fee.recordState);
    }

    const showPercentage = (fee: LoanFee, values: any) => {
        let result = `${fee.percentage}%`;

        if (values.initialLoanAmount && values.initialLoanAmount > 0) {
            const calculatedFee = values.initialLoanAmount * (fee.percentage / 100);
            result += ` (${formatCurrency(calculatedFee)})`;
        }
        return result;
    }

    const downloadLenderBreakdown = () => {
        setIsDownloading(true);
        loanStore.downloadLenderBreakdown(Number(id))
        .then(response => {
            downloadFile(response);
        })
        .finally(() => setIsDownloading(false));
    }

    return (
        <Segment clearing loading={loading}>
            <Formik
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={currentLoan}
                onSubmit={(values, { setFieldError, setSubmitting }) => {
                    handleFormSubmit(values, setFieldError, setSubmitting);
                }}
                innerRef={formikRef}
            >
                {({ values, handleSubmit, isSubmitting }) => (
                    <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                        <Button as={NavLink} to='/Loans' floated='right' type='button' content='Back to Loans' className='cancelButton' />
                        <SelectInput options={entitiesOptions} placeholder='Borrower' name='borrowerEntityId' showRequired disabled={readOnly} />
                        <Divider horizontal section>
                            <Header as='h4'>
                                General Details
                            </Header>
                        </Divider>
                        <Form.Group widths='equal'>
                            <TextInput placeholder='Loan Name' name='name' maxLength={255} showRequired readOnly={readOnly} />
                            <TextArea placeholder='Description' name='description' rows={3} readOnly={readOnly} maxLength={512} />
                        </Form.Group>

                        <Form.Group widths='equal'>
                            <TextInput placeholder='Property Address' name='addressLine1' maxLength={100} showRequired readOnly={readOnly} />
                            <TextInput placeholder='Address Line 2' name='addressLine2' maxLength={100} readOnly={readOnly} />
                            <TextInput placeholder='City' name='city' maxLength={50} showRequired readOnly={readOnly} />
                            <SelectInput options={stateOptions} placeholder='State' name='stateCode' showRequired disabled={readOnly} />
                            <TextInput placeholder='Zip' name='zip' mask={InputMasks.zip} showRequired readOnly={readOnly} />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <CurrencyInput placeholder='Purchase Price' name='purchasePrice' thousandSeparator=',' decimalScale={2} allowNegative={false} maxLength={16} readOnly={readOnly} />
                            <CurrencyInput placeholder='Initial Loan Amount' name='initialLoanAmount' thousandSeparator=',' decimalScale={2} allowNegative={false} maxLength={16} showRequired readOnly={readOnly} onChange={() => setInitialLoanAmount(getRandomNumber)} />
                            <CurrencyInput placeholder='ARV' name='afterRepairValue' thousandSeparator=',' decimalScale={2} allowNegative={false} maxLength={16} readOnly={readOnly} />
                        </Form.Group>
                        <Divider horizontal section>
                            <Header as='h4'>
                                Terms
                            </Header>
                        </Divider>
                        <Form.Group widths='equal'>
                            <DateInput placeholder='Loan Start Date' name='loanStartDate' mask={InputMasks.date} showRequired readOnly={readOnly} onChange={() => setStartDate(getRandomNumber)} />
                            <DateInput placeholder='First Payment Date' name='firstPaymentDate' mask={InputMasks.date} showRequired readOnly={readOnly} showMonthYearPicker dateFormat="MM/01/yyyy" restrictToday onChange={() => setPaymentDate(getRandomNumber)} />
                            <CurrencyInput placeholder='Loan Term (Months)' name='terms' allowNegative={false} maxLength={3} showRequired readOnly={readOnly} onChange={() => setLoanTerm(getRandomNumber)} />
                            {values.loanType === 2 && <CurrencyInput placeholder='Amortization Term (Months)' name='amortizationTerms' allowNegative={false} maxLength={3} showRequired readOnly={readOnly} onChange={() => setAmortizationTerm(getRandomNumber)} />}
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <SelectInput options={loanTypeOptions} placeholder='Loan Type' name='loanType' showRequired disabled={readOnly} onChange={(e, d) => { setLoanType(getRandomNumber); disableCalculationMethod(values, d) }} />
                            <CurrencyInput placeholder='Anual Interest Rate' name='annualInterestRate' thousandSeparator=',' decimalScale={2} allowNegative={false} maxLength={8} showRequired readOnly={readOnly} onChange={() => setInterestRate(getRandomNumber)} />
                            <SelectInput options={calculationMethodOptions} placeholder='Calculation Method' name='calculationMethod' showRequired disabled={readOnly || values.loanType === 2} onChange={() => setCalculationMethod(getRandomNumber)} />
                            <SelectInput options={payPeriodOptions} placeholder='Pay Period' name='paymentPeriod' showRequired disabled={readOnly} onChange={() => setPayPeriod(getRandomNumber)} />
                        </Form.Group>

                        {prePayment && prePayment.length > 0 && <Segment textAlign='center' basic loading={loadingPrePayment}>
                            <Message compact>
                                Pre-Payment is required to support this transaction schedule. The below statement(s) will be generated at loan activation.
                            </Message>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={3} />
                                    <Grid.Column width={10}>
                                        <Table compact>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Date Range</Table.HeaderCell>
                                                    <Table.HeaderCell>Amount</Table.HeaderCell>
                                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {prePayment.map((prePayment: LoanPrePayment, i: number) => (
                                                    <Table.Row key={i}>
                                                        <Table.Cell>{formatDate(prePayment.startDate)} - {formatDate(prePayment.endDate)}</Table.Cell>
                                                        <Table.Cell>{formatCurrency(prePayment.amount)}</Table.Cell>
                                                        <Table.Cell>{prePayment.description}</Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                    <Grid.Column width={3} />
                                </Grid.Row>
                            </Grid>
                            <div id='scrollDiv'></div>
                        </Segment>
                        }
                        {values.loanType === 2 && showAmortizationTable &&
                            <AmortizationView values={formCopy} />
                        }

                        {values.loanType <= 1 &&
                            <>
                                <Divider horizontal section>
                                    <Header as='h4'>
                                        Draws
                                    </Header>
                                </Divider>
                                <Form.Group widths='equal'>
                                    <CurrencyInput placeholder='Total Draw Amount' name='totalDrawAmount' thousandSeparator=',' decimalScale={2} allowNegative={false} maxLength={16} className='readOnlyField' readOnly />
                                    <CurrencyInput placeholder='Total Loan Amount' name='initialFundingAmount' thousandSeparator=',' decimalScale={2} allowNegative={false} maxLength={16} className='readOnlyField' readOnly />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <SelectInput options={drawOptions} placeholder='Number of Draws' name='numberDraws' onChange={(e, d) => handleDrawFields(values, d)} disabled={readOnly} />
                                    {values.draws.length > 0 &&
                                        <Grid celled='internally' verticalAlign='top'>
                                            <GridRow>
                                                <GridColumn width={5} className='drawFieldsTitle'>
                                                    <label><b>Amount per Draw</b></label>
                                                </GridColumn>
                                                <GridColumn width={5} className='drawFields'>
                                                    <FieldArray
                                                        name="draws"
                                                        render={arrayHelpers => (
                                                            <>
                                                                {values.draws.map((item, i) => (
                                                                    <CurrencyInput key={i} placeholder={`${i + 1}${i === 0 ? 'st' : i === 1 ? 'nd' : i === 2 ? 'rd' : 'th'}`} name={`draws[${i}].drawAmount`} thousandSeparator=',' decimalScale={2} allowNegative={false} maxLength={12} showRequired readOnly={readOnly} onChange={changeTotalDrawAmount} />
                                                                ))}
                                                            </>
                                                        )}
                                                    />
                                                </GridColumn>
                                            </GridRow>
                                        </Grid>
                                    }
                                </Form.Group>
                            </>
                        }

                        <Divider horizontal section>
                            <Header as='h4'>
                                Escrows
                            </Header>
                        </Divider>

                        <Segment clearing basic style={{padding: 0, marginTop: "-20px"}}>
                            <Button primary content="Add Escrow Item" floated='right' type='button' onClick={() => { setLoanEscrow(null); setOpenEscrowModal(true) }} />
                        </Segment>

                        <Grid columns={3}>
                            <GridRow>
                                <Grid.Column width={3} />
                                <Grid.Column width={10}>
                                    <Table singleLine sortable selectable>
                                        {showFullSize &&
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Type</Table.HeaderCell>
                                                    <Table.HeaderCell>Amount</Table.HeaderCell>
                                                    <Table.HeaderCell>Starting Balance</Table.HeaderCell>
                                                    <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                        }

                                        <Table.Body>
                                            {!loading && values.escrows.map((escrow, i) => {
                                                if (showFullSize) {
                                                    return <Table.Row key={i}>
                                                        <Table.Cell>{markAsDeleted(escrow.escrowTypeName, escrow.recordState)}</Table.Cell>
                                                        <Table.Cell textAlign='right'>{markAsDeleted(formatCurrency(escrow.amount), escrow.recordState)}</Table.Cell>
                                                        <Table.Cell textAlign='right'>{markAsDeleted(formatCurrency(escrow.startingBalance), escrow.recordState)}</Table.Cell>
                                                        <Table.Cell>
                                                            {<EscrowActions escrow={escrow} index={i} />}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                }
                                                else {
                                                    return <Table.Row key={i}>
                                                        <Table.Cell>
                                                            <Grid>
                                                                <Grid.Row style={{ cursor: 'pointer' }}>
                                                                    <Grid.Column width={6}>
                                                                        <label>Type:</label><br />
                                                                        <label>Amount:</label><br />
                                                                        <label>Starting Balance:</label><br />
                                                                        <label>Actions:</label><br />
                                                                    </Grid.Column>
                                                                    <Grid.Column style={{ fontWeight: 'normal' }} >
                                                                        <div style={{ width: '45vw' }}>
                                                                            {markAsDeleted(escrow.escrowTypeName, escrow.recordState)}<br />
                                                                            {markAsDeleted(formatCurrency(escrow.amount), escrow.recordState)}<br />
                                                                            {markAsDeleted(formatCurrency(escrow.startingBalance), escrow.recordState)}<br />
                                                                            {<EscrowActions escrow={escrow} index={i}/>}
                                                                        </div>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                }
                                            })}
                                            {!loading && values.escrows.length === 0 ?
                                                <EmptyGridMessage colSpan={showFullSize ? 4 : 1} message='No escrow available' />
                                            :
                                                <Table.Row>
                                                    <Table.Cell colSpan={4} textAlign='right'>
                                                        <Label size='small' content="Changes will be applied when saving the loan." />
                                                    </Table.Cell>
                                                </Table.Row>
                                            }
                                        </Table.Body>
                                    </Table>
                                </Grid.Column>
                                <Grid.Column width={3} />
                            </GridRow>
                        </Grid>


                        <Divider horizontal section>
                            <Header as='h4'>
                                Fees
                            </Header>
                        </Divider>

                        <Segment clearing basic style={{padding: 0, marginTop: "-20px"}}>
                            <Button primary content="Add Fee" floated='right' type='button' onClick={() => { setLoanFee(null); setOpenFeeModal(true) }} />
                        </Segment>
                        <Grid columns={3}>
                            <GridRow>
                                <Grid.Column width={3} />
                                <Grid.Column width={10}>
                                    <Table singleLine sortable selectable>
                                        {showFullSize &&
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Date</Table.HeaderCell>
                                                    <Table.HeaderCell>Type</Table.HeaderCell>
                                                    <Table.HeaderCell>Calculation Method</Table.HeaderCell>
                                                    <Table.HeaderCell>Amount/Percentage</Table.HeaderCell>
                                                    <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                        }

                                        <Table.Body>
                                            {!loading && values.fees.map((fee, i) => {
                                                if (showFullSize) {
                                                    return <Table.Row key={i}>
                                                        <Table.Cell>{markAsDeleted(formatDate(fee.feeDate), fee.recordState)}</Table.Cell>
                                                        <Table.Cell>{markAsDeleted(fee.feeTypeDescription, fee.recordState)}</Table.Cell>
                                                        <Table.Cell>{markAsDeleted(fee.calculationMethodDescription, fee.recordState)}</Table.Cell>
                                                        <Table.Cell textAlign='right'>
                                                            {showFeeValue(fee, values)}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {<FeeActions fee={fee} index={i} />}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                }
                                                else {
                                                    return <Table.Row key={i}>
                                                        <Table.Cell>
                                                            <Grid>
                                                                <Grid.Row style={{ cursor: 'pointer' }}>
                                                                    <Grid.Column width={6}>
                                                                        <label>Date:</label><br />
                                                                        <label>Type:</label><br />
                                                                        <label>Calculation Method:</label><br />
                                                                        <label>Amount/Percentage:</label><br />
                                                                        <label>Actions:</label><br />
                                                                    </Grid.Column>
                                                                    <Grid.Column style={{ fontWeight: 'normal' }} >
                                                                        <div style={{ width: '45vw' }}>
                                                                            {markAsDeleted(formatDate(fee.feeDate), fee.recordState)}<br />
                                                                            {markAsDeleted(fee.feeTypeDescription, fee.recordState)}<br />
                                                                            {markAsDeleted(fee.calculationMethodDescription, fee.recordState)}<br />
                                                                            {showFeeValue(fee, values)}<br />
                                                                            {<FeeActions fee={fee} index={i}/>}
                                                                        </div>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                }
                                            })}
                                            {!loading && values.fees.length === 0 ?
                                                <EmptyGridMessage colSpan={showFullSize ? 5 : 1} message='No fee available' />
                                            :
                                                <Table.Row>
                                                    <Table.Cell colSpan={5} textAlign='right'>
                                                        <Label size='small' content="Changes will be applied when saving the loan." />
                                                    </Table.Cell>
                                                </Table.Row>
                                            }
                                        </Table.Body>
                                    </Table>
                                </Grid.Column>
                                <Grid.Column width={3} />
                            </GridRow>
                        </Grid>

                        <br />

                        {canEdit && id && <ConfirmButton id={currentLoan.id} color='teal' floated='right' value='Finalize' content='Finalize Loan?' handleConfirm={handleConfirmFinalize} />}
                        {canEdit && id && <Button disabled={isSubmitting || isDownloading} loading={isSubmitting || isDownloading} floated='right' type='submit' primary content='Generate LWFS' onClick={() => setDownloadLWFS(true)} />}
                        {canEdit && <Button disabled={isSubmitting} loading={isSubmitting} floated='right' primary type='submit' content='Save' />}
                        {canEdit && id && <ConfirmButton id={currentLoan.id} value='Delete' content='Delete Loan?' handleConfirm={handleConfirm} />}
                        {openEscrowModal && <NewLoanEscrowModal show={openEscrowModal} onCancel={closeEscrowModal} loanEscrow={loanEscrow} />}
                        {openFeeModal && <NewLoanFeeModal show={openFeeModal} onCancel={closeFeeModal} loanFee={loanFee} />}

                        <ConfirmButton id={1} buttonId='btnDeleteEscrow' value='Delete Escrow' content='Mark escrow as deleted?' handleConfirm={confirmDeleteEscrow} hideButton />
                        <ConfirmButton id={2} buttonId='btnDeleteFee' value='Delete Fee' content='Mark fee as deleted?' handleConfirm={confirmDeleteFee} hideButton />

                    </Form>
                )}
            </Formik>
        </Segment>
    )
}

export default observer(LoanForm)