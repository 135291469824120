import React, { Fragment } from 'react'
import { Segment, Header, Message, Divider, Grid, GridColumn } from 'semantic-ui-react'
import { ShareClassification } from '../../../../../app/models/Investment/ShareClassification';
import { getPreferredReturnTypeAcronym } from '../../../../../shared/utils';

interface Props {
    shares: ShareClassification[];
}

function ShareHurdleSummary({ shares }: Props) {
    const hasMezzanine = shares.some(share => share.shareType === 3);
    const gpShare = shares.find(share => share.shareType === 2);
    const lpShares = shares.filter(share => share.shareType === 1);
    const mezzanineShares = shares.filter(share => share.shareType === 3);
    const totalRaise = lpShares.reduce((total, share) => total + Number(share.capitalRaised), 0);

    return (
        <Segment clearing style={{ padding: '0px 0px 0px 0px !important' }}>

            <Header as='h3'>Standard Distributions</Header>

            {hasMezzanine && mezzanineShares.map((mez, i) => {
                return <Fragment key={i}>
                    <Header as='h3'>First, distribute to Mezzanine shareholders</Header>
                    <Message color='blue'>
                        <p style={{ textAlign: 'center' }}>
                            Distribute 100% to <b>{mez.shareClass}</b> until <b>{mez.returnPercentage}% {getPreferredReturnTypeAcronym(mez.preferredReturnType!)}</b> has achieved.
                        </p>
                    </Message>
                    <Divider />
                </Fragment>
            })}

            <Header as='h3'>
                {hasMezzanine ? 'Then' : 'First'}, distribute remaining distribution amount to LP shareholders
            </Header>

            <Grid columns={'equal'}>
                {lpShares.map((share, i) => {
                    const sharePercentage = Math.round((Number(share.capitalRaised) / totalRaise) * 100);

                    return <GridColumn key={i}>
                        <table width='100%'>
                            <tbody>
                                <tr>
                                    <td>
                                        <Header as='h4'>
                                            Distribute {sharePercentage}% of remaining amount to <b>{share.shareClass}</b> shareholders
                                        </Header>
                                        <Segment>
                                            {share.hurdles?.map((hurdle, hurdleIndex) => (
                                                <Fragment key={hurdleIndex}>
                                                    <Message color="blue">
                                                        <p style={{ textAlign: 'center' }}>
                                                            Distribute <b>{hurdle.initialPercentage}%</b>{' '}
                                                            to <b>{share.shareClass}</b>{' '}
                                                            and <b>{hurdle.finalPercentage}%</b>{' '}
                                                            to <b>{gpShare?.shareClass}</b>

                                                            {hurdle.finalHurdle ? '.'
                                                                :
                                                                <>
                                                                    {' '} until <b>{share.shareClass}</b>{' '}
                                                                    has achieved <b>{hurdle.limitPercentage}% {getPreferredReturnTypeAcronym(hurdle.preferredReturnType)}</b>.
                                                                </>
                                                            }
                                                        </p>
                                                    </Message>
                                                    {!hurdle.finalHurdle && <><Divider />Then,</>}
                                                </Fragment>
                                            ))}
                                        </Segment>
                                    </td>
                                    {lpShares.length > 0 && (i < (lpShares.length - 1)) &&
                                        <td>
                                            <b>And,</b>
                                        </td>
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </GridColumn>
                })}
            </Grid>
        </Segment >
    )
}

export default ShareHurdleSummary