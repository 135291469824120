import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { UserCryptoAddress } from "../models/settings/userCryptoAddress";
import { EntityFilterParams } from "../models/common/entityFilterParams";

export default class UserCryptoAddressStore {
    constructor() {
        makeAutoObservable(this)
    }

    getUserCryptoAddresses = async (params: EntityFilterParams) => {
        return agent.UsersCryptoAddress.getUserAddresses(params);
    }

    getTargetEntityAddress = async (entityId: number, targetEntityId: number) => {
        return agent.UsersCryptoAddress.getTargetEntityAddress(entityId, targetEntityId);
    }

    saveUserCryptoAddress = async (userCryptoAddress: UserCryptoAddress) => {
        return await agent.UsersCryptoAddress.save(userCryptoAddress);
    }

    deleteUserCryptoAddress = async (userCryptoAddressId: number) => {
        return await agent.UsersCryptoAddress.delete(userCryptoAddressId);
    }
}
