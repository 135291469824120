import React, { useContext, useEffect, useState } from 'react'
import { Checkbox, Grid, Header, Icon, Segment, Table } from 'semantic-ui-react'
import EmptyGridMessage from '../../../shared/EmptyGridMessage'
import GridFooter from '../../../shared/GridFooter'
import { useStore } from '../../../app/stores/store';
import EntityContext from '../../../app/context/entityContext';
import { useMediaQuery } from 'react-responsive';
import { createGridInitialState, formatCurrency, formatDate, formatFullDate, getFullSizeWidth, handleGridNavigation, handleGridSort } from '../../../shared/utils';
import { PageParams } from '../../../app/models/common/PagedResult';
import { ShareHolderTransaction } from '../../../app/models/Investment/ShareHolderTransaction';
import { InvestmentQueryFilter } from '../../../app/models/common/InvestmentQueryFilter';
import { Investment } from '../../../app/models/Investment/Investment';

interface Props {
    investment: Investment;
}

function ShareTransactionsGrid({ investment }: Props) {
    const [loading, setLoading] = useState(true);
    const { investmentStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [transactions, setInvestments] = useState([new ShareHolderTransaction()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` });
    const [showAll, setShowAll] = useState(investment.sponsorEntityId === entity.id);

    const initialState: InvestmentQueryFilter = createGridInitialState({
        entityId: entity.id,
        investmentId: investment.id,
        showAll: showAll,
        sortIndex: 'TransactionDate',
        sortOrder: 'descending'
    });
    const [params, setParams] = useState(initialState);

    useEffect(() => {
        setLoading(true);
        params.showAll = showAll;
        investmentStore.getShareTransactions(params).then(r => {
            setTotalRecords(r.totalRecords);
            setInvestments(r.data);
            setLoading(false);
        });
    }, [investmentStore, params, showAll]);


    const handleSort = (clickedColumn: string) => {
        if (transactions.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    if (totalRecords === 0) return(<></>);

    return (
         <Segment clearing loading={loading}>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column width={showFullSize ? 10 : 6} verticalAlign='middle'>
                        <Header>{showAll === true ? "All Transactions" : "My Transactions"}</Header>
                    </Grid.Column>
                    <Grid.Column textAlign='right' verticalAlign='middle' width={showFullSize ? 6 : 10}>
                        {investment.sponsorEntityId !== entity.id &&
                            <Checkbox toggle label="Show All" style={{fontWeight: 'bold'}}
                                defaultChecked={showAll}
                                onChange={(e, { checked }) => setShowAll(checked ?? false)} />
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Table singleLine sortable selectable>
                {showFullSize &&
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={() => handleSort("TransactionDate")} sorted={params.sortIndex === "TransactionDate" && transactions.length > 0 ? params.sortOrder : undefined}>Date</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("InvestorName")} sorted={params.sortIndex === "InvestorName" && transactions.length > 0 ? params.sortOrder : undefined}>Investor</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Action")} sorted={params.sortIndex === "Action" ? params.sortOrder : undefined}>Action</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("ShareClass")} sorted={params.sortIndex === "ShareClass" ? params.sortOrder : undefined}>Class</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("ShareCount")} sorted={params.sortIndex === "ShareCount" ? params.sortOrder : undefined}>Quantity</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Amount")} sorted={params.sortIndex === "Amount" ? params.sortOrder : undefined}>Amount</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                }

                <Table.Body>
                    {!loading && transactions.map((transaction, i) => {
                        if (showFullSize) {
                            return <Table.Row key={i} >
                                <Table.Cell>{formatFullDate(transaction.transactionDate)}</Table.Cell>
                                <Table.Cell>{transaction.investorName}</Table.Cell>
                                <Table.Cell textAlign='center'>{transaction.action}</Table.Cell>
                                <Table.Cell textAlign='center'>{transaction.shareClass}</Table.Cell>
                                <Table.Cell textAlign='right'>{transaction.shareCount}</Table.Cell>
                                <Table.Cell textAlign='right'>{formatCurrency(transaction.amount)}</Table.Cell>
                            </Table.Row>
                        }
                        else {
                            return <Table.Row key={i}>
                                <Table.Cell>
                                    {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={6}>
                                                <label onClick={() => handleSort("TransactionDate")}>Date:</label><br />
                                                <label onClick={() => handleSort("InvestorName")}>Investor:</label><br />
                                                <label onClick={() => handleSort("Action")}>Action:</label><br />
                                                <label onClick={() => handleSort("ShareClass")}>Class:</label><br />
                                                <label onClick={() => handleSort("ShareCount")}>Quantity:</label><br />
                                                <label onClick={() => handleSort("Amount")}>Amount:</label><br />
                                            </Grid.Column>
                                            <Grid.Column style={{ fontWeight: 'normal' }} >
                                                <div style={{ width: '45vw' }}>
                                                    {formatDate(transaction.transactionDate)}<br />
                                                    {transaction.investorName}<br />
                                                    {transaction.action}<br />
                                                    {transaction.shareClass} <br />
                                                    {transaction.shareCount} <br />
                                                    {formatCurrency(transaction.amount)}<br />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                        }
                    })}
                    {!loading && transactions.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 6 : 1} message='You do not hold any share transactions on CapBlock' />}
                </Table.Body>
                {!loading && transactions.length > 0 &&
                    <GridFooter colSpan={showFullSize ? 6 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                }
            </Table>
        </Segment>
    )
}

export default ShareTransactionsGrid