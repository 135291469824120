import { useContext, useEffect, useState } from 'react'
import { Grid, Header, Icon, Message, Segment, Table } from 'semantic-ui-react'
import { Investment } from '../../../app/models/Investment/Investment';
import { useStore } from '../../../app/stores/store';
import { Offering } from '../../../app/models/Investment/InvestmentOffering';
import GridFooter from '../../../shared/GridFooter';
import { useMediaQuery } from 'react-responsive';
import { createGridInitialState, formatCurrency, formatDate, getFullSizeWidth, handleGridNavigation, handleGridSort } from '../../../shared/utils';
import { PageParams } from '../../../app/models/common/PagedResult';
import EmptyGridMessage from '../../../shared/EmptyGridMessage';
import { InvestmentQueryFilter } from '../../../app/models/common/InvestmentQueryFilter';
import EntityContext from '../../../app/context/entityContext';

interface Props {
    investment: Investment;
    reloadPendingApprovalOfferingsGrid: number;
    handleOfferingModal: (id: number) => void;
}

function PendingApprovalOfferingGrid({ investment, reloadPendingApprovalOfferingsGrid, handleOfferingModal}: Props) {

    const [loading, setLoading] = useState(true);
    const { entity } = useContext(EntityContext);
    const [showWarning, setShowWarning] = useState(false);
    const { investmentOfferingStore } = useStore();
    const [pendingOfferings, setPendingOfferings] = useState([new Offering()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })

    const initialState: InvestmentQueryFilter = createGridInitialState({
        entityId: entity.id,
        investmentId: investment.id,
        showAll: false,
        sortIndex: 'Created',
        sortOrder: 'ascending'
    });

    const [params, setParams] = useState(initialState);

    useEffect(() => {
        investmentOfferingStore.getPendingApprovalOfferings(params).then(r => {
            setTotalRecords(r.totalRecords);
            setPendingOfferings(r.data);
            if (r && r.data.length > 0) {
                setShowWarning(true);
            }
            setLoading(false);
        });
    }, [investmentOfferingStore, params, setLoading, reloadPendingApprovalOfferingsGrid]);

    const handleSort = (clickedColumn: string) => {
        if (pendingOfferings.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    const viewOffering = (id: number) => {
        handleOfferingModal(id);
    }

    return (
        <> {pendingOfferings?.length > 0 &&
            <>
                {showWarning && <Message color={'yellow'} onDismiss={() => setShowWarning(false)}
                    header='Action Required!' content='You have one or more actions or followup required' />}
                <Segment clearing loading={loading}>
                    <Header>Offerings with Pending Approval</Header>
                    <Table sortable>
                        {showFullSize &&
                            <Table.Header>
                                <Table.Row>
                                <Table.HeaderCell textAlign='left' onClick={() => handleSort("OfferingEntityName")} sorted={params.sortIndex === "Created" && pendingOfferings.length > 0 ? params.sortOrder : undefined}>Created On</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='left' onClick={() => handleSort("OfferingEntityName")} sorted={params.sortIndex === "OfferingEntityName" && pendingOfferings.length > 0 ? params.sortOrder : undefined}>Name</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' onClick={() => handleSort("SharePrice")} sorted={params.sortIndex === "SharePrice" ? params.sortOrder : undefined}>Price</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' onClick={() => handleSort("ShareCount")} sorted={params.sortIndex === "ShareCount" ? params.sortOrder : undefined}>Quantity</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' onClick={() => handleSort("Action")} sorted={params.sortIndex === "Action" ? params.sortOrder : undefined}>Offer</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' onClick={() => handleSort("OfferingExpiryDate")} sorted={params.sortIndex === "OfferingExpiryDate" ? params.sortOrder : undefined}>Expiry</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' onClick={() => handleSort("Status")} sorted={params.sortIndex === "Status" ? params.sortOrder : undefined}>Status</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                        }
                        <Table.Body>
                            {!loading && pendingOfferings.map((pendingOffering, i) => {
                                if (showFullSize) {
                                    return <Table.Row key={i} style={{ cursor: 'pointer' }} onClick={() => viewOffering(pendingOffering.id)}>
                                        <Table.Cell>{formatDate(pendingOffering.created)}</Table.Cell>
                                        <Table.Cell>{pendingOffering.offeringEntityName}</Table.Cell>
                                        <Table.Cell textAlign='right'>{formatCurrency(pendingOffering.sharePrice)}</Table.Cell>
                                        <Table.Cell textAlign='right'>{pendingOffering.shareCount}</Table.Cell>
                                        <Table.Cell textAlign='center'>{pendingOffering.action}</Table.Cell>
                                        <Table.Cell textAlign='center'>{formatDate(pendingOffering.expiryDate)}</Table.Cell>
                                        <Table.Cell textAlign='center'>
                                            {pendingOffering.offeringStatusDesc}
                                            {pendingOffering.actionMsg && <br />}
                                            {pendingOffering.actionMsg}
                                        </Table.Cell>
                                    </Table.Row>
                                }
                                else {
                                    return <Table.Row key={i}>
                                        <Table.Cell>
                                            {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column width={7}>
                                                        <label onClick={() => handleSort("Created")}>Created On:</label><br />
                                                        <label onClick={() => handleSort("OfferingEntityName")}>Name:</label><br />
                                                        <label onClick={() => handleSort("SharePrice")}>Price:</label><br />
                                                        <label onClick={() => handleSort("ShareCount")}>Quantity:</label><br />
                                                        <label onClick={() => handleSort("Action")}>Offer:</label><br />
                                                        <label onClick={() => handleSort("OfferingExpiryDate")}>Expiry:</label><br />
                                                        <label onClick={() => handleSort("Status")}>Status:</label><br />
                                                        <label></label><br />
                                                    </Grid.Column>
                                                    <Grid.Column style={{ fontWeight: 'normal' }} >
                                                        <div style={{ width: '45vw' }} onClick={() => viewOffering(pendingOffering.id)}>
                                                            {formatDate(pendingOffering.created)} <br/>
                                                            {pendingOffering.offeringEntityName}<br />
                                                            {formatCurrency(pendingOffering.sharePrice)}<br />
                                                            {pendingOffering.shareCount}<br />
                                                            {pendingOffering.action}<br />
                                                            {formatDate(pendingOffering.expiryDate)}<br />
                                                            {pendingOffering.offeringStatusDesc}<br />
                                                            {pendingOffering.actionMsg}<br />
                                                            {pendingOffering.offeringStatusDesc}
                                                            {pendingOffering.actionMsg && <br />}
                                                            {pendingOffering.actionMsg} <br />
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Table.Cell>
                                    </Table.Row>
                                }
                            })}
                            {!loading && pendingOfferings.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 7 : 1} message='No Pending Approval Offerings' />}
                        </Table.Body>
                        {!loading && pendingOfferings.length > 0 &&
                            <GridFooter colSpan={showFullSize ? 7 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                        }
                    </Table>
                </Segment>
            </>}</>
    )
}
export default PendingApprovalOfferingGrid;