import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { MonthlyStatementFilter } from "../models/MonthlyStatement/MonthlyStatementFilter";

export default class MonthlyStatementStore {

    constructor() {
        makeAutoObservable(this)
    }

    getMonthlyStatementDue = async (loanId: number) => {
        return agent.MonthlyStatementAgent.getMonthlyStatementDue(loanId);
    }

    downloadMonthlyStatement = async (loanId: number, statementId: number) => {
        return agent.MonthlyStatementAgent.downloadMonthlyStatement(loanId, statementId);
    }

    getStatements = async (params: MonthlyStatementFilter) => {
        return agent.MonthlyStatementAgent.getMonthlyStatements(params);
    }

    downloadMonthlyStatementJSON = async (loanId: number, statementId: number) => {
        return agent.MonthlyStatementAgent.downloadMonthlyStatementJSON(loanId, statementId);
    }

    deleteMonthlyStatement = async (entityId: number, loanId: number, statementId: number) => {
        return agent.MonthlyStatementAgent.deleteMonthlyStatement(entityId, loanId, statementId);
    }

    regenerateStatement = async (entityId: number, loanId: number, statementId: number) => {
        return agent.MonthlyStatementAgent.regenerateStatement(entityId, loanId, statementId);
    }

    generateNextStatement = async (entityId: number, loanId: number) => {
        return agent.MonthlyStatementAgent.generateNextStatement(entityId, loanId);
    }
}
