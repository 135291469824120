import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, Segment } from 'semantic-ui-react';
import EntityContext from '../../app/context/entityContext';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../app/stores/store';
import TextInput from '../../app/common/form/TextInput';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { WireXferProfile } from '../../app/models/wireXfer/wireXferProfile';
import { CommonRegularExpressions } from '../../shared/CommonRegularExpressions';


function WireXferProfilePanel()
{
    const { wireXferProfileStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [wireXferProfile, setWireXferProfile] = useState<WireXferProfile>(new WireXferProfile());

    useEffect(() => {
        wireXferProfileStore.getWireXferProfile(entity.id).then(result => {
            setWireXferProfile(result);
            setLoading(false);
        })
    }, [wireXferProfileStore, setLoading, entity]);

    const handleFormSubmit = (profile: WireXferProfile, setFieldError: any, setSubmitting: any) => {
        profile.entityId = entity.id;
        wireXferProfileStore.saveWireXferProfile(profile)
            .then(r => {
                toast.success("Wire transfer settings updated", { theme: "colored" });
                setWireXferProfile(profile);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    const validationSchema = yup.object({
        accountNumber: yup
            .string()
            .required('This field is required')
            .matches(CommonRegularExpressions.accountNumber, "Invalid account number"),
        routingNumber: yup
            .string()
            .required('This field is required')
            .matches(CommonRegularExpressions.routingNumber, "Invalid routing number"),
        });

      return (
        <Segment clearing loading={loading}>
            <Formik
                enableReinitialize
                initialValues={wireXferProfile}
                validationSchema={validationSchema}
                onSubmit={(values, { setFieldError, setSubmitting }) => {
                    return handleFormSubmit(values, setFieldError, setSubmitting);
                }} >
                {({ handleSubmit, isSubmitting, handleReset }) => (
                    <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                        <TextInput placeholder='Account number to receive payments' name='accountNumber' maxLength={256} />
                        <TextInput placeholder='Bank routing number to receive payments' name='routingNumber' maxLength={256} />

                        <Button
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            floated='right'
                            primary
                            type='submit'
                            content='Save'
                        />
                        <Button onClick={() => 
                            wireXferProfileStore.deleteZellProfile(entity.id)
                            .then(r => 
                                {setWireXferProfile(new WireXferProfile());                 
                                 toast.success("Wire transfer settings deleted", { theme: "colored" });})
                                                    
                        } floated='right' type='reset' content='Delete' disabled={isSubmitting || wireXferProfile.entityId === 0}/>
                        <Button onClick={() => navigate('/dashboard')} floated='right' type='button' content='Cancel' disabled={isSubmitting}/>
                    </Form>
                )}
            </Formik>
        </Segment>
  );
}

export default WireXferProfilePanel