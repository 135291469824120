export interface FileImportJobStatusDTO
{
    jobStatus:string,
    message:string
}

export class FileImportJobStatusDTO implements FileImportJobStatusDTO
{
    jobStatus:string = '';
    message:string = '';
    uploadedFileName:string = '';
    allowNewUpload:boolean = true;
    showJobStatusPanel:boolean = false;


    constructor(init?: FileImportJobStatusDTO)
    {
        Object.assign(this, init); 
    }
}