import { Modal, Button, Segment, Header } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { useEffect, useState } from "react";
import { ZelleAccountProfile } from "../../../app/models/zelle/zelleAccountProfile";

interface ZelleModalData {
    entityId: number;
    show: boolean;
    closeModal: () => void;
}

function ZellePaymentModal({ entityId, show, closeModal }: ZelleModalData) {
    const { zelleAccountStore } = useStore();
    const [zelleRecipient, setZelleRecipient] = useState(new ZelleAccountProfile());

    useEffect(() => {
        zelleAccountStore.getZelleProfile(entityId)
            .then(r => setZelleRecipient(r))
            .finally();
    }, [zelleAccountStore, entityId]);

    return (
        <Modal centered={false} open={show} closeOnEscape={false} closeOnDimmerClick={false} size="mini">
            <Modal.Header>Zelle Payment Recipient</Modal.Header>
            <Segment basic>
                <p>Use Zelle on your bank app to send payment to this recipient.</p>
                <Header as='h3'>
                    {zelleRecipient.recipientAccount}
                </Header>
            </Segment>
            <Modal.Actions>
                <Button onClick={() => closeModal()}>Cancel</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ZellePaymentModal