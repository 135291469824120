export interface FileImportJobUploadDTO
{
    entityId:number,
    sendWelcomeEmail:boolean,
    formFile:File
}

export class FileImportJobUploadDTO implements FileImportJobUploadDTO
{
    constructor(init?: FileImportJobUploadDTO)
    {
        Object.assign(this, init); 
    }
}