import { Modal, Button, Segment, Grid } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { useEffect, useState } from "react";
import { WireXferProfile } from "../../../app/models/wireXfer/wireXferProfile";

interface ZelleModalData {
    entityId: number;
    show: boolean;
    closeModal: () => void;
}

function ZellePaymentModal({ entityId, show, closeModal }: ZelleModalData) {
    const { wireXferProfileStore } = useStore();
    const [wireXferProfile, setWireXferProfile] = useState(new WireXferProfile());
    const containerStyle = {
        paddingTop: '0px',
        paddingBottom: '2px'
    };
    useEffect(() => {
        wireXferProfileStore.getWireXferProfile(entityId)
            .then(r => setWireXferProfile(r))
            .finally();
    }, [wireXferProfileStore, entityId]);

    return (
        <Modal centered={false} open={show} closeOnEscape={false} closeOnDimmerClick={false} size="mini">
            <Modal.Header>Wire Transfer Recipient Information</Modal.Header>
            <Segment basic>
                <p>Send wire transfer payments to this account.</p>
                <Grid.Row style={containerStyle}>
                    <Grid.Column as="h5">Account Number:</Grid.Column>
                    <Grid.Column>{wireXferProfile.accountNumber}</Grid.Column>
                </Grid.Row>
                <Grid.Row style={containerStyle}>
                    <Grid.Column as="h5">Routing Number:</Grid.Column>
                    <Grid.Column>{wireXferProfile.routingNumber}</Grid.Column>
                </Grid.Row>
            </Segment>
            <Modal.Actions>
                <Button onClick={() => closeModal()}>Cancel</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ZellePaymentModal