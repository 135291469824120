import { TabPane } from 'semantic-ui-react'
import ShareTransactionsGrid from './ShareTransactionsGrid';
import ShareClassificationsGrid from './ShareClassificationsGrid';
import { Investment } from '../../../app/models/Investment/Investment';

interface Props {
    investment: Investment;
}

function Shares({ investment }: Props) {
    return (
        <TabPane>
            <ShareTransactionsGrid investment={investment} />
            <ShareClassificationsGrid investment={investment} />
        </TabPane>
    )
}

export default Shares