import React, { useCallback, useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Header, Table } from 'semantic-ui-react'
import { Entity } from '../../app/models/registration/entity';
import { useStore } from '../../app/stores/store';
import ConfirmButton from '../../shared/ConfirmButton';
import { CacheNameEnum, formatPhoneNumber } from '../../shared/utils';

function EntityManagement() {
    const { entityStore } = useStore();
    const [entities, setEntities] = useState([new Entity()]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleConfirm = (entityId: number) => {
        entityStore.deleteEntity(entityId)
            .then(r => {
                sessionStorage.removeItem(CacheNameEnum.UserEntities);
                loadEntities();
            })
            .catch(err => {
                toast.error("There was an issue deleting the entity.", { theme: "colored" });
            });
    }

    const loadEntities = useCallback(() => {
        entityStore.getUserEntities().then(r => {
            setEntities(r);
            setIsLoading(false);
        });
    }, [entityStore]);

    useEffect(() => {
        loadEntities();
    }, [loadEntities]);

    const editEntity = (id: number) => {
        navigate(`/entity/${id}`, { state: { previousPath: pathname } });
    }

    return (
        <>
            <Header as="h2">
                Entity Management
                <Button as={NavLink} to='/entity' primary floated='right'>Add Entity</Button>
            </Header>
            <Table singleLine selectable compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Address</Table.HeaderCell>
                        <Table.HeaderCell>Cell Phone</Table.HeaderCell>
                        <Table.HeaderCell>Work Phone</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        {entities.length > 1 &&
                            <Table.HeaderCell></Table.HeaderCell>
                        }
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {!isLoading && entities.map((entity) => (
                        <Table.Row key={entity.id} style={{ cursor: 'pointer' }}>
                            <Table.Cell onClick={() => editEntity(entity.id)} >{entity.name}</Table.Cell>
                            <Table.Cell onClick={() => editEntity(entity.id)}>
                                {entity.addressLine1}{(entity.addressLine2 ? ', ' : '') + entity.addressLine2 === null ? '' : entity.addressLine2}
                                <br />{entity.city === null ? '' : entity.city + ','} {entity.stateCode} {entity.zip}
                            </Table.Cell>
                            <Table.Cell onClick={() => editEntity(entity.id)}>{formatPhoneNumber(entity.cellPhone)}</Table.Cell>
                            <Table.Cell onClick={() => editEntity(entity.id)}>{formatPhoneNumber(entity.workPhone)}</Table.Cell>
                            <Table.Cell onClick={() => editEntity(entity.id)}>{entity.entityType}</Table.Cell>
                            {entities.length > 1 &&
                                <Table.Cell textAlign='center'>
                                    <ConfirmButton
                                        id={entity.id}
                                        color='red' iconName='trash alternate' basic
                                        value=''
                                        content='Delete Entity?'
                                        confirmButtonText='Delete'
                                        handleConfirm={handleConfirm}
                                        compact
                                    />
                                </Table.Cell>
                            }
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Button as={NavLink} to='/switch' floated='right' type='button' content='Back' />
        </>
    )
}

export default EntityManagement