import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, Modal } from 'semantic-ui-react';
import { useStore } from '../../../app/stores/store';
import EntityContext from '../../../app/context/entityContext';
import SelectInput from '../../../app/common/form/SelectInput';
import { Formik } from 'formik';
import * as Yup from 'yup';

interface PaymentMethodProps {
  show: boolean;
  loanId: number,
  closeModal: (selectedPaymentMethod: string) => void;
}

function PaymentMethodModal({ show, loanId, closeModal }: PaymentMethodProps) {
  const { entity } = useContext(EntityContext);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const { paymentMethodStore } = useStore();

  const emptyOption = { key: '' };
  const [paymentOptions, setPaymentOptions] = useState([emptyOption]);

  useEffect(() => {
    paymentMethodStore.getAvaileblePaymentMethods(loanId).then((r) => {
      const options = JSON.stringify(r.map(s => ({ value: s.value, text: s.label })));
      setPaymentOptions(JSON.parse(options));
    });
  }, [paymentMethodStore, entity.id, loanId]);

  return (
    <Modal size="tiny" centered={false} open={show} closeOnEscape={false} closeOnDimmerClick={false}>
      <Modal.Header>Choose payment method</Modal.Header>
      <Modal.Content>
        <Formik enableReinitialize={true}
          validationSchema={Yup.object({
                action: Yup.string()
                .required('Payment Method is required')
          })}
          initialValues={{ 'action': '' }}
          onSubmit={() => { closeModal(selectedPaymentMethod) }}>
          {({ handleSubmit }) => (
            <Form id="paymentMethodForm" className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
              <SelectInput placeholder='Payment Method' name='action' options={paymentOptions} showRequired
                onChange={(e, d) => setSelectedPaymentMethod(d.value)} />
            </Form>
          )}
        </Formik>
      </Modal.Content>
      <Modal.Actions style={{ display: 'flex', justifyContent: 'center' }}>
        <Button content="Select" form="paymentMethodForm" type='submit' />
        <Button content="Cancel" onClick={(e) => { closeModal(''); }} />
      </Modal.Actions>
    </Modal>

  );
}

export default PaymentMethodModal